import React from "react";
import { motion } from "framer-motion";

// Placeholder images, replace with actual URLs or paths
const placeholderImage = "https://via.placeholder.com/300";

const sections = [
  {
    name: "Digital SAT 8 & 9",
    path: 14,
    xp: 75,
    progress: 0,
    image: './sat89.png',
    type: 'PSAT89',
    select: 'SAT89'
  },
  {
    name: "Digital MNSQT",
    path: 10,
    xp: 60,
    progress: 0,
    image: './sat10.png',
    type: 'NMSQT',
    select: 'SAT10'
  },
  {
    name: "Digital SAT",
    path: 1,
    xp: 30,
    progress: 0,
    image: './sat.png',
    type: 'SAT',
    select: 'SAT'
  },
  {
    name: "Digital ACT",
    path: 6,
    xp: 15,
    progress: 0,
    image: './act.png',
    type: 'ACT',
    select: 'ACT'
  },
  {
    name: "AP Calculus AB",
    path: 18,
    xp: 15,
    progress: 0,
    image: './calcab.png',
    type: 'APCALCAB',
    select: 'AP_Calc_AB'
  },
  {
    name: "AP Calculus BC",
    path: 22,
    xp: 15,
    progress: 0,
    image: './calcbc.png',
    type: 'APCALCBC',
    select: 'AP_Calc_BC'
  },
];

const MainDashboard = ({ setdashboard, setActiveIndex, setTestType, setSelectedTypes }) => {

  return (
    <div className="bg-gray-100 h-full p-8">
      {/* Header Section */}
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Select The Course</h1>
        <h2 className="text-xl text-gray-600">Dive into our comprehensive problem bank!</h2>
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {sections.map((section, index) => (
          <div
            key={index}
            className="mb-4 bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300"
            onClick={() => { setSelectedTypes([section.select]); setdashboard(section.path); setActiveIndex(section.path); setTestType(section.type); }}
          >
            {/* Image Section */}
            <div className="relative">
              <img
                src={section.image}
                alt={section.name}
                className="w-full h-40 object-cover"
              />
              <div className="absolute inset-0 bg-black opacity-30"></div>
              <div className="absolute bottom-0 left-0 p-4">
                <h3 className="text-lg font-semibold text-white">{section.name}</h3>
              </div>
            </div>

            {/* Special Popup for Digital SAT */}
            {section.type === "SAT" && (
              <motion.div
                className="absolute top-2 left-2 px-4 py-2 text-white text-sm font-bold rounded-lg"
                style={{
                  background: "linear-gradient(to right, #3b82f6, #9333ea)", // Blue to Purple
                  backgroundSize: "200% 200%", // 배경 크기 확장
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                }}
                animate={{
                  backgroundPosition: ["0% 50%", "100% 50%", "0% 50%"], // 배경 색상 움직임
                }}
                transition={{
                  duration: 6, // 애니메이션 주기
                  ease: "linear",
                  repeat: Infinity, // 무한 반복
                }}
              >
                ✨ AI Class Avaliable!
              </motion.div>
            )}

            {/* Progress and XP Section */}
            <div className="p-4">
              <div className="flex items-center justify-between mb-2">
                <div className="text-sm text-gray-600">Click to Start >></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainDashboard;
