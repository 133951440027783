import { useEffect, useState, useContext, useRef } from "react";
import { BASELINE } from "../../../util";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { toast } from "react-hot-toast";
import { AiOutlineCheck, AiOutlineInfoCircle } from 'react-icons/ai';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import InfoIconWithTooltip from '../InfoIconWithTooltip';
import { FaRandom } from "react-icons/fa";
import { motion } from "framer-motion";
import useAIAudio from "../../aivoice/aivoice";

const RandomAPCALCABReady = ({ setLoading }) => {

  const { user } = useContext(UserContext);
  const [selectedButton, setSelectedButton] = useState(null);
  const navigate = useNavigate();
  const [isTutorActive, setIsTutorActive] = useState(false);
  const [isTimedActive, setIsTimedActive] = useState(false);
  const [timerMinutes, setTimerMinutes] = useState(45); // Default time set to 45 minutes
  const [currentPassages, setCurrentPassages] = useState(0);
  const [min, setmin] = useState(0);

  const [totalq, settotalq] = useState([]);

  const [correct, setcorrect] = useState(0);
  const [incorrect, setincorrect] = useState(0);
  const [marked, setmarked] = useState(0);
  const [omitted, setomitted] = useState(0);
  const [mcqcount, setmcqcount] = useState([]);
  const [frqcount, setfrqcount] = useState([]);
  const [activeSegment, setActiveSegment] = useState('MCQ');  // Default to 'All'
  const [displaySubjects, setDisplaySubjects] = useState([]);
  const [total, settotal] = useState(0);
  const [aiplayed, setaiplayed] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalItems = 24;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate the buttons to display on the current page
  const currentButtons = Array.from(
    { length: itemsPerPage },
    (_, i) => (currentPage - 1) * itemsPerPage + i + 1
  ).filter((num) => num <= totalItems); // Ensure we don't exceed total items


  const [activeFilters, setActiveFilters] = useState({
    unused: false,
    incorrect: false,
    marked: false,
    omitted: false,
    correct: false
  });

  const [mcqsubjects, setmcqsubjects] = useState([
    {
      main: "Concept of a Limit", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U1_1', name: '1.1 Introducing Calculus: Can Change Occur at an Instant?', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_2', name: '1.2 Defining Limits and Using Limit Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_3', name: '1.3 Estimating Limit Values from Graphs', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_4', name: '1.4 Estimating Limit Values from Tables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_5', name: '1.5 Determining Limits Using Algebraic Properties of Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_6', name: '1.6 Determining Limits Using Algebraic Manipulation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_7', name: '1.7 Selecting Procedures for Determining Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_8', name: '1.8 Determining Limits Using the Squeeze Theorem', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_9', name: '1.9 Connecting Multiple Representations of Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Continuity", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U1_10', name: '1.10 Exploring Types of Discontinuities', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_11', name: '1.11 Defining Continuity at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_12', name: '1.12 Confirming Continuity over an Interval', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_13', name: '1.13 Removing Discontinuities', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Asymptotes & IVT", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U1_14', name: '1.14 Connecting Infinite Limits and Vertical Asymptotes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_15', name: '1.15 Connecting Limits at Infinity and Horizontal Asymptotes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_16', name: '1.16 Working with the Intermediate Value Theorem (IVT)', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Definition and Existence of Derivatives", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U2_1', name: '2.1 Defining Average and Instantaneous Rates of Change at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_2', name: '2.2 Defining the Derivative of a Function and Using Derivative Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_3', name: '2.3 Estimating Derivatives of a Function at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_4', name: '2.4 Connecting Differentiability and Continuity: Determining When Derivatives Do and Do Not Exist', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Calculating Derivatives", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U2_5', name: '2.5 Applying the Power Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_6', name: '2.6 Derivative Rules: Constant, Sum, Difference, and Constant Multiple', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_7', name: '2.7 Derivatives of \\cos x, \\sin x, \\mathrm, and \\ln x', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_8', name: '2.8 The Product Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_9', name: '2.9 The Quotient Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_10', name: '2.10 Finding the Derivatives of Tangent, Cotangent, Secant, and/or Cosecant Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Advanced Derivative Rules", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U3_1', name: '3.1 The Chain Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_2', name: '3.2 Implicit Differentiation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Inverse Functions and Higher-Order Derivatives", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U3_3', name: '3.3 Differentiating Inverse Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_4', name: '3.4 Differentiating Inverse Trigonometric Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_5', name: '3.5 Selecting Procedures for Calculating Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_6', name: '3.6 Calculating HigherOrder Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Derivatives in Context", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U4_1', name: '4.1 Interpreting the Meaning of the Derivative in Context', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_2', name: '4.2 Straight-Line Motion: Connecting Position, Velocity, and Acceleration', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_3', name: '4.3 Rates of Change in Applied Contexts Other Than Motion', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Related Rates", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U4_4', name: '4.4 Introduction to Related Rates', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_5', name: '4.5 Solving Related Rates Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Linearity and L'Hospital's Rule", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U4_6', name: '4.6 Approximating Values of a Function Using Local Linearity and Linearization', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_7', name: '4.7 Using LHospitals Rule for Determining Limits of Indeterminate Forms', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "MVT and EVT", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U5_1', name: '5.1 Using the Mean Value Theorem', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_2', name: '5.2 Extreme Value Theorem, Global Versus Local Extrema, and Critical Points', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Function Behavior", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U5_3', name: '5.3 Determining Intervals on Which a Function Is Increasing or Decreasing', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_4', name: '5.4 Using the First Derivative Test to Determine Relative (Local) Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_5', name: '5.5 Using the Candidates Test to Determine Absolute (Global) Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_6', name: '5.6 Determining Concavity of Functions over Their Domains', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_7', name: '5.7 Using the Second Derivative Test to Determine Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_8', name: '5.8 Sketching Graphs of Functions and Their Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_9', name: '5.9 Connecting a Function, Its First Derivative, and Its Second Derivative', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Optimization", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U5_10', name: '5.10 Introduction to Optimization Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_11', name: '5.11 Solving Optimization Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_12', name: '5.12 Exploring Behaviors of Implicit Relations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Concept of Integration", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U6_1', name: '6.1 Exploring Accumulations of Change', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_2', name: '6.2 Approximating Areas with Riemann Sums', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_3', name: '6.3 Riemann Sums, Summation Notation, and Definite Integral Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Fundamental Theorem of Calculus", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U6_4', name: '6.4 The Fundamental Theorem of Calculus and Accumulation Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_5', name: '6.5 Interpreting the Behavior of Accumulation Functions Involving Area', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_6', name: '6.6 Applying Properties of Definite Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_7', name: '6.7 The Fundamental Theorem of Calculus and Definite Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_8', name: '6.8 Finding Antiderivatives and Indefinite Integrals: Basic Rules and Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Integration Techniques", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U6_9', name: '6.9 Integrating Using Substitution', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_10', name: '6.10 Integrating Functions Using Long Division and Completing the Square', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_14', name: '6.14 Selecting Techniques for Antidifferentiation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Analyzing Differential Equations", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_1', name: '7.1 Modeling Situations with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_2', name: '7.2 Verifying Solutions for Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_3', name: '7.3 Sketching Slope Fields', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_4', name: '7.4 Reasoning Using Slope Fields', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Solving Differential Equations", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_6', name: '7.6 Finding General Solutions Using Separation of Variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_7', name: '7.7 Finding Particular Solutions Using Initial Conditions and Separation of Variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Definite Integrals in Context", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_1', name: '8.1 Finding the Average Value of a Function on an Interval', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_2', name: '8.2 Connecting Position, Velocity, and Acceleration of Functions Using Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_3', name: '8.3 Using Accumulation Functions and Definite Integrals in Applied Contexts', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Area Between Curves", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_4', name: '8.4 Finding the Area Between Curves Expressed as Functions of x', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_5', name: '8.5 Finding the Area Between Curves Expressed as Functions of y', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_6', name: '8.6 Finding the Area Between Curves That Intersect at More Than Two Points', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Volume", count: 0, isOpen: false, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_7', name: '8.7 Volumes with Cross Sections: Squares and Rectangles', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_8', name: '8.8 Volumes with Cross Sections: Triangles and Semicircles', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_9', name: '8.9 Volume with Disc Method: Revolving Around the x-or y-Axis', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_10', name: '8.10 Volume with Disc Method: Revolving Around Other Axes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_11', name: '8.11 Volume with Washer Method: Revolving Around the x - or y-Axis', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_12', name: '8.12 Volume with Washer Method: Revolving Around Other Axes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    }
  ]);

  const [frqsubjects, setfrqsubjects] = useState([
    {
      main: "Area and Volume", count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_01', name: 'APCALC_FRQ_01', count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Rates", count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_02', name: 'APCALC_FRQ_02', count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Charts with Riemann Sums, Mean Value Theorem, Average Rates/Values", count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_03', name: 'APCALC_FRQ_03', count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Analyzing the Graph of f", count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_04', name: 'APCALC_FRQ_04', count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Slope Fields/Differential Equations", count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_05', name: 'APCALC_FRQ_05', count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Related Rates", count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_06', name: 'APCALC_FRQ_06', count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Accumulation Functions", count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_07', name: 'APCALC_FRQ_07', count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Implicit Diferentiation", count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_08', name: 'APCALC_FRQ_08', count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Particle Motion", count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_09', name: 'APCALC_FRQ_09', count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: `Charts of f, f', f"`, count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_10', name: `APCALC_FRQ_10`, count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: `Functions/Misc.`, count: 0, isOpen: false, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_11', name: `APCALC_FRQ_11`, count: 0, isOpen: false, isSelected: false, tag: 'frq' }
      ]
    },
  ]);

  function getRandomExamDistribution(mcqsubjects, frqsubjects) {
    const sections = [
      { key: 'section1_mcq_no_calc', subjects: mcqsubjects, count: 30 },
      { key: 'section2_mcq_calc', subjects: mcqsubjects, count: 15 },
      { key: 'section3_frq_no_calc', subjects: frqsubjects, count: 2 },
      { key: 'section4_frq_calc', subjects: frqsubjects, count: 4 }
    ];

    // 주어진 subjects 배열에서 subtopics를 추출하고, 해당 수만큼 랜덤으로 선택하는 함수
    function getRandomTopics(subjects, totalCount) {
      const topics = subjects.flatMap(subject => subject.subtopics);
      // Fisher-Yates 알고리즘으로 shuffle
      for (let i = topics.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [topics[i], topics[j]] = [topics[j], topics[i]];
      }
      return topics.slice(0, totalCount).map(t => ({ id: t.id, num: 1 }));
    }

    const exam = {};
    for (const section of sections) {
      exam[section.key] = getRandomTopics(section.subjects, section.count);
    }

    return { exam };
  }


  useEffect(() => {
    if (activeSegment === 'MCQ') {
      setDisplaySubjects(mcqsubjects);
    } else if (activeSegment === 'FRQ') {
      setDisplaySubjects(frqsubjects);
    }
  }, [activeSegment, mcqsubjects, frqsubjects]);

  const toggleSubjectOpen = (index) => {
    const setSubjects = activeSegment === 'MCQ' ? setmcqsubjects : setfrqsubjects;
    setSubjects(prevSubjects => {
      const newSubjects = [...prevSubjects];
      newSubjects[index].isOpen = !newSubjects[index].isOpen;
      return newSubjects;
    });
  };

  const toggleSubjectSelect = (index) => {
    const isMCQ = activeSegment === 'MCQ';
    const setSubjects = isMCQ ? setmcqsubjects : setfrqsubjects;

    setSubjects((prevSubjects) => {
      // Create a deep copy of the previous subjects to avoid mutating state directly
      const newSubjects = [...prevSubjects].map((subject, idx) => {
        if (idx === index) {
          const newIsSelected = !subject.isSelected;

          if (newIsSelected) {
            // Toggling ON: Check if all subtopics have count > 0
            if (subject.subtopics && subject.subtopics.length > 0) {
              const allSubtopicsHaveCount = subject.subtopics.every(sub => sub.count > 0);
              if (allSubtopicsHaveCount) {
                // Set isSelected to true and all subtopics' isSelected to true
                return {
                  ...subject,
                  isSelected: true,
                  subtopics: subject.subtopics.map(sub => ({
                    ...sub,
                    isSelected: true,
                  })),
                };
              } else {
                // Cannot toggle on, show error
                toast.error("Cannot select subject: some subtopics have zero count.");
                return subject; // No change
              }
            } else {
              // No subtopics, simply toggle isSelected
              return {
                ...subject,
                isSelected: true,
              };
            }
          } else {
            // Toggling OFF: Set isSelected to false and set all subtopics' isSelected to false
            if (subject.subtopics && subject.subtopics.length > 0) {
              return {
                ...subject,
                isSelected: false,
                subtopics: subject.subtopics.map(sub => ({
                  ...sub,
                  isSelected: false,
                })),
              };
            } else {
              // No subtopics, simply toggle isSelected
              return {
                ...subject,
                isSelected: false,
              };
            }
          }
        }
        return subject;
      });

      return newSubjects;
    });
  };


  const toggleSubTopic = (subjectTag, subjectIndex, subTopicIndex) => {
    const setSubjects = subjectTag.tag === 'mcq' ? setmcqsubjects : setfrqsubjects;
    setSubjects((prevSubjects) => {
      return prevSubjects.map((subject, i) => {
        if (i === subjectIndex) {
          // Check if subtopics are defined
          if (!Array.isArray(subject.subtopics)) {
            // No subtopics to toggle; return subject as is
            return subject;
          }

          const updatedSubtopics = subject.subtopics.map((subTopic, stIndex) => {

            if (stIndex === subTopicIndex && subTopic.count !== 0) {
              return {
                ...subTopic,
                isSelected: !subTopic.isSelected,
              };
            }
            return subTopic;
          });

          // Determine if all subtopics are selected
          const allSubSelected = updatedSubtopics.every(
            (subTopic) => subTopic.isSelected
          );

          return {
            ...subject,
            subtopics: updatedSubtopics,
            isSelected: allSubSelected,
          };
        }
        return subject;
      });
    });
  };


  function processAllTestResults(testDataArray) {
    // Define the keys you expect to have arrays in JSON string format
    const keys = ['Correct_questionIds', 'Incorrect_questionIds', 'Marked_questionIds', 'Omitted_questionIds'];

    // Initialize objects to collect all IDs across all objects
    const allIds = {
      Correct_questionIds: [],
      Incorrect_questionIds: [],
      Marked_questionIds: [],
      Omitted_questionIds: []
    };

    let allQuestionIds = [];

    // Process each test data object
    testDataArray.forEach(testData => {
      keys.forEach(key => {
        // Concatenate all 'questionIds' from each object
        const questionIds = JSON.parse(testData[key] || '[]').map(item => item.question_id);
        allQuestionIds = allQuestionIds.concat(questionIds);
      });
    });

    allQuestionIds = Array.from(new Set(allQuestionIds));

    // Process each test data object in the array
    testDataArray.forEach(testData => {
      keys.forEach(key => {
        // Concatenate all IDs to respective keys
        allIds[key] = allIds[key].concat(JSON.parse(testData[key] || '[]'));
      });
    });

    // Use a Set to remove duplicates from all combined arrays and assign to respective keys
    keys.forEach(key => {
      allIds[key] = Array.from(new Set(allIds[key]));
    });

    setcorrect(allIds.Correct_questionIds);
    setincorrect(allIds.Incorrect_questionIds);
    setmarked(allIds.Marked_questionIds);
    setomitted(allIds.Omitted_questionIds);

    return {
      allQuestionIds
    };
  }

  async function createtestoptions() {
    if (!user) {
      toast.error("Please login before starting the test");
      navigate("/login");
      return;
    }

    try {
      const [questionsResponse, userTestsResponse] = await Promise.all([
        axios.get(`${BASELINE}getquestionidsapcalcab`),
        axios.get(`${BASELINE}tests/byuser/ap/AP_Calc_AB/${user.u_id}`)
      ]);

      const { mcq, frq } = questionsResponse.data;
      const allQuestions = [...mcq, ...frq];

      const results = processAllTestResults(userTestsResponse.data);

      if (results && results.allQuestionIds) {
        const existingIds = new Set(results.allQuestionIds);
        const uniqueQuestions = allQuestions.filter(
          question => !existingIds.has(question.question_id)
        );
        settotalq(uniqueQuestions);
      } else {
        settotalq(allQuestions);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    let total = 0;
    let min = 0;

    if (activeSegment === 'MCQ') {
      mcqsubjects.forEach(subject => {
        let alert = 0;
        subject.subtopics.forEach(subTopic => {
          if (subTopic.isSelected === false) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });
    } else if (activeSegment === 'FRQ') {
      frqsubjects.forEach(subject => {
        let alert = 0;
        subject.subtopics.forEach(subTopic => {
          if (subTopic.isSelected === false) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });
    }

    setmin(min);
    settotal(total);
  }, [activeSegment, mcqsubjects, frqsubjects]); // Added 'frqsubjects' here

  useEffect(() => {
    createtestoptions();
  }, []);

  useEffect(() => {
    let seenIds = new Set();  // To track seen question IDs to avoid duplicates

    const addItems = (items) => {
      items.forEach(item => {
        if (!seenIds.has(item.question_id)) {  // Check if the question ID has already been added
          seenIds.add(item.question_id);  // Mark this ID as seen
        }
      });
    };

    if (activeFilters.unused) {
      addItems(totalq);
    }

    if (activeFilters.correct) {
      addItems(correct);
    }

    if (activeFilters.incorrect) {
      addItems(incorrect);
    }

    if (activeFilters.marked) {
      addItems(marked);
    }

    if (activeFilters.omitted) {
      addItems(omitted);
    }
  }, [totalq, correct, incorrect, marked, omitted, activeFilters]);

  const toggleFilter = (filter) => {
    setActiveFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter]
    }));
  };

  useEffect(() => {
    if (user && selectedButton != null) {
      navigate(`/ap/preset/apcalcab/${selectedButton}`);
    }
  }, [selectedButton]);

  const random_sat = async () => {
    //  setLoading(true);
    const list = getRandomExamDistribution(mcqsubjects, frqsubjects);

    axios.post(`${BASELINE}get/ap/id/questionidreal`, { exam: list })
      .then(response => {
        const data = response.data;
        const data2 = {
          result: {
            ...data
          }
        };

        if (user) {
          //  navigate(`/ap/preset/apcalcab/customrandom`, { state: { data: data2 } });
        }
      })
      .catch(error => {
        console.error("Error fetching question IDs:", error);
      });
  }

  useEffect(() => {
    let combinedQuestions = []; // This will hold the combined questions from selected difficulties
    let seenIds = new Set(); // To track seen question IDs to avoid duplicates
    let mcqQuestions = []; // To hold English questions
    let frqQuestions = []; // To hold Math questions

    const addItems = (items) => {
      items.forEach(item => {
        if (item.section) {
          if (!seenIds.has(item.question_id)) { // Check if the question ID has already been added
            seenIds.add(item.question_id); // Mark this ID as seen
            combinedQuestions.push(item); // Push the item into the combined array
          }
        } else {
          let tags = JSON.parse(item.tags);
          let singleTag = tags.find(tag => tag === 'frq' || tag === 'mcq');

          if (!seenIds.has(item.question_id)) { // Check if the question ID has already been added
            seenIds.add(item.question_id); // Mark this ID as seen
            combinedQuestions.push({ item, section: singleTag }); // Push the item into the combined array
          }
        }
      });
    };

    if (activeFilters.unused) {
      addItems(totalq);
    }
    if (activeFilters.correct) {
      addItems(correct);
    }
    if (activeFilters.omitted) {
      addItems(omitted);
    }
    if (activeFilters.incorrect) {
      addItems(incorrect);
    }

    combinedQuestions.forEach(question => {
      if (question.section === 'mcq') {
        mcqQuestions.push(question);
      } else if (question.section === 'frq') {
        frqQuestions.push(question);
      }
    });

    setmcqcount(mcqQuestions);
    setfrqcount(frqQuestions);

  }, [activeFilters]); // Dependency array includes 'diff' and 'activeDifficulties'

  useEffect(() => {
    const resetSubjects = (subjects) => {
      return subjects.map(subject => ({
        ...subject,
        count: 0,
        isSelected: false,
        subtopics: subject.subtopics.map(subtopic => ({
          ...subtopic,
          count: 0,
          isSelected: false,
        })),
      }));
    };

    const incrementCount = (subjectArray, tagsArray) => {
      subjectArray.forEach(subject => {
        let mainCountIncremented = false;

        if (Array.isArray(subject.subtopics)) {
          subject.subtopics.forEach(subtopic => {
            if (tagsArray.includes(subtopic.id)) {
              subtopic.count += 1;
              subtopic.isSelected = true;
              mainCountIncremented = true;
            }
          });

          // Set main subject's isSelected based on whether all subtopics are selected
          subject.isSelected = subject.subtopics.every(subtopic => subtopic.isSelected);
        }

        if (mainCountIncremented) {
          subject.count += 1;
        }
      });
    };

    const processFilters = (subjects, filters) => {
      if (filters.unused) {
        totalq.forEach(item => {
          let tagsArray;
          try {
            tagsArray = JSON.parse(item.tags);
          } catch (error) {
            console.error("Error parsing tags:", error);
            return;
          }
          incrementCount(subjects, tagsArray);
        });
      }

      const filterTypes = ['correct', 'incorrect', 'marked', 'omitted'];
      filterTypes.forEach(filterType => {
        if (filters[filterType]) {
          const filterItems = { correct, incorrect, marked, omitted }[filterType];
          filterItems.forEach(item => {
            incrementCount(subjects, item.topic[0]);
          });
        }
      });
    };

    if (activeSegment === 'MCQ' || activeSegment === 'FRQ') {
      const subjects = activeSegment === 'MCQ' ? mcqsubjects : frqsubjects;
      const updatedSubjects = resetSubjects(subjects);

      processFilters(updatedSubjects, activeFilters);

      if (activeSegment === 'MCQ') {
        setmcqsubjects(updatedSubjects);
      } else {
        setfrqsubjects(updatedSubjects);
      }

      setDisplaySubjects(updatedSubjects);
    }
  }, [activeSegment, activeFilters]);


  const handleGenerateTest = () => {
    // Step 1: Validate the number of passages
    if (currentPassages < min) {
      toast.error("Number of passages exceeds the minimum allowed.");
      return;
    }

    if (currentPassages > total) {
      toast.error("Number of passages exceeds the maximum allowed.");
      return;
    }

    const isMCQ = (activeSegment === 'MCQ');

    // Step 2: Select the appropriate question set based on segment
    const combinedQuestions = isMCQ ? mcqcount : frqcount;

    // FRQ인 경우 tags 파싱 및 AP 태그 추출
    let processedQuestions = combinedQuestions;
    processedQuestions = combinedQuestions.map(question => {
      const tagsArray = JSON.parse(question.item.tags || "[]");
      const apTags = tagsArray.filter(tag => tag.startsWith("AP"));
      return { ...question, apTags };
    });

    // Step 3: 토픽 선택 정보 가져오기
    const selectedSubjects = isMCQ ? mcqsubjects : frqsubjects;
    const topicsSelected = selectedSubjects.flatMap(subject =>
      subject.subtopics.filter(st => st.isSelected === true && st.count > 0)
    );

    // Step 4: 토픽별로 문제 선별
    let questionsToUse = [];

    topicsSelected.forEach(topic => {
      let questionsForTopic;
      if (isMCQ) {
        questionsForTopic = processedQuestions.filter(q => {
          return Array.isArray(q.apTags) && q.apTags[0] === topic.id;
        });

      } else {
        questionsForTopic = processedQuestions.filter(q => {
          return Array.isArray(q.apTags) && q.apTags[0] === topic.id;
        });
      }

      for (const q of questionsForTopic) {
        const qid = q.item.question_id;
        if (questionsToUse.length < currentPassages && !questionsToUse.includes(qid)) {
          questionsToUse.push(qid);
        }
      }
    });

    // Step 5: 필요한 문제 수보다 적으면 랜덤으로 추가
    let attempts = 0;
    const maxAttempts = 600; // 예: 최대 1000번 시도 후 종료
    while (questionsToUse.length < currentPassages && attempts < maxAttempts) {
      // processedQuestions가 비어있다면 바로 종료
      if (!processedQuestions || processedQuestions.length === 0) {
        toast.error("No available questions to meet the required count.");
        break;
      }

      const randomQ = processedQuestions[Math.floor(Math.random() * processedQuestions.length)];
      const qid = isMCQ ? randomQ.question_id : randomQ.item.question_id;

      // 더 이상 추가할 question_id가 없는 경우 탈출
      if (questionsToUse.includes(qid) && attempts > processedQuestions.length * 2) {
        toast.error("Not enough unique questions to meet the desired count.");
        break;
      }

      if (!questionsToUse.includes(qid)) {
        questionsToUse.push(qid);
      }

      attempts++;
    }

    // 만약 attempts가 maxAttempts에 도달했는데도 채워지지 않았다면 처리
    if (questionsToUse.length < currentPassages) {
      toast.error("Unable to gather enough questions to meet your requirements.");
      return; // 더 이상 진행 불가하므로 return
    }

    // Step 6: Quiz 데이터 구성 후 페이지 이동
    localStorage.clear();
    const quizData = {
      questionIds: questionsToUse,
      tutorModeActive: isTutorActive,
      timedModeActive: isTimedActive,
      timeLimit: isTimedActive ? timerMinutes : null,
      tag: activeSegment,
      type: "AP_Calc_AB",
    };

    navigate(`/ap/quiz/`, { state: { data: quizData } });
  };

  const [showTooltip, setShowTooltip] = useState(false);
  // 말풍선을 랜덤한 시간 간격으로 표시
  useEffect(() => {
    const interval = setInterval(() => {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000); // 말풍선이 2초 동안 표시
    }, Math.random() * 3000 + 2000); // 3초에서 8초 사이 랜덤

    return () => clearInterval(interval);
  }, []);

  const [showList, setshowList] = useState(false);

  function getRandomFromArray(arr, count) {
    // arr를 복사한 뒤 Fisher-Yates shuffle
    const shuffled = [...arr];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled.slice(0, count);
  }

  function getQuestionsWithPriority(section, countNeeded) {
    // section: 'mcq' or 'frq'
    // incorrect, correct, totalq(unused)에서 해당 section만 필터링
    const incorrectQuestions = (incorrect || []).filter(q => q.section === section);
    const unusedQuestions = (totalq || []).filter(q => q.section === section);
    const correctQuestions = (correct || []).filter(q => q.section === section);

    let selected = [];
    // 1차: incorrect에서 최대 countNeeded까지
    const fromIncorrect = getRandomFromArray(incorrectQuestions, Math.min(countNeeded, incorrectQuestions.length));
    selected = [...selected, ...fromIncorrect];
    let remaining = countNeeded - selected.length;

    if (remaining > 0) {
      // 2차: unused에서 추가
      const fromUnused = getRandomFromArray(unusedQuestions, Math.min(remaining, unusedQuestions.length));
      selected = [...selected, ...fromUnused];
      remaining = countNeeded - selected.length;
    }

    if (remaining > 0) {
      // 3차: correct에서 추가
      const fromCorrect = getRandomFromArray(correctQuestions, Math.min(remaining, correctQuestions.length));
      selected = [...selected, ...fromCorrect];
      remaining = countNeeded - selected.length;
    }

    return selected;
  }

  const handleMCQClick = () => {
    const countNeeded = 15;
    const selectedMCQ = getQuestionsWithPriority('mcq', countNeeded);

    if (selectedMCQ.length < countNeeded) {
      toast.error("Not enough MCQ questions available to meet the requirement of 15 questions.");
      return;
    }

    const questionIds = selectedMCQ.map(q => q.item ? q.item.question_id : q.question_id);

    localStorage.clear();
    const quizData = {
      questionIds,
      tutorModeActive: isTutorActive,
      timedModeActive: isTimedActive,
      timeLimit: isTimedActive ? timerMinutes : null,
      tag: 'MCQ',
      type: "AP_Calc_AB",
    };
    navigate(`/ap/quiz/`, { state: { data: quizData } });
  };

  const handleFRQClick = () => {
    const countNeeded = 15;
    const selectedFRQ = getQuestionsWithPriority('frq', countNeeded);

    if (selectedFRQ.length < countNeeded) {
      toast.error("Not enough FRQ questions available to meet the requirement of 15 questions.");
      return;
    }

    const questionIds = selectedFRQ.map(q => q.item ? q.item.question_id : q.question_id);

    localStorage.clear();
    const quizData = {
      questionIds,
      tutorModeActive: isTutorActive,
      timedModeActive: isTimedActive,
      timeLimit: isTimedActive ? timerMinutes : null,
      tag: 'FRQ',
      type: "AP_Calc_AB",
    };
    navigate(`/ap/quiz/`, { state: { data: quizData } });
  };

  const [analai, setanalai] = useState("Hi, let me pick a question for you to practice with!");
  const voiceOptions = {
    gender: 'Female',
    voiceName: 'en-US-AriaNeural',
    rate: '15%',
    pitch: '9%',
    volume: '80%',
  };

  const audioUrl = useAIAudio(analai, voiceOptions, 0);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);


  const texttoaudio = async () => {
    if (audioUrl && aiplayed === false) {
      setaiplayed(true);
      // Auto-play logic
      audioRef.current = new Audio(audioUrl);
      audioRef.current
        .play()
        .then(() => {
          audioRef.current.onended = () => {
            setIsPlaying(false);
          };
        })
        .catch((error) => {
          console.error('Error auto-playing audio:', error);
        });
    } else {
      setaiplayed(false);
      if (audioRef.current) {
        audioRef.current.pause(); // Pause the audio
        audioRef.current.currentTime = 0; // Reset to the beginning
      }
    }
  }

  const showlist = () => {
    setshowList(!showList);
    texttoaudio();
  }

  return (<>
    <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
      <span className="hover:underline cursor-pointer">AP Calculus AB</span> &gt; <span className="font-semibold text-black">Generate Test</span>
    </div>
    <h1 className="bg-gray-100 border-[1px] border-gray-300 m-4 p-1 pl-6 text-xl font-bold mt-4">Quick AP Calculus AB</h1>

    <section className="wrapper mb-6">
      <div className="w-[100%] flex justify-start items-center flex-col px-5 bg-white rounded-2xl">
        {/* Buttons Section */}
        <div className="flex flex-row flex-wrap justify-center gap-2">
          {currentButtons.map((num) => (
            <button
              key={num}
              onClick={() => setSelectedButton(num)}
              className={`border border-gray-300 border-solid text-black text-sm py-2 px-4 rounded-md m-2 ${selectedButton === num ? "bg-gray-400" : "bg-white hover:bg-gray-300"
                }`}
            >
              AP Calculus AB #{num}
            </button>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="border border-gray-300 border-solid text-black font-bold py-2 px-4 rounded m-2 hover:bg-gray-300 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="text-lg mx-2">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="border border-gray-300 border-solid text-black font-bold py-2 px-4 rounded m-2 hover:bg-gray-300 disabled:opacity-50"
          >
            Next
          </button>
          {/* Random Generate Button */}
          {user && user?.premium === 1 ? (
            <button
              onClick={() => random_sat()}
              className="border border-gray-300 border-solid text-black font-bold py-2 px-4 rounded m-2"
            >
              <FaRandom />
            </button>
          ) : (
            <button
              className="border border-gray-300 border-solid text-black font-bold py-2 px-4 rounded m-2 bg-gray-400"
            >
              🔒 Random Generate
            </button>
          )}
        </div>
      </div>
    </section>

    {user && user?.premium === 1 ? (
      <>
        <h1 className="bg-gray-100 border-[1px] border-gray-300 m-4 p-1 pl-6 text-xl font-bold mt-4">Personalize AP Calculus AB</h1>
      </>
    ) : (
      <>
        <h1 className="bg-gray-100 rounded-2xl m-4 p-1 pl-6 text-xl font-bold mt-4">🔒 Personalize AP Calculus AB</h1>
      </>
    )}


    <div className={`bg-white pb-6 pl-6 pt-2 rounded-lg ${user?.premium !== 1 ? 'opacity-50 pointer-events-none' : ''}`}>
      {/* Test Mode Section */}
      <div className="text-gray-800 text-lg mb-2 font-semibold">Test Mode
      </div>

      <div className="flex items-center justify-between mb-4">

        <div className="flex justify-between items-center space-x-4 mb-2">
          <ToggleSwitch
            label="Tutor"
            isEnabled={isTutorActive}
            toggleSwitch={() => setIsTutorActive(!isTutorActive)}
            desc="Tutor Mode: Shows correct answer and explanation when the user chooses wrong."
          />
          <div className="flex items-center">
            <ToggleSwitch
              label="Timed"
              isEnabled={isTimedActive}
              toggleSwitch={() => setIsTimedActive(!isTimedActive)}
              desc="Quiz Timer: Can set a time limit for quizzes."
            />
            {isTimedActive && (
              <div className="ml-4">
                <input
                  type="number"
                  value={timerMinutes}
                  onChange={(e) => setTimerMinutes(e.target.value)}
                  className="w-20 pl-2 pr-1 border rounded text-gray-800"
                  min="1"
                />
                <span className="ml-1 text-gray-700">min</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mb-4">
        <div className="text-gray-800 text-lg font-semibold mb-1">Question Mode</div>
        <div className="mt-2 flex space-x-4">
          <FilterButton
            label="Unused"
            count={totalq.length}
            isActive={activeFilters.unused}
            toggleFilter={() => toggleFilter('unused')}
          />
          <FilterButton
            label="Correct"
            count={correct.length}
            isActive={activeFilters.correct}
            toggleFilter={() => toggleFilter('correct')}
          />
          <FilterButton
            label="Incorrect"
            count={incorrect.length}
            isActive={activeFilters.incorrect}
            toggleFilter={() => toggleFilter('incorrect')}
          />
          <FilterButton
            label="Marked"
            count={marked.length}
            isActive={activeFilters.marked}
            toggleFilter={() => toggleFilter('marked')}
          />
          <FilterButton
            label="Omitted"
            count={omitted.length}
            isActive={activeFilters.omitted}
            toggleFilter={() => toggleFilter('omitted')}
          />
        </div>
      </div>

      {/* Subjects */}
      <div className="mb-4 mt-8">
        <div className="flex">
          <label className="text-gray-800 text-lg font-semibold mb-1 block">Subjects</label>
          <div className="flex border border-slate-300 rounded-xl overflow-hidden mt-[-5px] mb-[20px] ml-[20px]">
            {['MCQ', 'FRQ'].map(segment => {
              // Get the appropriate count for each segment directly from the arrays
              let count = 0;
              if (segment === 'MCQ') {
                count = mcqcount.length; // Directly use the length of engQuestions
              } else if (segment === 'FRQ') {
                count = frqcount.length; // Directly use the length of mathQuestions
              }

              return (
                <button
                  key={segment}
                  onClick={() => setActiveSegment(segment)}
                  className={`flex-2 py-2 px-4 text-center transition-colors duration-300
                    ${activeSegment === segment ? 'bg-slate-700 text-white' : 'bg-white text-gray-800 hover:bg-gray-200'}`}
                >
                  {`${segment} (${count})`}
                </button>
              );
            })}
          </div>
        </div>

        <div className="bg-white flex flex-row flex-wrap sm:flex-nowrap items-center justify-center grid grid-cols-4">
          {displaySubjects.map((subject, index) => (
            <Subject
              key={`${subject.main}-${subject.tag}`} // Ensures unique key by combining main and tag
              name={subject.main}
              count={subject.count}
              isOpen={subject.isOpen}
              isSelected={subject.isSelected}
              toggleOpen={() => toggleSubjectOpen(index)}
              toggleSelect={() => toggleSubjectSelect(index)}
              subTopics={subject.subtopics.map((subTopic, subIndex) => ({
                ...subTopic,
                toggle: () => toggleSubTopic(subject, index, subIndex),
              }))}
            />
          ))}
        </div>

      </div>


      <div className="mt-8 mb-4">
        <label className="text-lg font-semibold mb-1 block">No. of Questions</label>
        <div className="flex flex-wrap">

          <div className="flex items-center space-x-4">
            <input
              type="number"
              value={currentPassages}
              onChange={(e) => setCurrentPassages(e.target.value)}
              className="w-24 bg-gray-300 border-gray-300 border text-center p-2"
              min={min}
            />

            <span>Max allowed per test</span>
            <input
              type="number"
              value={total}
              style={{ minWidth: '50px', width: 'auto' }} // Same here for the second input
              readOnly
            />
          </div>


        </div>
      </div>
      <div className="mt-8 space-y-4">
        <div className="text-lg font-bold">Generate Your Test</div>
        {user && user.premium === 1 ? (
          <GenerateButton onClick={handleGenerateTest} />
        ) : (
          <GenerateButton />
        )}
      </div>

    </div >

    <div className="relative">
      {/* AI 버튼 */}
      <motion.div
        className="fixed bottom-[30px] right-[30px] flex items-center justify-center w-16 h-16 text-white font-bold text-lg rounded-full shadow-lg cursor-pointer"
        initial={{ scale: 1 }}
        animate={{
          scale: [1, 1.1, 1],
          background: [
            "linear-gradient(45deg, #4a90e2, #8e44ad)",
            "linear-gradient(45deg, #8e44ad, #4a90e2)",
            "linear-gradient(45deg, #4a90e2, #8e44ad)",
          ],
        }}
        transition={{
          scale: {
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          },
          background: {
            duration: 3,
            repeat: Infinity,
            ease: "easeInOut",
          },
        }}
        style={{
          boxShadow: "0 0 15px 5px rgba(138, 43, 226, 0.7)",
        }}
        onClick={() => showlist()} // 버튼 클릭 시 이벤트
      >
        AI
      </motion.div>

      {/* 말풍선 1 */}
      {showTooltip && showList === false && (
        <motion.div
          className="fixed bottom-[100px] right-[25px] bg-gray-800 text-white text-sm w-[100px] p-2 rounded-md shadow-md"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.5 }}
        >
          <div className="ml-[9px]">
            Click me!
          </div>
        </motion.div>
      )}

      {/* 말풍선 2 */}
      {showList === true && (<>
        <motion.div
          className="fixed bottom-[255px] right-[25px] bg-gray-800 text-white text-sm w-[400px] p-4 rounded-md shadow-md"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.5 }}
        >
          Hi, Let me pick question you need to practice with!
        </motion.div>
        <motion.div
          className="fixed bottom-[200px] right-[25px] text-white text-sm w-[400px] p-4 rounded-md shadow-md cursor-pointer"

          initial={{ scale: 1 }}
          animate={{
            scale: [1, 1.1, 1],
            background: [
              "linear-gradient(45deg, #4a90e2, #8e44ad)",
              "linear-gradient(45deg, #8e44ad, #4a90e2)",
              "linear-gradient(45deg, #4a90e2, #8e44ad)",
            ],
          }}
          whileHover={{
            scale: 1.1, // Popping effect on hover
            transition: { duration: 0.2, ease: "easeInOut" },
          }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.5 }}
          style={{
            cursor: 'url("path-to-your-cursor-image.png"), pointer', // Custom cursor
          }}
          onClick={handleMCQClick}
        >
          ✨ Multiple Choice Questions
        </motion.div>

        <motion.div
          className="fixed bottom-[145px] right-[25px] text-white text-sm w-[400px] p-4 rounded-md shadow-md cursor-pointer"

          initial={{ scale: 1 }}
          animate={{
            scale: [1, 1.1, 1],
            background: [
              "linear-gradient(45deg, #4a90e2, #8e44ad)",
              "linear-gradient(45deg, #8e44ad, #4a90e2)",
              "linear-gradient(45deg, #4a90e2, #8e44ad)",
            ],
          }}
          whileHover={{
            scale: 1.1, // Popping effect on hover
            transition: { duration: 0.2, ease: "easeInOut" },
          }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.5 }}
          style={{
            cursor: 'url("path-to-your-cursor-image.png"), pointer', // Custom cursor
          }}
          onClick={handleFRQClick}
        >
          ✨ Free Resonse Questions
        </motion.div>

      </>)}
    </div>
  </>);

};

const GenerateButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded inline-flex items-center justify-center space-x-2"
    >
      <span>GENERATE TEST</span>
      <AiOutlineInfoCircle />
    </button>
  );
};

const SubTopic = ({ name, count, isSelected, toggle }) => {
  return (
    <div className="flex justify-between items-center p-1">
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={toggle}  // Ensure this is the only handler for onChange
          className="mr-2 cursor-pointer"
        />
        <span>{name}</span>
      </div>
      <span>{`${count}`}</span>
    </div>
  );
};

// Subject Component
const Subject = ({ name, count, isOpen, isSelected, toggleOpen, toggleSelect, subTopics }) => {
  return (
    <div className="mb-2 border-b w-[98%] h-[250px] border border-gray-200 p-2 rounded-xl">
      <div className="flex justify-between items-center p-2">
        <div className="flex items-center space-x-2">
          {/* Toggle Open/Close Button */}
          <button
            onClick={toggleOpen}
            className="focus:outline-none"
            aria-label={isOpen ? "Collapse Subject" : "Expand Subject"}
            aria-expanded={isOpen}
          >
            {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}
          </button>

          {/* Selection Checkbox */}
          <input
            type="checkbox"
            checked={isSelected}
            onChange={toggleSelect}
            className="mr-2 cursor-pointer"
            aria-label={`Select ${name}`}
          />

          {/* Clickable Subject Name to Toggle Open/Close */}
          <span
            onClick={toggleOpen}
            className="cursor-pointer"
            role="button"
            tabIndex="0"
            onKeyPress={(e) => { if (e.key === 'Enter') toggleOpen(); }}
          >
            {name}
          </span>
        </div>
        <span>{`${count}`}</span>
      </div>

      {/* Subtopics List */}
      {isOpen && (
        <div className="h-[150px] overflow-y-auto">
          {subTopics.map((subTopic, index) => (
            <SubTopic
              key={`${subTopic.name}-${index}`} // Use a unique key if possible
              name={subTopic.name}
              count={subTopic.count}
              isSelected={subTopic.isSelected}
              toggle={() => subTopic.toggle(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ToggleSwitch = ({ label, isEnabled, toggleSwitch, desc }) => {
  return (
    <div className="flex items-center">
      <span className="text-gray-700 mr-[-8px]">{label}</span>
      <InfoIconWithTooltip tooltipText={desc} />

      <div
        onClick={toggleSwitch}
        className={`ml-2 w-12 h-6 flex items-center bg-gray-200 rounded-full p-1 duration-300 ease-in-out ${isEnabled ? 'bg-slate-700 justify-end' : 'bg-gray-300 justify-start'
          }`}
      >
        <div
          className="bg-white w-4 h-4 rounded-full transform duration-300 ease-in-out"
        />
      </div>
    </div>
  );
};

const FilterButton = ({ label, count, isActive, toggleFilter }) => {
  return (
    <button
      disabled={count === 0} // Disable button if count is 0
      className={`flex items-center space-x-2 border px-3 py-2 rounded-full ${isActive ? 'bg-slate-700 text-white' : 'bg-white text-gray-800'} ${count === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-300'}`}
      onClick={count > 0 ? toggleFilter : undefined} // Prevent function call if count is 0
    >
      {isActive && <AiOutlineCheck className="text-lg" />}
      <span>{label}</span>
      <div className="flex items-center space-x-1">
        <span>{count}</span>
      </div>
    </button>
  );
};


export default RandomAPCALCABReady;
