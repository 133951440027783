import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiChatSmileFill } from "react-icons/ri";
import { Doughnut } from 'react-chartjs-2';
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import ModalComponent from "../pick_modal";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASELINE } from "../../../util";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const DigitalAPBCView = ({ setActiveIndex, user, setdashboard }) => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const [Incorrect_questionIds, setIncorrect_questionIds] = useState([]);
    const [Incorrect_frqquestionIds, setIncorrect_frqquestionIds] = useState([]);

    const topMathTopics = findTopMissedTopics(Incorrect_questionIds, "frq");
    const [topic, settopic] = useState(false);
    const [section, setsection] = useState("");
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [lastscore, setlastscore] = useState(0);

    const [avg_sat, setAverageAPScore] = useState(0);
    const [averageMCQ, setAverageMCQ] = useState(0);
    const [averageFRQ, setAverageFRQ] = useState(0);
    const [scorearr, setscorearr] = useState([]);


    const [mcqsubjects, setmcqsubjects] = useState([
        {
          main: "Concept of a Limit", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U1_1', name: '1.1 Introducing Calculus: Can Change Occur at an Instant?', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_2', name: '1.2 Defining Limits and Using Limit Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_3', name: '1.3 Estimating Limit Values from Graphs', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_4', name: '1.4 Estimating Limit Values from Tables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_5', name: '1.5 Determining Limits Using Algebraic Properties of Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_6', name: '1.6 Determining Limits Using Algebraic Manipulation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_7', name: '1.7 Selecting Procedures for Determining Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_8', name: '1.8 Determining Limits Using the Squeeze Theorem', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_9', name: '1.9 Connecting Multiple Representations of Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Continuity", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U1_10', name: '1.10 Exploring Types of Discontinuities', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_11', name: '1.11 Defining Continuity at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_12', name: '1.12 Confirming Continuity over an Interval', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_13', name: '1.13 Removing Discontinuities', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Asymptotes & IVT", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U1_14', name: '1.14 Connecting Infinite Limits and Vertical Asymptotes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_15', name: '1.15 Connecting Limits at Infinity and Horizontal Asymptotes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U1_16', name: '1.16 Working with the Intermediate Value Theorem (IVT)', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Definition and Existence of Derivatives", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U2_1', name: '2.1 Defining Average and Instantaneous Rates of Change at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U2_2', name: '2.2 Defining the Derivative of a Function and Using Derivative Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U2_3', name: '2.3 Estimating Derivatives of a Function at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U2_4', name: '2.4 Connecting Differentiability and Continuity: Determining When Derivatives Do and Do Not Exist', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Calculating Derivatives", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U2_5', name: '2.5 Applying the Power Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U2_6', name: '2.6 Derivative Rules: Constant, Sum, Difference, and Constant Multiple', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U2_7', name: '2.7 Derivatives of cos(x), sin(x), mathrm, and ln(x)', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U2_8', name: '2.8 The Product Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U2_9', name: '2.9 The Quotient Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U2_10', name: '2.10 Finding the Derivatives of Tangent, Cotangent, Secant, and/or Cosecant Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Advanced Derivative Rules", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U3_1', name: '3.1 The Chain Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U3_2', name: '3.2 Implicit Differentiation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Inverse Functions and Higher-Order Derivatives", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U3_3', name: '3.3 Differentiating Inverse Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U3_4', name: '3.4 Differentiating Inverse Trigonometric Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U3_5', name: '3.5 Selecting Procedures for Calculating Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U3_6', name: '3.6 Calculating HigherOrder Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Derivatives in Context", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U4_1', name: '4.1 Interpreting the Meaning of the Derivative in Context', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U4_2', name: '4.2 Straight-Line Motion: Connecting Position, Velocity, and Acceleration', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U4_3', name: '4.3 Rates of Change in Applied Contexts Other Than Motion', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Related Rates", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U4_4', name: '4.4 Introduction to Related Rates', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U4_5', name: '4.5 Solving Related Rates Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Linearity and L'Hospital's Rule", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U4_6', name: '4.6 Approximating Values of a Function Using Local Linearity and Linearization', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U4_7', name: '4.7 Using LHospitals Rule for Determining Limits of Indeterminate Forms', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "MVT and EVT", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U5_1', name: '5.1 Using the Mean Value Theorem', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U5_2', name: '5.2 Extreme Value Theorem, Global Versus Local Extrema, and Critical Points', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Function Behavior", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U5_3', name: '5.3 Determining Intervals on Which a Function Is Increasing or Decreasing', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U5_4', name: '5.4 Using the First Derivative Test to Determine Relative (Local) Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U5_5', name: '5.5 Using the Candidates Test to Determine Absolute (Global) Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U5_6', name: '5.6 Determining Concavity of Functions over Their Domains', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U5_7', name: '5.7 Using the Second Derivative Test to Determine Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U5_8', name: '5.8 Sketching Graphs of Functions and Their Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U5_9', name: '5.9 Connecting a Function, Its First Derivative, and Its Second Derivative', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Optimization", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U5_10', name: '5.10 Introduction to Optimization Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U5_11', name: '5.11 Solving Optimization Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U5_12', name: '5.12 Exploring Behaviors of Implicit Relations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Concept of Integration", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U6_1', name: '6.1 Exploring Accumulations of Change', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_2', name: '6.2 Approximating Areas with Riemann Sums', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_3', name: '6.3 Riemann Sums, Summation Notation, and Definite Integral Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Fundamental Theorem of Calculus", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U6_4', name: '6.4 The Fundamental Theorem of Calculus and Accumulation Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_5', name: '6.5 Interpreting the Behavior of Accumulation Functions Involving Area', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_6', name: '6.6 Applying Properties of Definite Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_7', name: '6.7 The Fundamental Theorem of Calculus and Definite Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_8', name: '6.8 Finding Antiderivatives and Indefinite Integrals: Basic Rules and Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Integration Techniques", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U6_9', name: '6.9 Integrating Using Substitution', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_10', name: '6.10 Integrating Functions Using Long Division and Completing the Square', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_11', name: '6.11 Integrating Using Integration by Parts', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_12', name: '6.12 Using Linear Partial Fractions ', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_13', name: '6.13 Evaluating Improper Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U6_14', name: '6.14 Selecting Techniques for Antidifferentiation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Analyzing Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U7_1', name: '7.1 Modeling Situations with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U7_2', name: '7.2 Verifying Solutions for Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U7_3', name: '7.3 Sketching Slope Fields', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U7_4', name: '7.4 Reasoning Using Slope Fields', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Solving Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U7_5', name: '7.5 Approximating Solutions Using Eulers Method', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U7_6', name: '7.6 Finding General Solutions Using Separation of Variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U7_7', name: '7.7 Finding Particular Solutions Using Initial Conditions and Separation of Variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Modeling Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U7_8', name: '7.8 Exponential Models with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Solving Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U7_9', name: '7.9 Logistic Models with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Definite Integrals in Context", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U8_1', name: '8.1 Finding the Average Value of a Function on an Interval', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U8_2', name: '8.2 Connecting Position, Velocity, and Acceleration of Functions Using Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U8_3', name: '8.3 Using Accumulation Functions and Definite Integrals in Applied Contexts', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Area Between Curves", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U8_4', name: '8.4 Finding the Area Between Curves Expressed as Functions of x', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U8_5', name: '8.5 Finding the Area Between Curves Expressed as Functions of y', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U8_6', name: '8.6 Finding the Area Between Curves That Intersect at More Than Two Points', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Volume", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U8_7', name: '8.7 Volumes with Cross Sections: Squares and Rectangles', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U8_8', name: '8.8 Volumes with Cross Sections: Triangles and Semicircles', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U8_9', name: '8.9 Volume with Disc Method: Revolving Around the x-or y-Axis', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U8_10', name: '8.10 Volume with Disc Method: Revolving Around Other Axes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U8_11', name: '8.11 Volume with Washer Method: Revolving Around the x - or y-Axis', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U8_12', name: '8.12 Volume with Washer Method: Revolving Around Other Axes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Arc Length", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U8_13', name: '8.13 The Arc Length of a Smooth, Planar Curve and Distance Traveled', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Parametric Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U9_1', name: '9.1 Defining and Differentiating Parametric Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U9_2', name: '9.2 Second Derivatives of Parametric Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U9_3', name: '9.3 Finding Arc Lengths of Curves Given by Parametric Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Vector-Valued Functions", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U9_4', name: '9.4 Defining and Differentiating VectorValued Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U9_5', name: '9.5 Integrating VectorValued Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U9_6', name: '9.6 Solving Motion Problems Using Parametric and VectorValued Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Polar Coordinates", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U9_7', name: '9.7 Defining Polar Coordinates and Differentiating in Polar Form', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U9_8', name: '9.8 Find the Area of a Polar Region or the Area Bounded by a Single Polar Curve', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U9_9', name: '9.9 Finding the Area of the Region Bounded by Two Polar Curves', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Infinite Series", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U10_1', name: '10.1 Defining Convergent and Divergent Infinite Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_2', name: '10.2 Working with Geometric Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Tests for Convergence and Divergence", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U10_3', name: '10.3 The nth Term Test for Divergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_4', name: '10.4 Integral Test for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_5', name: '10.5 Harmonic Series and p-Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_6', name: '10.6 Comparison Tests for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_7', name: '10.7 Alternating Series Test for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_8', name: '10.8 Ratio Test for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
        {
          main: "Sum of Series", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
          subtopics: [
            { id: 'APCALC_U10_9', name: '10.9 Determining Absolute or Conditional Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_10', name: '10.10 Alternating Series Error Bound', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_11', name: '10.11 Finding Taylor Polynomial Approximations of Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_12', name: '10.12 Lagrange Error Bound', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_13', name: '10.13 Radius and Interval of Convergence of Power Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_14', name: '10.14 Finding Taylor or Maclaurin Series for a Function', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
            { id: 'APCALC_U10_15', name: '10.15 Representing Functions as Power Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
          ]
        },
      ]);

    const [frqsubjects, setfrqsubjects] = useState([
        {
            main: "Area and Volume", count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_01', name: 'Area and Volume', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: "Rates", count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_02', name: 'Rates', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: "Charts with Riemann Sums, Mean Value Theorem, Average Rates/Values", count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_03', name: 'Charts with Riemann Sums, Mean Value Theorem, Average Rates/Values', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: "Analyzing the Graph of f", count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_04', name: 'Analyzing the Graph of f', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: "Slope Fields/Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_05', name: 'Slope Fields/Differential Equations', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: "Related Rates", count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_06', name: 'Related Rates', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: "Accumulation Functions", count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_07', name: 'Accumulation Functions', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: "Implicit Diferentiation", count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_08', name: 'Implicit Diferentiation', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: "Particle Motion", count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_09', name: 'Particle Motion', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: `Charts of f, f', f"`, count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_10', name: `Charts of f, f', f"`, count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
        {
            main: `Functions/Misc.`, count: 0, isOpen: true, isSelected: false, tag: 'frq',
            subtopics: [
                { id: 'APCALC_FRQ_11', name: `Functions/Misc.`, count: 0, isOpen: true, isSelected: false, tag: 'frq' }
            ]
        },
    ]);

    // Assuming mcqsubjects and frqsubjects are available in the scope
    const mcqIdToNameMap = {};
    mcqsubjects.forEach(subject => {
        subject.subtopics.forEach(subtopic => {
            mcqIdToNameMap[subtopic.id] = subtopic.name;
        });
    });

    const frqIdToNameMap = {};
    frqsubjects.forEach(subject => {
        subject.subtopics.forEach(subtopic => {
            frqIdToNameMap[subtopic.id] = subtopic.name;
        });
    });


    const data2 = {
        labels: [''],
        datasets: [
            {
                label: 'Predicted Score',
                data: [lastscore],
                backgroundColor: 'rgba(1, 24, 82, 0.6)',
            },
            {
                label: 'Target Score',
                data: [5],
                backgroundColor: 'rgba(201, 203, 207, 0.6)',
            },
        ],
    };

    const options2 = {
        indexAxis: 'y',
        scales: {
            x: {
                max: 5,
                beginAtZero: true,
            },
        },
    };

    // Helper function to calculate averages
    const calculateAverages = (data) => {
        let apScoreTotal = 0;
        let mcqTotal = 0;
        let frqTotal = 0;
        let incorrectQuestionIDList = []; // To collect all incorrect question IDs
        let count = 0;
        let mcq = []; // Array to hold { id, topic, name } objects for MCQ
        let frq = []; // Array to hold { id, topic, name } objects for FRQ
        let scorearr = []
        data.forEach(item => {
            if (item.type === 'AP_Calc_BC') {
                // Parse eng_score to accumulate APscore, mcq, and frq
                try {
                    const scores = JSON.parse(item.eng_score);
                    apScoreTotal += scores.APscore || 0;
                    scorearr.push(scores.APscore);
                    mcqTotal += scores.mcq || 0;
                    frqTotal += scores.frq || 0;
                    count += 1;
                } catch (error) {
                    console.error("Error parsing eng_score:", error);
                }

                // Parse Incorrect_questionIds to collect all incorrect question IDs
                try {
                    const incorrectIds = JSON.parse(item.Incorrect_questionIds || '[]');
                    if (Array.isArray(incorrectIds)) {
                        incorrectIds.forEach(q => {
                            // Validate that q.question_id and q.topic exist
                            if (!q.question_id || !Array.isArray(q.topic) || q.topic.length === 0) {
                                console.warn("Invalid question format or missing fields:", q);
                                return; // Skip this entry
                            }

                            // Push the question ID to the list for unique counting
                            incorrectQuestionIDList.push(q.question_id);

                            if (q.section === 'mcq') {
                                const topicId = q.topic[0];
                                const name = mcqIdToNameMap[topicId] || 'Unknown Topic';
                                mcq.push({ id: q.question_id, topic: topicId, name: name });
                            } else if (q.section === 'frqa' || q.section === 'frqb') {
                                const topicId = q.topic[0];
                                const name = frqIdToNameMap[topicId] || 'Unknown Topic';
                                frq.push({ id: q.question_id, topic: topicId, name: name });
                            } else {
                                console.warn("Invalid section in question:", q);
                            }
                        });
                    } else {
                        console.warn("Incorrect_questionIds is not an array:", incorrectIds);
                    }
                } catch (error) {
                    console.error("Error parsing Incorrect_questionIds:", error);
                }
            }
        });

        // Remove duplicate question IDs
        const uniqueIncorrectQuestionIds = [...new Set(incorrectQuestionIDList)];

        // Function to count and sort duplicate topics
        const getDuplicateTopics = (questions, idToNameMap) => {
            const topicCounts = questions.reduce((acc, curr) => {
                acc[curr.topic] = (acc[curr.topic] || 0) + 1;
                return acc;
            }, {});

            // Convert to array and filter duplicates (count > 1)
            const duplicates = Object.entries(topicCounts)
                .filter(([topic, count]) => count > 1)
                .map(([topic, count]) => ({
                    topic,
                    name: idToNameMap[topic] || 'Unknown Topic',
                    count
                }));

            // Sort duplicates in descending order of count
            duplicates.sort((a, b) => b.count - a.count);

            return duplicates;
        };

        // Count and sort duplicate topics in MCQ and FRQ
        const duplicateMCQTopics = getDuplicateTopics(mcq, mcqIdToNameMap);
        const duplicateFRQTopics = getDuplicateTopics(frq, frqIdToNameMap);
        setscorearr(scorearr);
        const labels = generateLabels(scorearr.length);
        setData3({
            labels: labels,
            datasets: [
                {
                    label: 'AP Calculus BC Score',
                    data: scorearr,
                    fill: false,
                    borderColor: 'rgba(51, 65, 85, 1)',
                    tension: 0.1
                }
            ]
        });

        return {
            averageAPScore: count > 0 ? apScoreTotal / count : 0,
            averageMCQ: count > 0 ? mcqTotal / count : 0,
            averageFRQ: count > 0 ? frqTotal / count : 0,
            incorrectQuestionIds: uniqueIncorrectQuestionIds,
            totalIncorrectQuestions: incorrectQuestionIDList.length, // Total with duplicates
            uniqueTotalIncorrectQuestions: uniqueIncorrectQuestionIds.length, // Total unique
            averageIncorrectQuestions: count > 0 ? incorrectQuestionIDList.length / count : 0,
            duplicateMCQTopics, // Sorted array of { topic, count } for duplicated MCQ topics
            duplicateFRQTopics  // Sorted array of { topic, count } for duplicated FRQ topics
        };
    };

    // Function to set the latest APscore
    const setLatestAPScore = (data) => {
        const latestItem = data.find(item => item.type === 'AP_Calc_BC');
        if (latestItem) {
            try {
                const scores = JSON.parse(latestItem.eng_score);
                setlastscore(scores.APscore || 0);
            } catch (error) {
                console.error("Error parsing eng_score for lastscore:", error);
            }
        }
    };

    useEffect(() => {
        if (!user) {
            toast.error("Please login before starting the test");
            navigate("/login"); // Redirect user to the /login page
            return;
        }

        let data = {
            id: user.u_id
        };

        axios.post(BASELINE + 'testresult/get', data)
            .then(response => {
                const reversedData = response.data.slice().reverse(); // Create a copy and reverse

                // Calculate averages
                const averages = calculateAverages(reversedData);
                setAverageAPScore(averages.averageAPScore);
                setAverageMCQ(averages.averageMCQ);
                setAverageFRQ(averages.averageFRQ);
                setIncorrect_questionIds(averages.duplicateMCQTopics);
                setIncorrect_frqquestionIds(averages.duplicateFRQTopics);
                // Set last score
                setLatestAPScore(reversedData);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleRowClick = (subjectName, section) => {
        setsection(section);
        settopic(subjectName);
        setIsModalOpen2(true);
        // You can perform any actions here, such as navigation or displaying details
    };

    function findTopMissedTopics(data, tag) {
        const topicCount = {};

        // Count incorrect topics for the specified tag
        data.forEach(item => {
            if (item.tag === tag && !item.correct) {
                topicCount[item.topic] = (topicCount[item.topic] || 0) + 1;
            }
        });

        // Convert the topicCount object into an array of [topic, count] pairs
        const sortedTopics = Object.entries(topicCount).sort((a, b) => b[1] - a[1]);

        // Select the top 10
        return sortedTopics.slice(0, 10).map(entry => ({ topic: entry[0], count: entry[1] }));
    }

    function generateLabels(length) {
        return Array.from({ length }, (_, index) => `Test ${index + 1}`);
    }

    const [data3, setData3] = useState({
        labels: [], // Will be set after calculation
        datasets: []
    });

    const data4 = {
        labels: [''],
        datasets: [
            {
                label: 'MCQ Max Score',
                data: [45],
                backgroundColor: 'rgba(201, 203, 207, 0.6)',
            },
            {
                label: 'MCQ Score',
                data: [averageMCQ],
                backgroundColor: 'rgba(51, 65, 85, 1)',
            }
        ],
    };

    const options4 = {
        indexAxis: 'y',
        scales: {
            x: {
                max: 45,
                beginAtZero: true,
            },
        },
    };

    const data5 = {
        labels: [''],
        datasets: [
            {
                label: 'FRQ Max Score',
                data: [54],
                backgroundColor: 'rgba(201, 203, 207, 0.6)',
            },
            {
                label: 'FRQ Score',
                data: [averageFRQ],
                backgroundColor: 'rgba(51, 65, 85, 1)',
            }
        ],
    };

    const options5 = {
        indexAxis: 'y',
        scales: {
            x: {
                max: 54,
                beginAtZero: true,
            },
        },
    };

    // Options for the line graph
    const options3 = {
        scales: {
            y: {
                beginAtZero: false,  // Change this as needed
                suggestedMax: 5  // Assuming SAT scores are out of 5
            }
        },
        maintainAspectRatio: false
    };

    const data = {
        datasets: [{
            data: [avg_sat, 5 - avg_sat],
            backgroundColor: ['#334155', '#D0D0D0'],
            hoverBackgroundColor: ['#334155', '#D0D0D0'],
            borderWidth: 0,
        }],
        labels: ['Score', 'Remaining'],
    };


    return (
        <div className="bg-white">
            <ModalComponent topic={topic} isOpen={isModalOpen2} onClose={setIsModalOpen2} section={'Apcalcab'} type={'AP_Calc_AB'} />
            <div className="p-6 flex flex-col items-center">
                <div className="w-full max-w-[100%]">
                    <div className="mb-4 text-gray-500 text-sm">
                        <span className="hover:underline cursor-pointer">AP Calculus BC</span> &gt; <span className="font-semibold text-black">Dashboard</span>
                    </div>
                    <h1 className="text-2xl font-bold mt-4">Digital AP Calculus BC</h1>

                    {isVisible && (
                        <div className="p-6 bg-white shadow-md rounded-md">
                            <button
                                onClick={handleClose}
                                className="text-gray-600 hover:text-gray-800 ml-[99%]"
                            >
                                ✖
                            </button>
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <div className="bg-indigo-500 rounded-full p-4">
                                        <RiChatSmileFill className="text-white text-4xl" />
                                    </div>
                                </div>
                                {user && (
                                    <div>
                                        <div className="text-lg font-medium text-black">
                                            Welcome <span className="gradient-text font-bold">{user.first_name}</span> <span role="img" aria-label="wave">👋</span>
                                        </div>
                                        <div className="text-gray-500">{user.email}</div>
                                        <div className="mt-2 text-gray-600">
                                            Study hard and aim for a <span className="font-semibold text-black">5 </span> on the <span className="text-red-500 font-semibold">AP Calculus BC!</span> <span role="img" aria-label="gift">🎁</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>


            <div className="pl-6 pr-6 flex flex-col items-center">
                <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="p-6 bg-gradient-to-br from-gray-800 to-gray-900 text-white shadow-md rounded-md flex flex-col justify-between">
                            <div>
                                <div className="flex items-center mb-4">
                                    <span className="bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital AP Calculus BC</span>
                                    <span className="text-lg font-medium">Your Latest AP Score</span>
                                </div>

                                <div className="flex">
                                    <div className="text-5xl font-bold mb-4">{lastscore}</div>
                                    <div className="text-5xl mb-4 ml-4"> | 1-5</div>
                                </div>

                                <span className="gradient-text">
                                    Check if you have an unfinished test. <br /> Complete the test to get your analytics report.
                                </span>
                            </div>
                            <div className="flex gap gap-2">

                                <button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 rounded-md mb-2" onClick={() => { setdashboard(5); }}
                                >

                                    Continue the Test
                                </button>
                                <button className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 rounded-md mb-2" onClick={() => { setdashboard(201); }}
                                >
                                    Get Help Session
                                </button>
                            </div>
                        </div>

                        <div className="p-6 bg-white shadow-md rounded-md">
                            <div className="h-full flex flex-col justify-center items-center">
                                <div className="w-full h-64 flex justify-center items-center">
                                    <Bar data={data2} options={options2} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="mt-6 pl-6 pr-6 flex flex-col items-center">
                <div className="w-full flex gap-4" style={{ maxHeight: '330px' }}>
                    <div className="relative flex-3 bg-white p-4 shadow rounded" style={{ flex: 2 }}>
                        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                            <div className="h-full w-full bg-white rounded-xl"></div>
                        </div>
                        <div className="relative">
                            <h2 className="text-lg font-semibold mb-2">Overall Average AP Score</h2>
                            <div style={{ height: '180px' }}>
                                <Doughnut
                                    data={data}
                                    options={{
                                        maintainAspectRatio: false, // This prevents the chart from inheriting aspect ratio
                                        aspectRatio: 1, // Sets the aspect ratio to 1:1 (you can adjust as needed)
                                    }}
                                />
                            </div>
                            <div className="text-center mt-2">
                                {avg_sat && (<>
                                    <span className="text-2xl font-bold">{avg_sat}</span> / 5
                                </>)}
                            </div>
                        </div>
                    </div>

                    <div className="relative flex-3 bg-white p-4 shadow rounded" style={{ flex: 4 }}>
                        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                            <div className="h-full w-full bg-white rounded-xl"></div>
                        </div>

                        <div className="relative">
                            <h2 className="text-lg font-semibold mb-2">Average MCQ. Scores</h2>

                            <div className="h-full flex flex-col justify-center items-center">
                                <div className="w-full h-64 flex justify-center items-center">
                                    <Bar data={data4} options={options4} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative flex-3 bg-white p-4 shadow rounded" style={{ flex: 4 }}>
                        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                            <div className="h-full w-full bg-white rounded-xl"></div>
                        </div>

                        <div className="relative">
                            <h2 className="text-lg font-semibold mb-2">Average FRQ. Scores</h2>

                            <div className="h-full flex flex-col justify-center items-center">
                                <div className="w-full h-64 flex justify-center items-center">
                                    <Bar data={data5} options={options5} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 pl-6 pr-6 flex flex-col items-center">
                <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="p-6 bg-gradient-to-br from-gray-800 to-gray-900 text-white shadow-md rounded-md flex flex-col justify-between">
                            <div>
                                <div className="flex items-center mb-4">
                                    <span className="bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital SAT</span>
                                    <span className="text-xl font-bold">🏆Recomend MCQ. Missing Topics</span>
                                </div>
                                <div className="flex-7 p-4 rounded overflow-auto" style={{ maxHeight: '240px', overflowY: 'auto' }}>

                                    <ul className="list-disc pl-5">
                                        {Incorrect_questionIds.map((topic, index) => (
                                            <li
                                                key={index}
                                                className="mb-1 text-xl cursor-pointer hover:bg-gray-300"
                                                onClick={() => handleRowClick(topic.topic, "mcq")}
                                                style={{ transition: 'background-color 0.3s' }}

                                            >
                                                {topic.name}: {topic.count}
                                            </li>
                                        ))}
                                    </ul>


                                </div>
                            </div>

                        </div>

                        <div className="p-6 bg-gradient-to-br from-gray-800 to-gray-900 text-white shadow-md rounded-md flex flex-col justify-between">
                            <div>
                                <div className="flex items-center mb-4">
                                    <span className="bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital SAT</span>
                                    <span className="text-xl font-bold">🏆Recomend FRQ. Missing Topics</span>
                                </div>

                                <div className="flex-7 p-4 rounded overflow-auto" style={{ maxHeight: '240px', overflowY: 'auto' }}>

                                    <ul className="list-disc pl-5">
                                        {Incorrect_frqquestionIds.map((topic, index) => (
                                            <li
                                                key={index}
                                                className="mb-1 text-xl cursor-pointer hover:bg-gray-300"
                                                onClick={() => handleRowClick(topic.topic, "frq")}
                                                style={{ transition: 'background-color 0.3s' }}
                                            >
                                                {topic.name}: {topic.count}
                                            </li>
                                        ))}
                                    </ul>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="relative flex-3 p-6 bg-white pr-6 pl-6 mr-6 ml-6 mb-[100px] shadow rounded mt-6">
                <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                    <div className="h-full w-full bg-white rounded-xl"></div>
                </div>

                <div className="relative">
                    <h2 className="text-lg font-semibold mb-2">Overall Digital AP Calculus BC Scores</h2>

                    <div className="h-full flex flex-col justify-center items-center">
                        <div className="w-full h-64 flex justify-center items-center">
                            <Line data={data3} options={options3} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DigitalAPBCView;
