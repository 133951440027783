import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../util";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ModalComponent from "../dashboard/pick_modal";

ChartJS.register(BarElement, CategoryScale, LinearScale);

const Hero = ({ testData, free }) => {
  console.log("=testData", testData);
  const { user } = useContext(UserContext);
  const [percentage, setPercentage] = useState(0);
  const [date, setDate] = useState();
  const [missingTopics, setMissingTopics] = useState([]);
  const [testname, setTestname] = useState("");
  const [name, setname] = useState("");
  const [missing_topics, setMissing_topics] = useState([]);
  const [newmissing_topics, setnewMissing_topics] = useState([]);

  const [eng, seteng] = useState(0);
  const [math, setmath] = useState(0);

  const [quizmode, setquizmode] = useState(false);
  const maxScore = 1600;

  const [correctRate, setCorrectRate] = useState("");
  const [incorrectRate, setIncorrectRate] = useState("");
  const [markedRate, setMarkedRate] = useState("");
  const [omittedRate, setOmittedRate] = useState("");
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [topic, settopic] = useState(false);
  const [section, setsection] = useState("");

  const [apmcq, setapmcq] = useState(0);
  const [apfrq, setapfrq] = useState(0);

  const [mcqsubjects, setmcqsubjects] = useState([
    {
      main: "Concept of a Limit", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U1_1', name: '1.1 Introducing Calculus: Can Change Occur at an Instant?', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_2', name: '1.2 Defining Limits and Using Limit Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_3', name: '1.3 Estimating Limit Values from Graphs', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_4', name: '1.4 Estimating Limit Values from Tables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_5', name: '1.5 Determining Limits Using Algebraic Properties of Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_6', name: '1.6 Determining Limits Using Algebraic Manipulation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_7', name: '1.7 Selecting Procedures for Determining Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_8', name: '1.8 Determining Limits Using the Squeeze Theorem', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_9', name: '1.9 Connecting Multiple Representations of Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Continuity", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U1_10', name: '1.10 Exploring Types of Discontinuities', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_11', name: '1.11 Defining Continuity at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_12', name: '1.12 Confirming Continuity over an Interval', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_13', name: '1.13 Removing Discontinuities', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Asymptotes & IVT", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U1_14', name: '1.14 Connecting Infinite Limits and Vertical Asymptotes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_15', name: '1.15 Connecting Limits at Infinity and Horizontal Asymptotes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_16', name: '1.16 Working with the Intermediate Value Theorem (IVT)', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Definition and Existence of Derivatives", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U2_1', name: '2.1 Defining Average and Instantaneous Rates of Change at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_2', name: '2.2 Defining the Derivative of a Function and Using Derivative Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_3', name: '2.3 Estimating Derivatives of a Function at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_4', name: '2.4 Connecting Differentiability and Continuity: Determining When Derivatives Do and Do Not Exist', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Calculating Derivatives", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U2_5', name: '2.5 Applying the Power Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_6', name: '2.6 Derivative Rules: Constant, Sum, Difference, and Constant Multiple', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_7', name: '2.7 Derivatives of cos(x), sin(x), mathrm, and ln(x)', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_8', name: '2.8 The Product Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_9', name: '2.9 The Quotient Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_10', name: '2.10 Finding the Derivatives of Tangent, Cotangent, Secant, and/or Cosecant Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Advanced Derivative Rules", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U3_1', name: '3.1 The Chain Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_2', name: '3.2 Implicit Differentiation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Inverse Functions and Higher-Order Derivatives", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U3_3', name: '3.3 Differentiating Inverse Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_4', name: '3.4 Differentiating Inverse Trigonometric Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_5', name: '3.5 Selecting Procedures for Calculating Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_6', name: '3.6 Calculating HigherOrder Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Derivatives in Context", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U4_1', name: '4.1 Interpreting the Meaning of the Derivative in Context', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_2', name: '4.2 Straight-Line Motion: Connecting Position, Velocity, and Acceleration', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_3', name: '4.3 Rates of Change in Applied Contexts Other Than Motion', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Related Rates", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U4_4', name: '4.4 Introduction to Related Rates', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_5', name: '4.5 Solving Related Rates Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Linearity and L'Hospital's Rule", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U4_6', name: '4.6 Approximating Values of a Function Using Local Linearity and Linearization', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_7', name: '4.7 Using LHospitals Rule for Determining Limits of Indeterminate Forms', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "MVT and EVT", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U5_1', name: '5.1 Using the Mean Value Theorem', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_2', name: '5.2 Extreme Value Theorem, Global Versus Local Extrema, and Critical Points', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Function Behavior", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U5_3', name: '5.3 Determining Intervals on Which a Function Is Increasing or Decreasing', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_4', name: '5.4 Using the First Derivative Test to Determine Relative (Local) Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_5', name: '5.5 Using the Candidates Test to Determine Absolute (Global) Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_6', name: '5.6 Determining Concavity of Functions over Their Domains', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_7', name: '5.7 Using the Second Derivative Test to Determine Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_8', name: '5.8 Sketching Graphs of Functions and Their Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_9', name: '5.9 Connecting a Function, Its First Derivative, and Its Second Derivative', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Optimization", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U5_10', name: '5.10 Introduction to Optimization Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_11', name: '5.11 Solving Optimization Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_12', name: '5.12 Exploring Behaviors of Implicit Relations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Concept of Integration", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U6_1', name: '6.1 Exploring Accumulations of Change', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_2', name: '6.2 Approximating Areas with Riemann Sums', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_3', name: '6.3 Riemann Sums, Summation Notation, and Definite Integral Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Fundamental Theorem of Calculus", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U6_4', name: '6.4 The Fundamental Theorem of Calculus and Accumulation Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_5', name: '6.5 Interpreting the Behavior of Accumulation Functions Involving Area', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_6', name: '6.6 Applying Properties of Definite Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_7', name: '6.7 The Fundamental Theorem of Calculus and Definite Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_8', name: '6.8 Finding Antiderivatives and Indefinite Integrals: Basic Rules and Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Integration Techniques", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U6_9', name: '6.9 Integrating Using Substitution', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_10', name: '6.10 Integrating Functions Using Long Division and Completing the Square', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_11', name: '6.11 Integrating Using Integration by Parts', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_12', name: '6.12 Using Linear Partial Fractions ', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_13', name: '6.13 Evaluating Improper Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_14', name: '6.14 Selecting Techniques for Antidifferentiation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Analyzing Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_1', name: '7.1 Modeling Situations with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_2', name: '7.2 Verifying Solutions for Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_3', name: '7.3 Sketching Slope Fields', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_4', name: '7.4 Reasoning Using Slope Fields', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Solving Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_5', name: '7.5 Approximating Solutions Using Eulers Method', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_6', name: '7.6 Finding General Solutions Using Separation of Variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_7', name: '7.7 Finding Particular Solutions Using Initial Conditions and Separation of Variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Modeling Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_8', name: '7.8 Exponential Models with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Solving Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_9', name: '7.9 Logistic Models with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Definite Integrals in Context", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_1', name: '8.1 Finding the Average Value of a Function on an Interval', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_2', name: '8.2 Connecting Position, Velocity, and Acceleration of Functions Using Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_3', name: '8.3 Using Accumulation Functions and Definite Integrals in Applied Contexts', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Area Between Curves", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_4', name: '8.4 Finding the Area Between Curves Expressed as Functions of x', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_5', name: '8.5 Finding the Area Between Curves Expressed as Functions of y', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_6', name: '8.6 Finding the Area Between Curves That Intersect at More Than Two Points', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Volume", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_7', name: '8.7 Volumes with Cross Sections: Squares and Rectangles', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_8', name: '8.8 Volumes with Cross Sections: Triangles and Semicircles', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_9', name: '8.9 Volume with Disc Method: Revolving Around the x-or y-Axis', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_10', name: '8.10 Volume with Disc Method: Revolving Around Other Axes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_11', name: '8.11 Volume with Washer Method: Revolving Around the x - or y-Axis', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_12', name: '8.12 Volume with Washer Method: Revolving Around Other Axes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Arc Length", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_13', name: '8.13 The Arc Length of a Smooth, Planar Curve and Distance Traveled', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Parametric Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U9_1', name: '9.1 Defining and Differentiating Parametric Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_2', name: '9.2 Second Derivatives of Parametric Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_3', name: '9.3 Finding Arc Lengths of Curves Given by Parametric Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Vector-Valued Functions", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U9_4', name: '9.4 Defining and Differentiating VectorValued Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_5', name: '9.5 Integrating VectorValued Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_6', name: '9.6 Solving Motion Problems Using Parametric and VectorValued Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Polar Coordinates", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U9_7', name: '9.7 Defining Polar Coordinates and Differentiating in Polar Form', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_8', name: '9.8 Find the Area of a Polar Region or the Area Bounded by a Single Polar Curve', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_9', name: '9.9 Finding the Area of the Region Bounded by Two Polar Curves', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Infinite Series", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U10_1', name: '10.1 Defining Convergent and Divergent Infinite Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_2', name: '10.2 Working with Geometric Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Tests for Convergence and Divergence", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U10_3', name: '10.3 The nth Term Test for Divergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_4', name: '10.4 Integral Test for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_5', name: '10.5 Harmonic Series and p-Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_6', name: '10.6 Comparison Tests for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_7', name: '10.7 Alternating Series Test for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_8', name: '10.8 Ratio Test for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Sum of Series", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U10_9', name: '10.9 Determining Absolute or Conditional Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_10', name: '10.10 Alternating Series Error Bound', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_11', name: '10.11 Finding Taylor Polynomial Approximations of Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_12', name: '10.12 Lagrange Error Bound', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_13', name: '10.13 Radius and Interval of Convergence of Power Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_14', name: '10.14 Finding Taylor or Maclaurin Series for a Function', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_15', name: '10.15 Representing Functions as Power Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
  ]);

  const [topics, settopics] = useState([
    { topic: "POW", full: "Production of Wirting" },
    { topic: "KLA", full: "knowledge of Language" },
    { topic: "CSE", full: "Conventions of Standard English" },

    { topic: "IES", full: "Intergrating Essential Skills" },
    { topic: "S", full: "Statistics and Probability" },
    { topic: "A", full: "Algebra" },
    { topic: "G", full: "Geometry" },
    { topic: "F", full: "Functions" },
    { topic: "N", full: "Number and Quantity" },
    { topic: "T", full: "Trigonometry" },

    { topic: "CS", full: "Craft and Structure" },
    { topic: "KID", full: "Key Ideas and Details" },
    { topic: "IKI", full: "Integration of Knowledge and Ideas" },

    { topic: "IOD", full: "Interpretation of Data" },
    { topic: "SIN", full: "Scientific Investigation" },
    { topic: "EMI", full: "Evaluation Models, Inferences, and Exprimental Results" }
  ]);

  const data = {
    labels: [''],
    datasets: [
      {
        label: 'Predicted Score',
        data: [percentage],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Target Score',
        data: [maxScore],
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
      },
    ],
  };
  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        max: maxScore,
        beginAtZero: true,
      },
    },
  };

  const maxScore2 = 100;
  const data2 = {
    labels: [''],
    datasets: [
      {
        label: 'Predicted Score',
        data: [percentage],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Target Score',
        data: [maxScore2],
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
      },
    ],
  };
  const options2 = {
    indexAxis: 'y',
    scales: {
      x: {
        max: maxScore2,
        beginAtZero: true,
      },
    },
  };

  const maxScore3 = 5;
  const data3 = {
    labels: [''],
    datasets: [
      {
        label: 'My Score',
        data: [percentage],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Target Score',
        data: [maxScore3],
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
      },
    ],
  };
  const options3 = {
    indexAxis: 'y',
    scales: {
      x: {
        max: maxScore3,
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    if (missing_topics && missing_topics.length > 0) {
      getSimilarTopics();
    }
  }, [missing_topics]);

  function calculateRate(questionIds, total) {
    if (total === 0) return "0%"; // Avoid division by zero
    const rate = (questionIds.length / total) * 100;

    return `${Math.min(rate, 100).toFixed(0)}%`; // Cap the rate at 100% and format it
  }


  async function sendResult(data) {
    try {
      // Check if `eng_incorrect_questionIds` is defined and parse it safely

      var eng_incorrect_questionIds = data.eng_incorrect_questionIds ? JSON.parse(data.eng_incorrect_questionIds) : [];
      if (data.section_data !== null) {

        // Safely parse the JSON fields
        var Correct_questionIds = data.Correct_questionIds ? safeParseJSON(data.Correct_questionIds) : [];
        var Incorrect_questionIds = data.Incorrect_questionIds ? safeParseJSON(data.Incorrect_questionIds) : [];
        var Marked_questionIds = data.Marked_questionIds ? safeParseJSON(data.Marked_questionIds) : [];
        var Omitted_questionIds = data.Omitted_questionIds ? safeParseJSON(data.Omitted_questionIds) : [];

        // Your existing logic...
        if (testData.type !== 'AP_Calc_AB') {
          setCorrectRate(calculateRate(Correct_questionIds, 98));
          setIncorrectRate(calculateRate(Incorrect_questionIds, 98));
          setMarkedRate(calculateRate(Marked_questionIds, 98));
          setOmittedRate(calculateRate(Omitted_questionIds, 98));
        } else {
          const mcqItems = Correct_questionIds.filter(item => item.section === 'mcq');
          setapmcq(mcqItems);

          const frqItems = Correct_questionIds.filter(item => item.section === 'frq');
          setapfrq(frqItems);

          setCorrectRate(calculateRate(Correct_questionIds, 51));
          setIncorrectRate(calculateRate(Incorrect_questionIds, 51));
          setMarkedRate(calculateRate(Marked_questionIds, 51));
          setOmittedRate(calculateRate(Omitted_questionIds, 51));
        }

        if (testData.quiz_data !== null) {
          console.log("1111");
          setquizmode(true);
        }

        seteng(data.eng_score);
        setmath(data.math_score);
        let newPercentage = Number(data.eng_score) + Number(data.math_score);
        setPercentage(newPercentage);

        var sat = data.section_data ? safeParseJSON(data.section_data) : [];
        let incorrectTopics = sat.filter(s => !s.correct).map(s => s.topic);
        let uniqueIncorrectTopics = [...new Set(incorrectTopics)];
        setMissingTopics([...uniqueIncorrectTopics]);
      } else {

        var length = JSON.parse(data.quiz_data).length;
        var eng_score = Math.round((JSON.parse(data.Correct_questionIds).length / length) * 100);

        setPercentage(eng_score);
        setquizmode(true);
      }

      let dateObj = new Date(data.date);
      let formattedDate = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
      setDate(formattedDate);
      setname(data.user_name);

      setTestname(testData.test_name);
      if (eng_incorrect_questionIds.length > 0) {
        setMissing_topics([...eng_incorrect_questionIds]);
      } else {
        let inc = JSON.parse(data.Incorrect_questionIds);
        const uniqueTopics = extractUniqueTopics(inc, data.type);
        setnewMissing_topics([...uniqueTopics]);
      }
    } catch (error) {
      //  console.error("Error in sendResult:", error.message);
    }
  }

  function safeParseJSON(jsonString) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Invalid JSON:", jsonString, error.message);
      return []; // Return an empty array or handle the error appropriately
    }
  }


  function extractUniqueTopics(questions, type) {
    const uniqueEntries = new Set(); // Use a Set to avoid duplicates
    const topicsWithTags = []; // To store the unique tag-topic pairs

    if (type === 'ACT') {
      questions.forEach(question => {
        const entry = `${question.type}-${question.category}`; // Combine tag and topic
        if (!uniqueEntries.has(entry)) {
          uniqueEntries.add(entry);
          topicsWithTags.push({ type: question.type, category: question.category });
        }
      });

    }
    else if (type === 'AP_Calc_AB') {
      questions.forEach(question => {
        const entry = `${question.type}-${question.topic[0]}`; // Combine tag and topic
        if (!uniqueEntries.has(entry)) {
          uniqueEntries.add(entry);
          topicsWithTags.push({ type: question.type, category: question.topic[0] });
        }
      });

    }
    else {
      questions.forEach(question => {
        const entry = `${question.tag}-${question.topic}`; // Combine tag and topic
        if (!uniqueEntries.has(entry)) {
          uniqueEntries.add(entry);
          topicsWithTags.push({ tag: question.tag, topic: question.topic });
        }
      });
    }

    return topicsWithTags; // Return array of unique tag-topic pairs
  }


  useEffect(() => {
    if (!free) {
      sendResult(testData);
    } else {

      if (testData.section_data !== null) {
        const totalScore = parseInt(testData.eng_score, 10) + parseInt(testData.math_score, 10);
        setPercentage(totalScore);
        seteng(testData.eng_score);
        setmath(testData.math_score);
        if (testData.type !== 'AP_Calc_AB') {
          setquizmode(true);
        };
      } else {
        console.log("h2");
        setquizmode(false);
        if (testData && testData.questions) {
          console.log("h3");
          setquizmode(false);
          const totalQuestions = testData.questions.length;
          const correctAnswers = testData.questions.filter(question => question.correct).length;
          const percentage = Math.round((correctAnswers / totalQuestions) * 100);
          setPercentage(percentage);
        }
      }
    }
  }, [testData])

  async function getSimilarTopics() {

    try {
      var res;
      if (testname.includes("89")) {
        res = await axios.get(BASELINE + "topics/89", { params: { itemIds: missing_topics } });
      }
      else if (testname.includes("10")) {
        res = await axios.get(BASELINE + "topics/10", { params: { itemIds: missing_topics } });
      }
      else {
        res = await axios.get(BASELINE + "topics", { params: { itemIds: missing_topics } });
      }
      let uniqueData = [...new Set(res.data)];
      setMissingTopics([...uniqueData]);

    } catch (err) {
      //  console.log(err);
    }

  }
  const capitalizeFirstLetter = (section) => {
    if (!section) return '';  // Check if section is empty or undefined
    return section.charAt(0).toUpperCase() + section.slice(1).toLowerCase();
  }
  const handleRowClick = (subjectName, section) => {
    if (section === 'eng') {
      setsection("English");
    } else if (section === 'ACT') {
      setsection("ACT");
    } else {
      setsection(capitalizeFirstLetter(section));
    }
    settopic(subjectName);
    setIsModalOpen2(true);
    // You can perform any actions here, such as navigation or displaying details
  };

  useEffect(() => {
    console.log("=>", quizmode);
  }, [quizmode]);

  return (<>
    <ModalComponent topic={topic} isOpen={isModalOpen2} onClose={setIsModalOpen2} section={section} type={testData?.type} />

    <div className="flex mt-2 justify-center items-center flex-col w-full h-full">
      <section className="wrapper flex flex-col items-center py-10 px-4">
        <div className="pl-6 pr-6 w-full max-w-[1000px] 2xl:max-w-[1300px] bg-white p-8 rounded-2xl shadow-md">
          <h1 className="text-[24px] sm:text-[32px] md:text-[40px] font-bold text-black">
            {user ? (
              <>
                <span className="gradient-text">{user.first_name}</span>'s Analytics Report is Here!
              </>
            ) : (
              <>
                <span className="gradient-text">Welcome User!</span>
              </>
            )}

          </h1>
          <h1 className="text-[24px] sm:text-[32px] md:text-[40px] font-bold text-black text-center sm:text-left">
            {free && (
              <>
                <span className="gradient-text">Free Trial Analytics Report is Here!</span>
              </>
            )}
          </h1>

          <h2 className="text-[20px] sm:text-[24px] md:text-[28px] font-bold text-black">
            Uncover Your Insights Through MMTPrep.
          </h2>
          <p className="text-gray-700 mt-[5px]">
            Our AI-powered test accurately predicts your score and provides a detailed analysis of your strengths and weaknesses based on the questions you’ve answered.
          </p>
          <div className="mt-8">
            <h3 className="text-[18px] sm:text-[22px] md:text-[26px] font-bold text-black">
              Score Analysis
            </h3>
            <div className="mt-4 bg-gray-900 text-white p-6 rounded-lg shadow-md flex flex-col sm:flex-row sm:justify-between sm:items-center">
              <div className="flex flex-col items-center sm:items-start text-center sm:text-left">
                {testData.type === 'AP_Calc_AB' ? (
                  <span className="text-[24px] font-bold">AP Test Score</span>
                ) : (
                  <span className="text-[24px] font-bold">Total Score</span>
                )}
                <span className="text-[32px] sm:text-[48px] md:text-[64px] font-bold">
                  {testData.type === 'AP_Calc_AB' ? (
                    quizmode
                      ? `${percentage} %`
                      : `${testData.eng_score && JSON.parse(testData.eng_score)?.APscore ? JSON.parse(testData.eng_score).APscore : 'N/A'} / 5`
                  ) : (
                    quizmode
                      ? `${percentage} %`
                      : `${percentage} / 1600`
                  )}

                </span>
                {free ? (
                  <span className="bg-purple-600 text-white text-[14px] px-2 py-1 rounded-full mt-2">
                    Math Quiz Report
                  </span>
                ) : (
                  <span className="bg-purple-600 text-white text-[14px] px-2 py-1 rounded-full mt-2">
                    {testname} REPORT
                  </span>
                )}
                {quizmode === false && (
                  <span className="bg-gray-700 text-white text-[14px] px-2 py-1 rounded-full mt-2">
                    SAT Total Score 1600
                  </span>
                )}
                {quizmode === true && (
                  <span className="bg-gray-700 text-white text-[14px] px-2 py-1 rounded-full mt-2">
                    Practice Test Total 100%
                  </span>
                )}
              </div>

              <div className="relative mb-4 hidden sm:block">
                {testData.type === 'AP_Calc_AB' ? (
                  quizmode === true ? (
                    <Bar data={data2} options={options2} />
                  ) : (
                    <Bar data={data3} options={options3} />
                  )
                ) : (
                  quizmode === true ? (
                    <Bar data={data2} options={options2} />
                  ) : (
                    <Bar data={data} options={options} />
                  )
                )}
              </div>

              <div className="mt-[-20px] text-sm hidden sm:block">
                {quizmode === false ? (
                  <>
                    <span className="block gradient-text">Your SAT Score</span>
                    <span className="mt-[20px] block text-gray-500">Target Score</span>
                  </>
                ) : (
                  <>
                    <span className="block gradient-text">Your Score</span>
                    <span className="mt-[20px] block text-gray-500">Target Score</span>
                  </>
                )}
              </div>
            </div>

          </div>

          {quizmode === false && (<>
            <div className="w-[100%] mt-4 flex flex-col sm:flex-row justify-around gap-4">
              <GradientBorderBox
                header="SAT Section Score"
                title="Reading & Writing"
                score={eng}
                range="200 - 800"
                description="Upgrade and Develop your skills!"
                icon="🗣️"
              />
              <GradientBorderBox
                header="SAT Section Score"
                title="Math"
                score={math}
                range="200 - 800"
                description="Upgrade and Develop your skills!"
                icon="🚀"
              />
            </div>

            <div className="w-[100%] mt-4 flex flex-col sm:flex-row justify-around gap-4">
              <GradientBorderBox
                header="Overall Performance"
                title="Accuracy Rate"
                score={correctRate}
                range="%"
                description="You're on the right track!"
                icon="✔️"
              />
              <GradientBorderBox
                header="Areas for Improvement"
                title="Error Rate"
                score={incorrectRate}
                range="%"
                description="Review incorrect answers to improve further."
                icon="❌"
              />

              <GradientBorderBox
                header="Review Priority"
                title="Reviewed Questions"
                score={markedRate}
                range="%"
                description="Good effort in revisiting questions!"
                icon="🔖"
              />
              <GradientBorderBox
                header="Opportunities Missed"
                title="Skipped Questions"
                score={omittedRate}
                range="%"
                description="Try to attempt more questions next time to maximize your scoring potential."
                icon="🫙"
              />
            </div>

          </>)}

          {testData.section_data !== null && testData.type === 'AP_Calc_AB' && (<>
            <div className="w-[100%] mt-4 flex flex-col sm:flex-row justify-around gap-4">
              <GradientBorderBox
                header="AP Calculus AB MCQ Score"
                title="Multiple Choice Questions"
                score={apmcq.length}
                range="1 - 45"
                description="Upgrade and Develop your skills!"
                icon="☑"
              />
              <GradientBorderBox
                header="AP Calculus AB FRQ Score"
                title="Free Response Question"
                score={apfrq.length}
                range="1 - 36"
                description="Upgrade and Develop your skills!"
                icon="🚀"
              />
            </div>

            <div className="w-[100%] mt-4 flex flex-col sm:flex-row justify-around gap-4">
              <GradientBorderBox
                header="Overall Performance"
                title="Accuracy Rate"
                score={correctRate}
                range=""
                description="You're on the right track!"
                icon="✔️"
              />
              <GradientBorderBox
                header="Areas for Improvement"
                title="Error Rate"
                score={incorrectRate}
                range=""
                description="Review incorrect answers to improve further."
                icon="❌"
              />

              <GradientBorderBox
                header="Review Priority"
                title="Reviewed Questions"
                score={markedRate}
                range=""
                description="Good effort in revisiting questions!"
                icon="🔖"
              />
              <GradientBorderBox
                header="Opportunities Missed"
                title="Skipped Questions"
                score={omittedRate}
                range=""
                description="Try to attempt more questions next time to maximize your scoring potential."
                icon="🫙"
              />
            </div>

          </>)}

          <div className="mt-4 bg-gray-900 text-white p-6 rounded-lg shadow-md flex justify-between items-center">
            <div className="flex flex-row justify-between w-full">
              <div className="flex items-center">
                <span className="text-[24px] font-bold">
                  Missing Topic
                </span>
              </div>
              <div className="ml-[200px] flex justify-start items-start flex-col gap-1 min-h-[190px] max-h-[200px] w-full overflow-y-auto ml-auto">

                <div>
                  {[...missing_topics].map((item, index) => (
                    <button
                      key={`${item.tag}-${item.topic}-${index}`}  // Ensures uniqueness by combining tag, topic, and index
                      onClick={() => handleRowClick(item.topic, item.tag)}  // Pass the topic and tag to handleRowClick
                      className="text-white text-[22px] hover:text-[24px] hover:text-purple outline-none focus:text-purple font-normal transition-all text-left block w-full mb-2">
                      {item.topic} {/* Display both tag and topic */}
                    </button>
                  ))}
                  {testData.type === 'AP_Calc_AB' ? (<>
                    {newmissing_topics.map((item, index) => {
                      // Find the matching subtopic
                      const matchingSubtopic = mcqsubjects.reduce((found, topic) => {
                        return found || topic.subtopics.find(subtopic => subtopic.id === item.category);
                      }, null);

                      // If no matching subtopic is found, skip rendering this item
                      if (!matchingSubtopic) {
                        return null;
                      }

                      return (
                        <button
                          key={`${item.type}-${item.category}-${index}`} // Use item.type and item.category for uniqueness
                          onClick={() => handleRowClick(item.category, item.type)} // Use item.category and item.type
                          className="text-white text-[22px] hover:text-[24px] hover:text-purple outline-none focus:text-purple font-normal transition-all text-left block w-full mb-2"
                        >
                          {matchingSubtopic.name} {/* Display the subtopic name */}
                        </button>
                      );
                    })}

                  </>) : (<>
                    {testData.type === 'ACT' ? (<>
                      {[...newmissing_topics].map((item, index) => {
                        // Find the matching topic in the topics array
                        const matchingTopic = topics.find(topic => topic.topic === item.category);

                        return (
                          <button
                            key={`${item.category}-${item.questionIds}-${index}`}  // Ensures uniqueness by combining tag, topic, and index
                            onClick={() => handleRowClick(item.category, item.type)}  // Pass the topic and tag to handleRowClick
                            className="text-white text-[22px] hover:text-[24px] hover:text-purple outline-none focus:text-purple font-normal transition-all text-left block w-full mb-2">
                            {/* Display the full name if found, otherwise show the category */}
                            {matchingTopic ? matchingTopic.full : item.category}
                          </button>
                        );
                      })}
                    </>) : (<>
                      {[...newmissing_topics].map((item, index) => (
                        <button
                          key={`${item.tag}-${item.topic}-${index}`}  // Ensures uniqueness by combining tag, topic, and index
                          onClick={() => handleRowClick(item.topic, item.tag)}  // Pass the topic and tag to handleRowClick
                          className="text-white text-[22px] hover:text-[24px] hover:text-purple outline-none focus:text-purple font-normal transition-all text-left block w-full mb-2">
                          {item.topic} {/* Display both tag and topic */}
                        </button>
                      ))}
                    </>)}
                  </>)}
                </div>

              </div>
            </div>
          </div>

        </div>
      </section >
    </div >
  </>);
};

const GradientBorderBox = ({ header, title, score, range, description, icon }) => {
  return (
    <div className="relative bg-white p-6 rounded-xl shadow-md w-full md:mx-2">
      <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
        <div className="h-full w-full bg-white rounded-xl"></div>
      </div>
      <div className="relative">
        <span className="inline-block bg-purple-200 text-purple-700 text-xs font-semibold px-2 py-1 rounded-full">{header}</span>
        <h2 className="text-xl font-bold text-gray-900 mt-2">{title}</h2>
        <div className="flex items-center mt-4">
          {icon && <span className="mr-2">{icon}</span>}
          <span className="text-4xl font-bold text-gray-800">{score}</span>
          <span className="text-xl text-gray-500 ml-2">{range}</span>
        </div>
        <span className="text-gray-500 text-sm">Score</span>
        <p className="text-gray-600 mt-2">{description}</p>
      </div>
    </div>
  );
};

export default Hero;
