import { useEffect, useState, useContext, useRef } from "react";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import { BASELINE } from "../../../util";
import { motion } from "framer-motion";
import ModalComponent from "../pick_modal";

ChartJS.register(ArcElement, Tooltip, Legend);

const OverallNMSQTAnal = () => {
    const boxItemLeftRef = useRef();

    const { user } = useContext(UserContext);
    const [selected, setSelected] = useState('English');  // Default selection\
    const [eproblems, seteproblems] = useState([]);
    const [mproblems, setmproblems] = useState([]);
    const [co, setco] = useState(0);
    const [inco, setinco] = useState(0);
    const [omit, setomit] = useState(0);

    const [report_eng, set_report_eng] = useState([]);
    const [report_math, set_report_math] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const [tag, settag] = useState('');
    const [hideExplanation, setHideExplanation] = useState(false);
    const [topic, settopic] = useState(false);
    const [section, setsection] = useState("");
    const type = "SAT10";

    const handleChange2 = () => {
      setHideExplanation(!hideExplanation);
    };

    useEffect(() => {
        getting_problems();
        getallsat();
        getusersat();
    }, []);

    useEffect(() => {
        // Dynamically load the KaTeX script
        const script2 = document.createElement('script');
        script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
        script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
        script2.crossOrigin = 'anonymous';
    
        // Handle script load
        script2.onload = () => {
          if (boxItemLeftRef.current) {
            const options = {
              delimiters: [
                { left: '$$', right: '$$', display: false },
                { left: "$", right: "$", display: false },
                { left: '\\(', right: '\\)', display: false },
                { left: '\\[', right: '\\]', display: true },
                { left: '\\begin{array}', right: '\\end{array}', display: true }
              ],
              throwOnError: false,
            };
    
            window.renderMathInElement(boxItemLeftRef.current, options);
          }
        };
        document.body.appendChild(script2);
    
        // Cleanup: remove the script when component unmounts
        return () => {
          document.body.removeChild(script2);
        };
      }, [selectedTopic, hideExplanation]);

    const getsatquestion = (questionId, tag) => {
        settag(tag);
        axios.get(BASELINE + "questions/psat10/byqid", { // Ensure the endpoint is correctly specified
            params: {
                qid: questionId  // Changed from questionId to qid to match the server expectation
            }
        })
        .then(response => {
        //    console.log("Response data:", response.data); // Log or handle the response data appropriately
            setSelectedTopic(response.data);
            setIsModalOpen(true);
        })
        .catch(err => {
            console.error("Error fetching SAT question data:", err);
        });
    }
    
    const updateScoresWithApiData = (apiData) => {
        setScores(prevScores => {
            // Create a map from the API data for quick lookup
            const apiScoresMap = apiData.reduce((acc, item) => {
                acc[item.test_name] = item.total_score;
                return acc;
            }, {});
    
            // Map over the existing scores and update 'myScore' if there's a matching entry in the API data
            return prevScores.map(score => ({
                ...score,
                myScore: apiScoresMap[score.name] || score.myScore // Use API score if available, otherwise keep the existing score
            }));
        });
    }

    const getusersat = () => {
        axios.get(`${BASELINE}get/user/sat10`, {
            params: {
                user_id: user.u_id  // assuming 'user_id' is the correct parameter name expected by your server
            }
        })
            .then(response => {
            //    console.log("Data received:", response.data);
                updateScoresWithApiData(response.data);
            })
            .catch(err => {
                console.error("Error fetching user SAT data:", err);
            });
    }

    const getallsat = () => {
        axios.get(`${BASELINE}get/all/sat10`)
            .then(response => {
                const data = response.data;
                // Aggregate scores by test_name
                const scoresMap = data.reduce((acc, item) => {
                    if (!acc[item.test_name]) {
                        acc[item.test_name] = { total: 0, count: 0 };
                    }
                    acc[item.test_name].total += item.total_score;
                    acc[item.test_name].count += 1;
                    return acc;
                }, {});

                // Calculate averages and round them
                const averages = Object.keys(scoresMap).map(test => ({
                    test_name: test,
                    avgScore: Math.round(scoresMap[test].total / scoresMap[test].count) // Rounds to the nearest integer
                }));

                // Update the scores array
                const updatedScores = scores.map(score => {
                    const averageData = averages.find(avg => avg.test_name === score.name);
                    return {
                        ...score,
                        avgScore: averageData ? averageData.avgScore : score.avgScore // Uses the rounded average
                    };
                });

                setScores(updatedScores);
                // Now you can set this updatedScores to your state or use it as needed
            })
            .catch(err => console.error("Error fetching SAT scores:", err));
    }

    const getting_problems = () => {
        axios.get(`${BASELINE}tests/byuser/nmsqt/${user.u_id}`)
            .then(response => {
                const allIncorrectIds = response.data.reduce((acc, test) => {
                    if (test.Incorrect_questionIds) {
                        const incorrectIds = JSON.parse(test.Incorrect_questionIds);
                        acc = acc.concat(incorrectIds);
                    }
                    return acc;
                }, []);

                const allcorrectIds = response.data.reduce((acc, test) => {
                    if (test.Correct_questionIds) {
                        const correctIds = JSON.parse(test.Correct_questionIds);
                        acc = acc.concat(correctIds);
                    }
                    return acc;
                }, []);

                const allomittedIds = response.data.reduce((acc, test) => {
                    if (test.Omitted_questionIds) {
                        const omittedIds = JSON.parse(test.Omitted_questionIds);
                        acc = acc.concat(omittedIds);
                    }
                    return acc;
                }, []);

                // Splitting the incorrect questions by tag
                const allQuestions = [...allIncorrectIds, ...allcorrectIds];

                const engQuestions = allQuestions.filter(question => question.tag === 'eng');
                const mathQuestions = allQuestions.filter(question => question.tag === 'math');

                // Further split by topics
                const groupedByEngTopic = groupByTopic(engQuestions);
                const groupedByMathTopic = groupByTopic(mathQuestions);

                const topEngQuestions = getTopQuestions(engQuestions);
                const topMathQuestions = getTopQuestions(mathQuestions);

                seteproblems(topEngQuestions);
                setmproblems(topMathQuestions);

                const inco = allIncorrectIds.length;
                const co = allcorrectIds.length;
                const omit = allomittedIds.length;
                const totalQuestions = inco + co + omit;

                const incorrectPercentage = (inco / totalQuestions) * 100;
                const correctPercentage = (co / totalQuestions) * 100;
                const omittedPercentage = (omit / totalQuestions) * 100;

                setco(correctPercentage.toFixed(1));
                setinco(incorrectPercentage.toFixed(1));
                setomit(omittedPercentage.toFixed(1));
                updateChartData(incorrectPercentage, correctPercentage, omittedPercentage);

                const engSubjects = transformData(groupedByEngTopic);
                const mathSubjects = transformData(groupedByMathTopic);

                set_report_eng(engSubjects);
                set_report_math(mathSubjects);

            })
            .catch(error => {
                console.error("Error fetching user tests:", error);
            });
    }

    const updateChartData = (incorrectPercentage, correctPercentage, omittedPercentage) => {
        setDoughnutData({
            datasets: [{
                data: [incorrectPercentage, correctPercentage, omittedPercentage],
                backgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
                hoverBackgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
                borderWidth: 0,
            }],
            labels: ['Incorrect', 'Correct', 'Omitted'],
        });
    };

    const [doughnutData, setDoughnutData] = useState({
        datasets: [{
            data: [7, 93], // Default values
            backgroundColor: ['#FF6384', '#E7E9ED'],
            hoverBackgroundColor: ['#FF6384', '#E7E9ED'],
            borderWidth: 0,
        }],
        labels: ['Incorrect', 'Correct', 'Omitted'],
    });

    const groupByTopic = (questions) => {
        return questions.reduce((acc, question) => {
            const topic = question.topic;
            if (!acc[topic]) {
                acc[topic] = { correct: 0, incorrect: 0, omitted: 0, total: 0 };
            }
            if (question.selectedOption === null) {
                acc[topic].omitted++;
            } else if (question.correct) {
                acc[topic].correct++;
            } else {
                acc[topic].incorrect++;
            }
            acc[topic].total++;
            return acc;
        }, {});
    };

    const calculatePercentage = (count, total) => {
        return `${((count / total) * 100).toFixed(1)}%`; // Ensure percentages are in string format with a '%'
    };

    const transformData = (groupedData) => {
        return Object.entries(groupedData).map(([topic, counts]) => ({
            name: topic,
            correct: calculatePercentage(counts.correct, counts.total),
            incorrect: calculatePercentage(counts.incorrect, counts.total),
            omitted: calculatePercentage(counts.omitted, counts.total),
        }));
    };

    const getTopQuestions = (questions) => {
        const frequencyMap = questions.reduce((acc, question) => {
            if (!acc[question.questionIds]) {
                acc[question.questionIds] = { count: 0, topic: question.topic };
            }
            acc[question.questionIds].count += 1;
            return acc;
        }, {});

        return Object.entries(frequencyMap)
            .sort((a, b) => b[1].count - a[1].count) // Sort by frequency
            .slice(0, 10) // Get top 10
            .map(([id, { topic }]) => ({
                id: id,
                name: topic
            }));
    }

    const handleChange = (event) => {
        setSelected(event.target.value);
    };

    const handleRowClick = (subjectName, section) => {
        setsection(section);
        settopic(subjectName);
        setIsModalOpen2(true);
        // You can perform any actions here, such as navigation or displaying details
    };

    const [scores, setScores] = useState([
        { name: "SAT10 p10_1", myScore: 0, avgScore: 400 },
        { name: "SAT10 p10_2", myScore: 0, avgScore: 410 },
        { name: "SAT10 p10_3", myScore: 0, avgScore: 420 },
        { name: "SAT10 p10_4", myScore: 0, avgScore: 400 },
        { name: "SAT10 p10_5", myScore: 0, avgScore: 410 },
        { name: "SAT10 p10_6", myScore: 0, avgScore: 420 },
        { name: "SAT10 p10_7", myScore: 0, avgScore: 420 },
        { name: "SAT10 p10_8", myScore: 0, avgScore: 420 },
    ]);

    const doughnutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        cutout: '80%',
    };

    return (<>
    <ModalComponent topic={topic} isOpen={isModalOpen2} onClose={setIsModalOpen2} section={section} type={type}/>
        <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
            <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">NMSQT Performance</span>
        </div>

        <div className="mt-[20px] text-gray-800 text-lg font-semibold">Dashboard</div>
        <hr />
        <div className="flex space-x-4 p-4">
            <div className="relative flex-3 bg-white p-4 rounded" style={{ flex: '0 0 36%' }}>
                <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                    <div className="h-full w-full bg-white rounded-xl"></div>
                </div>
                <div className="relative">
                    <h2 className="text-lg font-semibold mb-2">👑 Top 10 English Missing Problems</h2>
                    <div style={{ height: '180px', overflowY: 'auto' }}> {/* Enable vertical scrolling */}
                        <table className="min-w-full" style={{ borderCollapse: 'collapse', border: 'none' }}>
                            <thead className="border-b">
                                <tr style={{ border: 'none' }}>
                                    <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Topic</th>
                                    <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ border: 'none' }}>
                                {eproblems.map((problem) => (
                                    <tr key={problem.id} style={{ border: 'none' }}>
                                        <td className="px-4 py-2" style={{ border: 'none' }}>{problem.name}</td>
                                        <td className="px-4 py-2" style={{ border: 'none' }}>
                                            <button className="bg-slate-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" onClick={() => getsatquestion(problem.id, 'Eng')}>
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <div className="relative flex-3 bg-white p-4 rounded" style={{ flex: '0 0 36%' }}>
                <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                    <div className="h-full w-full bg-white rounded-xl"></div>
                </div>
                <div className="relative">
                    <h2 className="text-lg font-semibold mb-2">👑 Top 10 Math Missing Problems</h2>
                    <div style={{ height: '180px', overflowY: 'auto' }}> {/* Enable vertical scrolling */}
                        <table className="min-w-full" style={{ borderCollapse: 'collapse', border: 'none' }}>
                            <thead className="border-b">
                                <tr style={{ border: 'none' }}>
                                    <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Topic</th>
                                    <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ border: 'none' }}>
                                {mproblems.map((problem) => (
                                    <tr key={problem.id} style={{ border: 'none' }}>
                                        <td className="px-4 py-2" style={{ border: 'none' }}>{problem.name}</td>
                                        <td className="px-4 py-2" style={{ border: 'none' }}>
                                            <button className="bg-slate-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" onClick={() => getsatquestion(problem.id, 'Math')}>
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <div className="relative flex-3 bg-white p-4 rounded" style={{ flex: '0 0 23%' }}>
                <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                    <div className="h-full w-full bg-white rounded-xl"></div>
                </div>
                <div className="relative">
                    <h2 className="text-lg font-semibold mb-2">🥇 Overall Average NMSQT Score</h2>
                    <div style={{ height: '180px', overflowY: 'auto' }}>
                        <table className="min-w-full text-sm" style={{ borderCollapse: 'collapse', border: 'none' }}>
                            <thead className="border-b">
                                <tr>
                                    <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Test #</th>
                                    <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Score</th>
                                    <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Avg Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scores.map((score, index) => (
                                    <tr key={index} style={{ border: 'none' }}>
                                        <td className="px-4 py-2" style={{ border: 'none' }}>{score.name}</td>
                                        <td className="px-4 py-2" style={{ border: 'none' }}>{score.myScore}</td>
                                        <td className="px-4 py-2" style={{ border: 'none' }}>{score.avgScore}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <div className="text-gray-800 text-lg font-semibold">Statistics</div>
        <hr />
        <div className="ml-[15px] bg-white p-4 rounded-lg flex justify-center items-center mb-[10px]">
            <div className="flex w-full">
                {/* Doughnut Chart */}
                <div className="flex">
                    <div className="relative w-48 h-48 mr-[150px]"> {/* Ensure this div is relatively positioned */}
                        <Doughnut data={doughnutData} options={doughnutOptions} />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <p className="text-sm font-semibold"></p> {/* This will center the text in the Doughnut */}
                        </div>
                    </div>
                </div>

                {/* Score Table */}
                <div className="w-4/6 flex flex-col justify-center">
                    <div className="text-lg font-semibold mb-4">Total Solved Score</div>
                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <div>Total Correct:</div>
                            <div className='bg-gray-200 p-2 rounded-2xl'>{co} %</div>
                        </div>
                        <hr />
                        <div className="flex justify-between">
                            <div>Total Incorrect:</div>
                            <div className='bg-gray-200 p-2 rounded-2xl'>{inco} %</div>
                        </div>
                        <hr />
                        <div className="flex justify-between">
                            <div>Total Omitted:</div>
                            <div className='bg-gray-200 p-2 rounded-2xl'>{omit} %</div>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
        </div>

        <div className="mt-[20px] text-gray-800 text-lg font-semibold">Reports</div>
        <hr />
        <div className="ml-[5px] mt-[10px] flex items-center space-x-2">
            <label htmlFor="category-select" className="text-sm font-medium">Shows:</label>
            <select
                id="category-select"
                value={selected}
                onChange={handleChange}
                className="bg-transparent border-none border-b border-gray-200 text-gray-700 py-1 focus:ring-0 focus:border-blue-500"
            >
                <option value="English">English</option>
                <option value="Math">Math</option>
            </select>
        </div>
        <div className="bg-white w-full">
            <table className="w-full border-none mb-[120px]">
                <thead className="border-b">
                    <tr>
                        <th className="border-none px-6 py-3 text-left text-gray-500">NAME</th>
                        <th className="border-none px-6 py-3 text-left text-gray-500">CORRECT Q</th>
                        <th className="border-none px-6 py-3 text-left text-gray-500">INCORRECT Q</th>
                        <th className="border-none px-6 py-3 text-left text-gray-500">OMITTED Q</th>
                    </tr>
                </thead>
                <tbody>
                    {selected === 'English' ? report_eng.map((item) => (
                        <tr key={item.name} className="border-none cursor-pointer hover:bg-gray-200" onClick={() => handleRowClick(item.name, "English")}>
                            <td className="border-none px-6 py-4">
                                {item.name}
                                <div className="relative w-full bg-gray-200 rounded-full mt-2">
                                    <div className="absolute bg-blue-600 h-2 rounded-full" style={{ width: `${item.correct}`, left: 0 }}></div>
                                    <div className="absolute bg-red-600 h-2 rounded-full" style={{ width: `${item.incorrect}`, left: `${item.correct}` }}></div>
                                    <div className="absolute bg-gray-400 h-2 rounded-full" style={{ width: `${item.omitted}`, left: `calc(${item.correct} + ${item.incorrect})` }}></div>
                                </div>
                            </td>
                            <td className="border-none px-6 py-4">{item.correct}</td>
                            <td className="border-none px-6 py-4">{item.incorrect}</td>
                            <td className="border-none px-6 py-4">{item.omitted}</td>
                        </tr>
                    )) : report_math.map((item) => (
                        <tr key={item.name} className="border-none cursor-pointer hover:bg-gray-200" onClick={() => handleRowClick(item.name, "Math")}>
                            <td className="border-none px-6 py-4">
                                {item.name}
                                <div className="relative w-full bg-gray-200 rounded-full mt-2">
                                    <div className="absolute bg-blue-600 h-2 rounded-full" style={{ width: `${item.correct}`, left: 0 }}></div>
                                    <div className="absolute bg-red-600 h-2 rounded-full" style={{ width: `${item.incorrect}`, left: `${item.correct}` }}></div>
                                    <div className="absolute bg-gray-400 h-2 rounded-full" style={{ width: `${item.omitted}`, left: `calc(${item.correct} + ${item.incorrect})` }}></div>
                                </div>
                            </td>
                            <td className="border-none px-6 py-4">{item.correct}</td>
                            <td className="border-none px-6 py-4">{item.incorrect}</td>
                            <td className="border-none px-6 py-4">{item.omitted}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        <div className={`fixed inset-0 flex items-center justify-center z-50 ${isModalOpen ? '' : 'hidden'}`}>
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-85"></div>
        </div>
        {tag === 'Eng' && (
          <div className="relative bg-white w-[100%] h-[95%] overflow-auto">
            <button className="absolute top-0 right-0 p-2" onClick={() => setIsModalOpen(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="bg-slate-700 text-white text-[32px] font-bold pt-[5px] pl-[20px] pt-[20px]">
              Reading and Writing
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2 w-full mt-[20px] border-b-[1px] md:border-b-0 md:border-r-[1px] border-gray-300">
                <div>
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.stimulus }} className="max-h-[500px] overflow-y-auto overflow-x-hidden ml-[20px] mt-[10px]" />
                </div>

                <label className="ml-[20px] mt-[20px] md:mt-[100px] flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={hideExplanation}
                    onChange={handleChange2}
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                  <span>Hide correct answer and explanation</span>
                </label>
              </div>
              <div className="md:w-1/2 w-full md:ml-[20px] md:mr-[20px]">
                <div className="mt-8 flex flex-row justify-between bg-gray-100">
                  <div className="flex flex-row gap-2">
                    <div className="ml-[10px] p-2 flex flex-row items-center gap-1">
                      <div>
                        <p>Question Review</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.stem }} className="ml-[20px]" />
                  <>
                    <Option idx={0} data={selectedTopic.a} />
                    <Option idx={1} data={selectedTopic.b} />
                    <Option idx={2} data={selectedTopic.c} />
                    <Option idx={3} data={selectedTopic.d} />
                  </>
                </div>

                {!hideExplanation && (
                  <div className="mt-[20px]">
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic.rationale2 ? selectedTopic.rationale2 : selectedTopic.rationale }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {tag === 'Math' && (
          <div className="relative bg-white w-[100%] h-[95%] overflow-auto">
            <button className="absolute top-0 right-0 p-2" onClick={() => setIsModalOpen(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="bg-slate-700 text-white text-[32px] font-bold pt-[5px] pl-[20px] pt-[20px]">
              Math
            </div>
            <div className="flex" ref={boxItemLeftRef}>
              <div className="w-1/2 mt-[20px] border-r-[1px] border-gray-300">
                <div>
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.stem }} className="ml-[20px] max-h-[500px] overflow-y-auto overflow-x-hidden ml-[20px] mt-[10px]" />
                </div>

                <label className="ml-[20px] mt-[100px] flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={hideExplanation}
                    onChange={handleChange2}
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                  <span>Hide correct answer and explanation</span>
                </label>
              </div>
              <div className="w-1/2 ml-[20px] mr-[20px]">
                <div className="mt-8 flex flex-row justify-between bg-gray-100">
                  <div className="flex flex-row gap-2">
                    <div className="ml-[20px] p-2 flex flex-row items-center gap-1">
                      <div>
                        <p>Question Review</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                  {selectedTopic.a != null ? (
                    <>
                      <Option idx={0} data={selectedTopic.a} />
                      <Option idx={1} data={selectedTopic.b} />
                      <Option idx={2} data={selectedTopic.c} />
                      <Option idx={3} data={selectedTopic.d} />
                    </>
                  ) : (
                    <input className="w-[90%] border-[1px] py-1 px-2 border-gray-300 outline-none rounded-md" type="text" />
                  )}
                </div>

                {!hideExplanation && (
                  <div className="mt-[20px]">
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic.rationale2 ? selectedTopic.rationale2 : selectedTopic.rationale }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>);
};

export default OverallNMSQTAnal;

const Option = ({ idx, data }) => {
    return (
      <div className="flex flex-row gap-1">
        <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full border-[1px] rounded-md border-black gap-2 p-1 m-3 cursor-pointer relative`} >
          <div className="flex flex-row items-center">
            <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
              {getOptionLetter(idx)}
            </p>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data }} />
        </motion.div>
      </div>
    );
  };
  
  function getOptionLetter(idx) {
    switch (idx) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
      default:
        return "A";
    }
  }