import React, { useState, useEffect, useRef } from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { ImSpellCheck } from "react-icons/im";
import { motion } from "framer-motion";
import 'katex/dist/katex.min.css';
import { MathfieldElement } from 'mathlive';
import Latex from "react-latex-next";
import { FaQuestionCircle } from "react-icons/fa";
import axios from 'axios';
import { BASELINE } from "../../util";

const Question = ({
    time,
    sat,
    handleAnswerValueChange,
    handleCrossOutOptions,
    handleSelectOption,
    handleBookMark,
    currentQuestion,
    setQuestions,
    result
}) => {
    const containerRef = useRef(null);
    const boxItemLeftRef = useRef();
    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);
    const [crossedOutOptions, setCrossedOutOptions] = useState([]);
    const [isKatexRendered, setIsKatexRendered] = useState(false);
    const [leftWidth, setLeftWidth] = useState(50); // Start with 50% width
    const [isResizing, setIsResizing] = useState(false);
    const dividerRef = useRef(null);
    const [mcq, setmcq] = useState(false);
    const options = JSON.parse(currentQuestion.options);
    const cleanOptionText = (text) => text.substring(3);
    const [processedContent, setProcessedContent] = useState(currentQuestion.content);
    const [processedOptions, setProcessedOptions] = useState([]);

    const fetchImagesInText = async (text, examName) => {
        const imageRegex = /\[IMAGE\s*#(\d+)\]/g;
        const matches = [...text.matchAll(imageRegex)];
        let replacedText = text;

        for (const match of matches) {
            const imageNumber = match[1];
            try {
                const res = await axios.get(BASELINE + `getImage?exam=${examName}&imageNumber=${imageNumber}`, { responseType: 'blob' });
                const blob = res.data;
                const imgURL = URL.createObjectURL(blob);
                replacedText = replacedText.replace(match[0],
                    `<img src="${imgURL}" alt="Image #${imageNumber}" style="max-width:100%; height:auto;" />`
                );
            } catch (error) {
                console.error("이미지 로딩 실패:", error);
                // 실패 시 placeholder 유지하거나 다른 처리 가능
            }
        }
        return replacedText;
    };

    useEffect(() => {
        const examName = encodeURIComponent(currentQuestion.exam);

        // content 처리
        (async () => {
            const replacedContent = await fetchImagesInText(currentQuestion.content, examName);
            setProcessedContent(replacedContent);
        })();

        // options 처리
        (async () => {
            const replacedOptions = await Promise.all(
                options.map(async (opt) => {
                    return await fetchImagesInText(cleanOptionText(opt), examName);
                })
            );
            setProcessedOptions(replacedOptions);
        })();
    }, [currentQuestion]);

    const handleAnswerUpdate = (questionId, updatedAnswer) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((question) => {
                if (question.question_id === questionId) {
                    let mathAnswers = Array.isArray(question.mathAnswers)
                        ? [...question.mathAnswers]
                        : [];

                    const index = mathAnswers.findIndex(
                        (answer) =>
                            answer.optionLetter.toUpperCase() === updatedAnswer.optionLetter.toUpperCase()
                    );

                    if (index !== -1) {
                        mathAnswers[index] = updatedAnswer;
                    } else {
                        mathAnswers.push(updatedAnswer);
                    }

                    return { ...question, mathAnswers };
                }
                return question;
            })
        );
    };

    // Event Handlers for Resizing
    const onMouseDown = (e) => {
        setIsResizing(true);
    };

    const onMouseMove = (e) => {
        if (!isResizing) return;

        if (containerRef.current) {
            const container = containerRef.current.getBoundingClientRect();
            const newLeftWidth = ((e.clientX - container.left) / container.width) * 100;

            if (newLeftWidth > 20 && newLeftWidth < 80) {
                setLeftWidth(newLeftWidth);
            }
        }
    };

    const onMouseUp = () => {
        setIsResizing(false);
    };

    useEffect(() => {
        if (isResizing) {
            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
            document.body.style.userSelect = "none"; // Prevent text selection
        } else {
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
            document.body.style.userSelect = "auto";
        }

        // Cleanup
        return () => {
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
            document.body.style.userSelect = "auto";
        };
    }, [isResizing]);

    useEffect(() => {
        const tags = JSON.parse(currentQuestion.tags);
        const hasMCQ = tags.includes("mcq");
        if (hasMCQ === true) { setmcq(true); }

        const script2 = document.createElement('script');
        script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
        script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
        script2.crossOrigin = 'anonymous';

        script2.onload = () => {
            if (boxItemLeftRef.current) {
                const options = {
                    delimiters: [
                        { left: '$$', right: '$$', display: false },
                        { left: "$", right: "$", display: false },
                        { left: '\\(', right: '\\)', display: false },
                        { left: '\\[', right: '\\]', display: true },
                        { left: '\\begin{array}', right: '\\end{array}', display: true }
                    ],
                    throwOnError: false,
                };
                window.renderMathInElement(boxItemLeftRef.current, options);
                setIsKatexRendered(true);
            }
        };
        document.body.appendChild(script2);
        return () => {
            document.body.removeChild(script2);
        };
    }, [processedContent, processedOptions]);

    const setRefs = (...refs) => (element) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(element);
            } else if (ref) {
                ref.current = element;
            }
        });
    };

    const preprocessOptions = (optionsString) => {
        try {
            // Check if the input is already a valid JSON array string
            if (typeof optionsString === "string") {
                // Now try to parse the JSON
                return JSON.parse(optionsString);
            }

            // If input is not a string, return an empty array
            return [];
        } catch (e) {
            console.error("Failed to parse options:", e);
            return []; // Fallback to an empty array if parsing fails
        }
    };

    const RenderOptions = () => {
        try {
            // processedOptions에는 이미지 치환된 HTML 문자열이 들어있음
            if (!processedOptions || processedOptions.length === 0) {
                return <p>No options available</p>
            }

            return processedOptions.map((option, idx) => (
                <Option
                    key={idx}
                    handleCrossOutOptions={handleCrossOutOptions}
                    currentQuestion={currentQuestion}
                    handleSelectOption={handleSelectOption}
                    idx={idx}
                    showCrossOutMenu={showCrossOutMenu}
                    crossedOutOptions={currentQuestion.crossedOutOptions}
                    data={option}
                />
            ));
        } catch (error) {
            console.error("Error displaying options:", error);
            return <p>Error displaying options</p>;
        }
    };

    return (
        <div id="box-item-left" ref={setRefs(containerRef, boxItemLeftRef)}>
            {mcq === true ? (
                <div className="mt-[20px] flex h-full">
                    {/* Left Pane */}
                    <div
                        className="left-pane"
                        style={{ width: `${leftWidth}%` }}
                    >
                        <div className="mb-[160px] mt-[3%] z-[10]">
                            <div
                                className={`flex flex-col justify-start ml-2 mr-2 box-item-left`}
                            >
                                <div>
                                    <div
                                        id="test-imgsat"
                                        dangerouslySetInnerHTML={{
                                            __html: processedContent,
                                        }}
                                        className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style stem-content"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Divider */}
                    <div
                        ref={dividerRef}
                        className="divider mt-[20px] h-[800px]"
                        onMouseDown={onMouseDown}
                    />

                    {/* Right Pane */}
                    <div
                        className="right-pane ml-[15px] mr-[15px]"
                        style={{ width: `${100 - leftWidth}%` }}
                    >
                        <div className="mt-[40px] mr-[20px] ml-[20px]">
                            <div className="flex flex-row justify-between bg-gray-100">
                                <div className="flex flex-row gap-2">
                                    <div className=" flex flex-row items-center">
                                        <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                                    </div>
                                    <div className="flex flex-row items-center gap-1">
                                        <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.question_id)} className="m-1 cursor-pointer">
                                            {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                                        </motion.div>
                                        <div>
                                            <p>Mark For Review</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center">
                                    <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu(pre => !pre)}>
                                        <ImSpellCheck />
                                    </motion.div>
                                </div>
                            </div>
                        </div>

                        <div className="my-2 min-w-[100px] max-w-[600px] max-h-[580px] overflow-y-auto">
                            {result === true ? (
                                <>
                                    <div className="text-black text-l mb-[5px]">
                                        ⏲️Time Spent: {time}sec
                                    </div>
                                </>
                            ) : result === false ? (
                                <>
                                    <div className="text-black text-l mb-[5px]">
                                        ⏲️Time Spent: {time}sec
                                    </div>
                                </>
                            ) : (
                                <span></span>
                            )}

                            <RenderOptions />

                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-[20px] flex h-full">
                    {/* Left Pane */}
                    <div
                        className="left-pane"
                        style={{ width: `${leftWidth}%` }}
                    >
                        <div className="mb-[160px] mt-[3%] z-[10]">
                            <div
                                className={`flex flex-col justify-start ml-2 mr-2 box-item-left`}
                            >
                                <div>
                                    <div
                                        id="test-imgsat"
                                        dangerouslySetInnerHTML={{
                                            __html: processedContent,
                                        }}
                                        className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style stem-content"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Divider */}
                    <div
                        ref={dividerRef}
                        className="divider mt-[20px] h-[800px]"
                        onMouseDown={onMouseDown}
                    />

                    {/* Right Pane */}
                    <div
                        className="right-pane ml-[15px] mr-[15px]"
                        style={{ width: `${100 - leftWidth}%` }}
                    >
                        <div className="mt-[40px] mr-[20px] ml-[20px]">
                            <div className="flex flex-row justify-between bg-gray-100">
                                <div className="flex flex-row gap-2">
                                    <div className="flex flex-row items-center">
                                        <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                                    </div>
                                    <div className="flex flex-row items-center gap-1">
                                        <motion.div
                                            whileTap={{ scale: 0.97 }}
                                            onClick={() => handleBookMark(currentQuestion.question_id)}
                                            className="m-1 cursor-pointer"
                                        >
                                            {currentQuestion.bookMarked ? (
                                                <BsBookmarkFill color="red" size="1.2rem" />
                                            ) : (
                                                <BsBookmark size="1.2rem" />
                                            )}
                                        </motion.div>
                                        <div className="flex flex-row items-center gap-1">
                                            <p>Mark For Review</p>
                                            {/* Tooltip Container */}
                                            <div className="relative group cursor-pointer">
                                                <FaQuestionCircle size="1rem" />
                                                {/* Tooltip */}
                                                <div className="absolute left-0 bottom-6 w-max px-3 py-1 text-sm text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity">
                                                    Press " + shift to toggle between LaTeX and text.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="my-2 min-w-[100px] max-h-[580px] overflow-y-auto">
                            {options.map((option, index) => (
                                <Option2
                                    key={index}
                                    handleCrossOutOptions={handleCrossOutOptions}
                                    currentQuestion={currentQuestion}
                                    handleSelectOption={handleSelectOption}
                                    idx={index}
                                    showCrossOutMenu={showCrossOutMenu} // Use state to show or hide cross-out menu
                                    crossedOutOptions={crossedOutOptions}
                                    data={cleanOptionText(option)} // Pass cleaned option text
                                    handleAnswerUpdate={handleAnswerUpdate}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default Question;

const Option = ({
    handleCrossOutOptions,
    currentQuestion,
    idx,
    handleSelectOption,
    showCrossOutMenu,
    crossedOutOptions,
    data
}) => {
    const optionLetter = getOptionLetter(idx).toLowerCase();
    const selectedOption = currentQuestion.selectedOption;
    const isSelected = typeof selectedOption === 'string' && selectedOption === optionLetter;

    return (
        <div className="flex flex-row gap-1" >
            <motion.div
                whileTap={{ scale: 0.98 }}
                className={`flex flex-row w-full ${currentQuestion.selectedOption === optionLetter.toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === optionLetter.toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${crossedOutOptions.includes(optionLetter.toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`}
                onClick={() => handleSelectOption(currentQuestion.question_id, optionLetter.toLowerCase())}
            >
                <div className="flex items-center">
                    <p className="flex items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                <div className="flex flex-row items-center p-[2px]">
                    <Latex>{data}</Latex>
                </div>
            </motion.div>
            {showCrossOutMenu && (
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions?.includes(optionLetter) ?
                            (<p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.question_id, optionLetter)}>Undo</p>) :

                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.question_id, optionLetter)} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.question_id, optionLetter)}>
                                </div>
                            </>

                        }
                    </div>
                </motion.div>
            )}
        </div>
    );
};

const Option2 = ({
    currentQuestion,
    idx,
    data,
    handleAnswerUpdate,
}) => {
    const mathFieldContainerRef = useRef(null);
    const mathFieldRef = useRef(null);

    // Initialize MathFieldElement once
    useEffect(() => {
        if (mathFieldContainerRef.current && !mathFieldRef.current) {
            const mathField = new MathfieldElement({
                virtualKeyboardMode: "manual",
                virtualKeyboardLayout: "math",
            });

            // Apply styles
            mathField.style.width = "100%";
            mathField.style.boxSizing = "border-box";
            mathField.style.overflowWrap = "break-word";
            mathField.style.wordBreak = "break-word";
            mathField.style.whiteSpace = "normal";
            mathField.style.resize = "vertical";
            mathField.style.minHeight = "3em";
            mathField.style.padding = "10px";

            mathFieldContainerRef.current.appendChild(mathField);
            mathFieldRef.current = mathField;
        }

        return () => {
            if (mathFieldRef.current && mathFieldContainerRef.current) {
                mathFieldContainerRef.current.removeChild(mathFieldRef.current);
                mathFieldRef.current = null;
            }
        };
    }, []); // Empty dependency array to initialize only once

    // Update event listener when questionId or idx changes
    useEffect(() => {
        if (mathFieldRef.current) {
            const mathField = mathFieldRef.current;

            // Define the event handler
            const handleInput = () => {
                const latexValue = mathField.getValue('latex');

                const updatedAnswer = {
                    optionLetter: getOptionLetter(idx).toUpperCase(),
                    latex: latexValue,
                };

                // Use the latest questionId
                handleAnswerUpdate(currentQuestion.question_id, updatedAnswer);
            };

            // Attach the event listener
            mathField.addEventListener('input', handleInput);

            // Cleanup function to remove the event listener
            return () => {
                mathField.removeEventListener('input', handleInput);
            };
        }
    }, [currentQuestion.question_id, idx, handleAnswerUpdate]);

    // Update MathFieldElement value when mathAnswers change
    useEffect(() => {
        if (mathFieldRef.current) {
            const currentOptionLetter = getOptionLetter(idx).toUpperCase();
            const existingAnswer = currentQuestion.mathAnswers?.find(
                (answer) => answer.optionLetter.toUpperCase() === currentOptionLetter
            );
            if (existingAnswer) {
                mathFieldRef.current.setValue(existingAnswer.latex);
            } else {
                mathFieldRef.current.setValue('');
            }
        }
    }, [currentQuestion.mathAnswers, idx]);

    return (
        <div className="flex flex-row gap-1">
            <div className="w-full gap-2 p-1 m-3 relative">
                <div className="flex">
                    <p>{getOptionLetter(idx) + ". "}</p>
                    <div dangerouslySetInnerHTML={{ __html: data }} />
                </div>
                <div
                    ref={mathFieldContainerRef}
                    className="Z-[400] border border-2 rounded-md mt-2 p-2 min-w-[200px] max-w-full wrap-text break-words whitespace-normal"
                    style={{
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                        maxWidth: '100%',
                    }}
                ></div>
            </div>
        </div>
    );
};

function getOptionLetter(idx) {
    const letters = ["A", "B", "C", "D", "E"];
    return letters[idx] || "A";
}