import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { CiCalculator1 } from "react-icons/ci";
import { VscReferences } from "react-icons/vsc";
import { BsThreeDotsVertical, BsExclamationTriangle } from "react-icons/bs";
import Question from "./MathQuestion";
import axios from "axios";
import Timer from "./Timer";
import { motion } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { BASELINE } from "../../util";
import Review from "./Review";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DirectionModal from './DirectionModal';
import ReferenceSheet from './ReferenceSheet';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { useStopwatch } from "react-timer-hook";
import AIAudioPlayer from '../aivoice/AIAudioComponent';

const APQuiz = ({ free, freesat }) => {
  const { id: initialId } = useParams();
  const [id, setId] = useState(initialId);

  useEffect(() => {
    if (freesat) {
      setId(freesat);
    }
  }, [freesat]);

  const [questionsarr, setquestionsarr] = useState([]);
  const [section, setsection] = useState(4);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const location = useLocation();
  const responseData = location.state?.data;

  const [showCalculator, setShowCalculator] = useState(false);
  const [questionOverViewOpen, setQuestionOverviewOpen] = useState(false);
  const [showReference] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [Error, setError] = useState('');
  const [showDirectionModal, setShowDirectionModal] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [showDirectionModalRef, setShowDirectionRefModal] = useState(false);
  const [sat, setsat] = useState("");
  const [answers, setanswers] = useState([]);
  const [breaks, setbreaks] = useState(true);

  const [test_id, settest_id] = useState(uuidv4());
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [context, setContext] = useState('');

  const [timeSpentPerQuestion, setTimeSpentPerQuestion] = useState({});
  const [timer, setTimer] = useState(null);
  const [userResponses, setUserResponses] = useState({});
  const [loadcurr, setloadcurr] = useState(0);
  const [qanswers, setqanswers] = useState([]);
  const [qbookmarks, setqbookmarks] = useState([]);
  const [qarr, setqarr] = useState([]);

  const [auto, setauto] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null); // Countdown timer
  const [timerActive, setTimerActive] = useState(false); // Track if countdown timer is active
  const { start, reset } = useStopwatch({ autoStart: false }); // Use stopwatch for untimed mode
  const [showanswer, setshowanswer] = useState(false);
  const [askedConfirmation, setAskedConfirmation] = useState([]);
  const [result, setResult] = useState([]);
  const [disablesave, setdisablesave] = useState(true);
  const [autotutor, setautotutor] = useState(false);
  const [autotype, setsutotpye] = useState("");
  const [autotimer, setautotimer] = useState(false);
  const [tag, settag] = useState();

  const displayMinutes = Math.floor((timeLeft || 0) / 60);
  const displaySeconds = (timeLeft || 0) % 60;

  const pathSegments = location.pathname.split('/');
  const targetPath = pathSegments[3]; // "apcalcab"

  const [aicontext, setaicontext] = useState("");

  const [questionTimers, setQuestionTimers] = useState([]);

  useEffect(() => {
    if (currentQuestion) {
      setaicontext(`
        You are a college teacher assisting a student in understanding a problem.
        Please follow these instructions:
        1. Do NOT include any <greetings> (e.g., "Hi", "Hello") in the output.
        2. Do NOT use <LaTeX> in your <greetings> use only <<English>> in words.
        3. Provide a 15-word introduction without using any greeting words.
        4. Offer a step-by-step explanation, guiding the student to the answer.
        
        Format the output as a JSON object with two keys: "greeting" and "stepbystep".
        - "greeting": an array containing a single 15-word string.
        - "stepbystep": an array each representing a step.
        
        Here is the problem:
        
        <Question> ${currentQuestion.content} </Question>
        <option list> ${currentQuestion.options} </option list>
        <answer> ${currentQuestion.answer} </answer>
        <step by step> ${currentQuestion.rationale} </step by step>
  
        [[MAKE sure the output does not have the Latex, Only in English]]
        Example Output:
        {
          "greeting": ["This is a brief introduction to help you solve the problem."],
          "stepbystep": [
            "Step 1: Identify the main question being asked.",
            "Step 2: Analyze the given options carefully.",
            "...",
            "Step N: Select the most appropriate answer based on your analysis."
          ]
        }
        
        Please adhere strictly to the above format.
      `);
    }
  }, [currentQuestion]);

  const handleSubmit2 = () => {
    var type;
    if (id.startsWith('p89_')) {
      type = 'SAT89';
    } else if (id.startsWith('p10_')) {
      type = 'SAT10';
    } else {
      type = 'SAT';
    }
    const questionId = currentQuestion.question_id;
    const reportFrom = user.first_name + " " + user.last_name;

    // Do something with questionId, reportFrom, and context

    var data = {
      questionId: questionId,
      name: reportFrom,
      context: context,
      type: type
    }

    axios.post(BASELINE + 'questions/report/note', [data])
      .then(response => {
        alert('Report submitted successfully!');
      })
      .catch(error => {
        console.error(error);
      });

    setContext('');
    setShowModal(false);
  };

  const startCountdownTimer = (startingTime) => {
    if (startingTime > 0) {
      startTimer(startingTime); // Start countdown timer from the given starting time
    }
  };

  const startTimer = (initialTime) => {
    setTimeLeft(initialTime);
    setTimerActive(true);
  };

  const startStopwatch = (startingTime) => {
    setTimeLeft(startingTime);
    setTimerActive(true);
  };

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  const handleReportClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = () => {
    // Clear the context and close the modal
    setContext('');
    setShowModal(false);
  };

  const stopTimer = () => {
    setTimerActive(false);
    setTimeLeft(0);
    reset(); // Reset the stopwatch if needed
  };

  useEffect(() => {
    let interval;
    if (timerActive) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => {
          if (responseData?.timedModeActive || autotimer === true) {
            // Countdown timer
            if (prevTime > 0) {
              return prevTime - 1;
            } else {
              clearInterval(interval);
              setTimerActive(false);
              // Handle time up event here if needed
              return 0;
            }
          } else {
            // Count up timer
            return prevTime + 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerActive, responseData]);


  async function handleLocalStorageData(currentTest) {
    settest_id(currentTest);
    setauto(true);
    try {
      setLoadingQuestions(true);
      const response = await axios.get(BASELINE + 'getautosavebytid', { params: { tid: currentTest } });
      const savedData = response.data.data[0];
      console.log("=-savedData", savedData);
      if (savedData) {
        let res;
        let jsonsetting = JSON.parse(savedData.Setting);
        let jsondata = JSON.parse(savedData.quiz_data);
        let qrr = jsondata.questions;
        console.log("=-savedData", jsonsetting);

        setqarr(qrr);
        setautotutor(jsonsetting.tutormode);
        setAskedConfirmation(jsonsetting.tutorasked);
        setResult(jsonsetting.result);
        settag(jsonsetting.tag);
        setQuestionTimers(jsonsetting.questiontimer);

        // Fetch questions based on the type of test
        if (savedData.type === 'AP_Calc_AB') {
          setsutotpye('AP_Calc_AB');
          res = await axios.get(BASELINE + "questions/ap", { params: { id: qrr } });
        }

        // Map the questions and prepare the state for displaying them
        let mappedQuestions = res.data.map((question, idx) => ({
          ...question,
          index: idx,
          answered: false,
          selectedOption: jsondata.qanswers[idx] || null,
          bookMarked: jsondata.qbookmarks[idx] || false,
          crossedOutOptions: [],
        }));
        setQuestions(mappedQuestions);

        // Restore the timer correctly based on the mode
        if (jsonsetting.timedMode === true) {
          setautotimer(true);
          startCountdownTimer(jsonsetting.timeLeft); // Start countdown timer
        } else {
          startStopwatch(jsonsetting.timeLeft); // Start stopwatch from the restored time
        }

        const questionIndex = jsondata.questions.findIndex(question => {
          return parseInt(question) === parseInt(savedData.questionIds);
        });
        if (questionIndex >= 0) {
          setCurrentQuestion(mappedQuestions[questionIndex]);
        }

        // Remove localStorage item after a delay
        setTimeout(() => {
          setLoadingQuestions(false);
          localStorage.removeItem('currentTest');
        }, 2000);
      } else {
        setdisablesave(false);
      }
    } catch (error) {
      console.error("Error fetching autosave data:", error);
    }
  }

  const autosaveData = async () => {
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');

    const qanswers = questions?.map(question => question.selectedOption);
    const qrealanswers = questions?.map(question => question.answer);
    const qskillsets = questions?.map((question) => {
      const tagsArray = JSON.parse(question.tags);
      const apTags = tagsArray.filter(tag => tag.startsWith("AP"));
      return apTags[0];
    });

    const qbookmarks = questions?.map(question => question.bookMarked);
    const qids = questions?.map(question => question.question_id);
    let quiz = responseData?.type || '';
    const questiontimer = questionTimers;

    // Determine the time value based on the mode
    let totalTimeSpent;
    if (responseData?.timedModeActive) {
      // Save the remaining time in countdown mode
      totalTimeSpent = timeLeft;
    } else {
      totalTimeSpent = timeLeft;
    }

    let ttag;
    if (responseData) {
      ttag = responseData?.tag;
    } else {
      ttag = tag;
    }

    const data = {
      id: quiz,
      user_id: user.u_id,
      questions: qids,
      questionIds: currentQuestion?.question_id,
      timeLeft: totalTimeSpent,
      qanswers: qanswers,
      qbookmarks: qbookmarks,
      test_id: test_id,
      date: formattedDate,
      type: 'QUIZ',
      name: `${quiz} Quiz`,
      tutormode: responseData?.tutorModeActive || autotutor,
      timedMode: responseData?.timedModeActive || autotimer,
      tutorasked: askedConfirmation,
      result: result,
      qrealanswers: qrealanswers,
      qskillsets: qskillsets,
      tag: ttag,
      questiontimer: questiontimer
    };
    console.log(data);
    try {
      await axios.post(BASELINE + "autosave", { params: { data: JSON.stringify(data) } });
    } catch (err) {
      console.error("Error during autosave:", err.message);
    }

  };

  useEffect(() => {
    setResult(new Array(questionsarr.length).fill(undefined));
  }, [questionsarr]);

  useEffect(() => {
    let timerId;
    if (currentQuestion && result[currentQuestion.index] === undefined) {
      timerId = setInterval(() => {
        setQuestionTimers(prevTimers => {
          const newTimers = [...prevTimers];
          if (newTimers[currentQuestion.index] === undefined) {
            newTimers[currentQuestion.index] = 0;
          }
          newTimers[currentQuestion.index] += 1;
          return newTimers;
        });
      }, 1000); // Update every second
    }

    return () => clearInterval(timerId); // Clear interval when question changes or component unmounts
  }, [currentQuestion, result]);

  useEffect(() => {
    const currentTest = localStorage.getItem('currentTest');

    const runLocalStorageFlow = async () => {
      if (currentTest) {
        await handleLocalStorageData(currentTest); // Wait for handleLocalStorageData to finish
      } else {
        if (responseData) {
          if (responseData.test_id) {
            settest_id(responseData.test_id);
          } else {
            settest_id(uuidv4());
          }

          if (responseData.timedModeActive) {
            // Setup countdown timer
            startCountdownTimer(responseData.timeLimit * 60);
          } else {
            startStopwatch(0);
          }

          setquestionsarr(responseData.questionIds);
        } else {
          getQuestions();
          setdisablesave(false);
        }
      }
    };

    runLocalStorageFlow(); // Run the local storage flow on component mount

    // Clean up interval on component unmount to avoid memory leaks
    return () => {
      stopTimer(); // Function to stop and clear any active timers
    };
  }, [responseData]);

  useEffect(() => {
    const localStorageData = localStorage.getItem('currentTest');

    if (!localStorageData) {
      if (free || !currentQuestion) {
        if (timer) {
          clearInterval(timer);
        }
        return;
      }

      const startTime = Date.now();

      const newTimer = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = (currentTime - startTime) / 1000 + (timeSpentPerQuestion[currentQuestion.question_id] || 0);
        setTimeSpentPerQuestion(prev => ({
          ...prev,
          [currentQuestion.question_id]: elapsedTime
        }));
      }, 1000);

      setTimer(newTimer);

      if (responseData?.help !== true && user && user?.role !== "teacher") {
        if (disablesave === true) {
          autosaveData();  // Your existing condition to autosave data
        }
      }

      return () => {
        clearInterval(newTimer);
      };
    }
  }, [timerActive, timeLeft, currentQuestion, user, section, questions, answers, id, test_id, timeSpentPerQuestion, breaks]);

  useEffect(() => {
    if (questionsarr.length > 0 && auto === true) {
      getQuestions();
    }
  }, [questionsarr, auto]);

  useEffect(() => {
    var elt = document.getElementById('calculator');
    var calculator = window.Desmos?.GraphingCalculator(elt, {
      graphpaper: true,
      keypad: true
    });
    return () => {
      calculator?.destroy();
    }
  }, [showCalculator]);

  useEffect(() => {
    if (questions.length > 0 && currentQuestion) {
      let q = questions.find((ques => ques?.question_id === currentQuestion?.question_id));
      setCurrentQuestion(q);
    }
  }, [questions]);

  async function getQuestions() {

    if (qarr.length > 0) { setquestionsarr(qarr); setqarr([]); }

    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "questions/ap", { params: { id: questionsarr } });

      let arr = [res.data]
      let mappedQuestions = arr[0].map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });


      if (qanswers.length > 0) {
        mappedQuestions = mappedQuestions.map((q, idx) => ({
          ...q,
          selectedOption: qanswers[idx] || null,
          bookMarked: qbookmarks[idx] || false,
        }));
        setqanswers([]);
      }

      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[loadcurr]);
      setLoadingQuestions(false);
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  async function handleSubmit() {
    let newDat;

    if (responseData) {
      if (responseData.tag === "MCQ") {
        newDat = questions.map((question) => {
          const tagsArray = JSON.parse(question.tags);
          const apTags = tagsArray.filter(tag => tag.startsWith("AP"));
          let correctAnswer = question.answer.toLowerCase();

          if (question.selectedOption === correctAnswer) {
            question.correct = true;
          } else {
            question.correct = false;
          }

          const bookMarked = question.bookMarked;

          if (responseData.type === "AP_Calc_AB") {
            return {
              section: "mcq",
              correct: question.correct,
              selectedOption: question.selectedOption,
              correct_answer: correctAnswer,
              question_id: question.question_id,
              tag: 'math',
              topic: apTags,
              type: 'apcalcab',
              bookmark: bookMarked
            }
          }

        });

      } else if (responseData.tag === "FRQ") {
        console.log("=responseData", responseData);
        console.log("=questions", questions);
        
        newDat = await Promise.all(questions.map(async (question) => {
          let inner = [{
            main: question.content,
            questions: question.options,
            user_answer: question.mathAnswers,
            question_answer: question.answer,
            questino_rationale: question.step_by_step
          }];

          const tagsArray = JSON.parse(question.tags);
          const apTags = tagsArray.filter(tag => tag.startsWith("AP"));
          const bookMarked = question.bookMarked;

          if (responseData.type === "AP_Calc_AB") {
            try {
              const chatCompletion = await getChatCompletion(inner);

              // Extract the FrqResult and Total Score using regex
              const frqResult = chatCompletion || "";
              const totalScoreMatch = frqResult.match(/Total Score:\s*(\d+)\s*pt/); // Regex to extract the number
              const totalScore = totalScoreMatch ? parseInt(totalScoreMatch[1], 10) : null; // Convert to number
              const isCorrect = totalScore === 9;

              return {
                section: "frq",
                correct: isCorrect,
                selectedOption: question.mathAnswers,
                correct_answer: question.answer,
                question_id: question.question_id,
                tag: 'math',
                topic: apTags,
                type: 'apcalcab',
                bookMarked: bookMarked,
                FrqResult: frqResult,
                TotalScore: totalScore // Store the extracted total score
              };
            } catch (error) {
              console.error("Error in chat completion:", error);
              return null; // Handle the error gracefully
            }
          }
        }));

        setanswers(prevAnswers => [
          ...prevAnswers,
          ...newDat
        ]);
      }
    } else {
      if (tag === "MCQ") {
        newDat = questions.map((question) => {
          const tagsArray = JSON.parse(question.tags);
          console.log("=tagsArray", tagsArray);
          const apTags = tagsArray.filter(tag => tag.startsWith("AP"));
          let correctAnswer = question.answer.toLowerCase();

          if (question.selectedOption === correctAnswer) {
            question.correct = true;
          } else {
            question.correct = false;
          }

          const bookMarked = question.bookMarked;

          if (autotype === "AP_Calc_AB") {
            return {
              section: "mcq",
              correct: question.correct,
              selectedOption: question.selectedOption,
              correct_answer: correctAnswer,
              question_id: question.question_id,
              tag: 'math',
              topic: apTags,
              type: 'apcalcab',
              bookmark: bookMarked
            }
          }

        });
      } else if (tag === "FRQ") {
        newDat = await Promise.all(questions.map(async (question) => {
          let inner = [{
            main: question.content,
            questions: question.options,
            user_answer: question.mathAnswers,
            question_answer: question.answer,
            questino_rationale: question.step_by_step
          }];

          const tagsArray = JSON.parse(question.tags);
          const apTags = tagsArray.filter(tag => tag.startsWith("AP"));
          const bookMarked = question.bookMarked;

          if (targetPath === 'apcalcab') {
            try {
              const chatCompletion = await getChatCompletion(inner);

              // Extract the FrqResult and Total Score using regex
              const frqResult = chatCompletion || "";
              const totalScoreMatch = frqResult.match(/Total Score:\s*(\d+)\s*pt/); // Regex to extract the number
              const totalScore = totalScoreMatch ? parseInt(totalScoreMatch[1], 10) : null; // Convert to number
              const isCorrect = totalScore === 9;

              return {
                section: "frq",
                correct: isCorrect,
                selectedOption: question.mathAnswers,
                correct_answer: question.answer,
                question_id: question.question_id,
                tag: 'math',
                topic: apTags,
                type: 'apcalcab',
                bookMarked: bookMarked,
                FrqResult: frqResult,
                TotalScore: totalScore // Store the extracted total score
              };
            } catch (error) {
              console.error("Error in chat completion:", error);
              return null; // Handle the error gracefully
            }
          }
        }));

        setanswers(prevAnswers => [
          ...prevAnswers,
          ...newDat
        ]);
      }
    }

    try {
      let updatedAnswers = newDat;
      console.log("=updatedAnswers", updatedAnswers);
      // Assuming SAT_scores is populated appropriately:
      const correctQuestions = updatedAnswers.filter(question => question.correct === true);
      const incorrectQuestions = updatedAnswers.filter(question => question.correct === false);
      const bookmarkedQuestions = updatedAnswers.filter(question => question.bookmark === true); // Assuming 'bookMarked' exists
      const nullCorrectAnswerQuestions = updatedAnswers.filter(question => question.selectedOption === null);

      let mcqscore = 0;
      let frqscore = 0;
      let tt = 0;
      for (let i = 0; i < updatedAnswers.length; i++) {
        if (updatedAnswers[i].section === 'mcq' && updatedAnswers[i].correct === true) {
          mcqscore = mcqscore + 1;
        }
        else if (updatedAnswers[i].section === 'frq') {
          tt = 1;
          frqscore = frqscore + updatedAnswers[i].TotalScore;
        }
      }

      let score = 0;
      if (tt === 1) {
        score = frqscore;
      } else {
        score = mcqscore;
      }

      let test_name;
      if (responseData) {
        if (responseData.type === "AP_Calc_AB") {
          test_name = "AP Calculus AB Practice"
        }
      } else {
        if (autotype === 'AP_Calc_AB') {
          test_name = "AP Calculus AB Practice"
        }
      }
      var ori_id = localStorage.getItem('currentTest');

      let data = {
        Quiz_score: updatedAnswers,
        timeframe: timeLeft,
        userId: user.u_id,
        username: user.first_name + " " + user.last_name,
        test_name: test_name,
        ori_id: ori_id, //can be null
        test_id: test_id,
        math_score: score,
        usertime: timeSpentPerQuestion,
        opthistory: userResponses,
        type: 'AP_Calc_AB',
        correctQuestions: correctQuestions,
        incorrectQuestions: incorrectQuestions,
        bookmarkedQuestions: bookmarkedQuestions,
        nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
      }

      localStorage.removeItem('currentTest');
      localStorage.removeItem('testname');
      localStorage.removeItem('type');

      try {
        let saveResult = await axios.post(BASELINE + "tests/quiz", data);
        navigate(`/results/ap/quiz/${test_id}`);
      } catch (err) {
        console.error("Error saving test results:", err);
        // Handle error (e.g., show a notification)
      }


    } catch (err) {
      toast.error(err.message);
      console.error("Error saving test results:", err);

      setError(err.message);
    }
  }

  async function getChatCompletion(ele) {
    let data = [ele][0][0];
    const prompt = `
    <section1>
    You are an AP Calculus AB FRQ A exam grader; please accurately grade this FRQ question. Below is the main content of the problem.
    ${data.main} 
    </section1>

    <section2>
    Here is the answer key for the problem, which can be used to determine the score.
    ${data.questions}
    </section2>

    <section3>
    Below is the answer with the point guide line. It gives the direction of how many point the student can get and the answer that the student needs to have.
    
    <Question PROVIDE Answer>
    ${data.question_answer}
    </Question PROVIDE Answer>

    </section3>

    <section4>
    Below is the student's response indicated by the selected option letters. Please review and check if there are any undefined or null values in this section. If such values are found, it indicates that the user did not provide an answer to the question.
    
    <Student Answer>
    ${data.user_answer}
    </Student Answer>
    
    </section4>

    <section5>
    And here is the rationale of the answer please use this to grade the question
    
    <Question PROVIDE Rationale>
    ${data.questino_rationale}
    </Question PROVIDE Rationale>

    </section5>

    Below is the grading rubric. Please follow it carefully to assign an accurate score.
    Total Points: 9. The grading is divided into four categories: Understanding and Setup (2 points)—identify the correct calculus concept and properly set up the problem using appropriate formulas or definitions; Execution of Calculations (4 points)—correctly apply calculus rules, simplify expressions accurately, and substitute values when required, with partial credit awarded for each correct step; Final Answer (2 points)—provide the correct final numerical or algebraic answer, including units or interpreting the answer in context if applicable; and Communication and Justification (1 point)—clearly communicate reasoning using proper mathematical notation and terminology, justify steps when needed, and accurately label any graphs or diagrams used.
    
    Please provide the total points earned and list the scores I received for understanding, calculation, final answer, and justification. This is for the entire question and the total points that the user can get from this problem is 9. It needs to give the Grading summary within 400 tokens and the score chart like below.
    
    You need to follow the below chart to give the score. Do not change the word just fill the score below. Keep the Chart Format 
    Understanding and Setup (2 points) / []pt
    Execution of Calculations (4 points) / []pt
    Final Answer (2 points) / []pt
    Communication and Justification (1 point) / []pt
    Total Score: []pt
    The output should be split into two Grading Summary (within 400 tokens) and Scoring Chart
    `;

    try {
      const response = await axios.post(BASELINE + 'askai', { prompt })
        .then(res => {
          // Safely return the content of the AI response
          if (res.data && res.data.message) {
            return res.data.message;
          } else {
            console.error("Unexpected response structure:", res.data);
            return "Error: Unexpected response from the server.";
          }
        })
        .catch(err => {
          console.error("Error during API call:", err);
          return "Error: Unable to process the request.";
        });

      return response;
    } catch (error) {
      console.error("Unexpected error:", error);
      throw error; // Re-throw for further error handling if needed
    }

  }

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.question_id === itemId) {
          if (question.bookMarked) {
            return { ...question, bookMarked: false };
          } else {
            return { ...question, bookMarked: true };
          }
        } else {
          return question;
        }
      })
    }))
  }

  function updateQuestionsAfterInteraction(itemId, selectedOption) {
    setQuestions(prev => {
      const updatedQuestions = prev.map(question => {
        if (question.question_id === itemId) {
          return {
            ...question,
            selectedOption: selectedOption ? selectedOption : question.selectedOption,
          };
        }
        return question;
      });
      const updatedQuestion = updatedQuestions.find(question => question.question_id === itemId);
      setCurrentQuestion(updatedQuestion);
      return updatedQuestions;
    });
  }

  async function handleSelectOption(itemId, option) {
    if (result[currentQuestion.index] === null || result[currentQuestion.index] === undefined) {
      setUserResponses(prev => {
        const currentActions = prev[itemId] ? [...prev[itemId].actions] : [];
        currentActions.push({ action: "mark", option: option });

        return {
          ...prev,
          [itemId]: {
            ...prev[itemId],
            actions: currentActions,
          }
        };
      });

      setQuestions((pre) => {
        const updatedQuestions = pre.map((question) => {
          if (question?.question_id === itemId) {
            return { ...question, selectedOption: option };
          } else {
            return question;
          }
        });

        // Find the updated question
        const updatedQuestion = updatedQuestions.find((question) => question.question_id === itemId);

        // Update the currentQuestion state
        setCurrentQuestion(updatedQuestion);
        updateQuestionsAfterInteraction(itemId, option);

        return updatedQuestions;
      });
    }
  }

  async function handleCrossOutOptions(itemId, option) {
    setUserResponses(prev => {
      const currentActions = prev[itemId] ? [...prev[itemId].actions] : [];
      if (prev[itemId] && prev[itemId].actions.some(act => act.action === "crossout" && act.option === option)) {
        // Remove the crossout action if it exists
        return {
          ...prev,
          [itemId]: {
            ...prev[itemId],
            actions: currentActions.filter(act => !(act.action === "crossout" && act.option === option)),
          }
        };
      } else {
        // Add a new crossout action
        currentActions.push({ action: "crossout", option: option });
        return {
          ...prev,
          [itemId]: {
            ...prev[itemId],
            actions: currentActions,
          }
        };
      }
    });
    updateQuestionsAfterInteraction(itemId, null);  // No selection change, just update for crossout.

    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.question_id === itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }

  const handleAnswerValueChange = (itemId, value) => {
    setQuestions(prevQuestions =>
      prevQuestions.map(question =>
        question.question_id === itemId ? { ...question, selectedOption: value } : question
      )
    );
  };

  function parseFraction(fraction) {
    let [numerator, denominator] = fraction.split('/');
    return denominator ? parseFloat(numerator) / parseFloat(denominator) : parseFloat(numerator);
  }

  function checkAnswer(userInput, correctAnswersJSON) {
    let correctAnswers;

    // Attempt to parse the correct answers JSON
    try {
      correctAnswers = JSON.parse(correctAnswersJSON);
    } catch (e) {
      // If parsing fails, wrap the input as a single answer in an array
      correctAnswers = [correctAnswersJSON];
    }

    // Normalize the correct answers, whether it's a single value or an array
    correctAnswers = Array.isArray(correctAnswers) ? correctAnswers : [correctAnswers];

    correctAnswers = correctAnswers.map(answer => {
      // Normalize each answer: handle numbers and fractions
      if (typeof answer === 'string' && answer.includes('/')) {
        return parseFraction(answer);  // Handle fractions
      } else if (!isNaN(answer)) {
        // Handle integers and floats
        return parseFloat(answer);
      }
      return answer.toString().toLowerCase(); // Handle string answers (e.g., 'A', 'B', etc.)
    });

    // Normalize user input for both numbers and strings
    let normalizedInput;
    if (typeof userInput === 'string' && userInput.includes('/')) {
      normalizedInput = parseFraction(userInput);  // Handle fraction input
    } else if (!isNaN(userInput)) {
      normalizedInput = parseFloat(userInput);  // Handle number input
    } else {
      normalizedInput = userInput.toString().toLowerCase();  // Handle string input (e.g., 'A', 'B', etc.)
    }

    // Check if the normalized user input is in the list of correct answers
    return correctAnswers.includes(normalizedInput);
  }

  async function handleNext() {
    setshowanswer(false);
    const tutorModeActive = responseData?.tutorModeActive || autotutor;

    if (!tutorModeActive) {
      if (currentQuestion.index < questions.length - 1) {
        setCurrentQuestion(questions[currentQuestion.index + 1]);
      } else {
        setCurrentQuestion(null);
        setQuestionOverviewOpen(false);
      }
    } else {
      const currentIdx = currentQuestion.index;

      // Only ask confirmation if not already asked for the current question
      if (!askedConfirmation[currentIdx]) {
        const userResponse = window.confirm(
          "You have not selected an answer. Are you sure you want to proceed without answering?"
        );
        if (!userResponse) {
          // If the user doesn't want to proceed, return early
          return;
        }
        // If the user chooses to proceed, set confirmation as asked for this question
        setAskedConfirmation(prev => {
          const newAsked = [...prev];
          newAsked[currentIdx] = true;
          return newAsked;
        });
      }

      // Check if the selected option is correct
      const isCorrect = checkAnswer(
        currentQuestion.selectedOption,
        currentQuestion.answer
      );

      // Update the result array at the current question's index
      setResult((prevResults) => {
        const newResults = [...prevResults];
        newResults[currentQuestion.index] = isCorrect;
        return newResults;
      });

      // Use the updated result (since setState is async)
      setTimeout(() => {
        if (result[currentIdx] !== undefined) {
          if (currentQuestion.index < questions.length - 1) {
            setCurrentQuestion(questions[currentQuestion.index + 1]);
          } else {
            setCurrentQuestion(null);
            setQuestionOverviewOpen(false);
          }
        }
      }, 0);
    }
  }

  async function handleBack() {
    if (!currentQuestion) {
      setCurrentQuestion(questions[questions.length - 1]);
      return;
    }
    if (currentQuestion.index > 0) {
      setCurrentQuestion((pre) => questions[pre.index - 1])
    } else {

    }
  }

  const closeRefModal = () => {
    setShowDirectionRefModal(false);
  };

  const closeModal = () => {
    setShowDirectionModal(false);
  };

  return (
    <div className="min-h-screen bg-white p-1 relative">
      {loadingQuestions ?
        <div className="flex flex-col justify-center items-center py-48">
          <div className="border-t-transparent border-solid animate-spin rounded-full border-blue-400 border-8 h-52 w-52"></div>

        </div>
        :
        <>

          <div className="flex flex-row justify-between">

            <div className="flex flex-row justify-between items-center w-[100%] mt-[20px] pb-[10px] border-b border-gray-200">
              <div className="ml-[20px] flex flex-col">
                <h3>
                  <strong>
                    Section: Practice AP
                  </strong>
                </h3>
                <motion.div whileTap={{ scale: 0.97 }} className="hidden left-0 flex flex-row gap-1 hover:text-blue-900">
                  <div>
                    <button onClick={() => setShowDirectionModal((pre) => !pre)}>Directions</button>
                  </div>
                  <div onClick={() => setShowDirectionModal(true)} className="flex flex-row items-center cursor-pointer">
                    <BsChevronDown />
                  </div>
                </motion.div>
                {showDirectionModal && <DirectionModal onClose={closeModal} mode={sat} />}
              </div>

              <div className="flex-grow">
                <div
                  className="fixed left-1/2 transform -translate-x-1/2 z-50 mt-[-20px]"
                  style={{
                    textAlign: 'center', // Center the text inside the timer box
                  }}
                >
                  <Timer
                    minutes={displayMinutes.toString().padStart(2, '0')}
                    seconds={displaySeconds.toString().padStart(2, '0')}
                  />
                </div>
              </div>



              {targetPath === "apcalcab" && (section === 3 || section === 1) && (<>
                <motion.button whileTap={{ scale: 0.97 }} onClick={() => setShowCalculator(pre => !pre)} className={`mr-[4px] flex flex-col items-center cursor-pointer hover:text-blue-900 ${showCalculator ? "text-blue-900" : "text-black"}`}>
                  <div className={`flex flex-row justify-center`}>
                    <CiCalculator1 size="1.5em" />
                  </div>
                  <div>
                    <p className="text-sm">Calculator</p>
                  </div>
                </motion.button>
                <motion.button whileTap={{ scale: 0.97 }} className={`mr-[4px] flex flex-col items-center cursor-pointer hover:text-blue-900 ${showReference === true ? 'text-blue-900' : "text-black"} `} onClick={() => setShowDirectionRefModal(!showDirectionModalRef)}>
                  <div className="flex flex-row justify-center">
                    <VscReferences size="1.5em" />
                  </div>
                  <div>
                    <p className="text-sm">Reference</p>
                  </div>
                </motion.button>
                {showDirectionModalRef && <ReferenceSheet onClose={closeRefModal} />}
              </>)}

              <div className="ml-[5px] flex flex-col items-center mr-[20px]">
                {!free && (
                  <>
                    <div className="flex flex-col items-center" onClick={() => setShowOptions(!showOptions)}>
                      <BsThreeDotsVertical size="1.5em" />
                      <p className="text-sm mt-1 text-center">More</p>
                    </div>

                    {showOptions && (
                      <div className="fixed mt-[70px] mr-[10px] flex flex-row justify-center cursor-pointer hover:text-red-900 z-[100]" onClick={handleReportClick}>
                        <BsExclamationTriangle size="1.5em" />
                        <p className="text-sm ml-1">Report</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>


            <div className="flex flex-row gap-2">

              <div className="flex flex-col cursor-pointer">
                {showModal && (
                  <div className="fixed z-[20] inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                      </div>
                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                Report Card
                              </h3>
                              <div className="mt-2">
                                <form>
                                  <label>Question ID:</label>
                                  <input type="text" className="border p-1 mt-1 w-full" value={currentQuestion.question_id} readOnly />
                                  <label>Report from:</label>
                                  <input type="text" className="border p-1 mt-1 w-full" value={user?.first_name + " " + user?.last_name} readOnly />
                                  <label>Context:</label>
                                  <textarea
                                    className="border p-1 mt-1 w-full"
                                    value={context}
                                    onChange={handleContextChange}
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={handleSubmit2}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={handleCloseModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>

          <div className="flex max-h-full">
            <div className="flex-[8]">
              <div className="h-[90%]">
                <motion.div drag style={{
                  width: '600px',
                  height: '400px',
                  position: 'absolute',
                  zIndex: 100,
                  display: showCalculator ? "inline" : "none",
                }}
                  dragConstraints={{
                    top: -125,
                    right: 500,
                    bottom: 300,
                    left: -125,
                  }}
                >
                  <div id="calculator" className="w-full h-full">

                  </div>

                </motion.div>
                {/* question */}
                <div className="flex flex-col">

                  {currentQuestion ?
                    <div className="min-w-[80%] flex flex-col sm:flex-row justify-center h-[50%]">
                      <Question
                        sat={sat}
                        handleAnswerValueChange={handleAnswerValueChange}
                        handleCrossOutOptions={handleCrossOutOptions}
                        handleSelectOption={handleSelectOption}
                        currentQuestion={currentQuestion}
                        idx={1}
                        bookmarkedQuestions={bookmarkedQuestions}
                        setBookMarkedQuestions={setBookMarkedQuestions}
                        handleBookMark={handleBookMark}
                        setQuestions={setQuestions}
                        result={result[currentQuestion.index]}
                        time={questionTimers[currentQuestion.index]}
                      />
                    </div>
                    :
                    <div className="min-w-[80%] min-h-[50%] flex justify-center items-center">
                      <Review
                        currentQuestion={currentQuestion}
                        setCurrentQuestion={setCurrentQuestion}
                        questions={questions}
                        setQuestions={setQuestions}
                      />
                    </div>

                  }
                </div>

              </div>
              <div className="z-[100] fixed inset-x-0 bottom-[10px] flex flex-row justify-around border-t-2 border-black pt-4 bg-white">
                {questionOverViewOpen && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      type: "spring",
                    }}
                    className="absolute z-[100] rounded-md flex flex-row justify-center p-1 bg-white -top-40 border-[1px] border-gray-300"
                  >
                    <div className="bg-white min-h-[140px] max-h-[140px] min-w-[200px] max-w-[400px] flex flex-row flex-wrap rounded-md">
                      {questions.map((ques, idx) => (
                        <div
                          key={'questions-' + idx}
                          onClick={() => setCurrentQuestion(questions[idx])}
                          className={`${ques.bookMarked && 'bg-red-500 border-none text-white'} 
                      m-1 rounded-full border-[1px] w-[25px] h-[25px] flex flex-row justify-center items-center cursor-pointer 
                      ${currentQuestion?.question_id === ques?.question_id && 'bg-black text-white'}
                      ${ques.selectedOption != null && 'border-none bg-blue-700 text-white'}
                      border-black`}
                        >
                          {idx + 1}
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}

                <div className="flex flex-row items-center z-[999]">
                  <p className="font-semibold text-lg">{free ? 'Free Trial' : `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.trim() || 'John Doe'}</p>
                </div>
                {currentQuestion && (
                  <motion.button
                    whileTap={{ scale: 0.97 }}
                    onClick={() => setQuestionOverviewOpen((pre) => !pre)}
                    className="flex flex-row items-center gap-1 bg-black hover:bg-gray-800 text-white rounded-md p-2 cursor-pointer"
                  >
                    <div className="flex flex-row items-center">
                      <p className="text-sm md:text-l">
                        Question {currentQuestion?.index + 1} of {questions?.length}
                      </p>
                    </div>
                    <div className="flex flex-row items-center">
                      {questionOverViewOpen ? <BsChevronDown /> : <BsChevronUp />}
                    </div>
                  </motion.button>
                )}
                <div className="flex flex-row items-center gap-2">
                  <motion.button
                    whileTap={{ scale: 0.97 }}
                    className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                    onClick={() => handleBack()}
                  >
                    Back
                  </motion.button>
                  {currentQuestion ? (
                    <motion.button
                      whileTap={{ scale: 0.97 }}
                      className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                      onClick={() => handleNext()}
                    >
                      Next
                    </motion.button>
                  ) : (
                    <motion.button
                      whileTap={{ scale: 0.97 }}
                      className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </motion.button>
                  )}
                </div>
              </div>
            </div>
            {currentQuestion && result.length > 0 && (autotutor === true || responseData?.tutorModeActive === true) && (
              <AIAudioPlayer
                result={result[currentQuestion?.index]}
                context={aicontext}
                currentQuestion={currentQuestion}
                type1={responseData?.type}
                type2={autotype}
              />
            )}
          </div>
        </>
      }
    </div>
  );
};
export default APQuiz;