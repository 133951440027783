import { useEffect, useState, useContext, useRef } from "react";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import { BASELINE } from "../../../util";
import { motion } from "framer-motion";
import ModalComponent from "../pick_modal";

ChartJS.register(ArcElement, Tooltip, Legend);

const OverallBCAnal = () => {
  const boxItemLeftRef = useRef();

  const { user } = useContext(UserContext);
  const [selected, setSelected] = useState('English');  // Default selection\
  const [eproblems, seteproblems] = useState([]);
  const [mproblems, setmproblems] = useState([]);
  const [co, setco] = useState(0);
  const [inco, setinco] = useState(0);
  const [omit, setomit] = useState(0);

  const [co2, setco2] = useState(0);
  const [inco2, setinco2] = useState(0);
  const [omit2, setomit2] = useState(0);

  const [report_eng, set_report_eng] = useState([]);
  const [report_math, set_report_math] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const [tag, settag] = useState('');
  const [hideExplanation, setHideExplanation] = useState(false);
  const [topic, settopic] = useState(false);
  const [section, setsection] = useState("");

  const [mcqsubjects, setmcqsubjects] = useState([
    {
      main: "Concept of a Limit", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U1_1', name: '1.1 Introducing Calculus: Can Change Occur at an Instant?', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_2', name: '1.2 Defining Limits and Using Limit Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_3', name: '1.3 Estimating Limit Values from Graphs', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_4', name: '1.4 Estimating Limit Values from Tables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_5', name: '1.5 Determining Limits Using Algebraic Properties of Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_6', name: '1.6 Determining Limits Using Algebraic Manipulation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_7', name: '1.7 Selecting Procedures for Determining Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_8', name: '1.8 Determining Limits Using the Squeeze Theorem', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_9', name: '1.9 Connecting Multiple Representations of Limits', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Continuity", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U1_10', name: '1.10 Exploring Types of Discontinuities', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_11', name: '1.11 Defining Continuity at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_12', name: '1.12 Confirming Continuity over an Interval', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_13', name: '1.13 Removing Discontinuities', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Asymptotes & IVT", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U1_14', name: '1.14 Connecting Infinite Limits and Vertical Asymptotes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_15', name: '1.15 Connecting Limits at Infinity and Horizontal Asymptotes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U1_16', name: '1.16 Working with the Intermediate Value Theorem (IVT)', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Definition and Existence of Derivatives", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U2_1', name: '2.1 Defining Average and Instantaneous Rates of Change at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_2', name: '2.2 Defining the Derivative of a Function and Using Derivative Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_3', name: '2.3 Estimating Derivatives of a Function at a Point', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_4', name: '2.4 Connecting Differentiability and Continuity: Determining When Derivatives Do and Do Not Exist', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Calculating Derivatives", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U2_5', name: '2.5 Applying the Power Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_6', name: '2.6 Derivative Rules: Constant, Sum, Difference, and Constant Multiple', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_7', name: '2.7 Derivatives of cos(x), sin(x), mathrm, and ln(x)', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_8', name: '2.8 The Product Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_9', name: '2.9 The Quotient Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U2_10', name: '2.10 Finding the Derivatives of Tangent, Cotangent, Secant, and/or Cosecant Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Advanced Derivative Rules", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U3_1', name: '3.1 The Chain Rule', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_2', name: '3.2 Implicit Differentiation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Inverse Functions and Higher-Order Derivatives", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U3_3', name: '3.3 Differentiating Inverse Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_4', name: '3.4 Differentiating Inverse Trigonometric Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_5', name: '3.5 Selecting Procedures for Calculating Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U3_6', name: '3.6 Calculating HigherOrder Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Derivatives in Context", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U4_1', name: '4.1 Interpreting the Meaning of the Derivative in Context', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_2', name: '4.2 Straight-Line Motion: Connecting Position, Velocity, and Acceleration', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_3', name: '4.3 Rates of Change in Applied Contexts Other Than Motion', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Related Rates", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U4_4', name: '4.4 Introduction to Related Rates', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_5', name: '4.5 Solving Related Rates Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Linearity and L'Hospital's Rule", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U4_6', name: '4.6 Approximating Values of a Function Using Local Linearity and Linearization', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U4_7', name: '4.7 Using LHospitals Rule for Determining Limits of Indeterminate Forms', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "MVT and EVT", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U5_1', name: '5.1 Using the Mean Value Theorem', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_2', name: '5.2 Extreme Value Theorem, Global Versus Local Extrema, and Critical Points', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Function Behavior", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U5_3', name: '5.3 Determining Intervals on Which a Function Is Increasing or Decreasing', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_4', name: '5.4 Using the First Derivative Test to Determine Relative (Local) Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_5', name: '5.5 Using the Candidates Test to Determine Absolute (Global) Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_6', name: '5.6 Determining Concavity of Functions over Their Domains', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_7', name: '5.7 Using the Second Derivative Test to Determine Extrema', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_8', name: '5.8 Sketching Graphs of Functions and Their Derivatives', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_9', name: '5.9 Connecting a Function, Its First Derivative, and Its Second Derivative', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Optimization", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U5_10', name: '5.10 Introduction to Optimization Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_11', name: '5.11 Solving Optimization Problems', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U5_12', name: '5.12 Exploring Behaviors of Implicit Relations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Concept of Integration", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U6_1', name: '6.1 Exploring Accumulations of Change', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_2', name: '6.2 Approximating Areas with Riemann Sums', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_3', name: '6.3 Riemann Sums, Summation Notation, and Definite Integral Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Fundamental Theorem of Calculus", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U6_4', name: '6.4 The Fundamental Theorem of Calculus and Accumulation Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_5', name: '6.5 Interpreting the Behavior of Accumulation Functions Involving Area', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_6', name: '6.6 Applying Properties of Definite Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_7', name: '6.7 The Fundamental Theorem of Calculus and Definite Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_8', name: '6.8 Finding Antiderivatives and Indefinite Integrals: Basic Rules and Notation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Integration Techniques", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U6_9', name: '6.9 Integrating Using Substitution', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_10', name: '6.10 Integrating Functions Using Long Division and Completing the Square', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_11', name: '6.11 Integrating Using Integration by Parts', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_12', name: '6.12 Using Linear Partial Fractions ', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_13', name: '6.13 Evaluating Improper Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U6_14', name: '6.14 Selecting Techniques for Antidifferentiation', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Analyzing Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_1', name: '7.1 Modeling Situations with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_2', name: '7.2 Verifying Solutions for Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_3', name: '7.3 Sketching Slope Fields', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_4', name: '7.4 Reasoning Using Slope Fields', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Solving Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_5', name: '7.5 Approximating Solutions Using Eulers Method', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_6', name: '7.6 Finding General Solutions Using Separation of Variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U7_7', name: '7.7 Finding Particular Solutions Using Initial Conditions and Separation of Variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Modeling Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_8', name: '7.8 Exponential Models with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Solving Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U7_9', name: '7.9 Logistic Models with Differential Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Definite Integrals in Context", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_1', name: '8.1 Finding the Average Value of a Function on an Interval', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_2', name: '8.2 Connecting Position, Velocity, and Acceleration of Functions Using Integrals', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_3', name: '8.3 Using Accumulation Functions and Definite Integrals in Applied Contexts', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Area Between Curves", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_4', name: '8.4 Finding the Area Between Curves Expressed as Functions of x', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_5', name: '8.5 Finding the Area Between Curves Expressed as Functions of y', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_6', name: '8.6 Finding the Area Between Curves That Intersect at More Than Two Points', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Volume", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_7', name: '8.7 Volumes with Cross Sections: Squares and Rectangles', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_8', name: '8.8 Volumes with Cross Sections: Triangles and Semicircles', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_9', name: '8.9 Volume with Disc Method: Revolving Around the x-or y-Axis', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_10', name: '8.10 Volume with Disc Method: Revolving Around Other Axes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_11', name: '8.11 Volume with Washer Method: Revolving Around the x - or y-Axis', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U8_12', name: '8.12 Volume with Washer Method: Revolving Around Other Axes', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Arc Length", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U8_13', name: '8.13 The Arc Length of a Smooth, Planar Curve and Distance Traveled', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Parametric Equations", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U9_1', name: '9.1 Defining and Differentiating Parametric Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_2', name: '9.2 Second Derivatives of Parametric Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_3', name: '9.3 Finding Arc Lengths of Curves Given by Parametric Equations', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Vector-Valued Functions", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U9_4', name: '9.4 Defining and Differentiating VectorValued Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_5', name: '9.5 Integrating VectorValued Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_6', name: '9.6 Solving Motion Problems Using Parametric and VectorValued Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Polar Coordinates", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U9_7', name: '9.7 Defining Polar Coordinates and Differentiating in Polar Form', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_8', name: '9.8 Find the Area of a Polar Region or the Area Bounded by a Single Polar Curve', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U9_9', name: '9.9 Finding the Area of the Region Bounded by Two Polar Curves', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Infinite Series", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U10_1', name: '10.1 Defining Convergent and Divergent Infinite Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_2', name: '10.2 Working with Geometric Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Tests for Convergence and Divergence", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U10_3', name: '10.3 The nth Term Test for Divergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_4', name: '10.4 Integral Test for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_5', name: '10.5 Harmonic Series and p-Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_6', name: '10.6 Comparison Tests for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_7', name: '10.7 Alternating Series Test for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_8', name: '10.8 Ratio Test for Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      main: "Sum of Series", count: 0, isOpen: true, isSelected: false, tag: 'mcq',
      subtopics: [
        { id: 'APCALC_U10_9', name: '10.9 Determining Absolute or Conditional Convergence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_10', name: '10.10 Alternating Series Error Bound', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_11', name: '10.11 Finding Taylor Polynomial Approximations of Functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_12', name: '10.12 Lagrange Error Bound', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_13', name: '10.13 Radius and Interval of Convergence of Power Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_14', name: '10.14 Finding Taylor or Maclaurin Series for a Function', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { id: 'APCALC_U10_15', name: '10.15 Representing Functions as Power Series', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
  ]);

  const [frqsubjects, setfrqsubjects] = useState([
    {
      main: "Area and Volume", count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_01', name: 'Area and Volume', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Rates", count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_02', name: 'Rates', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Charts with Riemann Sums, Mean Value Theorem, Average Rates/Values", count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_03', name: 'Charts with Riemann Sums, Mean Value Theorem, Average Rates/Values', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Analyzing the Graph of f", count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_04', name: 'Analyzing the Graph of f', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Slope Fields/Differential Equations", count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_05', name: 'Slope Fields/Differential Equations', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Related Rates", count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_06', name: 'Related Rates', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Accumulation Functions", count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_07', name: 'Accumulation Functions', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Implicit Diferentiation", count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_08', name: 'Implicit Diferentiation', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: "Particle Motion", count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_09', name: 'Particle Motion', count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: `Charts of f, f', f"`, count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_10', name: `Charts of f, f', f"`, count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
    {
      main: `Functions/Misc.`, count: 0, isOpen: true, isSelected: false, tag: 'frq',
      subtopics: [
        { id: 'APCALC_FRQ_11', name: `Functions/Misc.`, count: 0, isOpen: true, isSelected: false, tag: 'frq' }
      ]
    },
  ]);

  const handleChange2 = () => {
    setHideExplanation(!hideExplanation);
  };

  useEffect(() => {
    getting_problems();
    getallsat();
    getusersat();
  }, []);

  useEffect(() => {
    // Dynamically load the KaTeX script
    const script2 = document.createElement('script');
    script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
    script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
    script2.crossOrigin = 'anonymous';

    // Handle script load
    script2.onload = () => {
      if (boxItemLeftRef.current) {
        const options = {
          delimiters: [
            { left: '$$', right: '$$', display: false },
            { left: "$", right: "$", display: false },
            { left: '\\(', right: '\\)', display: false },
            { left: '\\[', right: '\\]', display: true },
            { left: '\\begin{array}', right: '\\end{array}', display: true }
          ],
          throwOnError: false,
        };

        window.renderMathInElement(boxItemLeftRef.current, options);
      }
    };
    document.body.appendChild(script2);

    // Cleanup: remove the script when component unmounts
    return () => {
      document.body.removeChild(script2);
    };
  }, [selectedTopic, hideExplanation]);

  const getsatquestion = (questionId, tag) => {
    settag(tag);
    axios.get(BASELINE + "questions/ap/code", { // Ensure the endpoint is correctly specified
      params: {
        id: questionId  // Changed from questionId to qid to match the server expectation
      }
    })
      .then(response => {
        //    console.log("Response data:", response.data); // Log or handle the response data appropriately
        setSelectedTopic(response.data);
        setIsModalOpen(true);
      })
      .catch(err => {
        console.error("Error fetching SAT question data:", err);
      });
  }


  const updateScoresWithApiData = (apiData) => {
    setScores(prevScores => {
      // Create a map from the API data for quick lookup
      const apiScoresMap = apiData.reduce((acc, item) => {
        acc[item.test_name] = JSON.parse(item.eng_score)["APscore"];
        return acc;
      }, {});
      // Map over the existing scores and update 'myScore' if there's a matching entry in the API data
      return prevScores.map(score => (
        {
          ...score,
          myScore: apiScoresMap[score.id] || score.myScore // Use API score if available, otherwise keep the existing score
        }));
    });
  }

  const getusersat = () => {
    axios.get(`${BASELINE}get/user/ap`, {
      params: {
        user_id: user.u_id  // assuming 'user_id' is the correct parameter name expected by your server
      }
    })
      .then(response => {
        //    console.log("Data received:", response.data);
        updateScoresWithApiData(response.data);
      })
      .catch(err => {
        console.error("Error fetching user SAT data:", err);
      });
  }

  const getallsat = () => {
    axios.get(`${BASELINE}get/all/ap`)
      .then(response => {
        const data = response.data;
        console.log(data);
        // Aggregate scores by test_name
        const scoresMap = data.reduce((acc, item) => {
          if (!acc[item.test_name]) {
            acc[item.test_name] = { total: 0, count: 0 };
          }
          acc[item.test_name].total += JSON.parse(item.eng_score)["APscore"];
          acc[item.test_name].count += 1;
          return acc;
        }, {});

        // Calculate averages and round them
        const averages = Object.keys(scoresMap).map(test => ({
          test_name: test,
          avgScore: Math.round(scoresMap[test].total / scoresMap[test].count) // Rounds to the nearest integer
        }));

        // Update the scores array
        const updatedScores = scores.map(score => {
          const averageData = averages.find(avg => avg.test_name === score.id);
          return {
            ...score,
            avgScore: averageData ? averageData.avgScore : score.avgScore // Uses the rounded average
          };
        });

        setScores(updatedScores);
        // Now you can set this updatedScores to your state or use it as needed
      })
      .catch(err => console.error("Error fetching SAT scores:", err));
  }

  const getting_problems = () => {
    axios.get(`${BASELINE}tests/byuser/ap/${user.u_id}`)
      .then(response => {
        const allIncorrectIds = response.data.reduce((acc, test) => {
          if (test.Incorrect_questionIds) {
            const incorrectIds = JSON.parse(test.Incorrect_questionIds);
            acc = acc.concat(incorrectIds);
          }
          return acc;
        }, []);

        const allcorrectIds = response.data.reduce((acc, test) => {
          if (test.Correct_questionIds) {
            const correctIds = JSON.parse(test.Correct_questionIds);
            acc = acc.concat(correctIds);
          }
          return acc;
        }, []);

        const allomittedIds = response.data.reduce((acc, test) => {
          if (test.Omitted_questionIds) {
            const omittedIds = JSON.parse(test.Omitted_questionIds);
            acc = acc.concat(omittedIds);
          }
          return acc;
        }, []);

        // Splitting the incorrect questions by tag
        const allQuestions = [...allIncorrectIds, ...allcorrectIds];

        const engQuestions = allQuestions.filter(question => question.section === 'mcq');
        const mathQuestions = allQuestions.filter(question => question.section === 'frqa' || question.section === 'frqb');

        // Further split by topics
        const groupedByEngTopic = groupByTopic(engQuestions);
        const groupedByMathTopic = groupByTopic(mathQuestions);

        const topEngQuestions = getTopQuestions(engQuestions);
        const topMathQuestions = getTopQuestions(mathQuestions);

        seteproblems(topEngQuestions);
        setmproblems(topMathQuestions);

        const inco = engQuestions.filter(q => q.correct === false).length;
        const co = engQuestions.filter(q => q.correct === true).length;
        const omit = engQuestions.filter(q => q.correct_answer.length > 1).length;
        const totalQuestions = engQuestions.length;

        const incorrectPercentage = (inco / totalQuestions) * 100;
        const correctPercentage = (co / totalQuestions) * 100;
        const omittedPercentage = (omit / totalQuestions) * 100;

        updateChartData(incorrectPercentage, correctPercentage, omittedPercentage);

        setco(correctPercentage.toFixed(1));
        setinco(incorrectPercentage.toFixed(1));
        setomit(omittedPercentage.toFixed(1));

        //----

        const inco2 = mathQuestions.filter(q => q.correct === false).length;
        const co2 = mathQuestions.filter(q => q.correct === true).length;
        const omit2 = mathQuestions.filter(q => q.correct_answer.length > 1).length;
        const totalQuestions2 = mathQuestions.length;

        const incorrectPercentage2 = (inco2 / totalQuestions2) * 100;
        const correctPercentage2 = (co2 / totalQuestions2) * 100;
        const omittedPercentage2 = (omit2 / totalQuestions2) * 100;

        updateChartData2(incorrectPercentage2, correctPercentage2, omittedPercentage2);

        setco2(correctPercentage2.toFixed(1));
        setinco2(incorrectPercentage2.toFixed(1));
        setomit2(omittedPercentage2.toFixed(1));

        const engSubjects = transformData(groupedByEngTopic);
        const mathSubjects = transformData2(groupedByMathTopic);

        console.log("engSubjects", engSubjects);
        set_report_eng(engSubjects);
        set_report_math(mathSubjects);

      })
      .catch(error => {
        console.error("Error fetching user tests:", error);
      });
  }

  const updateChartData = (incorrectPercentage, correctPercentage, omittedPercentage) => {
    setDoughnutData({
      datasets: [{
        data: [incorrectPercentage, correctPercentage, omittedPercentage],
        backgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
        hoverBackgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
        borderWidth: 0,
      }],
      labels: ['Incorrect', 'Correct', 'Omitted'],
    });
  };

  const updateChartData2 = (incorrectPercentage, correctPercentage, omittedPercentage) => {
    setDoughnutData2({
      datasets: [{
        data: [incorrectPercentage, correctPercentage, omittedPercentage],
        backgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
        hoverBackgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
        borderWidth: 0,
      }],
      labels: ['Incorrect', 'Correct', 'Omitted'],
    });
  };

  const [doughnutData, setDoughnutData] = useState({
    datasets: [{
      data: [7, 93], // Default values
      backgroundColor: ['#FF6384', '#E7E9ED'],
      hoverBackgroundColor: ['#FF6384', '#E7E9ED'],
      borderWidth: 0,
    }],
    labels: ['Incorrect', 'Correct', 'Omitted'],
  });

  const [doughnutData2, setDoughnutData2] = useState({
    datasets: [{
      data: [7, 93], // Default values
      backgroundColor: ['#FF6384', '#E7E9ED'],
      hoverBackgroundColor: ['#FF6384', '#E7E9ED'],
      borderWidth: 0,
    }],
    labels: ['Incorrect', 'Correct', 'Omitted'],
  });

  const groupByTopic = (questions) => {
    return questions.reduce((acc, question) => {
      const topic = question.topic;
      if (!acc[topic]) {
        acc[topic] = { correct: 0, incorrect: 0, omitted: 0, total: 0 };
      }
      if (question.selectedOption === null) {
        acc[topic].omitted++;
      } else if (question.correct) {
        acc[topic].correct++;
      } else {
        acc[topic].incorrect++;
      }
      acc[topic].total++;
      return acc;
    }, {});
  };

  const calculatePercentage = (count, total) => {
    return `${((count / total) * 100).toFixed(1)}%`; // Ensure percentages are in string format with a '%'
  };

  const findIdByName2 = (id, mcqsubjects) => {
    for (const subject of mcqsubjects) {
      for (const subtopic of subject.subtopics) {
        if (subtopic.id === id) {
          return subtopic.name;
        }
      }
    }
    return null; // Return null if no match is found
  };

  const transformData2 = (groupedData) => {
    return Object.entries(groupedData).map(([topic, counts]) => ({
      name: findIdByName2(topic, frqsubjects),
      id: topic,
      correct: calculatePercentage(counts.correct, counts.total),
      incorrect: calculatePercentage(counts.incorrect, counts.total),
      omitted: calculatePercentage(counts.omitted, counts.total),
    }));
  };

  const findIdByName = (id, mcqsubjects) => {
    for (const subject of mcqsubjects) {
      for (const subtopic of subject.subtopics) {
        if (subtopic.id === id) {
          return subtopic.name;
        }
      }
    }
    return null; // Return null if no match is found
  };

  const transformData = (groupedData) => {
    return Object.entries(groupedData).map(([topic, counts]) => ({
      name: findIdByName(topic, mcqsubjects),
      id: topic,
      correct: calculatePercentage(counts.correct, counts.total),
      incorrect: calculatePercentage(counts.incorrect, counts.total),
      omitted: calculatePercentage(counts.omitted, counts.total),
    }));
  };

  const getTopQuestions = (questions) => {
    const frequencyMap = questions.reduce((acc, question) => {
      if (!acc[question.question_id]) {
        acc[question.question_id] = { count: 0, topic: question.topic[0] };
      }
      acc[question.question_id].count += 1;
      return acc;
    }, {});

    return Object.entries(frequencyMap)
      .sort((a, b) => b[1].count - a[1].count) // Sort by frequency
      .slice(0, 10) // Get top 10
      .map(([id, { topic }]) => ({
        id: id,
        name: topic
      }));
  }

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const handleRowClick = (subjectName, section) => {
    setsection(section);
    settopic(subjectName);
    setIsModalOpen2(true);
    // You can perform any actions here, such as navigation or displaying details
  };

  const [scores, setScores] = useState([
    { name: "AP Calc AB 1", myScore: 0, avgScore: 5, id: "AP_Calculus_AB 1" },
    { name: "AP Calc AB 2", myScore: 0, avgScore: 5, id: "AP_Calculus_AB 2" },
    { name: "AP Calc AB 3", myScore: 0, avgScore: 5, id: "AP_Calculus_AB 3" },
    { name: "AP Calc AB 4", myScore: 0, avgScore: 5, id: "AP_Calculus_AB 4" },
    { name: "AP Calc AB 5", myScore: 0, avgScore: 5, id: "AP_Calculus_AB 5" },
    { name: "AP Calc AB 6", myScore: 0, avgScore: 5, id: "AP_Calculus_AB 6" },
  ]);

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    cutout: '80%',
  };

  return (<>
    <ModalComponent topic={topic} isOpen={isModalOpen2} onClose={setIsModalOpen2} section={section} type={'SAT'} />
    <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
      <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">AP Calculus BC Performance</span>
    </div>

    <div className="mt-[20px] text-gray-800 text-lg font-semibold">Dashboard</div>
    <hr />
    <div className="flex space-x-4 pt-4 pb-4">
      <div className="relative flex-3 bg-white p-4 rounded" style={{ flex: '0 0 36%' }}>
        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
          <div className="h-full w-full bg-white rounded-xl"></div>
        </div>
        <div className="relative">
          <h2 className="text-lg font-semibold mb-2">👑 Top 10 MCQ Missing Problems</h2>
          <div style={{ height: '180px', overflowY: 'auto' }}> {/* Enable vertical scrolling */}
            <table className="min-w-full" style={{ borderCollapse: 'collapse', border: 'none' }}>
              <thead className="border-b">
                <tr style={{ border: 'none' }}>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Topic</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Action</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                {eproblems.map((problem) => (
                  <tr key={problem.id} style={{ border: 'none' }}>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{problem.name}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>
                      <button className="bg-slate-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" onClick={() => getsatquestion(problem.id, 'Eng')}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>

      <div className="relative flex-3 bg-white p-4 rounded" style={{ flex: '0 0 36%' }}>
        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
          <div className="h-full w-full bg-white rounded-xl"></div>
        </div>
        <div className="relative">
          <h2 className="text-lg font-semibold mb-2">👑 Top 10 FRQ Missing Problems</h2>
          <div style={{ height: '180px', overflowY: 'auto' }}> {/* Enable vertical scrolling */}
            <table className="min-w-full" style={{ borderCollapse: 'collapse', border: 'none' }}>
              <thead className="border-b">
                <tr style={{ border: 'none' }}>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Topic</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Action</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                {mproblems.map((problem) => (
                  <tr key={problem.id} style={{ border: 'none' }}>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{problem.name}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>
                      <button className="bg-slate-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" onClick={() => getsatquestion(problem.id, 'Math')}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>

      <div className="relative flex-3 bg-white p-4 rounded" style={{ flex: '0 0 25%' }}>
        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
          <div className="h-full w-full bg-white rounded-xl"></div>
        </div>
        <div className="relative">
          <h2 className="text-lg font-semibold mb-2">🥇 Overall Avg. AP Calc. AB</h2>
          <div style={{ height: '180px', overflowY: 'auto' }}>
            <table className="min-w-full text-sm" style={{ borderCollapse: 'collapse', border: 'none' }}>
              <thead className="border-b">
                <tr>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Test #</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Score</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Avg Score</th>
                </tr>
              </thead>
              <tbody>
                {scores.map((score, index) => (
                  <tr key={index} style={{ border: 'none' }}>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{score.name}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{score.myScore}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{score.avgScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <div className="text-gray-800 text-lg font-semibold">Statistics</div>
    <hr />
    <div className="flex flex-col md:flex-row justify-center items-center gap-1">
      <div className="ml-[15px] bg-white p-4 rounded-lg flex justify-center items-center mb-[10px]">
        <div className="flex w-full">
          {/* Doughnut Chart */}
          <div className="flex">
            <div className="relative w-48 h-48 mr-[150px]"> {/* Ensure this div is relatively positioned */}
              <Doughnut data={doughnutData} options={doughnutOptions} />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <p className="text-sm font-semibold"></p> {/* This will center the text in the Doughnut */}
              </div>
            </div>
          </div>

          {/* Score Table */}
          <div className="w-4/6 flex flex-col justify-center">
            <div className="text-lg font-semibold mb-4">Total MCQ. Solved Score</div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <div>Total Correct:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{co} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Incorrect:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{inco} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Omitted:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{omit} %</div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>

      <div className="ml-[15px] bg-white p-4 rounded-lg flex justify-center items-center mb-[10px]">
        <div className="flex w-full">
          {/* Doughnut Chart */}
          <div className="flex">
            <div className="relative w-48 h-48 mr-[150px]"> {/* Ensure this div is relatively positioned */}
              <Doughnut data={doughnutData2} options={doughnutOptions} />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <p className="text-sm font-semibold"></p> {/* This will center the text in the Doughnut */}
              </div>
            </div>
          </div>

          {/* Score Table */}
          <div className="w-4/6 flex flex-col justify-center">
            <div className="text-lg font-semibold mb-4">Total FRQ. Solved Score</div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <div>Total Correct:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{co2} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Incorrect:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{inco2} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Omitted:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{omit2} %</div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="mt-[20px] text-gray-800 text-lg font-semibold">Reports</div>
    <hr />
    <div className="ml-[5px] mt-[10px] flex items-center space-x-2">
      <label htmlFor="category-select" className="text-sm font-medium">Shows:</label>
      <select
        id="category-select"
        value={selected}
        onChange={handleChange}
        className="bg-transparent border-none border-b border-gray-200 text-gray-700 py-1 focus:ring-0 focus:border-blue-500"
      >
        <option value="English">Multiple Choice</option>
        <option value="Math">Free Response</option>
      </select>
    </div>
    <div className="bg-white w-full">
      <table className="w-full border-none mb-[120px]">
        <thead className="border-b">
          <tr>
            <th className="border-none px-6 py-3 text-left text-gray-500">NAME</th>
            <th className="border-none px-6 py-3 text-left text-gray-500">CORRECT Q</th>
            <th className="border-none px-6 py-3 text-left text-gray-500">INCORRECT Q</th>
            <th className="border-none px-6 py-3 text-left text-gray-500">OMITTED Q</th>
          </tr>
        </thead>
        <tbody>
          {selected === 'English' ? report_eng.map((item) => (
            <tr key={item.name} className="border-none cursor-pointer hover:bg-gray-200" onClick={() => handleRowClick(item.name, "English")}>
              <td className="border-none px-6 py-4">
                {item.name}
                <div className="relative w-full bg-gray-200 rounded-full mt-2">
                  <div className="absolute bg-blue-600 h-2 rounded-full" style={{ width: `${item.correct}`, left: 0 }}></div>
                  <div className="absolute bg-red-600 h-2 rounded-full" style={{ width: `${item.incorrect}`, left: `${item.correct}` }}></div>
                  <div className="absolute bg-gray-400 h-2 rounded-full" style={{ width: `${item.omitted}`, left: `calc(${item.correct} + ${item.incorrect})` }}></div>
                </div>
              </td>
              <td className="border-none px-6 py-4">{item.correct}</td>
              <td className="border-none px-6 py-4">{item.incorrect}</td>
              <td className="border-none px-6 py-4">{item.omitted}</td>
            </tr>
          )) : report_math.map((item) => (
            <tr key={item.name} className="border-none cursor-pointer hover:bg-gray-200" onClick={() => handleRowClick(item.name, "Math")}>
              <td className="border-none px-6 py-4">
                {item.name}
                <div className="relative w-full bg-gray-200 rounded-full mt-2">
                  <div className="absolute bg-blue-600 h-2 rounded-full" style={{ width: `${item.correct}`, left: 0 }}></div>
                  <div className="absolute bg-red-600 h-2 rounded-full" style={{ width: `${item.incorrect}`, left: `${item.correct}` }}></div>
                  <div className="absolute bg-gray-400 h-2 rounded-full" style={{ width: `${item.omitted}`, left: `calc(${item.correct} + ${item.incorrect})` }}></div>
                </div>
              </td>
              <td className="border-none px-6 py-4">{item.correct}</td>
              <td className="border-none px-6 py-4">{item.incorrect}</td>
              <td className="border-none px-6 py-4">{item.omitted}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <div className={`fixed inset-0 flex items-center justify-center z-50 ${isModalOpen ? '' : 'hidden'}`}>
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-85"></div>
      </div>
      {tag === 'Eng' && (
        <div className="relative bg-white w-[100%] h-[95%] overflow-auto">
          <button className="absolute top-0 right-0 p-2 text-white" onClick={() => setIsModalOpen(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="bg-slate-700 text-white text-[32px] font-bold pt-[5px] pl-[20px] pt-[20px]">
            Reading and Writing
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 w-full mt-[20px] border-b-[1px] md:border-b-0 md:border-r-[1px] border-gray-300">
              <div>
                <div dangerouslySetInnerHTML={{ __html: selectedTopic.stimulus }} className="max-h-[500px] overflow-y-auto overflow-x-hidden ml-[20px] mt-[10px]" />
              </div>

              <label className="ml-[20px] mt-[20px] md:mt-[100px] flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={hideExplanation}
                  onChange={handleChange2}
                  className="form-checkbox h-5 w-5 text-gray-600"
                />
                <span>Hide correct answer and explanation</span>
              </label>
            </div>
            <div className="md:w-1/2 w-full md:ml-[20px] md:mr-[20px]">
              <div className="mt-6 flex flex-row justify-between bg-gray-100">
                <div className="flex flex-row gap-2">
                  <div className="ml-[10px] p-2 flex flex-row items-center gap-1">
                    <div>
                      <p>Question Review</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                <div dangerouslySetInnerHTML={{ __html: selectedTopic.stem }} className="ml-[20px]" />
                <>
                  <Option idx={0} data={selectedTopic.a} />
                  <Option idx={1} data={selectedTopic.b} />
                  <Option idx={2} data={selectedTopic.c} />
                  <Option idx={3} data={selectedTopic.d} />
                </>
              </div>

              {!hideExplanation && (
                <div className="mt-[20px]">
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.rationale2 ? selectedTopic.rationale2 : selectedTopic.rationale }} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {tag === 'Math' && (
        <div className="relative bg-white w-[100%] h-[95%] overflow-auto">
          <button className="absolute top-0 right-0 p-2" onClick={() => setIsModalOpen(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="bg-slate-700 text-white text-[32px] font-bold pt-[5px] pl-[20px] pt-[20px]">
            Math
          </div>
          <div className="flex" ref={boxItemLeftRef}>
            <div className="w-1/2 mt-[20px] border-r-[1px] border-gray-300">
              <div>
                <div dangerouslySetInnerHTML={{ __html: selectedTopic.stem }} className="ml-[20px] max-h-[500px] overflow-y-auto overflow-x-hidden ml-[20px] mt-[10px]" />
              </div>

              <label className="ml-[20px] mt-[100px] flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={hideExplanation}
                  onChange={handleChange2}
                  className="form-checkbox h-5 w-5 text-gray-600"
                />
                <span>Hide correct answer and explanation</span>
              </label>
            </div>
            <div className="w-1/2 ml-[20px] mr-[20px]">
              <div className="mt-6 flex flex-row justify-between bg-gray-100">
                <div className="flex flex-row gap-2">
                  <div className="ml-[20px] p-2 flex flex-row items-center gap-1">
                    <div>
                      <p>Question Review</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                {selectedTopic.a != null ? (
                  <>
                    <Option idx={0} data={selectedTopic.a} />
                    <Option idx={1} data={selectedTopic.b} />
                    <Option idx={2} data={selectedTopic.c} />
                    <Option idx={3} data={selectedTopic.d} />
                  </>
                ) : (
                  <input className="w-[90%] border-[1px] py-1 px-2 border-gray-300 outline-none rounded-md" type="text" />
                )}
              </div>

              {!hideExplanation && (
                <div className="mt-[20px]">
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.rationale2 ? selectedTopic.rationale2 : selectedTopic.rationale }} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  </>);
};

export default OverallBCAnal;

const Option = ({ idx, data }) => {
  return (
    <div className="flex flex-row gap-1">
      <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full border-[1px] rounded-md border-black gap-2 p-1 m-3 cursor-pointer relative`} >
        <div className="flex flex-row items-center">
          <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
            {getOptionLetter(idx)}
          </p>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </motion.div>
    </div>
  );
};

function getOptionLetter(idx) {
  switch (idx) {
    case 0:
      return 'A';
    case 1:
      return 'B';
    case 2:
      return 'C';
    case 3:
      return 'D';
    default:
      return "A";
  }
}