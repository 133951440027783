import React, { useState, useContext } from 'react';
import axios from 'axios';
import { BASELINE, MMTLOGIN } from "../util/index";
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { motion } from "framer-motion";
import EyeTrackingHeader from "./eyetracker";

const SignInForm = ({ onLogin }) => {
    const { savelogin, user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [smile, setIsSmiling] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        axios.post(BASELINE + 'user/login', formData).then(function (response) {
            if (response.data.u_id !== '') {
                savelogin(response.data);
                setFormData({
                    email: '',
                    password: '',
                });
                onLogin();
                if (location.state?.from?.pathname) {
                    navigate(location.state.from.pathname);
                } else {
                    navigate("/");
                }
            } else {
                window.alert("Please double check the login information.");
            }
        })
            .catch(function (error) {
                window.alert("Please double check the login information.");
            });
    };

    const mmt_login = () => {
        axios.post(MMTLOGIN + 'mmtlogin', formData).then(function (response) {
            if (response.data.hasOwnProperty('uid') && response.data.uid !== '' && response.data.status !== "Registered") {
                savelogin(response.data);
                setFormData({
                    email: '',
                    password: '',
                });
                onLogin();
                navigate("/");
            } else {
                window.alert("Please double check the login information. or Contact to the administer to activate the account.");
            }
        })
            .catch(function (error) {
                window.alert("Please double check the login information. or Contact to the administer to activate the account.");
            });
    };

    return (
        <div className="pt-[120px] sm:pl-[38%] sm:pr-[38%] items-center h-screen px-4 sm:px-0">
            <div className="rounded text-[#232323]">
                <EyeTrackingHeader isSmiling={smile} />
                <p className='mb-4'>Please sign-in to your account and start the adventure</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2" htmlFor="email">
                            EMAIL
                        </label>
                        <input
                            className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 bg-white text-[#232323]"
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="">
                        <label className="block text-sm font-bold mb-2" htmlFor="password">
                            PASSWORD
                        </label>
                        <div className="relative">
                            <input
                                className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 bg-white text-[#232323]"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="text-gray-700 absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 w-8"
                            >
                                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                            </button>
                        </div>
                    </div>
                    <Link to="/signup" className="mb-4 text-gray-400 underline">Sign Up</Link>
                    <p className='hidden mb-4 text-gray-400 underline'>Click here to reset the Password!</p>
                    <div className="mt-2 flex flex-row space-x-2">
                        {/* 첫 번째 버튼 */}
                        <motion.div
                            className="flex-1 relative overflow-hidden rounded-xl cursor-pointer bg-slate-500"
                            whileHover={{
                                scale: 1.05,
                                background: "linear-gradient(270deg, #6a0dad, #1e90ff, #6a0dad)",
                                backgroundSize: "200% 200%",
                                backgroundPosition: "50% 50%",
                            }}
                            transition={{
                                type: "spring",
                                stiffness: 200,
                                damping: 10,
                            }}
                        >
                            <button
                                className="w-full h-full bg-transparent text-white border border-white font-bold py-2 px-4 rounded-xl z-10 relative"
                                type="submit"
                                onMouseEnter={() => setIsSmiling(true)} // Hover 시 웃는 눈 활성화
                                onMouseLeave={() => setIsSmiling(false)} // Hover 해제 시 기본 눈으로 복귀
                            >
                                Sign In
                            </button>
                        </motion.div>

                        {/* 두 번째 버튼 */}
                        <motion.div
                            className="flex-1 relative overflow-hidden rounded-xl cursor-pointer bg-slate-700"
                            whileHover={{
                                scale: 1.05,
                                background: "linear-gradient(270deg, #6a0dad, #1e90ff, #6a0dad)",
                                backgroundSize: "200% 200%",
                                backgroundPosition: "50% 50%",
                            }}
                            transition={{
                                type: "spring",
                                stiffness: 200,
                                damping: 10,
                            }}
                        >
                            <button
                                className="w-full h-full bg-transparent text-white border border-white font-bold py-2 px-4 rounded-xl z-10 relative"
                                type="button"
                                onClick={mmt_login}
                                onMouseEnter={() => setIsSmiling(true)} // Hover 시 웃는 눈 활성화
                                onMouseLeave={() => setIsSmiling(false)} // Hover 해제 시 기본 눈으로 복귀
                            >
                                Sign In with MMTPrep
                            </button>
                        </motion.div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignInForm;
