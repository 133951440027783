/*
export const CLIENT_URL = "http://localhost:8080/files";
export const BASELINE = "http://localhost:8080/";
export const AFTER_LOGIN = "http://localhost:3000";

/*
export const CLIENT_URL = "http://192.168.0.25:8080/files";
export const BASELINE = "http://192.168.0.25:8080/";
export const AFTER_LOGIN = "http://192.168.0.25:3000";
 */

export const CLIENT_URL = "https://pvault.mmtprep.com:8080/files";
export const BASELINE = "https://pvault.mmtprep.com:8080/";
export const AFTER_LOGIN = "https://pvault.mmtprep.com"; 


/*
export const CLIENT_URL = " http://192.168.0.205:8080/files";
export const BASELINE = " http://192.168.0.205:8080/";
export const AFTER_LOGIN = " http://192.168.0.205:3000";
*/

export const MMTLOGIN = "https://mmtprep.com:8081/";
