// useAIAudio.js
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASELINE } from '../../util';

const useAIAudio = (context, voiceOptions = {}, indexs) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const previousParamsRef = useRef(null);

  function escapeSSML(text) {
    return text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&apos;');
  }

  useEffect(() => {
    // Only proceed if indexs === 0
    if (indexs !== 0) {
      return;
    }

    const sanitizedContext = escapeSSML(context);

    const fetchAudio = async () => {
      try {
        const ssml = `
          <speak version='1.0' xml:lang='en-US' xmlns:mstts="http://www.w3.org/2001/mstts">
            <voice xml:lang='en-US' name='${
              voiceOptions.voiceName || 'en-US-JennyMultilingualNeural'
            }'>
              <mstts:express-as style='cheerful'>
                <prosody rate='${voiceOptions.rate || '0%'}' pitch='${
          voiceOptions.pitch || '0%'
        }' volume='${voiceOptions.volume || '100%'}'>
                  ${sanitizedContext}
                </prosody>
              </mstts:express-as>
            </voice>
          </speak>
        `;

        const response = await axios.post(
          `${BASELINE}api/tts`,
          { ssml },
          { responseType: 'arraybuffer' }
        );

        const blob = new Blob([response.data], { type: 'audio/mp3' });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        previousParamsRef.current = { context, voiceOptions: { ...voiceOptions } };
      } catch (error) {
        console.error('Error generating audio:', error);
        setAudioUrl(null);
      }
    };

    const currentParams = JSON.stringify({ context, voiceOptions });
    const prevParams = previousParamsRef.current
      ? JSON.stringify(previousParamsRef.current)
      : null;

    if (context && currentParams !== prevParams) {
      fetchAudio();
    }
  }, [context, voiceOptions, indexs]);

  return audioUrl;
};

export default useAIAudio;
