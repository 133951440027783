import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { useStopwatch } from "react-timer-hook";
import { BsChevronDown, BsChevronUp, BsExclamationTriangle } from "react-icons/bs";
import { CiCalculator1 } from "react-icons/ci";
import { VscReferences } from "react-icons/vsc";
import { BsThreeDotsVertical } from "react-icons/bs";
import Question from "./MathQuestion";
import axios from "axios";
import Timer from "./Timer";
import { motion } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import { BASELINE } from "../../util";
import Review from "./Review";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DirectionModal from './DirectionModal';
import ReferenceSheet from './ReferenceSheet';
import { AiOutlineSave } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';
import Modal from "react-modal"; // Example of using react-modal for the modal
import { FaHandBackFist } from "react-icons/fa6";
import { FaChalkboard } from "react-icons/fa";
import FabricJSCanvas from "./whiteboard";
import { AiOutlineClose } from "react-icons/ai"; // For the close button
import { RiPlayList2Fill } from "react-icons/ri";
import { GrView } from "react-icons/gr";
import { Tooltip } from '@material-ui/core';
import { RiLiveFill } from "react-icons/ri";
import { FaKey } from "react-icons/fa6";
import { VscDebugContinue } from "react-icons/vsc";
import { FaRegSave } from "react-icons/fa";
import html2canvas from "html2canvas"; // Import html2canvas

const SATQuiz = () => {
  const navigate = useNavigate();
  const boxItemLeftRef = useRef();
  const { user } = useContext(UserContext);
  const [showCalculator, setShowCalculator] = useState(false);
  const [questionOverViewOpen, setQuestionOverviewOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [showDirectionModal, setShowDirectionModal] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [showDirectionModalRef, setShowDirectionRefModal] = useState(false);
  const [test_id, settest_id] = useState(uuidv4());
  const [disablesave, setdisablesave] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [showNotes, setshowNotes] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [context, setContext] = useState('');

  const location = useLocation();
  const responseData = location.state?.data;
  const [timeLeft, setTimeLeft] = useState(null); // Countdown timer
  const [timerActive, setTimerActive] = useState(false); // Track if countdown timer is active
  const { start, reset } = useStopwatch({ autoStart: false }); // Use stopwatch for untimed mode

  const displayMinutes = Math.floor(timeLeft / 60);
  const displaySeconds = timeLeft % 60;

  const [questionsarr, setquestionsarr] = useState([]);
  const [result, setResult] = useState([]);
  const [questionTimers, setQuestionTimers] = useState([]);

  const [askedConfirmation, setAskedConfirmation] = useState([]);

  const [username, setUsername] = useState(""); // State for username input
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [c, setc] = useState(false);
  const [showanswer, setshowanswer] = useState(false);
  const [autotimer, setautotimer] = useState(false);
  const [autotutor, setautotutor] = useState(false);
  const [qarr, setqarr] = useState([]);
  const [auto, setauto] = useState(false);
  const [autotype, setsutotpye] = useState("");
  const [publicnotes, setpublicnotes] = useState([]);
  const [publicnotesuserid, setpublicnotesuserid] = useState("");
  const [privatenotes, setprivatenotes] = useState([]);
  const [rewritenotes, setrewritenotes] = useState([]);
  const [savednotes, setsavednotes] = useState([]);

  const [showWhiteboard, setShowWhiteboard] = useState(false); // State to control whiteboard visibility
  const quizContentRef = useRef(); // Reference to the quiz content for the screenshot
  const [screenshotData, setScreenshotData] = useState(null);

  const toggleWhiteboard = async (userid) => {
    setpublicnotesuserid(userid);
    setShowWhiteboard(!showWhiteboard);
    if (userid) {
      try {
        const response = await axios.post(BASELINE + 'whiteboard/view/add/by/id', { id: userid });
      } catch (error) {
        console.error('Error sending initial data:', error);
      }
    }

    // Capture the screenshot
    if (!showWhiteboard) {
      if (quizContentRef.current) {
        html2canvas(quizContentRef.current).then((canvas) => {
          const dataURL = canvas.toDataURL();
          // Pass the dataURL to the FabricJSCanvas component
          setScreenshotData(dataURL);
        });
      }
    }
  };


  useEffect(() => {
    let timerInterval;
    // Determine whether to count down or count up based on `timedMode`
    if (timerActive && timeLeft !== null) {
      if (responseData?.timedModeActive || autotimer === true) {
        // Countdown Timer Mode
        timerInterval = setInterval(() => {
          setTimeLeft((prevTimeLeft) => {
            if (prevTimeLeft <= 1) {
              clearInterval(timerInterval); // Stop timer when it reaches 0
              toast.error("Time's Up!"); // Notify that the time is up
              handleSubmit(); // Call submit function when time is up
              return 0;
            }
            return prevTimeLeft - 1; // Decrease the timer by 1 second
          });
        }, 1000);
      } else {
        // Stopwatch Mode
        timerInterval = setInterval(() => {
          setTimeLeft((prevTimeLeft) => prevTimeLeft + 1); // Increase the timer by 1 second
        }, 1000);
      }
    }

    return () => clearInterval(timerInterval);
  }, [timerActive, timeLeft, responseData?.timedModeActive]);

  const autosaveData = async () => {
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');

    const qanswers = questions?.map(question => question.selectedOption);
    const qrealanswers = questions?.map(question => question.correct_answer);
    const qskillsets = questions?.map(question => question.primary_class_cd_descs);
    const qbookmarks = questions?.map(question => question.bookMarked);
    const qids = questions?.map(question => question.questionIds);

    let quiz = responseData?.type || 'SAT';

    // Determine the time value based on the mode
    let totalTimeSpent;
    if (responseData?.timedModeActive) {
      // Save the remaining time in countdown mode
      totalTimeSpent = timeLeft;
    } else {
      totalTimeSpent = timeLeft;
    }

    const data = {
      id: quiz,
      user_id: user.u_id,
      questions: qids,
      questionIds: currentQuestion?.questionIds,
      timeLeft: totalTimeSpent, // Save either time left or elapsed time correctly
      qanswers: qanswers,
      qbookmarks: qbookmarks,
      test_id: test_id,
      date: formattedDate,
      type: 'QUIZ',
      name: `${quiz} Quiz`,
      tutormode: responseData?.tutorModeActive || autotutor,
      timedMode: responseData?.timedModeActive || autotimer,
      tutorasked: askedConfirmation,
      result: result,
      qrealanswers: qrealanswers,
      qskillsets: qskillsets
    };

    try {
      await axios.post(BASELINE + "autosave", { params: { data: JSON.stringify(data) } });
    } catch (err) {
      console.error("Error during autosave:", err.message);
    }
  };

  useEffect(() => {
    // Function to fetch initial data based on the current question
    const fetchInitialData = async () => {
      try {
        const response = await axios.post(
          BASELINE + 'whiteboard/search/by/qid',
          { qid: currentQuestion?.questionIds }
        );

        const newNotes = response.data.data.filter(
          (note) => note.shared === "true" && note.stat === "complete" && note.question_id === currentQuestion.questionIds
        );

        const newNotes2 = response.data.data.filter(
          (note) => note.test_id === test_id && note.stat === "writing" && note.question_id === currentQuestion.questionIds && note.user_id !== user.u_id
        );

        const newNotes3 = response.data.data.filter(
          (note) => note.test_id === test_id && note.stat === "writing" && note.question_id === currentQuestion.questionIds && note.user_id === user.u_id
        );

        const newNotes5 = response.data.data.filter(
          (note) => note.shared === "false" && note.stat === "complete" && note.question_id === currentQuestion.questionIds && note.user_id === user.u_id
        );



        setsavednotes(newNotes5);
        setrewritenotes(newNotes3);
        setprivatenotes(newNotes2);
        setpublicnotes(newNotes);  // Directly set to newNotes, replacing old notes
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    // Conditionally call fetchInitialData based on certain conditions
    if (responseData?.help !== true && user && user?.role !== "teacher") {
      if (disablesave !== false) {
        autosaveData();  // Your existing condition to autosave data
      }
    }

    fetchInitialData();  // Fetch new data every time dependencies change

  }, [currentQuestion, user, questions, test_id, timeLeft]);  // Dependencies array


  useEffect(() => {
    const script2 = document.createElement('script');
    script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
    script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
    script2.crossOrigin = 'anonymous';

    script2.onload = () => {
      if (boxItemLeftRef.current) {
        const options = {
          delimiters: [
            { left: '$$', right: '$$', display: false },
            { left: "$", right: "$", display: false },
            { left: '\\(', right: '\\)', display: false },
            { left: '\\[', right: '\\]', display: true },
            { left: '\\begin{array}', right: '\\end{array}', display: true }
          ],
          throwOnError: false,
        };
        window.renderMathInElement(boxItemLeftRef.current, options);
      }
    };
    document.body.appendChild(script2);
    return () => {
      document.body.removeChild(script2);
    };
  }, [showanswer]);

  useEffect(() => {
    setLoadingQuestions(false);
  }, [c]);

  const handleModalSubmit = () => {
    if (username.length !== 0) {
      setc(true);
      setIsModalOpen(false); // Close the modal after submission
    } else {
      alert("Please enter the name");
    }
  };

  useEffect(() => {
    let timerId;
    if (currentQuestion && result[currentQuestion.index] === undefined) {
      timerId = setInterval(() => {
        setQuestionTimers(prevTimers => {
          const newTimers = [...prevTimers];
          if (newTimers[currentQuestion.index] === undefined) {
            newTimers[currentQuestion.index] = 0;
          }
          newTimers[currentQuestion.index] += 1;
          return newTimers;
        });
      }, 1000); // Update every second
    }

    return () => clearInterval(timerId); // Clear interval when question changes or component unmounts
  }, [currentQuestion, result]);

  useEffect(() => {
    const currentTest = localStorage.getItem('currentTest');

    const runLocalStorageFlow = async () => {
      if (currentTest) {
        await handleLocalStorageData(currentTest); // Wait for handleLocalStorageData to finish
      } else {
        if (responseData) {
          if (responseData.test_id) {
            settest_id(responseData.test_id);
          } else {
            settest_id(uuidv4());
          }

          // Check if rank is true and show modal
          if (responseData.rank) {
            setLoadingQuestions(true);
            setIsModalOpen(true); // Show the modal when rank is true
          }

          if (responseData.timedModeActive) {
            // Setup countdown timer
            startCountdownTimer(responseData.timeLimit * 60);
          } else {
            // If not timed mode, start stopwatch
            startStopwatch(0);
          }

          setquestionsarr(responseData.questionIds);
        } else {
          getQuestions();
          setdisablesave(false);
        }
      }
    };

    runLocalStorageFlow(); // Run the local storage flow on component mount

    // Clean up interval on component unmount to avoid memory leaks
    return () => {
      stopTimer(); // Function to stop and clear any active timers
    };
  }, [responseData]);

  useEffect(() => {
    const currentTest = localStorage.getItem('currentTest');

    if (currentTest) {
      handleLocalStorageData(currentTest).then(() => {
        if (responseData && responseData.timedModeActive) {
          startCountdownTimer(responseData.timeLimit * 60);
        } else if (responseData) {
          startStopwatch(0);
        }
      });
    } else {
      if (responseData) {
        if (responseData.timedModeActive) {
          startCountdownTimer(responseData.timeLimit * 60);
        } else {
          startStopwatch(0);
        }
        setquestionsarr(responseData.questionIds);
      } else {
        getQuestions();
      }
    }

    // Clean up interval on component unmount
    return () => {
      stopTimer(); // Function to stop and clear any active timers
    };
  }, []);


  async function handleLocalStorageData(currentTest) {
    settest_id(currentTest);
    setauto(true);
    try {
      const response = await axios.get(BASELINE + 'getautosavebytid', { params: { tid: currentTest } });
      const savedData = response.data.data[0];
      if (savedData) {
        let res;
        let jsonsetting = JSON.parse(savedData.Setting);
        let jsondata = JSON.parse(savedData.quiz_data);
        let qrr = jsondata.questions;
        setqarr(qrr);
        setautotutor(jsonsetting.tutormode);
        setAskedConfirmation(jsonsetting.tutorasked);
        setResult(jsonsetting.result);

        // Fetch questions based on the type of test
        if (savedData.type === 'SAT89') {
          setsutotpye('SAT89');
          res = await axios.get(BASELINE + "questions/sat89", { params: { id: qrr } });
        } else if (savedData.type === 'SAT10') {
          setsutotpye('SAT10');
          res = await axios.get(BASELINE + "questions/sat10", { params: { id: qrr } });
        } else {
          setsutotpye('SAT');
          res = await axios.get(BASELINE + "questions/sat", { params: { id: qrr } });
        }

        // Map the questions and prepare the state for displaying them
        let mappedQuestions = res.data.map((question, idx) => ({
          ...question,
          index: idx,
          answered: false,
          selectedOption: jsondata.qanswers[idx] || null,
          bookMarked: jsondata.qbookmarks[idx] || false,
          crossedOutOptions: [],
        }));
        setQuestions(mappedQuestions);

        // Restore the timer correctly based on the mode
        if (jsonsetting.timedMode === true) {
          setautotimer(true);
          startCountdownTimer(jsonsetting.timeLeft); // Start countdown timer
        } else {
          startStopwatch(jsonsetting.timeLeft); // Start stopwatch from the restored time
        }

        const questionIndex = jsondata.questions.findIndex(question => question === savedData.questionIds);
        if (questionIndex >= 0) {
          setCurrentQuestion(mappedQuestions[questionIndex]);
        }

        // Remove localStorage item after a delay
        setTimeout(() => {
          setLoadingQuestions(false);
          localStorage.removeItem('currentTest');
        }, 2000);
      }
      setdisablesave(false);
    } catch (error) {
      console.error("Error fetching autosave data:", error);
    }
  }

  const startCountdownTimer = (startingTime) => {
    if (startingTime > 0) {
      startTimer(startingTime); // Start countdown timer from the given starting time
    }
  };

  const startStopwatch = (startingTime) => {
    startTimer(startingTime); // Start stopwatch from the given starting time
  };

  const startTimer = (initialTime) => {
    setTimeLeft(initialTime);
    setTimerActive(true);
  };

  const stopTimer = () => {
    setTimerActive(false);
    setTimeLeft(0);
    reset(); // Reset the stopwatch if needed
  };

  useEffect(() => {
    if (responseData?.timedModeActive) {
      startCountdownTimer(responseData.timeLimit * 60); // Start timer from saved time (in seconds)
    } else {
      start(); // Start stopwatch if untimed mode
    }
  }, [responseData, start]);

  async function handleSubmit() {
    try {
      let obtainedMarks = 0;
      let newDat = questions.map((question) => {
        var correctAnswer;

        if (question.correct_answer) {
          // Parse the string into a JSON array
          correctAnswer = JSON.parse(question.correct_answer);

          // If there's only one element, convert it to lowercase
          if (correctAnswer.length === 1) {
            correctAnswer = [correctAnswer[0].toLowerCase()];
          }
        } else {
          correctAnswer = ["No Answer"];
        }

        let isCorrect = false;
        if (Array.isArray(correctAnswer)) {
          for (let i = 0; i < correctAnswer.length; i++) {
            if (String(question.selectedOption).trim() === String(correctAnswer[i]).trim()) {
              isCorrect = true;
              break;
            }
          }
        } else {
          isCorrect = question.selectedOption === correctAnswer;
        }

        if (isCorrect) {
          obtainedMarks++;
          question.correct = true;
        } else {
          question.correct = false;
        }

        const bookMarked = question.bookMarked;
        const difficultys = question.difficultys;
        var type = responseData?.type || 'SAT'
        return {
          difficultys: difficultys,
          bookMarked: bookMarked,
          correct: question.correct,
          selectedOption: question.selectedOption,
          answered: question.answered,
          questionIds: question.questionIds,
          tag: question.tag,
          topic: question.skill_descs,
          correctAnswer: question.correct_answer,
          type: type
        }
      });

      var test_name;
      const testNames = {
        "SAT": "SAT Practice Quiz",
        "SAT89": "SAT89 Practice Quiz",
        "SAT10": "NMSQT Practice Quiz"
      };
      var ori_id = localStorage.getItem('currentTest');

      if (location && location.state) {
        const testType = responseData?.type || 'SAT';
        test_name = testNames[testType];
      } else {
        test_name = localStorage.testname;
      }

      if (!test_name) {
        test_name = "SAT Practice Test";  // Set custom name if test_name is empty
      }

      const correctQuestions = newDat.filter(question => question.correct === true);
      const incorrectQuestions = newDat.filter(question => question.correct === false);
      const bookmarkedQuestions = newDat.filter(question => question.bookMarked === true); // Assuming 'bookMarked' exists
      const nullCorrectAnswerQuestions = newDat.filter(question => question.selectedOption === null);

      let data
      if (!user) {
        if (c) {
          const formattedTime = `${String(displayMinutes).padStart(2, '0')}:${String(displaySeconds).padStart(2, '0')}`;
          data = {
            Quiz_score: newDat,
            timeframe: formattedTime,
            userId: "Free user",
            username: username,
            test_name: "Free User Test",
            ori_id: "Free User Test",
            type: 'SAT',
            test_id: test_id,
            correctQuestions: correctQuestions,
            incorrectQuestions: incorrectQuestions,
            bookmarkedQuestions: bookmarkedQuestions,
            nullCorrectAnswerQuestions: nullCorrectAnswerQuestions,
            rank: true,
          }
        } else {
          data = {
            Quiz_score: newDat,
            timeframe: "",
            userId: "Free user",
            username: "Free user",
            test_name: "Free User Test",
            ori_id: "Free User Test",
            type: 'SAT',
            test_id: test_id,
            correctQuestions: correctQuestions,
            incorrectQuestions: incorrectQuestions,
            bookmarkedQuestions: bookmarkedQuestions,
            nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
          }
        }
      } else {
        if (autotype.length === 0) {
          data = {
            Quiz_score: newDat,//all qu
            timeframe: timeLeft,
            userId: user.u_id,
            username: user.first_name + " " + user.last_name,
            test_name: test_name,
            ori_id: ori_id, //can be null
            type: responseData?.type || 'SAT',
            test_id: test_id,
            correctQuestions: correctQuestions,
            incorrectQuestions: incorrectQuestions,
            bookmarkedQuestions: bookmarkedQuestions,
            nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
          }
        } else {
          data = {
            Quiz_score: newDat,//all qu
            timeframe: timeLeft,
            userId: user.u_id,
            username: user.first_name + " " + user.last_name,
            test_name: autotype + " Practice Test Report Card",
            ori_id: ori_id, //can be null
            type: autotype,
            test_id: test_id,
            correctQuestions: correctQuestions,
            incorrectQuestions: incorrectQuestions,
            bookmarkedQuestions: bookmarkedQuestions,
            nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
          }
        }
      }

      localStorage.removeItem('currentTest');
      localStorage.removeItem('testname');
      localStorage.removeItem('type');
      let saveResult = await axios.post(BASELINE + "tests/quiz", data);

      let saveResult2 = await axios.post(BASELINE + "removeautosavequiz", { data: { test_id: test_id } });

      navigate(`/results/sat/quiz/${test_id}`);

    } catch (err) {
      toast.error(err.message);
    }


  }

  useEffect(() => {
    var elt = document.getElementById('calculator');
    var calculator = window.Desmos?.GraphingCalculator(elt, {
      graphpaper: true,
      keypad: true
    });
    return () => {
      calculator?.destroy();
    }
  }, [showCalculator])

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.questionIds === itemId) {
          if (question.bookMarked) {
            return { ...question, bookMarked: false };
          } else {
            return { ...question, bookMarked: true };
          }
        } else {
          return question;
        }
      })
    }))
  }

  useEffect(() => {
    if (questions.length > 0 && currentQuestion) {
      let q = questions.find((ques => ques?.questionIds === currentQuestion?.questionIds));
      setCurrentQuestion(q);
    }
    if (setauto === false) {
      setAskedConfirmation(questions.map(() => false));
    }
  }, [questions]);

  async function handleSelectOption(itemId, option) {
    setQuestions(prev => {
      const updatedQuestions = prev.map(question => {
        if (question.questionIds === itemId) {
          return { ...question, selectedOption: option };
        } else {
          return question;
        }
      });

      // Find the updated question and update the currentQuestion state
      const updatedQuestion = updatedQuestions.find(question => question.questionIds === itemId);
      setCurrentQuestion(updatedQuestion);

      return updatedQuestions;
    });

    // Optionally reset the confirmation state if the user selects an option after confirming
    setAskedConfirmation(prev => {
      const newAsked = [...prev];
      newAsked[currentQuestion.index] = false; // Reset if needed
      return newAsked;
    });
  }



  async function handleCrossOutOptions(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.questionIds == itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }

  const handleAnswerValueChange = (itemId, value) => {
    setQuestions(prevQuestions =>
      prevQuestions.map(question =>
        question.questionIds === itemId ? { ...question, selectedOption: value } : question
      )
    );
  };

  async function getQuestions() {
    if (auto === false) {
      try {
        setLoadingQuestions(true);
        let globalIndex = 0;
        let allQuestions = [];
        if (location && location.state && Array.isArray(location.state.modules)) {
          for (let module of location.state.modules) {
            let mappedVals = module.topics;

            var res;
            if (module.mmtmode !== true) {
              if (module.test === "SAT") {
                res = await axios.get(BASELINE + "questions/ppsat", { params: { section: module.selectedSection, subject: module.subject, difficulty: module.difficulty, topics: mappedVals, limit: module.questionLimit } });
              } else if (module.test === "SAT89") {
                res = await axios.get(BASELINE + "questions/ppsat89", { params: { section: module.selectedSection, subject: module.subject, difficulty: module.difficulty, topics: mappedVals, limit: module.questionLimit } });
              }
              else if (module.test === "SAT10") {
                res = await axios.get(BASELINE + "questions/ppsat10", { params: { section: module.selectedSection, subject: module.subject, difficulty: module.difficulty, topics: mappedVals, limit: module.questionLimit } });
              }
            } else {
              res = await axios.get(BASELINE + "questions/mmt", { params: { section: module.selectedSection, subject: module.subject, difficulty: module.difficulty, topics: mappedVals, limit: module.questionLimit } });
            }
            if (res.data.length < 1) {
              toast.error("Questions With these topics not found. Try other topics for now by going back")
            } else {
              start();
            }

            let mappedQuestions = res.data.map((question) => {
              return {
                ...question,
                index: globalIndex++,
                answered: false,
                bookMarked: false,
                crossedOutOptions: [],
                selectedOption: null,
                section: module.selectedSection
              };
            })

            allQuestions = [...allQuestions, ...mappedQuestions];
          }
        } else {
          console.warn('location.state.modules is not iterable');
        }
        setQuestions(allQuestions);
        setCurrentQuestion(allQuestions[0]);
        setAskedConfirmation(allQuestions.map(() => false));

        if (responseData?.rank) {
          if (c) {
            setLoadingQuestions(false);
          }
        } else {
          setLoadingQuestions(false);
        }

      } catch (err) {
        setLoadingQuestions(false);
        toast.error(err.message);
      }
    }
  }

  useEffect(() => {

    setLoadingQuestions(true);
    let globalIndex = 0;
    let allQuestions = [];

    const fetchData = async () => {
      try {
        var res;
        if (responseData.type === 'SAT') {
          res = await axios.get(`${BASELINE}questions/sat`, {
            params: {
              id: questionsarr,
              section: responseData.tag
            }
          });
        }
        else if (responseData.type === 'SAT10' || responseData.type === 'NMSQT') {
          res = await axios.get(`${BASELINE}questions/sat10`, {
            params: {
              id: questionsarr,
              section: responseData.tag
            }
          });
        }
        else if (responseData.type === 'SAT89') {
          res = await axios.get(`${BASELINE}questions/sat89`, {
            params: {
              id: questionsarr,
              section: responseData.tag
            }
          });
        }

        if (res.data.length < 1) {
          toast.error("Questions With these topics not found. Try other topics for now by going back")
        } else {
          start();
        }

        let mappedQuestions = res.data.map((question) => {
          return {
            ...question,
            index: globalIndex++,
            answered: false,
            bookMarked: false,
            crossedOutOptions: [],
            selectedOption: null,
            section: responseData.tag
          };
        })

        allQuestions = [...allQuestions, ...mappedQuestions];

        setQuestions(allQuestions);
        setCurrentQuestion(allQuestions[0]);
        if (responseData?.rank) {
          if (c) {
            setLoadingQuestions(false);
          }
        } else {
          setLoadingQuestions(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        // Handle errors if needed
      }
    };

    if (questionsarr.length > 0) {
      fetchData();
      setResult(new Array(questionsarr.length).fill(undefined));
    }
  }, [questionsarr]);  // Include responseData.tag in the dependency array if it's expected to change

  function checkAnswer(userInput, correctAnswersJSON) {
    // Parse the correct answers JSON and normalize
    const correctAnswers = JSON.parse(correctAnswersJSON).map(answer => {
      // Normalize each answer: handle numbers and fractions
      if (typeof answer === 'string' && answer.includes('/')) {
        return parseFraction(answer);  // Handle fractions
      } else if (!isNaN(answer)) {
        // Handle integers and floats
        return parseFloat(answer);
      }
      return answer.toString().toLowerCase(); // Handle string answers (e.g., 'A', 'B', etc.)
    });

    // Normalize user input for both numbers and strings
    let normalizedInput;
    if (typeof userInput === 'string' && userInput.includes('/')) {
      normalizedInput = parseFraction(userInput);  // Handle fraction input
    } else if (!isNaN(userInput)) {
      normalizedInput = parseFloat(userInput);  // Handle number input
    } else {
      normalizedInput = userInput.toString().toLowerCase();  // Handle string input (e.g., 'A', 'B', etc.)
    }

    // Check if the normalized user input is in the list of correct answers
    return correctAnswers.includes(normalizedInput);
  }

  function parseFraction(fraction) {
    let [numerator, denominator] = fraction.split('/');
    return denominator ? parseFloat(numerator) / parseFloat(denominator) : parseFloat(numerator);
  }


  const handleNext = async () => {
    setshowanswer(false);
    // Treat tutorModeActive as false if it's not defined
    const tutorModeActive = responseData?.tutorModeActive || autotutor;

    if (!tutorModeActive) {
      if (currentQuestion.index < questions.length - 1) {
        setCurrentQuestion((prev) => questions[prev.index + 1]);
      } else {
        setCurrentQuestion(null);
        setQuestionOverviewOpen(false);
      }
    } else {
      const currentIdx = currentQuestion.index;

      // Only ask confirmation if not already asked for the current question
      if (!askedConfirmation[currentIdx]) {
        const userResponse = window.confirm(
          "You have not selected an answer. Are you sure you want to proceed without answering?"
        );
        if (!userResponse) {
          return;
        }
        setAskedConfirmation(prev => {
          const newAsked = [...prev];
          newAsked[currentIdx] = true;
          return newAsked;
        });
      }

      // Check if the selected option is correct
      const isCorrect = checkAnswer(
        currentQuestion.selectedOption,
        currentQuestion.correct_answer
      );
      // Update the result array at the current question's index
      setResult((prevResults) => {
        const newResults = [...prevResults];
        newResults[currentQuestion.index] = isCorrect;
        return newResults;
      });

      // Use the updated result (since setState is async)
      setTimeout(() => {
        if (result[currentIdx] !== undefined) {
          if (currentQuestion.index < questions.length - 1) {
            setCurrentQuestion(questions[currentQuestion.index + 1]);
          } else {
            setCurrentQuestion(null);
            setQuestionOverviewOpen(false);
          }
        }
      }, 0);
    }
  }


  async function handleBack() {
    if (!currentQuestion) {
      setCurrentQuestion(questions[questions.length - 1]);
      return;
    }
    if (currentQuestion.index > 0) {
      setCurrentQuestion((pre) => questions[pre.index - 1])
    } else {

    }
  }

  const closeRefModal = () => {
    setShowDirectionRefModal(false);
  };

  const closeModal = () => {
    setShowDirectionModal(false);
  };

  const handleSave = async () => {
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');

    const qanswers = questions?.map(question => question.selectedOption);
    const qrealanswers = questions?.map(question => question.correct_answer);
    const qskillsets = questions?.map(question => question.primary_class_cd_descs);
    const qbookmarks = questions?.map(question => question.bookMarked);
    const qids = questions?.map(question => question.questionIds);

    let quiz = responseData?.type || 'SAT';

    // Determine the time value based on the mode
    let totalTimeSpent;
    if (responseData?.timedModeActive) {
      // Save the remaining time in countdown mode
      totalTimeSpent = timeLeft;
    } else {
      totalTimeSpent = timeLeft;
    }

    const data = {
      id: quiz,
      user_id: user.u_id,
      questions: qids,
      questionIds: currentQuestion?.questionIds,
      timeLeft: totalTimeSpent, // Save either time left or elapsed time correctly
      qanswers: qanswers,
      qbookmarks: qbookmarks,
      test_id: test_id,
      date: formattedDate,
      type: 'QUIZ',
      name: `${quiz} Quiz`,
      tutormode: responseData?.tutorModeActive || autotutor,
      timedMode: responseData?.timedModeActive || autotimer,
      tutorasked: askedConfirmation,
      result: result,
      qrealanswers: qrealanswers,
      qskillsets: qskillsets
    };

    try {
      let res = await axios.post(BASELINE + "autosave", { params: { data: JSON.stringify(data) } });
      if (res.status === 200) {
        toast.success("Quiz Saved Successfully");
        navigate("/dashboard/teacher");
      } else {
        throw new Error("Unexpected response from the server.");
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err.response.data.message || "Error: Unable to save quiz.");
      } else {
        toast.error(err.message || "An unexpected error occurred.");
      }
    }
  }

  const handleReportClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  const handleSubmit2 = () => {
    const questionId = currentQuestion.questionIds;
    const reportFrom = user.first_name + " " + user.last_name;

    var type;
    if (currentQuestion.programs === 'SAT') {
      type = 'SAT';
    } else if (currentQuestion.programs === 'P10') {
      type = 'SAT10';
    } else {
      type = 'SAT89';
    }

    var data = {
      questionId: questionId,
      name: reportFrom,
      context: context,
      type: type
    }

    axios.post(BASELINE + 'questions/report/note', [data])
      .then(response => {
        alert('Report submitted successfully!');
      })
      .catch(error => {
        console.error(error);
      });

    setContext('');
    setShowModal(false);
  };

  const handleCloseModal = () => {
    // Clear the context and close the modal
    setContext('');
    setShowModal(false);
  };

  async function askforhelp() {
    var data;
    if (currentQuestion.programs === 'SAT') {
      data = {
        questionidsarr: questionsarr,
        student_id: user.u_id,
        type: "SAT",
        tag: currentQuestion.tag,
        test_id: test_id
      };
    } else if (currentQuestion.programs === 'P10') {
      data = {
        questionidsarr: questionsarr,
        student_id: user.u_id,
        type: "NMSQT",
        tag: currentQuestion.tag,
        test_id: test_id
      };
    }
    else if (currentQuestion.programs === 'P89') {
      data = {
        questionidsarr: questionsarr,
        student_id: user.u_id,
        type: "SAT89",
        tag: currentQuestion.tag,
        test_id: test_id
      };
    }
    console.log(data);
    if (window.confirm("Are you sure you want to get help?")) {
      axios.post(BASELINE + 'post/question/set', [data])
        .then(response => {
          // Handle different responses based on status codes
          if (response.status === 201) {
            window.alert("If you have an assigned teacher, then the teacher will contact you.");
          } else if (response.status === 409) {
            window.alert("A help request with this test ID already exists.");
          } else {
            window.alert("Unexpected response from the server: " + response.status);
          }
        })
        .catch(error => {
          // Handle errors based on the status code returned from the server
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            if (status === 409) {
              window.alert("A help request with this test ID already exists.");
            } else if (status === 500) {
              window.alert("Internal server error. Please try again later.");
            } else {
              window.alert("An error occurred: " + error.response.status);
            }
          } else if (error.request) {
            // The request was made but no response was received
            window.alert("No response from the server. Please check your network connection.");
          } else {
            window.alert("Error: " + error.message);
          }
        });
    } else {
      window.alert("Help request cancelled.");
    }
  }

  return (<>
    <div className="flex min-h-screen bg-white">

      {showWhiteboard && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="absolute top-0 right-0 p-4">
            {/* Close button to hide the whiteboard */}
            <button onClick={() => setShowWhiteboard(false)} className="text-white hover:text-gray-300">
              <AiOutlineClose size="2em" />
            </button>
          </div>
          <div className="bg-white shadow-lg rounded-lg relative z-50">
            {/* Pass the screenshot as a prop to the FabricJSCanvas */}
            <FabricJSCanvas shareuserid={publicnotesuserid} currentQuestion={currentQuestion} test_id={test_id} user={user} onClose={() => setShowWhiteboard(false)} screenshotData={screenshotData} />
          </div>
        </div>
      )}
      {showDirectionModalRef && <ReferenceSheet onClose={closeRefModal} />}

      <motion.div drag style={{
        width: '50%',
        height: '400px',
        position: 'absolute',
        zIndex: 100,
        display: showCalculator ? "inline" : "none",
      }}
        dragConstraints={{
          top: -125,
          right: 500,
          bottom: 300,
          left: -125,
        }}
      >
        <div id="calculator" className="w-full h-full">

        </div>

      </motion.div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Enter Your Name"
        shouldCloseOnOverlayClick={false}
        className="z-[11] absolute bg-white p-6 rounded-lg shadow-md max-w-lg mx-auto mt-12 relative"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
      >
        <h2 className="text-lg font-bold mb-4">Enter Your Name</h2>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border p-2 rounded w-full"
          placeholder="Your name"
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={handleModalSubmit}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Submit
          </button>
        </div>
      </Modal>
      <div className="flex-grow" style={{ flexBasis: showanswer ? '80%' : '100%' }}>

        {loadingQuestions ?
          <div className="flex flex-row justify-center items-center py-48">
            <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-52 w-52"></div>
          </div>
          :
          <>
            <div className={`flex flex-row justify-between ${responseData?.help ? 'bg-slate-700 text-white' : 'bg-white'}`}>
              <div className="flex flex-row justify-between items-center w-[100%] mt-[20px] pb-[10px] border-b border-gray-200">
                <div className="absolute ml-[20px] flex flex-col">
                  <h4 className="font-bold">
                    {currentQuestion ? (
                      <>
                        {responseData?.help !== true ? (<>
                          <span className="block sm:hidden">Quiz</span>
                          <span className="hidden sm:block">Quiz Module: {currentQuestion.section} {currentQuestion.programs}</span>
                        </>) : (<>
                          <span className="block">Review: Help Session</span>
                        </>)}
                      </>
                    ) : (
                      <>
                        <span className="block sm:hidden">Review</span> {/* Visible only on mobile */}
                        <span className="hidden sm:block">Module Review</span> {/* Hidden on mobile */}
                      </>
                    )}
                  </h4>
                </div>


                {showDirectionModal && <DirectionModal onClose={closeModal} />}

                {responseData?.help !== true ? (
                  <div className="flex flex-col items-center justify-center flex-grow">
                    <div className="flex flex-col sm:flex-row items-center text-center sm:text-left">
                      <Timer
                        minutes={displayMinutes.toString().padStart(2, '0')}
                        seconds={displaySeconds.toString().padStart(2, '0')}
                      />
                    </div>
                  </div>
                ) : (<>
                  <div className="flex flex-col items-center justify-center flex-grow">
                    <div className="flex flex-col sm:flex-row items-center text-center sm:text-left">

                    </div>
                  </div>
                </>)}

                <div>

                  <div className="flex flex-row mr-[20px] items-center">
                    {currentQuestion && currentQuestion.section === "Math" && (
                      <>
                        <motion.button
                          whileTap={{ scale: 0.97 }}
                          onClick={() => setShowCalculator((pre) => !pre)}
                          className={`mr-[15px] flex flex-col items-center cursor-pointer hover:text-blue-900 ${showCalculator ? "text-blue-900" : "text-black"
                            } ${responseData?.help && "text-white"}`}
                        >
                          <div className="flex justify-center">
                            <CiCalculator1 size="1.5em" />
                          </div>
                          <div>
                            <p className="text-sm">Calculator</p>
                          </div>
                        </motion.button>
                        <motion.button
                          whileTap={{ scale: 0.97 }}
                          className={`mr-[15px] flex flex-col items-center cursor-pointer hover:text-blue-900 hidden sm:flex text-blue-900 ${responseData?.help && "text-white"}`}
                          onClick={() => setShowDirectionRefModal(!showDirectionModalRef)}
                        >
                          <div className="flex justify-center">
                            <VscReferences size="1.5em" />
                          </div>
                          <div>
                            <p className="text-sm">Reference</p>
                          </div>
                        </motion.button>
                      </>
                    )}

                    <div
                      className={`mr-[10px] flex flex-col items-center cursor-pointer hover:text-blue-900 text-black ${responseData?.help && "text-white"}`}
                      onClick={() => setshowNotes(!showNotes)}
                    >
                      <div className="flex justify-center">
                        <RiPlayList2Fill size="1.5em" />
                      </div>
                      <div>
                        <p className="text-sm">Notes</p>
                      </div>
                    </div>

                    <div
                      className={`flex flex-col items-center cursor-pointer hover:text-blue-900 text-black ${responseData?.help && "text-white"}`}
                      onClick={() => setShowOptions(!showOptions)}
                    >
                      <div className="flex justify-center">
                        <BsThreeDotsVertical size="1.5em" />
                      </div>
                      <div>
                        <p className="text-sm">More</p>
                      </div>
                    </div>

                  </div>

                  {showNotes && (
                    < div
                      className={`z-[20] w-full max-w-[200px] bg-white p-4 fixed ${responseData?.help ? "ml-[120px]" : "ml-[-110px]"} 
                      flex flex-col space-y-4 shadow-lg rounded-lg cursor-pointer ${responseData?.help ? "text-gray-700" : "text-black"}
                      ${currentQuestion?.section === "Math" && "ml-[60px]"}
                      `}
                    >

                      {rewritenotes && rewritenotes.length > 0 && (
                        rewritenotes.map((note) => (
                          <Tooltip title={`Continue My Note`} placement="top">
                            <div
                              key={note.id}
                              className="flex hover:text-blue-600 transition duration-300"
                              onClick={() => toggleWhiteboard(note.id)}
                            >
                              <div className="flex items-center mr-[3px]">
                                <VscDebugContinue size="1.5em" />
                              </div>
                              <p className="ml-[10px] text-xs mt-1 font-medium">Continue the Note</p>
                            </div>
                          </Tooltip>
                        ))
                      )}

                      {privatenotes && privatenotes.length > 0 && (
                        privatenotes.map((note) => (
                          <Tooltip title={`${note.viewed} views`} placement="top">
                            <div
                              key={note.id}
                              className="flex hover:text-blue-600 transition duration-300"
                              onClick={() => toggleWhiteboard(note.id)}
                            >
                              <div className="flex items-center mr-[3px] text-red-600">
                                <RiLiveFill size="1.5em" />
                              </div>
                              <p className="ml-[10px] text-xs mt-1 font-medium">Live Note by {note.user_id}</p>
                            </div>
                          </Tooltip>
                        ))
                      )}
                      <hr />
                      {savednotes && savednotes.length > 0 && (
                        savednotes.map((note) => (
                          <Tooltip title={`Private Note`} placement="top">
                            <div
                              key={note.id}
                              className="flex hover:text-blue-600 transition duration-300"
                              onClick={() => toggleWhiteboard(note.id)}
                            >
                              <div className="flex items-center mr-[3px]">
                                <FaRegSave size="1.5em" />
                              </div>
                              <p className="ml-[10px] text-xs mt-1 font-medium">View Note</p>
                            </div>
                          </Tooltip>
                        ))
                      )}

                      {publicnotes && publicnotes.length > 0 ? (
                        publicnotes.map((note) => (
                          <Tooltip title={`${note.viewed} views`} placement="top">
                            <div
                              key={note.id}
                              className="flex hover:text-blue-600 transition duration-300"
                              onClick={() => toggleWhiteboard(note.id)}
                            >
                              <div className="flex items-center mr-[3px]">
                                <GrView size="1.5em" />
                              </div>
                              <p className="ml-[10px] text-xs mt-1 font-medium">View Note by {note.user_id}</p>
                            </div>
                          </Tooltip>
                        ))
                      ) : (
                        // Default content when no public notes exist
                        <div className="text-center text-gray-500">
                          No public notes available.
                        </div>
                      )}
                    </div>
                  )}

                  {showOptions && (
                    <div className={`z-[20] bg-white p-4 fixed ${responseData?.help ? "ml-[120px]" : "ml-[-10px]"} flex flex-col space-y-4 shadow-lg rounded-lg cursor-pointer 
                    ${responseData?.help ? 'text-gray-700' : 'text-black'} ${currentQuestion?.section === "Math" && "ml-[170px]"}`}>

                      {/* Report Button */}
                      <div
                        className="flex flex-col items-center justify-center hover:text-red-600 transition duration-300"
                        onClick={() => handleReportClick()}>
                        <BsExclamationTriangle size="1.5em" />
                        <p className="text-xs mt-1 font-medium">Report</p>
                      </div>

                      {user && user?.role === "teacher" &&
                        <div className="flex flex-col items-center justify-center hover:text-blue-600 transition duration-300" onClick={(() => setshowanswer(!showanswer))}>
                          <FaKey size="1.5em" />
                          <p className="text-sm">Answer Key</p>
                        </div>
                      }

                      {/* Help Button (Visible only for non-teachers) */}
                      {user && user?.role !== 'teacher' && responseData?.help !== true && (
                        <motion.button
                          whileTap={{ scale: 0.97 }}
                          onClick={() => askforhelp()}
                          className={`flex flex-col items-center justify-center transition duration-300 ${showCalculator ? 'text-blue-600' : 'text-gray-700'} hover:text-blue-800`}
                        >
                          <FaHandBackFist size="1.5em" />
                          <p className="text-xs mt-1 font-medium">Get Help</p>
                        </motion.button>
                      )}

                      {user && (
                        <div
                          className="flex flex-col items-center justify-center hover:text-blue-600 transition duration-300"
                          onClick={() => toggleWhiteboard()}>
                          <FaChalkboard size="1.5em" />
                          <p className="text-xs mt-1 font-medium">Whiteboard</p>
                        </div>
                      )}

                      {user && user?.role === "teacher" &&
                        <div className="flex flex-col items-center justify-center hover:text-blue-600 transition duration-300" onClick={handleSave}>
                          <AiOutlineSave size="1.5em" />
                          <p className="text-sm">Save</p>
                        </div>
                      }
                    </div>
                  )}

                  {showModal && (
                    <div className="fixed z-20 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left text-black">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                  Report Card
                                </h3>
                                <div className="mt-2">
                                  <form>
                                    <label>Question ID:</label>
                                    <input type="text" className="border p-1 mt-1 w-full" value={currentQuestion.questionIds} readOnly />
                                    <label>Report from:</label>
                                    {user ? (
                                      <input type="text" className="border p-1 mt-1 w-full" value={user?.first_name + " " + user?.last_name} readOnly />
                                    ) : (
                                      <input type="text" className="border p-1 mt-1 w-full" value="Thank you for the report" readOnly />
                                    )}
                                    <label>Context:</label>
                                    <textarea
                                      className="border p-1 mt-1 w-full"
                                      value={context}
                                      onChange={handleContextChange}
                                    />
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={handleSubmit2}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                              onClick={handleCloseModal}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>

            <div className="py-4">
              <div className="flex flex-col">
                {currentQuestion ?
                  <div ref={quizContentRef} className={currentQuestion.a ? "h-[50%]" : "flex flex-col sm:flex-row justify-center h-[50%]"}>
                    <Question
                      sat={currentQuestion.section}
                      handleAnswerValueChange={handleAnswerValueChange}
                      handleCrossOutOptions={handleCrossOutOptions}
                      handleSelectOption={handleSelectOption}
                      currentQuestion={currentQuestion}
                      idx={1}
                      bookmarkedQuestions={bookmarkedQuestions}
                      setBookMarkedQuestions={setBookMarkedQuestions}
                      handleBookMark={handleBookMark}
                      result={result[currentQuestion.index]}
                      time={questionTimers[currentQuestion.index]}
                    />
                  </div>
                  :
                  <div className={"flex flex-col sm:flex-row justify-center h-[50%]"}>
                    <Review
                      currentQuestion={currentQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      questions={questions}
                      setQuestions={setQuestions}
                    />
                  </div>
                }

              </div>

            </div>

            <div className="z-[300] fixed inset-x-0 bottom-[2px] flex flex-row justify-around border-t-2 border-black pt-4 bg-white">
              {questionOverViewOpen && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    type: "spring",
                  }}
                  className="absolute z-[3000] rounded-md flex flex-row justify-center p-1 bg-white -top-40 border-[1px] border-gray-300"
                >
                  <div className="bg-white min-h-[140px] max-h-[140px] min-w-[200px] max-w-[400px] flex flex-row flex-wrap rounded-md">
                    {questions.map((ques, idx) => (
                      <div
                        key={'questions-' + idx}
                        onClick={() => setCurrentQuestion(questions[idx])}
                        className={`
                        ${ques.bookMarked ? 'bg-red-500 border-none text-white' : ''}
                        m-1 rounded-full border-[1px] w-[25px] h-[25px] flex flex-row justify-center items-center cursor-pointer 
                        ${currentQuestion?.questionIds === ques?.questionIds ? 'bg-black text-white' : ''}
                        ${ques.selectedOption != null ? 'border-none bg-blue-700 text-white' : ''}
                        ${askedConfirmation[idx] ? 'bg-gray-500 text-white' : ''}
                        border-black
                      `}
                      >
                        {idx + 1}
                      </div>
                    ))}
                  </div>
                </motion.div>
              )}

              <div className="hidden md:block flex flex-row items-center z-[999]">
                <p className="font-semibold text-lg">
                  {c ? username : (user ? `${user.first_name} ${user.last_name}` : 'Free User')}

                </p>
              </div>


              {currentQuestion && (
                <motion.button
                  whileTap={{ scale: 0.97 }}
                  onClick={() => setQuestionOverviewOpen((pre) => !pre)}
                  className="flex flex-row items-center gap-1 bg-black hover:bg-gray-800 text-white rounded-md p-2 cursor-pointer"
                >
                  <div className="flex flex-row items-center">
                    <p className="text-sm">
                      Question {currentQuestion?.index + 1} of {questions?.length}
                    </p>
                  </div>
                  <div className="flex flex-row items-center">
                    {questionOverViewOpen ? <BsChevronDown /> : <BsChevronUp />}
                  </div>
                </motion.button>
              )}
              <div className="flex flex-row items-center gap-2">

                <motion.button
                  whileTap={{ scale: 0.97 }}
                  className="py-2 px-5 bg-slate-700 rounded-3xl text-white hover:bg-slate-800"
                  onClick={() => handleBack()}
                >
                  Back
                </motion.button>

                {currentQuestion ? (
                  <motion.button
                    whileTap={{ scale: 0.97 }}
                    className="py-2 px-5 bg-slate-700 rounded-3xl text-white hover:bg-slate-800"
                    onClick={() => handleNext()}
                  >
                    Next
                  </motion.button>
                ) : (
                  responseData?.help !== true && (
                    <motion.button
                      whileTap={{ scale: 0.97 }}
                      className="py-2 px-5 bg-slate-700 rounded-3xl text-white hover:bg-slate-800"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </motion.button>
                  )
                )}
              </div>
            </div>
          </>
        }
      </div>
      {showanswer && (
        <div className="flex-initial border-l mb-[50px]" style={{ flexBasis: '20%' }} ref={boxItemLeftRef}>
          <div className="pt-[10px] pl-2">
            Answer:
            {currentQuestion.rationale && (
              <div className="m-2" dangerouslySetInnerHTML={{ __html: currentQuestion.rationale }} />
            )}
          </div>
        </div>
      )}
    </div >
  </>
  );
};
export default SATQuiz;