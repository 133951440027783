import React, { useState, useRef } from 'react';

const InfoIconWithTooltip = ({ tooltipText }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef(null);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseMove = (e) => {
    requestAnimationFrame(() => {
      setTooltipPosition({
        x: e.clientX,
        y: e.clientY,
      });
    });
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div className="relative inline-block">
      {/* Info Icon */}
      <span
        className="ml-2 text-gray-400 cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        ℹ
      </span>

      {/* Tooltip */}
      <div
        ref={tooltipRef}
        className={`fixed bg-gray-700 text-white text-sm rounded p-2 transition-opacity duration-300 ${
          isTooltipVisible ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
        style={{
          top: `${tooltipPosition.y + 15}px`, // Offset below the cursor
          left: `${tooltipPosition.x + 15}px`, // Offset to the right of the cursor
          whiteSpace: 'nowrap',
          zIndex: 1000,
        }}
      >
        {tooltipText}
      </div>
    </div>
  );
};

export default InfoIconWithTooltip;
