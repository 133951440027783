import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../../util";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { toast } from "react-hot-toast";
import { AiOutlineCheck, AiOutlineInfoCircle } from 'react-icons/ai';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import InfoIconWithTooltip from '../InfoIconWithTooltip';

const RandomNMSQTReady = ({ setLoading }) => {

  const { user } = useContext(UserContext);
  const [selectedButton, setSelectedButton] = useState(null);
  const navigate = useNavigate();
  const [isTutorActive, setIsTutorActive] = useState(false);
  const [isTimedActive, setIsTimedActive] = useState(false);
  const [timerMinutes, setTimerMinutes] = useState(45); // Default time set to 45 minutes
  const [currentPassages, setCurrentPassages] = useState(0);
  const [min, setmin] = useState(0);

  const [totalq, settotalq] = useState([]);

  const [correct, setcorrect] = useState(0);
  const [incorrect, setincorrect] = useState(0);
  const [marked, setmarked] = useState(0);
  const [omitted, setomitted] = useState(0);
  const [diff, setDiff] = useState({ E: [], M: [], H: [] });
  const [englishCount, setEnglishCount] = useState([]);
  const [mathCount, setMathCount] = useState([]);
  const [activeSegment, setActiveSegment] = useState('English');  // Default to 'All'
  const [displaySubjects, setDisplaySubjects] = useState([]);
  const [total, settotal] = useState(0);

  const [activeFilters, setActiveFilters] = useState({
    unused: false,
    incorrect: false,
    marked: false,
    omitted: false,
    correct: false
  });

  const [esubjects, esetSubjects] = useState([
    {
      name: 'Information and Ideas', count: 0, isOpen: true, isSelected: false, tag: 'English',
      subTopics: [
        { name: 'Inferences', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Central Ideas and Details', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Command of Evidence', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      name: 'Craft and Structure', count: 0, isOpen: true, isSelected: false, tag: 'English',
      subTopics: [
        { name: 'Words in Context', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Text Structure and Purpose', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Cross-Text Connections', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      name: 'Expression of Ideas', count: 0, isOpen: true, isSelected: false, tag: 'English',
      subTopics: [
        { name: 'Rhetorical Synthesis', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Transitions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } }
      ]
    },
    {
      name: 'Standard English Conventions', count: 0, isOpen: true, isSelected: false, tag: 'English',
      subTopics: [
        { name: 'Boundaries', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Form, Structure, and Sense', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } }
      ]
    }
  ]);

  const [msubjects, msetSubjects] = useState([
    {
      name: 'Algebra', count: 0, isOpen: true, isSelected: false, tag: 'Math',
      subTopics: [
        { name: 'Linear functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Systems of two linear equations in two variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Linear inequalities in one or two variables', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Linear equations in one variable', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      name: 'Advanced Math', count: 0, isOpen: true, isSelected: false, tag: 'Math',
      subTopics: [
        { name: 'Nonlinear functions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Equivalent expressions', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    },
    {
      name: 'Problem-solving and Data Analysis', count: 0, isOpen: true, isSelected: false, tag: 'Math',
      subTopics: [
        { name: 'Ratios, rates, proportional relationships, and units', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Probability and conditional probability', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'One-variable data: Distributions and measures of center and spread', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Two-variable data: Models and scatterplots', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Percentages', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },

      ]
    },
    {
      name: 'Geometry and Trigonometry', count: 0, isOpen: true, isSelected: false, tag: 'Math',
      subTopics: [
        { name: 'Lines, angles, and triangles', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Right triangles and trigonometry', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Area and volume', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
      ]
    }
  ]);

  const toggleSubTopic = (subjectType, subjectIndex, subTopicIndex) => {
    const setSubjects = subjectType === 'English' ? esetSubjects : msetSubjects;
  
    setSubjects(prevSubjects => {
      return prevSubjects.map((subject, i) => {
        if (i === subjectIndex) {
          const updatedSubTopics = subject.subTopics.map((subTopic, stIndex) => {
            if (stIndex === subTopicIndex && subTopic.count !== 0) { // Only toggle if count is not zero
              return {
                ...subTopic,
                isSelected: !subTopic.isSelected
              };
            }
            return subTopic;
          });
  
          // Determine if any subtopic is selected
          const anySubSelected = updatedSubTopics.some(subTopic => subTopic.isSelected);
  
          return {
            ...subject,
            subTopics: updatedSubTopics,
            isSelected: anySubSelected // Set main subject's isSelected based on subtopics
          };
        }
        return subject;
      });
    });
  };
  
  // Function to toggle the open/close state of a subject
  const toggleSubjectOpen = (index) => {
    let subjectsArray = activeSegment === 'English' ? esubjects :
      activeSegment === 'Math' ? msubjects : [...esubjects, ...msubjects];

    if (index >= 0 && index < subjectsArray.length) {
      const newSubjects = subjectsArray.map((subject, i) => {
        if (i === index) {
          return {
            ...subject,
            isOpen: !subject.isOpen // Toggle isOpen
          };
        }
        return subject;
      });

      if (activeSegment === 'English') {
        esetSubjects(newSubjects);
      } else if (activeSegment === 'Math') {
        msetSubjects(newSubjects);
      } else {
        esetSubjects(newSubjects.slice(0, esubjects.length));
        msetSubjects(newSubjects.slice(esubjects.length));
      }
    } else {
      console.error("Subject index out of bounds");
    }
  };

  const toggleSubjectSelect = (index) => {
    let subjectsArray = activeSegment === 'English' ? esubjects :
      activeSegment === 'Math' ? msubjects : [...esubjects, ...msubjects];
  
    if (index >= 0 && index < subjectsArray.length) {
      const newSubjects = subjectsArray.map((subject, i) => {
        if (i === index) {
          const newIsSelected = !subject.isSelected;
          let updatedSubTopics = subject.subTopics;
  
          if (newIsSelected) {
            // Select all subtopics that have count > 0
            updatedSubTopics = subject.subTopics.map(subTopic => ({
              ...subTopic,
              isSelected: subTopic.count > 0 ? true : subTopic.isSelected
            }));
          } else {
            // Unselect all subtopics when deselecting
            updatedSubTopics = subject.subTopics.map(subTopic => ({
              ...subTopic,
              isSelected: false
            }));
          }
  
          return {
            ...subject,
            isSelected: newIsSelected,
            subTopics: updatedSubTopics
          };
        }
        return subject;
      });
  
      if (activeSegment === 'English') {
        esetSubjects(newSubjects);
      } else if (activeSegment === 'Math') {
        msetSubjects(newSubjects);
      } else {
        esetSubjects(newSubjects.slice(0, esubjects.length));
        msetSubjects(newSubjects.slice(esubjects.length));
      }
    } else {
      console.error("Subject index out of bounds");
    }
  };  

  function processAllTestResults(testDataArray) {
    // Define the keys you expect to have arrays in JSON string format
    const keys = ['Correct_questionIds', 'Incorrect_questionIds', 'Marked_questionIds', 'Omitted_questionIds'];

    // Initialize objects to collect all IDs across all objects
    const allIds = {
      Correct_questionIds: [],
      Incorrect_questionIds: [],
      Marked_questionIds: [],
      Omitted_questionIds: []
    };

    let allQuestionIds = [];

    // Process each test data object
    testDataArray.forEach(testData => {
      keys.forEach(key => {
        // Concatenate all 'questionIds' from each object
        const questionIds = JSON.parse(testData[key] || '[]').map(item => item.questionIds);
        allQuestionIds = allQuestionIds.concat(questionIds);
      });
    });

    allQuestionIds = Array.from(new Set(allQuestionIds));

    // Process each test data object in the array
    testDataArray.forEach(testData => {
      keys.forEach(key => {
        // Concatenate all IDs to respective keys
        allIds[key] = allIds[key].concat(JSON.parse(testData[key] || '[]'));
      });
    });

    // Use a Set to remove duplicates from all combined arrays and assign to respective keys
    keys.forEach(key => {
      allIds[key] = Array.from(new Set(allIds[key]));
    });

    setcorrect(allIds.Correct_questionIds);
    setincorrect(allIds.Incorrect_questionIds);
    setmarked(allIds.Marked_questionIds);
    setomitted(allIds.Omitted_questionIds);

    return {
      allQuestionIds
    };
  }

  async function createtestoptions() {
    if (!user) {
      toast.error("Please login before starting the test");
      navigate("/login"); // Redirect user to the /login page
      return;
    }

    axios.get(`${BASELINE}tests/byuser/nmsqt/${user.u_id}`)
      .then(response => {
        const results = processAllTestResults(response.data);
        if (results) {
          axios.get(`${BASELINE}getquestionidsnmsqt`)
            .then(response => {
              // Assuming allQuestionIds is an array of IDs, create a Set for quick lookup
              const existingIds = new Set(results.allQuestionIds);

              // Filter out any question IDs already present in existingIds
              const uniqueQuestions = response.data.filter(question => !existingIds.has(question.questionIds));

              // Update the state with filtered questions
              settotalq(uniqueQuestions);
            })
            .catch(error => {
              console.error("Error fetching question IDs:", error);
            });
        }
      })
      .catch(error => {
        console.error("Error fetching user tests:", error);
      });

  }

  useEffect(() => {
    createtestoptions();
  }, []);

  useEffect(() => {
    let newDiff = { E: [], M: [], H: [] }; // Start with a fresh object
    let seenIds = new Set();  // To track seen question IDs to avoid duplicates


    const addItems = (items) => {
      items.forEach(item => {
        if (!seenIds.has(item.questionIds)) {  // Check if the question ID has already been added
          seenIds.add(item.questionIds);  // Mark this ID as seen
          if (newDiff[item.difficultys]) {  // Ensure the difficulty key exists in the diff object
            newDiff[item.difficultys].push(item);  // Push the item
          }
        }
      });
    };

    if (activeFilters.unused) {
      addItems(totalq);
    }

    if (activeFilters.correct) {
      addItems(correct);
    }

    if (activeFilters.incorrect) {
      addItems(incorrect);
    }

    if (activeFilters.marked) {
      addItems(marked);
    }

    if (activeFilters.omitted) {
      addItems(omitted);
    }

    setDiff(newDiff);  // Update the state with the new diff object
  }, [totalq, correct, incorrect, marked, omitted, activeFilters]);

  const toggleSubject = (index) => {
    // First, determine which subject array to use based on `activeSegment`
    let subjectsArray = activeSegment === 'English' ? esubjects :
      activeSegment === 'Math' ? msubjects : [...esubjects, ...msubjects];

    // Check if the index is within the bounds of the array
    if (index >= 0 && index < subjectsArray.length) {
      const newSubjects = subjectsArray.map((subject, i) => {
        if (i === index) {
          return { ...subject, isOpen: !subject.isOpen }; // Toggle isOpen
        }
        return subject;
      });

      // Update the appropriate state based on `activeSegment`
      if (activeSegment === 'English') {
        esetSubjects(newSubjects);
      } else if (activeSegment === 'Math') {
        msetSubjects(newSubjects);
      } else {
        // If 'All', you may need a more complex logic to handle both arrays
        esetSubjects(newSubjects.slice(0, esubjects.length));
        msetSubjects(newSubjects.slice(esubjects.length));
      }
    } else {
      console.error("Subject index out of bounds");
    }
  };


  const [activeDifficulties, setActiveDifficulties] = useState({
    low: false,
    medium: false,
    high: false
  });

  const toggleDifficulty = (level) => {
    setActiveDifficulties(prev => ({
      ...prev,
      [level]: !prev[level] // Toggle the specific difficulty level
    }));
  };

  const toggleFilter = (filter) => {
    setActiveFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter]
    }));
  };

  useEffect(() => {
    if (user && selectedButton != null) {
      navigate(`/sat/preset/quiz/p10_${selectedButton}`);
    }
  }, [selectedButton]);

  const random_sat = () => {
    setLoading(true);
    axios.get(`${BASELINE}sat10/random`)
      .then(response => {
        if (user) {
          navigate(`/sat/preset/quiz/p10_customrandom`, { state: { data: response.data } });
        }
      })
      .catch(error => {
        console.error(error);
        toast.error('Failed to fetch SAT data!');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    let combinedQuestions = []; // This will hold the combined questions from selected difficulties
    let seenIds = new Set(); // To track seen question IDs to avoid duplicates
    let engQuestions = []; // To hold English questions
    let mathQuestions = []; // To hold Math questions

    const addItems = (items) => {
      items.forEach(item => {
        if (!seenIds.has(item.questionIds)) { // Check if the question ID has already been added
          seenIds.add(item.questionIds); // Mark this ID as seen
          combinedQuestions.push(item); // Push the item into the combined array
        }
      });
    };

    // Include questions based on active difficulty filters
    if (activeDifficulties.low) {
      addItems(diff.E);
    }
    if (activeDifficulties.medium) {
      addItems(diff.M);
    }
    if (activeDifficulties.high) {
      addItems(diff.H);
    }

    // Split combined questions into 'eng' and 'math'
    combinedQuestions.forEach(question => {
      if (question.tag === 'eng') {
        engQuestions.push(question);
      } else if (question.tag === 'math') {
        mathQuestions.push(question);
      }
    });
    setEnglishCount(engQuestions);
    setMathCount(mathQuestions);
    // Optionally update state with these categorized questions
    // setEnglishQuestions(engQuestions);
    // setMathQuestions(mathQuestions);

  }, [diff, activeDifficulties]); // Dependency array includes 'diff' and 'activeDifficulties'

  useEffect(() => {
    if (activeSegment === 'English') {
      setDisplaySubjects(esubjects);
    } else if (activeSegment === 'Math') {
      setDisplaySubjects(msubjects);
    } else if (activeSegment === 'All') {
      // Combines English and Math subjects without duplicates
      setDisplaySubjects([...esubjects, ...msubjects]);
    }
  }, [activeSegment, esubjects, msubjects]);

  useEffect(() => {
    // Create a deep copy of esubjects to manipulate
    const updatedSubjects2 = esubjects.map(subject => ({
      ...subject,
      count: 0,  // Reset subject count to 0 initially
      isSelected: false,
      subTopics: subject.subTopics.map(sub => ({
        ...sub,
        count: 0, // Reset subtopic count to 0
        isSelected: false
      }))
    }));

    // Create a deep copy of msubjects to manipulate
    const updatedSubjects = msubjects.map(subject => ({
      ...subject,
      count: 0,  // Reset subject count to 0 initially
      isSelected: false,
      subTopics: subject.subTopics.map(sub => ({
        ...sub,
        count: 0,  // Reset subtopic count to 0
        isSelected: false,
      }))
    }));

    // Iterate over each question and update the corresponding subTopic count
    mathCount.forEach(question => {
      updatedSubjects.forEach(subject => {
        let subjectCount = 0;
        subject.subTopics.forEach(subTopic => {
          if (subTopic.name === question.topic) {
            subTopic.count += 1; // Increment the count if the topics match
            subTopic.isSelected = true; // Update isSelected based on conditions
            subjectCount += 1;
          }
          if (subTopic.count === 0) {
            subTopic.isSelected = false;
          }
        });
        subject.count += subjectCount;
        if (subject.count > 0) {
          subject.isSelected = true; // Set the subject to selected if any subTopics are selected
        }
      });
    });

    // Update the state with the new subjects data
    msetSubjects(updatedSubjects);

    // Iterate over each question and update the corresponding subTopic count
    englishCount.forEach(question => {
      updatedSubjects2.forEach(subject => {
        let subjectCount = 0;
        subject.subTopics.forEach(subTopic => {
          if (subTopic.name === question.topic) {
            subTopic.count += 1; // Increment the count if the topics match
            subTopic.isSelected = true;
            subjectCount += 1;
          }
          if (subTopic.count === 0) {
            subTopic.isSelected = false;
          }
        });
        subject.count += subjectCount;

        if (subject.count > 0) {
          subject.isSelected = true;
        }
      });
    });

    // Update the state with the new subjects data
    esetSubjects(updatedSubjects2);

  }, [englishCount, mathCount, diff]);  // Depend on mathCount to trigger this effect

  useEffect(() => {

    if (activeSegment === 'English') {
      let total = 0;
      let min = 0;
      esubjects.forEach(subject => {
        let alert = 0;
        subject.subTopics.forEach(subTopic => {
          if (subTopic.isSelected === false) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });
      setmin(min);
      settotal(total);
    }

    if (activeSegment === 'Math') {
      let total = 0;
      let min = 0;
      msubjects.forEach(subject => {
        let alert = 0;
        subject.subTopics.forEach(subTopic => {
          if (!subTopic.isSelected) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });
      settotal(total);  // Assume you have a separate state for math total
      setmin(min);      // Assume you have a separate state for math minimum
    }

    if (activeSegment === 'All') {
      let total = 0;
      let min = 0;

      msubjects.forEach(subject => {
        let alert = 0;
        subject.subTopics.forEach(subTopic => {
          if (!subTopic.isSelected) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });

      esubjects.forEach(subject => {
        let alert = 0;
        subject.subTopics.forEach(subTopic => {
          if (subTopic.isSelected === false) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });

      settotal(total);  // Assume you have a separate state for math total
      setmin(min);      // Assume you have a separate state for math minimum
    }

  }, [activeSegment, msubjects, esubjects])

  const handleGenerateTest = () => {
    // Step 1: Validate the number of passages
    if (currentPassages < min) {
      toast.error("Number of passages exceeds the minimum allowed.");
      return;
    }

    if (currentPassages > total) {
      toast.error("Number of passages exceeds the maximum allowed.");
      return;
    }

    // Step 2: Combine English and Math questions based on the active segment
    let combinedQuestions = [];
    if (activeSegment === 'English') {
      combinedQuestions = englishCount;
    } else if (activeSegment === 'Math') {
      combinedQuestions = mathCount;
    } else if (activeSegment === 'All') {
      combinedQuestions = [...englishCount, ...mathCount];
    }

    // Step 3: Apply filters based on difficulty
    const difficultyFilteredQuestions = combinedQuestions.filter(question =>
      (activeDifficulties.low && question.difficultys === 'E') ||
      (activeDifficulties.medium && question.difficultys === 'M') ||
      (activeDifficulties.high && question.difficultys === 'H')
    );

    // Step 4: Ensure at least one subject is selected and matches the selected topics
    let questionsToUse = [];
    const topicsSelected = displaySubjects.filter(subject => subject.isSelected)
      .flatMap(subject => subject.subTopics.filter(st => st.isSelected && st.count > 0));

    const minimumQuestions = topicsSelected.length;

    // Step 5: Select questions based on selected topics
    topicsSelected.forEach(topic => {
      const questionsForTopic = difficultyFilteredQuestions.filter(question => question.topic === topic.name);
      questionsForTopic.forEach(q => {
        if (questionsToUse.length < currentPassages && !questionsToUse.includes(q.questionIds)) {
          questionsToUse.push(q.questionIds);
        }
      });
    });

    // Add random questions if needed to meet the passage count
    while (questionsToUse.length < currentPassages) {
      const additionalQuestion = difficultyFilteredQuestions[Math.floor(Math.random() * difficultyFilteredQuestions.length)];
      if (!questionsToUse.includes(additionalQuestion.questionIds)) {
        questionsToUse.push(additionalQuestion.questionIds);
      }
    }

    // Prepare data for navigation
    const quizData = {
      questionIds: questionsToUse,
      tutorModeActive: isTutorActive,
      timedModeActive: isTimedActive,
      timeLimit: isTimedActive ? timerMinutes : null,
      tag: activeSegment,
      type: "SAT10",
    };

    localStorage.clear();

    navigate(`/sat/quiz/`, { state: { data: quizData } });
  };

  return (<>
    <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
      <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">Create Digital NMSQT</span>
    </div>
    <h1 className="bg-gray-100 rounded-2xl m-4 p-1 pl-6 text-xl font-bold mt-4">Quick Digital NMSQT</h1>

    <section className="wrapper mb-6">
      <div className="w-[100%] flex justify-start items-center flex-col px-5 bg-white rounded-2xl">
        <div className="flex flex-row flex-wrap justify-center gap-2">
          {Array.from({ length: 8 }, (_, i) => i + 1).map((num) => (
            <button
              key={num}
              onClick={() => setSelectedButton(num)}
              className={`border border-black border-solid text-black font-bold py-2 px-4 rounded m-2 ${selectedButton === num ? 'bg-gray-400' : 'bg-white hover:bg-gray-300'}`}
            >
              #Digital NMSQT {num}
            </button>
          ))}

          {user && user?.premium === 1 ? (
            <button
              onClick={() => random_sat()}
              className={`border border-black border-solid text-black font-bold py-2 px-4 rounded m-2 bg-gray-400'}`}
            >
              Random Generate
            </button>
          ) : (
            <button
              className={`border border-black border-solid text-black font-bold py-2 px-4 rounded m-2 bg-gray-400'}`}
            >
              🔒 Random Generate
            </button>
          )}
        </div>

      </div>
    </section>

    {user && user?.premium === 1 ? (
      <>
        <h1 className="bg-gray-100 rounded-2xl m-4 p-1 pl-6 text-xl font-bold mt-4">Personalize NMSQT Test</h1>
      </>
    ) : (
      <>
        <h1 className="bg-gray-100 rounded-2xl m-4 p-1 pl-6 text-xl font-bold mt-4">🔒 Personalize NMSQT Test</h1>
      </>
    )}


    <div className={`bg-white pb-6 pl-6 pt-2 rounded-lg ${user?.premium !== 1 ? 'opacity-50 pointer-events-none' : ''}`}>
      {/* Test Mode Section */}
      <div className="text-gray-800 text-lg mb-2 font-semibold">Test Mode
      </div>

      <div className="flex items-center justify-between mb-4">

        <div className="flex justify-between items-center space-x-4 mb-2">
          <ToggleSwitch
            label="Tutor"
            isEnabled={isTutorActive}
            toggleSwitch={() => setIsTutorActive(!isTutorActive)}
            desc="Tutor Mode: Shows correct answer and explanation when the user chooses wrong."
          />
          <div className="flex items-center">
            <ToggleSwitch
              label="Timed"
              isEnabled={isTimedActive}
              toggleSwitch={() => setIsTimedActive(!isTimedActive)}
              desc="Quiz Timer: Can set a time limit for quizzes."
            />
            {isTimedActive && (
              <div className="ml-4">
                <input
                  type="number"
                  value={timerMinutes}
                  onChange={(e) => setTimerMinutes(e.target.value)}
                  className="w-20 pl-2 pr-1 border rounded text-gray-800"
                  min="1"
                />
                <span className="ml-1 text-gray-700">min</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Question Mode Section */}
      <div className="mb-4">
        <div className="text-gray-800 text-lg font-semibold mb-1">Question Mode</div>
        <div className="mt-2 flex space-x-4">
          <FilterButton
            label="Unused"
            count={totalq.length}
            isActive={activeFilters.unused}
            toggleFilter={() => toggleFilter('unused')}
          />
          <FilterButton
            label="Correct"
            count={correct.length}
            isActive={activeFilters.correct}
            toggleFilter={() => toggleFilter('correct')}
          />
          <FilterButton
            label="Incorrect"
            count={incorrect.length}
            isActive={activeFilters.incorrect}
            toggleFilter={() => toggleFilter('incorrect')}
          />
          <FilterButton
            label="Marked"
            count={marked.length}
            isActive={activeFilters.marked}
            toggleFilter={() => toggleFilter('marked')}
          />
          <FilterButton
            label="Omitted"
            count={omitted.length}
            isActive={activeFilters.omitted}
            toggleFilter={() => toggleFilter('omitted')}
          />
        </div>
      </div>

      {/* Filter Options */}
      <div className="grid grid-cols-2 gap-4 mt-6 mb-4">
        <div>
          <div className="flex items-center justify-between mb-2">
            <span className="font-semibold text-lg text-gray-800">Difficulty Level</span>
          </div>
          <div className="flex gap-4">
            <FilterButton
              label="Easy"
              count={diff.E.length}
              isActive={activeDifficulties.low}
              toggleFilter={() => toggleDifficulty('low')}
            />
            <FilterButton
              label="Medium"
              count={diff.M.length}
              isActive={activeDifficulties.medium}
              toggleFilter={() => toggleDifficulty('medium')}
            />
            <FilterButton
              label="Hard"
              count={diff.H.length}
              isActive={activeDifficulties.high}
              toggleFilter={() => toggleDifficulty('high')}
            />
          </div>
        </div>
      </div>


      {/* Subjects */}
      <div className="mb-4 mt-8">
        <div className="flex">
          <label className="text-gray-800 text-lg font-semibold mb-1 block">Subjects</label>
          <div className="flex border border-slate-300 rounded-xl overflow-hidden mt-[-5px] mb-[20px] ml-[20px]">
            {['English', 'Math'].map(segment => {
              // Get the appropriate count for each segment directly from the arrays
              let count = 0;
              if (segment === 'English') {
                count = englishCount.length; // Directly use the length of engQuestions
              } else if (segment === 'Math') {
                count = mathCount.length; // Directly use the length of mathQuestions
              } else {
                count = englishCount.length + mathCount.length; // Sum for 'All'
              }

              return (
                <button
                  key={segment}
                  onClick={() => setActiveSegment(segment)}
                  className={`flex-2 py-2 px-4 text-center transition-colors duration-300
                    ${activeSegment === segment ? 'bg-slate-700 text-white' : 'bg-white text-gray-800 hover:bg-gray-200'}`}
                >
                  {`${segment} (${count})`}
                </button>
              );
            })}
          </div>
        </div>

        <div className="bg-white flex flex-row flex-wrap sm:flex-nowrap items-center justify-center grid grid-cols-4">
          {displaySubjects.map((subject, index) => (
            <Subject
              key={`${subject.name}-${subject.tag}`}  // Ensures unique key by combining name and tag
              name={subject.name}
              count={subject.count}
              isOpen={subject.isOpen}
              isSelected={subject.isSelected}
              toggleOpen={() => toggleSubjectOpen(index)}    // Pass toggleOpen
              toggleSelect={() => toggleSubjectSelect(index)} // Pass toggleSelect
              subTopics={subject.subTopics.map((subTopic, subIndex) => ({
                ...subTopic,
                toggle: () => toggleSubTopic(subject.tag, index, subIndex)
              }))}
            />
          ))}
        </div>

      </div>


      <div className="mt-8 mb-4">
        <label className="text-lg font-semibold mb-1 block">No. of Passages</label>
        <div className="flex flex-wrap">

          <div className="flex items-center space-x-4">
            <input
              type="number"
              value={currentPassages}
              onChange={(e) => setCurrentPassages(e.target.value)}
              className="w-24 bg-gray-300 border-gray-300 border text-center p-2"
              min={min}
            />

            <span>Max allowed per test</span>
            <input
              type="number"
              value={total}
              style={{ minWidth: '50px', width: 'auto' }} // Same here for the second input
              readOnly
            />
          </div>


        </div>
      </div>
      <div className="mt-8 space-y-4">
        <div className="text-lg font-bold">Generate Your Test</div>
        {user && user.premium === 1 ? (
          <GenerateButton onClick={handleGenerateTest} />
        ) : (
          <GenerateButton />
        )}
      </div>

    </div >
  </>);

};

const GenerateButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded inline-flex items-center justify-center space-x-2"
    >
      <span>GENERATE TEST</span>
      <AiOutlineInfoCircle />
    </button>
  );
};

const SubTopic = ({ name, count, isSelected, toggle }) => {
  return (
    <div className="flex justify-between items-center p-1">
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={toggle}  // Ensure this is the only handler for onChange
          className="mr-2 cursor-pointer"
        />
        <span>{name}</span>
      </div>
      <span>{`${count}`}</span>
    </div>
  );
};

// Subject Component
const Subject = ({ name, count, isOpen, isSelected, toggleOpen, toggleSelect, subTopics }) => {
  return (
    <div className="mb-2 border-b w-[98%] h-[250px] border border-gray-200 p-2 rounded-xl">
      <div className="flex justify-between items-center p-2">
        <div className="flex items-center space-x-2">
          {/* Toggle Open/Close Button */}
          <button
            onClick={toggleOpen}
            className="focus:outline-none"
            aria-label={isOpen ? "Collapse Subject" : "Expand Subject"}
            aria-expanded={isOpen}
          >
            {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}
          </button>

          {/* Selection Checkbox */}
          <input
            type="checkbox"
            checked={isSelected}
            onChange={toggleSelect}
            className="mr-2 cursor-pointer"
            aria-label={`Select ${name}`}
          />

          {/* Clickable Subject Name to Toggle Open/Close */}
          <span
            onClick={toggleOpen}
            className="cursor-pointer"
            role="button"
            tabIndex="0"
            onKeyPress={(e) => { if (e.key === 'Enter') toggleOpen(); }}
          >
            {name}
          </span>
        </div>
        <span>{`${count}`}</span>
      </div>

      {/* Subtopics List */}
      {isOpen && (
        <div className="h-[150px] overflow-y-auto">
          {subTopics.map((subTopic, index) => (
            <SubTopic
              key={`${subTopic.name}-${index}`} // Use a unique key if possible
              name={subTopic.name}
              count={subTopic.count}
              isSelected={subTopic.isSelected}
              toggle={() => subTopic.toggle(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ToggleSwitch = ({ label, isEnabled, toggleSwitch, desc }) => {
  return (
    <div className="flex items-center">
      <span className="text-gray-700 mr-[-8px]">{label}</span>
      <InfoIconWithTooltip tooltipText={desc} />

      <div
        onClick={toggleSwitch}
        className={`ml-2 w-12 h-6 flex items-center bg-gray-200 rounded-full p-1 duration-300 ease-in-out ${isEnabled ? 'bg-slate-700 justify-end' : 'bg-gray-300 justify-start'
          }`}
      >
        <div
          className="bg-white w-4 h-4 rounded-full transform duration-300 ease-in-out"
        />
      </div>
    </div>
  );
};

const FilterButton = ({ label, count, isActive, toggleFilter }) => {
  return (
    <button
      disabled={count === 0} // Disable button if count is 0
      className={`flex items-center space-x-2 border px-3 py-2 rounded-full ${isActive ? 'bg-slate-700 text-white' : 'bg-white text-gray-800'} ${count === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-300'}`}
      onClick={count > 0 ? toggleFilter : undefined} // Prevent function call if count is 0
    >
      {isActive && <AiOutlineCheck className="text-lg" />}
      <span>{label}</span>
      <div className="flex items-center space-x-1">
        <span>{count}</span>
      </div>
    </button>
  );
};


export default RandomNMSQTReady;
