import React, { useState } from 'react';
import axios from 'axios';
import { BASELINE } from "../util/index";
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";

const SignupPanel = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        grade: '',
        role: '',
        couponCode: '',
    });
    const [isCouponValid, setIsCouponValid] = useState(null);
    const [isVerifying, setIsVerifying] = useState(false);
    const [subscribeNewsletter, setSubscribeNewsletter] = useState(false); 
    const validCouponCodes = ['MMTFREE3MONTHS', 'MMTPREMIUM123'];
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left; 
        const y = event.clientY - rect.top;  
        setMousePosition({ x, y });
    };

    const getPremiumDates = () => {
        const today = new Date();
        const startPremiumDate = today.toISOString().split('T')[0];

        const endPremiumDate = new Date();
        endPremiumDate.setMonth(endPremiumDate.getMonth() + 3);
        const endPremiumDateString = endPremiumDate.toISOString().split('T')[0];

        return { startPremiumDate, endPremiumDateString };
    };

    const handleVerifyCoupon = () => {
        setIsVerifying(true);
        setTimeout(() => {
            if (validCouponCodes.includes(formData.couponCode)) {
                setIsCouponValid(true);
            } else {
                setIsCouponValid(false);
            }
            setIsVerifying(false);
        }, 1000);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "role" && value === "parents") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                grade: '99',
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleNewsletterChange = (event) => {
        setSubscribeNewsletter(event.target.checked);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (formData.couponCode && isCouponValid === false) {
            alert('Please enter a valid coupon code or remove it.');
            return;
        }

        const { startPremiumDate, endPremiumDateString } = getPremiumDates();

        try {
            const dataToSend = {
                ...formData,
                premium: isCouponValid ? 1 : 0,
                start_premium_date: isCouponValid ? startPremiumDate : null,
                end_premium_date: isCouponValid ? endPremiumDateString : null,
            };

            const response = await axios.post(BASELINE + 'user/insert/info', dataToSend);

            if (subscribeNewsletter) {
                await axios.post(BASELINE + 'email', [formData.email]); 
            }

            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                grade: '',
                role: '',
                couponCode: '',
            });
            setIsCouponValid(null);
            setSubscribeNewsletter(false);
            alert('Registration successful!');
            navigate('/');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message || 'Email is already in use. Please use a different email.');
            } else {
                console.error('Error during registration:', error);
                alert('An error occurred during registration. Please try again later.');
            }
        }
    };

    const { first_name, last_name, role, email, password } = formData;

    let currentStep = 0;

    const isStep1Complete = first_name.trim() !== '' && last_name.trim() !== '' && role !== '';
    if (isStep1Complete) currentStep = 1;

    const isStep2Complete = isStep1Complete && email.trim() !== '';
    if (isStep2Complete) currentStep = 2;

    let isStep3Complete = false;
    if (role === 'student' || role === 'teacher' || role === 'parents') {
        isStep3Complete = isStep2Complete && password.trim().length >= 8;
    }

    if (isStep3Complete) currentStep = 3;

    const totalSteps = 3;
    const progressPercentage = (currentStep / totalSteps) * 100;

    return (
        <div className="pt-6 px-4 sm:px-16 md:px-32 lg:px-40 xl:px-[20%] min-h-screen pb-10 bg-white">
            
            {/* Progress Bar Section */}
            <div className="mb-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div 
                        className="bg-blue-600 h-2.5 rounded-full transition-all duration-500" 
                        style={{ width: `${progressPercentage}%` }}
                    ></div>
                </div>
                <div className="flex justify-between text-sm mt-2">
                    <span className={currentStep >= 1 ? "text-blue-600 font-bold" : "text-gray-500"}>Step 1</span>
                    <span className={currentStep >= 2 ? "text-blue-600 font-bold" : "text-gray-500"}>Step 2</span>
                    <span className={currentStep === 3 ? "text-blue-600 font-bold" : "text-gray-500"}>Step 3</span>
                </div>
            </div>

            <div className="rounded text-[#232323]">
                <motion.div
                    className="text-white py-8 px-4 rounded-lg text-center mb-8 relative overflow-hidden"
                    style={{
                        background: "linear-gradient(to right, #3b82f6, #9333ea)",
                        backgroundSize: "200% 200%",
                    }}
                    animate={{
                        backgroundPosition: ["0% 50%", "100% 50%", "0% 50%"],
                    }}
                    transition={{
                        duration: 6,
                        ease: "linear",
                        repeat: Infinity,
                    }}
                    onMouseMove={handleMouseMove}
                >
                    <div
                        className="absolute inset-0 pointer-events-none"
                        style={{
                            background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(255, 255, 255, 0.6), transparent 20%)`,
                            mixBlendMode: "screen",
                        }}
                    ></div>

                    <h1 className="text-4xl font-bold">Join the MMT Community!</h1>
                    <p className="mt-4 text-lg">
                        Get access to premium resources, expert guidance, and a supportive
                        community.
                    </p>
                </motion.div>

                <form onSubmit={handleSubmit}>
                    {/* Step 1 fields */}
                    <div className="flex flex-col sm:grid sm:grid-cols-3 gap-4 sm:gap-6">
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="firstName">
                                First Name*
                            </label>
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <input
                                    className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </motion.div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="lastName">
                                Last Name*
                            </label>
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <input
                                    className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </motion.div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="role">
                                Role*
                            </label>
                            <select
                                className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                id="role"
                                name="role"
                                value={formData.role}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select a role</option>
                                <option value="student">Student</option>
                                <option value="parents">Parents</option>
                                <option value="teacher">Teacher</option>
                            </select>
                        </div>
                    </div>

                    {/* Step 2 fields */}
                    {isStep1Complete && (
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="email">
                                Email*
                            </label>
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <input
                                    className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </motion.div>
                        </div>
                    )}

                    {isStep2Complete && (
                        <>
                            <div className="mb-4">
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox"
                                        checked={subscribeNewsletter}
                                        onChange={handleNewsletterChange}
                                    />
                                    <span className="ml-2 text-sm font-bold text-[#232323]">Get the latest newsletter</span>
                                </label>
                            </div>

                            <div className="relative group mb-4">
                                <label className="block text-sm font-bold mb-2">Password*</label>
                                <input
                                    className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="absolute top-0 right-0 mt-2 mr-2 group-hover:opacity-100 opacity-0 transition-opacity duration-300">
                                    <div className="bg-gray-800 text-white text-xs rounded py-1 px-2">
                                        Must be at least 8 characters.
                                    </div>
                                </div>
                            </div>

                            {(formData.role === 'student' || formData.role === 'teacher') && (
                                <>
                                    {formData.role === 'student' && (
                                        <div className="mb-4">
                                            <label className="block text-sm font-bold mb-2" htmlFor="grade">
                                                School Grade (Numbers Only)
                                            </label>
                                            <input
                                                className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                                type="number"
                                                id="grade"
                                                name="grade"
                                                value={formData.grade}
                                                onChange={handleInputChange}
                                                min="1"
                                                max="12"
                                            />
                                        </div>
                                    )}
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2" htmlFor="couponCode">
                                            Coupon Code (Optional)
                                        </label>
                                        <div className="flex">
                                            <input
                                                className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                                type="text"
                                                id="couponCode"
                                                name="couponCode"
                                                value={formData.couponCode}
                                                onChange={handleInputChange}
                                            />
                                            <button
                                                type="button"
                                                onClick={handleVerifyCoupon}
                                                className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                            >
                                                Verify
                                            </button>
                                        </div>
                                        {isCouponValid !== null && (
                                            <p className={`mt-2 ${isCouponValid ? 'text-green-500' : 'text-red-500'}`}>
                                                {isCouponValid ? 'Coupon code is valid!' : 'Coupon code is invalid!'}
                                            </p>
                                        )}
                                    </div>
                                </>
                            )}

                            <div className="flex justify-end">
                                <motion.div
                                    whileHover={{
                                        scale: 1.1,
                                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                                        backgroundColor: "#004080",
                                    }}
                                    whileTap={{
                                        scale: 0.95,
                                    }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                        damping: 10,
                                    }}
                                    className="mt-2 w-full sm:w-auto"
                                >
                                    <button
                                        className="bg-[#001F3F] text-white border border-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                                        type="submit"
                                        disabled={!isStep3Complete}
                                    >
                                        Sign Up
                                    </button>
                                </motion.div>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </div>
    );
};

export default SignupPanel;
