import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASELINE } from '../../util';
import Message from './message';
import { motion } from "framer-motion";

const AIAudioPlayer = ({ result, context, currentQuestion, type1, type2 }) => {
    const voiceOptions = {
        gender: 'Female',
        voiceName: 'en-US-AriaNeural',
        rate: '15%',
        pitch: '9%',
        volume: '80%',
      };

    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [visibleSteps, setVisibleSteps] = useState(1); // Controls how many messages are visible
    const [userInput, setUserInput] = useState(''); // State to track user's input
    const [error, setError] = useState(null); // State to track errors

    const [isHidden, setIsHidden] = useState(false); // State to manage hide/expand
    const [isPlayingVoice, setIsPlayingVoice] = useState(true); // Controls auto-playing of voice

    useEffect(() => {
        setVisibleSteps(1);
        if (isHidden) {
            setIsPlayingVoice(false); // Disable voice playback when hidden
        } else {
            setIsPlayingVoice(true);
        }
    }, [context, isHidden]);

    // Hide or Expand functionality
    const toggleChat = () => {
        setIsHidden(!isHidden);
    };

    // Fetch AI response for initial steps
    const genprompt = async (contextText) => {
        try {
            const data = {
                prompt: contextText,
                qid: currentQuestion.question_id,
            };
            if (type1 && type1.trim() !== '') {
                data.type = type1;
            } else if (type2 && type2.trim() !== '') {
                data.type = type2;
            }
            const res = await axios.post(`${BASELINE}askai`, data);
            if (res.data && res.data.message) {
                const parsedData = JSON.parse(res.data.message);
                return parsedData;
            } else {
                console.error('Unexpected response structure:', res.data);
                return {
                    greeting: ['Error: Unexpected response from the server.'],
                    stepbystep: [],
                };
            }
        } catch (error) {
            console.error('Error during API call:', error);
            return { greeting: ['Error: Unable to process the request.'], stepbystep: [] };
        }
    };

    useEffect(() => {
        const fetchAIResponse = async () => {
            setIsLoading(true);
            setError(null); // Reset any previous errors
            const aiData = await genprompt(context);
            const allMessages = [];
            // Add greeting
            if (aiData.greeting && aiData.greeting.length > 0) {
                allMessages.push({ type: 'greeting', text: aiData.greeting[0], indexs: 0 });
            }

            // Add steps
            if (aiData.stepbystep && aiData.stepbystep.length > 0) {
                aiData.stepbystep.forEach((step, idx) => {
                    allMessages.push({ type: 'step', text: step, indexs: idx + 1 });
                });
            }
            setMessages(allMessages);
            setIsLoading(false);
        };
        if (currentQuestion) {
            fetchAIResponse();
        }
    }, [context]);

    // Function to reveal the next step
    const showNextStep = () => {
        if (visibleSteps < messages.length) {
            setVisibleSteps(visibleSteps + 1);
        }
    };

    // Handle the "Ask" button click
    const handleAsk = async () => {
        if (!userInput.trim()) {
            setError('Please enter a question before asking.');
            return;
        }


        const visibleMessages = messages.slice(0, visibleSteps);
        const remainingMessages = messages.slice(visibleSteps);

        const visibleMessagesTexts = visibleMessages.map((message) => message.text);

        // Construct the prompt for the AI
        let question = `
You are a math professor, and I have a question about a math problem.
Please do not include any information that does not pertain to the math problem.

Please follow these instructions:
1. Do NOT include any greetings (e.g., "Hi", "Hello") in your output.
2. Your response should not exceed 100 tokens.
3. Do NOT give the answer directly to the user. Guide them how to find. 

Here is the problem:
<Question> ${currentQuestion.content} </Question>
<Option list> ${currentQuestion.options} </Option list>
<Answer> ${currentQuestion.answer} </Answer>

The information I know about this problem is ${JSON.stringify(visibleMessagesTexts)}.
The question I want to ask is ${userInput}.
        `;
        setUserInput('');
        try {
            const res = await axios.post(`${BASELINE}askai`, { prompt: question });
            if (res.data && res.data.message) {
                const answer = res.data.message.trim();

                // Insert user's question and AI's answer into messages array
                const updatedMessages = [
                    ...visibleMessages,
                    { type: 'userQuestion', text: userInput, indexs: null }, // User's question
                    { type: 'aiAnswer', text: answer, indexs: null }, // AI's answer
                    ...remainingMessages,
                ];

                setMessages(updatedMessages);

                // Update visibleSteps to include the new messages
                setVisibleSteps(visibleSteps + 2);

                // Clear the userInput
                setUserInput('');
            } else {
                console.error('Unexpected response structure:', res.data);
                setError('Failed to get a valid response from the server.');
            }
        } catch (error) {
            console.error('Error during API call:', error);
            setError('Failed to get a response. Please try again.');
        } finally {

        }
    };

    // Determine the index of the last 'step' message in the visible slice
    const getLastStepIndex = () => {
        let lastStepIdx = -1;
        for (let i = 0; i < visibleSteps; i++) {
            if (messages[i]?.type === 'step') {
                lastStepIdx = i;
            }
        }
        return lastStepIdx;
    };

    const lastStepIndex = getLastStepIndex();

    return (
        <>
            {/* Floating Button */}
            {isHidden && (
                <motion.div
                    className="fixed bottom-[95px] right-[30px] flex items-center justify-center w-16 h-16 text-white font-bold text-lg rounded-full shadow-lg cursor-pointer"
                    initial={{ scale: 1 }}
                    animate={{
                        scale: [1, 1.1, 1],
                        background: [
                            "linear-gradient(45deg, #4a90e2, #8e44ad)",
                            "linear-gradient(45deg, #8e44ad, #4a90e2)",
                            "linear-gradient(45deg, #4a90e2, #8e44ad)",
                        ],
                    }}
                    transition={{
                        scale: {
                            duration: 2,
                            repeat: Infinity,
                            ease: "easeInOut",
                        },
                        background: {
                            duration: 3,
                            repeat: Infinity,
                            ease: "easeInOut",
                        },
                    }}
                    style={{
                        boxShadow: "0 0 15px 5px rgba(138, 43, 226, 0.7)",
                    }}
                    onClick={toggleChat} // Expand the chat
                >
                    AI
                </motion.div>
            )}

            {/* Chat Box */}
            {!isHidden && (
                <div className="relative flex-[2] border-l pb-[30px] max-h-[800px] overflow-auto">
                    {/* Header with Hide Button */}
                    <div className="flex justify-between items-center p-4 border-b bg-gray-100">
                        <h2 className="text-lg font-bold">AI Assistant</h2>
                        <button
                            onClick={toggleChat}
                            className="px-3 py-1 text-sm bg-red-500 text-white rounded hover:bg-red-600"
                        >
                            Hide
                        </button>
                    </div>

                    {isLoading ? (
                        <div className="m-2 p-4 rounded-md border border-blue-400 bg-blue-100 text-sm flex items-start">
                            <div className="mr-3">
                                <svg
                                    className="w-6 h-6 text-blue-500 animate-spin"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M10 2a8 8 0 00-8 8v4a2 2 0 002 2h4v-4H6v-2a4 4 0 118 0v2h-2v4h4a2 2 0 002-2v-4a8 8 0 00-8-8z" />
                                </svg>
                            </div>
                            <p className="text-blue-800">AI generating....</p>
                        </div>
                    ) : (
                        <>
                            {result === true && (
                                <Message
                                    key={-1}
                                    indexs={-1}
                                    text={'Congratulations! You have successfully solved this problem!'}
                                    voiceOptions={isPlayingVoice ? voiceOptions : null}
                                />
                            )}
                            {result === false && (
                                <Message
                                    key={-2}
                                    indexs={-2}
                                    text={"Let's try this again later!"}
                                    voiceOptions={isPlayingVoice ? voiceOptions : null}
                                />
                            )}
                            {messages.slice(0, visibleSteps).map((message, index) => (
                                <Message
                                    key={index}
                                    indexs={message.indexs}
                                    text={message.text}
                                    voiceOptions={isPlayingVoice ? voiceOptions : null}
                                    onGetMoreHelp={showNextStep}
                                    type={message.type}
                                    showGetMoreHelp={
                                        index !== message.index && (message.type === 'step' || message.type === 'greeting')
                                    }
                                />
                            ))}
                            {/* Input field */}
                            <div className="flex items-center gap-2 mt-4">
                                <textarea
                                    value={userInput}
                                    onChange={(e) => setUserInput(e.target.value)}
                                    placeholder="Type your question..."
                                    className="ml-1 flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                />
                                <button
                                    onClick={handleAsk}
                                    disabled={!userInput.trim()}
                                    className={`px-4 py-2 text-white rounded ${userInput.trim()
                                        ? 'bg-blue-600 hover:bg-blue-700'
                                        : 'bg-gray-400 cursor-not-allowed'
                                        }`}
                                >
                                    Ask
                                </button>
                            </div>
                            {error && <p className="text-red-500 mt-2">{error}</p>}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default AIAudioPlayer;
