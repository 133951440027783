import React, { useState, useEffect, useRef } from "react";

const EyeTrackingHeader = ({ isSmiling }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const leftEyeRef = useRef(null);
  const rightEyeRef = useRef(null);

  // Window에서 마우스 좌표를 추적
  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // 눈의 중심 좌표와 각도 계산
  const calculateEyePosition = (eyeRef) => {
    if (!eyeRef.current) return { x: 0, y: 0 };

    const eyeRect = eyeRef.current.getBoundingClientRect();
    const eyeCenterX = eyeRect.left + eyeRect.width / 2;
    const eyeCenterY = eyeRect.top + eyeRect.height / 2;

    const dx = mousePosition.x - eyeCenterX;
    const dy = mousePosition.y - eyeCenterY;
    const distance = Math.min(Math.sqrt(dx ** 2 + dy ** 2), 10); // 눈알이 움직이는 최대 거리
    const angle = Math.atan2(dy, dx);

    return {
      x: Math.cos(angle) * distance,
      y: Math.sin(angle) * distance,
    };
  };

  // 눈 위치
  const leftEyePosition = calculateEyePosition(leftEyeRef);
  const rightEyePosition = calculateEyePosition(rightEyeRef);

  return (
    <div className="flex flex-col items-center mt-10">
      <h2 className="text-2xl font-bold mb-4 flex items-center gap-2">
        ✋Welcome to SAT.MMT!
        {/* 눈 아이콘 */}
        <div className="flex items-center">
          {/* 왼쪽 눈 */}
          <div
            ref={leftEyeRef}
            className="relative w-8 h-8 bg-white border-2 border-black rounded-full flex items-center justify-center mr-2"
          >
            {isSmiling ? (
              <div className="text-sm font-bold">💖</div> // 웃는 눈
            ) : (
              <div
                className="w-3 h-3 bg-black rounded-full"
                style={{
                  transform: `translate(${leftEyePosition.x}px, ${leftEyePosition.y}px)`,
                }}
              ></div>
            )}
          </div>
          {/* 오른쪽 눈 */}
          <div
            ref={rightEyeRef}
            className="relative w-8 h-8 bg-white border-2 border-black rounded-full flex items-center justify-center"
          >
            {isSmiling ? (
              <div className="text-sm font-bold">💖</div> // 웃는 눈
            ) : (
              <div
                className="w-3 h-3 bg-black rounded-full"
                style={{
                  transform: `translate(${rightEyePosition.x}px, ${rightEyePosition.y}px)`,
                }}
              ></div>
            )}
          </div>
        </div>
      </h2>
    </div>
  );
};

export default EyeTrackingHeader;
