import React, { useState, useEffect, useRef } from "react";
import { MdVideoLibrary } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import { FaVideo, FaQuestionCircle, FaFilePowerpoint, FaCheck } from "react-icons/fa";
import { shaderMaterial } from "@react-three/drei";
import { extend, Canvas, useFrame } from "@react-three/fiber";
import glsl from "babel-plugin-glsl/macro";

const GasShaderMaterial = shaderMaterial(
    { uTime: 0 },
    // Vertex Shader
    glsl`
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    // Fragment Shader
    glsl`
      precision mediump float;
      varying vec2 vUv;
      uniform float uTime;
  
      void main() {
        vec2 position = vUv * 2.0 - 1.0;
        float len = length(position);
        
        // 색상 정의: 보라색, 파랑색, 노란색의 혼합
        vec3 color1 = vec3(0.8, 0.3, 0.9); // 보라색
        vec3 color2 = vec3(0.2, 0.6, 1.0); // 파란색
        vec3 color3 = vec3(1.0, 0.9, 0.5); // 노란색
  
        // 부드럽게 색상을 혼합하면서 시간에 따라 변화
        float mixedValue = 0.5 + 0.5 * sin(uTime + len * 3.0);
        vec3 mixedColor = mix(color1, color2, smoothstep(0.2, 0.8, mixedValue));
        mixedColor = mix(mixedColor, color3, smoothstep(0.4, 0.9, sin(uTime + len * 2.0)));
  
        gl_FragColor = vec4(mixedColor, 0.8); // 부드러운 투명도 추가
      }
    `
);

extend({ GasShaderMaterial });

const GasEffect = () => {
    const materialRef = useRef();
    useFrame(({ clock }) => {
        if (materialRef.current) {
            materialRef.current.uTime = clock.getElapsedTime();
        }
    });

    return (
        <mesh>
            {/* Replace PlaneGeometry with planeGeometry to use React-Three-Fiber declaratively */}
            <planeGeometry args={[5, 5]} />
            <gasShaderMaterial ref={materialRef} />
        </mesh>
    );
};

const Mainboard = () => {
    const [showIntro, setShowIntro] = useState(true);
    const sphereRef = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowIntro(false);
        }, 2000); // 인트로 애니메이션이 5초간 지속됩니다.

        return () => clearTimeout(timer);
    }, []);

    const introVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 1, ease: "easeOut" } },
        exit: { opacity: 0, transition: { duration: 1, ease: "easeIn" } },
    };

    const units = [
        {
            title: (
                <div className="flex items-center gap-2">
                    <MdVideoLibrary className="text-slate-700" />
                    <span>Course Video</span>
                </div>
            ),
            progress: 0,
            totalXP: 315,
            lessons: [
                {
                    title: "Week 1A: Introduction to the Digital SAT & Diagnostics Test",
                    progress: 0, xp: 75,
                    image: "./satweek1a.jpg",
                    course: [
                        { type: "video", link: "./video1.mp4" },
                        { type: "quiz", link: [12, 14, 15, 16] },
                        { type: "ppt", link: "./presentation1.pptx" },
                    ],
                },
                { title: "Week 1B: Words in Context, part 1 (CS)", progress: 0, xp: 60, image: "./satweek1b.jpg" },
                { title: "Week 2A: Central Idea and Details (II)", progress: 0, xp: 30, image: "./satweek2a.jpg" },
                { title: "Week 2B: Words in Context, part 2 (CS)", progress: 0, xp: 15, image: "./satweek2b.jpg" },
                { title: "Week 3A: Tense, Structure and Purpose (CS)", progress: 0, xp: 15, image: "./satweek3a.jpg" },
                { title: "Week 3B: Form, Sense, and Structure, part 1 (SEC)", progress: 0, xp: 45, image: "./satweek3b.jpg" },
                { title: "Week 4A: Command of Evidence (II): both textual and quantitative", progress: 0, xp: 75, image: "./satweek4a.jpg" },
                { title: "Week 4B: Boundaries, part 1 (SEC): commas, colons, dashes", progress: 0, xp: 75, image: "./satweek4b.jpg" },
                { title: "Week 5A: Inferences (II)", progress: 0, xp: 75, image: "./satweek5a.jpg" },
                { title: "Week 5B: Transitions (EoI)", progress: 0, xp: 75, image: "./satweek5b.jpg" },
                { title: "Week 6A: Cross Text Connections (CS)", progress: 0, xp: 75, image: "./satweek6a.jpg" },
                { title: "Week 6B: Form, Sense, and Structure, part 2 (SEC)", progress: 0, xp: 75, image: "./satweek6b.jpg" },
                { title: "Week 7A: Rhetorical Synthesis/Student Notes (EoI)", progress: 0, xp: 75, image: "./satweek7a.jpg" },
                { title: "Week 7B: Boundaries, part 2 (SEC): sentence str..", progress: 0, xp: 75, image: "comparison.jpg" },
                { title: "Week 8A: Review", progress: 0, xp: 75, image: "comparison.jpg" },
                { title: "Week 8B: Practice Test", progress: 0, xp: 75, image: "comparison.jpg" },

            ],
        },
        {
            title: "Digital SAT English",
            progress: 0,
            totalXP: 315,
            lessons: [
                { title: "Developments in East Asia from c. 1200 to c. 1450", progress: 0, xp: 75, image: "east-asia.jpg" },
                { title: "Developments in Dar al-Islam from c. 1200 to c. 1450", progress: 0, xp: 60, image: "dar-al-islam.jpg" },
                { title: "Developments in South and Southeast Asia from c. 1200 to c. 1450", progress: 0, xp: 30, image: "south-asia.jpg" },
                { title: "State Building in the Americas from c. 1200 to c. 1450", progress: 0, xp: 15, image: "americas.jpg" },
                { title: "State Building in Africa from c. 1200 to c. 1450", progress: 0, xp: 15, image: "africa.jpg" },
                { title: "Developments in Europe from c. 1200 to c. 1450", progress: 0, xp: 45, image: "europe.jpg" },
                { title: "Comparison in the Period from c. 1200 to c. 1450", progress: 0, xp: 75, image: "comparison.jpg" },
            ],
        },

        {
            title: "Digital SAT Math",
            progress: 0,
            totalXP: 315,
            lessons: [
                { title: "Developments in East Asia from c. 1200 to c. 1450", progress: 0, xp: 75, image: "east-asia.jpg" },
                { title: "Developments in Dar al-Islam from c. 1200 to c. 1450", progress: 0, xp: 60, image: "dar-al-islam.jpg" },
                { title: "Developments in South and Southeast Asia from c. 1200 to c. 1450", progress: 0, xp: 30, image: "south-asia.jpg" },
                { title: "State Building in the Americas from c. 1200 to c. 1450", progress: 0, xp: 15, image: "americas.jpg" },
                { title: "State Building in Africa from c. 1200 to c. 1450", progress: 0, xp: 15, image: "africa.jpg" },
                { title: "Developments in Europe from c. 1200 to c. 1450", progress: 0, xp: 45, image: "europe.jpg" },
                { title: "Comparison in the Period from c. 1200 to c. 1450", progress: 0, xp: 75, image: "comparison.jpg" },
            ],
        },
    ];

    const maxVisibleLessons = 7; // 초기 표시할 레슨 수
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [modalTitle, settitle] = useState("");
    // 모달 열기 함수
    const openCourseModal = (title, course) => {
        settitle(title);
        setSelectedCourse(course);
        setCurrentStep(0);
    };

    // 모달 닫기 함수
    const closeModal = () => {
        setSelectedCourse(null);
        setCurrentStep(0);
    };

    // 다음 단계로 넘어가기
    const nextStep = () => {
        if (currentStep < selectedCourse.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            closeModal(); // 모든 스텝이 끝났으면 모달 닫기
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };


    // 모달 내 콘텐츠 렌더링
    const renderContent = () => {
        if (!selectedCourse || selectedCourse.length === 0) return null;
        const currentContent = selectedCourse[currentStep];

        const isFirstStep = currentStep === 0;
        const isLastStep = currentStep === selectedCourse.length - 1;

        switch (currentContent.type) {
            case "video":
                return (
                    <div className="flex flex-col items-center justify-center">
                        <video width="80%" controls>
                            <source src={currentContent.link} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="flex justify-between w-full max-w-md mt-4">
                            <button
                                className={`bg-gray-500 text-white py-2 px-4 rounded ${isFirstStep ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={prevStep}
                                disabled={isFirstStep}
                            >
                                Back
                            </button>
                            <button
                                className="bg-blue-500 text-white py-2 px-4 rounded"
                                onClick={nextStep}
                            >
                                {isLastStep ? "Finish" : "Next"}
                            </button>
                        </div>
                    </div>
                );
            case "quiz":
                return (
                    <div className="flex flex-col items-center justify-center">
                        <p>Quiz Question IDs: {currentContent.link.join(", ")}</p>
                        <div className="flex justify-between w-full max-w-md mt-4">
                            <button
                                className={`bg-gray-500 text-white py-2 px-4 rounded ${isFirstStep ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={prevStep}
                                disabled={isFirstStep}
                            >
                                Back
                            </button>
                            <button
                                className="bg-blue-500 text-white py-2 px-4 rounded"
                                onClick={nextStep}
                            >
                                {isLastStep ? "Finish" : "Next"}
                            </button>
                        </div>
                    </div>
                );
            case "ppt":
                return (
                    <div className="flex flex-col items-center justify-center">
                        <p>PPT Link: <a href={currentContent.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">View PPT</a></p>
                        <div className="flex justify-between w-full max-w-md mt-4">
                            <button
                                className={`bg-gray-500 text-white py-2 px-4 rounded ${isFirstStep ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={prevStep}
                                disabled={isFirstStep}
                            >
                                Back
                            </button>
                            <button
                                className="bg-blue-500 text-white py-2 px-4 rounded"
                                onClick={nextStep}
                            >
                                Finish
                            </button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const UnitBlock = ({ unit }) => {
        const [expanded, setExpanded] = useState(false); // Expand state management
        const toggleExpand = () => setExpanded(!expanded); // Toggle expand/collapse
        const visibleLessons = expanded ? unit.lessons : unit.lessons.slice(0, maxVisibleLessons);

        return (
            <div className="ml-4 mr-4 bg-gray-100 rounded-md border border-1 border-gray-200 p-4 mb-8">
                {/* Unit Header */}
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold text-slate-700">{unit.title}</h2>
                    <div className="flex items-center gap-2">
                        <span className="text-sm text-gray-500">{unit.progress}/{unit.totalXP} XP</span>
                        <div className="w-40 bg-gray-200 rounded-full h-2 mr-2">
                            <div
                                className="bg-slate-700 h-2 rounded-full"
                                style={{ width: `${(unit.progress / unit.totalXP) * 100}%` }}
                            ></div>
                        </div>
                    </div>
                </div>

                {/* Lessons Grid with Animation */}
                <motion.div
                    className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 m-2"
                    initial={{ opacity: 0, height: 0 }} // Initial collapsed state
                    animate={{ opacity: 1, height: "auto" }} // Expanded state
                    exit={{ opacity: 0, height: 0 }} // Collapsed state
                    transition={{ duration: 0.5, ease: "easeInOut" }} // Animation timing
                >
                    {visibleLessons.map((lesson, idx) => (
                        <motion.div key={idx} className="bg-white rounded-lg shadow-md" whileHover={{ scale: 1.05 }} onClick={() => openCourseModal(lesson.title, lesson.course)}>
                            <div
                                className="h-24 bg-cover bg-center rounded-t-md mb-4"
                                style={{ backgroundImage: `url(${lesson.image})` }}
                            ></div>
                            <h3 className="pt-2 pl-2 pr-2 text-sm font-medium text-slate-700 mb-2">{lesson.title}</h3>
                            <div className="pl-2 pr-2 pb-2 flex justify-between items-center">
                                <span className="text-xs text-gray-500">0/{lesson.xp} XP </span>
                                <div className="w-24 bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-slate-700 h-2 rounded-full"
                                        style={{ width: `${(lesson.progress / lesson.xp) * 100}%` }}
                                    ></div>
                                </div>
                            </div>
                        </motion.div>
                    ))}

                    {/* Expand/Collapse Button */}
                    {unit.lessons.length > maxVisibleLessons && (
                        <motion.div
                            className="bg-white p-4 rounded-lg shadow-md cursor-pointer"
                            onClick={toggleExpand}
                            whileHover={{ scale: 1.05 }} // Hover effect
                            whileTap={{ scale: 0.95 }} // Click effect
                        >
                            <h3 className="text-sm font-bold text-slate-700 mb-2 text-center">
                                {expanded ? "Collapse" : "Click to Expand >>"}
                            </h3>
                        </motion.div>
                    )}
                </motion.div>
            </div>
        );
    };

    const renderCourseProgress = () => {
        if (!selectedCourse) return null;

        return (
            <div className="bg-gray-100 rounded-md p-2 border border-1 flex justify-center items-center mb-4 gap-4">
                {selectedCourse.map((courseItem, index) => {
                    // Determine if this course step is completed
                    const isCompleted = currentStep + 1 > index;

                    // Icon mapping based on type
                    let IconComponent;
                    switch (courseItem.type) {
                        case "video":
                            IconComponent = FaVideo;
                            break;
                        case "quiz":
                            IconComponent = FaQuestionCircle;
                            break;
                        case "ppt":
                            IconComponent = FaFilePowerpoint;
                            break;
                        case "review":
                            IconComponent = FaCheck;
                            break;
                        default:
                            IconComponent = FaQuestionCircle;
                    }

                    // Step 클릭 시 해당 단계로 이동하는 핸들러 함수
                    const handleStepClick = () => {
                        setCurrentStep(index);
                    };

                    return (
                        <div
                            key={index}
                            className="hover:bg-gray-200 p-1 rounded-md flex flex-col items-center cursor-pointer"
                            onClick={handleStepClick}
                        >
                            <IconComponent
                                className={`text-2xl ${isCompleted || currentStep === index ? 'text-blue-500' : 'text-gray-500'}`}
                            />
                            <span className="text-xs text-gray-700 mt-1">
                                Step {index + 1} - {courseItem.type.charAt(0).toUpperCase() + courseItem.type.slice(1)}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="bg-white min-h-screen">
            {/* 인트로 애니메이션 */}
            <AnimatePresence>
                {showIntro && (
                    <motion.div
                        className="fixed inset-0 flex flex-col items-center justify-center bg-black z-50"
                        variants={introVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    >
                        {/* Three.js Canvas with Gas Effect */}
                        <Canvas className="absolute inset-0">
                            <GasEffect />
                        </Canvas>

                        {/* Pulse 효과를 주는 Welcome 텍스트 */}
                        <motion.div
                            className="text-white text-4xl font-bold mt-[-100px]"
                            initial={{ scale: 1 }}
                            animate={{ scale: [1, 1.1, 1], opacity: [0.8, 1, 0.8] }}
                            transition={{ duration: 1.5, ease: "easeInOut", repeat: Infinity }}
                        >
                            The AI Is Preparing The Response
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Header */}
            <div className="bg-slate-900 p-2 pb-3 pt-3 flex justify-between items-center mb-6">
                <h1 className="text-xl font-bold text-white">✨ Digital SAT Online Class</h1>
            </div>

            {/* Render Units */}
            {units.map((unit, index) => (
                <UnitBlock key={index} unit={unit} />
            ))}

            {/* Modal */}
            {selectedCourse && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                >
                    <div className="bg-white w-full h-full m-4 p-4 relative overflow-y-auto rounded-lg shadow-lg">
                        {/* Header Section with Title and Close Button */}
                        <div className="flex justify-between items-center mb-4">
                            <p className="text-xl font-bold">{modalTitle}</p>
                            <button className="text-2lg text-black text-lg font-bold" onClick={closeModal}>
                                &times;
                            </button>
                        </div>

                        {/* Course Progress Icons */}
                        {renderCourseProgress()}

                        {/* Dynamic Content */}
                        {renderContent()}
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default Mainboard;