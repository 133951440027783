import React, { useState, useEffect, useRef } from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { ImSpellCheck } from "react-icons/im";
import { motion } from "framer-motion";
import 'katex/dist/katex.min.css';
import { MathfieldElement } from 'mathlive';

const Question = ({
    sat,
    handleAnswerValueChange,
    handleCrossOutOptions,
    handleSelectOption,
    handleBookMark,
    currentQuestion,
    setQuestions
}) => {
    console.log(currentQuestion.question_id);

    const containerRef = useRef(null);
    const boxItemLeftRef = useRef();
    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);
    const [crossedOutOptions, setCrossedOutOptions] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isKatexRendered, setIsKatexRendered] = useState(false);
    const [leftWidth, setLeftWidth] = useState(50); // Start with 50% width
    const [isResizing, setIsResizing] = useState(false);
    const dividerRef = useRef(null);
    const [mcq, setmcq] = useState(false);
    const options = JSON.parse(currentQuestion.options);
    const cleanOptionText = (text) => text.substring(3);

    const handleAnswerUpdate = (questionId, updatedAnswer) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((question) => {
                if (question.question_id === questionId) {
                    // Ensure mathAnswers is an array
                    let mathAnswers = Array.isArray(question.mathAnswers)
                        ? [...question.mathAnswers]
                        : [];
    
                    // Find the index of the existing answer (case-insensitive)
                    const index = mathAnswers.findIndex(
                        (answer) => 
                            answer.optionLetter.toUpperCase() === updatedAnswer.optionLetter.toUpperCase()
                    );
    
                    if (index !== -1) {
                        // Update existing answer
                        mathAnswers[index] = updatedAnswer;
                    } else {
                        // Add new answer
                        mathAnswers.push(updatedAnswer);
                    }
    
                    console.log('Updated mathAnswers:', mathAnswers);
    
                    return { ...question, mathAnswers };
                }
                return question;
            })
        );
    };    
    
    // Event Handlers for Resizing
    const onMouseDown = (e) => {
        setIsResizing(true);
    };

    const onMouseMove = (e) => {
        if (!isResizing) return;

        if (containerRef.current) {
            const container = containerRef.current.getBoundingClientRect();
            const newLeftWidth = ((e.clientX - container.left) / container.width) * 100;

            if (newLeftWidth > 20 && newLeftWidth < 80) {
                setLeftWidth(newLeftWidth);
            }
        }
    };

    const onMouseUp = () => {
        setIsResizing(false);
    };

    useEffect(() => {
        if (isResizing) {
            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
            document.body.style.userSelect = "none"; // Prevent text selection
        } else {
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
            document.body.style.userSelect = "auto";
        }

        // Cleanup
        return () => {
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
            document.body.style.userSelect = "auto";
        };
    }, [isResizing]);

    useEffect(() => {
        const tags = JSON.parse(currentQuestion.tags);
        const hasMCQ = tags.includes("mcq");
        if (hasMCQ === true) { setmcq(true); }

        const script2 = document.createElement('script');
        script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
        script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
        script2.crossOrigin = 'anonymous';

        script2.onload = () => {
            if (boxItemLeftRef.current) {
                const options = {
                    delimiters: [
                        { left: '$$', right: '$$', display: false },
                        { left: "$", right: "$", display: false },
                        { left: '\\(', right: '\\)', display: false },
                        { left: '\\[', right: '\\]', display: true },
                        { left: '\\begin{array}', right: '\\end{array}', display: true }
                    ],
                    throwOnError: false,
                };
                window.renderMathInElement(boxItemLeftRef.current, options);
                setIsKatexRendered(true);
            }
        };
        document.body.appendChild(script2);
        return () => {
            document.body.removeChild(script2);
        };
    }, [currentQuestion]);

    const setRefs = (...refs) => (element) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(element);
            } else if (ref) {
                ref.current = element;
            }
        });
    };

    return (
        <div id="box-item-left" ref={setRefs(containerRef, boxItemLeftRef)}>
            {mcq === true ? (
                <div className="mt-[20px] flex h-full">
                    {/* Left Pane */}
                    <div
                        className="left-pane"
                        style={{ width: `${leftWidth}%` }}
                    >
                        <div className="mb-[160px] mt-[3%] z-[10]">
                            <div
                                className={`flex flex-col justify-start ml-2 mr-2 box-item-left`}
                            >
                                <div>
                                    <div
                                        id="test-imgsat"
                                        dangerouslySetInnerHTML={{
                                            __html: currentQuestion.content,
                                        }}
                                        className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style stem-content"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Divider */}
                    <div
                        ref={dividerRef}
                        className="divider mt-[20px] h-[800px]"
                        onMouseDown={onMouseDown}
                    />

                    {/* Right Pane */}
                    <div
                        className="right-pane ml-[15px] mr-[15px]"
                        style={{ width: `${100 - leftWidth}%` }}
                    >
                        <div className="mt-[40px] mr-[20px] ml-[20px]">
                            <div className="flex flex-row justify-between bg-gray-100">
                                <div className="flex flex-row gap-2">
                                    <div className=" flex flex-row items-center">
                                        <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                                    </div>
                                    <div className="flex flex-row items-center gap-1">
                                        <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.q_num)} className="m-1 cursor-pointer">
                                            {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                                        </motion.div>
                                        <div>
                                            <p>Mark For Review</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center">
                                    <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu(pre => !pre)}>
                                        <ImSpellCheck />
                                    </motion.div>
                                </div>
                            </div>
                        </div>

                        <div className="my-2 min-w-[100px] max-w-[600px] max-h-[580px] overflow-y-auto">
                            {options.map((option, index) => (
                                <Option
                                    key={index}
                                    handleCrossOutOptions={handleCrossOutOptions}
                                    currentQuestion={currentQuestion}
                                    handleSelectOption={handleSelectOption}
                                    idx={index}
                                    showCrossOutMenu={showCrossOutMenu} // Use state to show or hide cross-out menu
                                    crossedOutOptions={crossedOutOptions}
                                    data={cleanOptionText(option)} // Pass cleaned option text
                                />
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-[20px] flex h-full">
                    {/* Left Pane */}
                    <div
                        className="left-pane"
                        style={{ width: `${leftWidth}%` }}
                    >
                        <div className="mb-[160px] mt-[3%] z-[10]">
                            <div
                                className={`flex flex-col justify-start ml-2 mr-2 box-item-left`}
                            >
                                <div>
                                    <div
                                        id="test-imgsat"
                                        dangerouslySetInnerHTML={{
                                            __html: currentQuestion.content,
                                        }}
                                        className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style stem-content"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Divider */}
                    <div
                        ref={dividerRef}
                        className="divider mt-[20px] h-[800px]"
                        onMouseDown={onMouseDown}
                    />

                    {/* Right Pane */}
                    <div
                        className="right-pane ml-[15px] mr-[15px]"
                        style={{ width: `${100 - leftWidth}%` }}
                    >
                        <div className="mt-[40px] mr-[20px] ml-[20px]">
                            <div className="flex flex-row justify-between bg-gray-100">
                                <div className="flex flex-row gap-2">
                                    <div className=" flex flex-row items-center">
                                        <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                                    </div>
                                    <div className="flex flex-row items-center gap-1">
                                        <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.q_num)} className="m-1 cursor-pointer">
                                            {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                                        </motion.div>
                                        <div>
                                            <p>Mark For Review</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="my-2 min-w-[100px] max-w-[600px] max-h-[580px] overflow-y-auto">
                            {options.map((option, index) => (
                                <Option2
                                    key={index}
                                    handleCrossOutOptions={handleCrossOutOptions}
                                    currentQuestion={currentQuestion}
                                    handleSelectOption={handleSelectOption}
                                    idx={index}
                                    showCrossOutMenu={showCrossOutMenu} // Use state to show or hide cross-out menu
                                    crossedOutOptions={crossedOutOptions}
                                    data={cleanOptionText(option)} // Pass cleaned option text
                                    handleAnswerUpdate={handleAnswerUpdate}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default Question;

const Option = ({
    handleCrossOutOptions,
    currentQuestion,
    idx,
    handleSelectOption,
    showCrossOutMenu,
    crossedOutOptions,
    data
}) => {
    const optionLetter = getOptionLetter(idx).toLowerCase();
    const selectedOption = currentQuestion.selectedOption;
    const isSelected = typeof selectedOption === 'string' && selectedOption === optionLetter;

    return (
        <div className="flex flex-row gap-1">
            <motion.div
                whileTap={{ scale: 0.98 }}
                className={`flex flex-row w-full hover:bg-gray-200 ${isSelected ? "border-[2px]" : "border-[1px]"} rounded-md ${isSelected ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${currentQuestion.crossedOutOptions?.includes(optionLetter) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`}
                onClick={() => handleSelectOption(currentQuestion.question_id, optionLetter)}
            >
                <div className="flex items-center">
                    <p className="flex items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                <div dangerouslySetInnerHTML={{ __html: data }} />
            </motion.div>

            {showCrossOutMenu && (
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions?.includes(optionLetter) ?
                            (<p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.question_id, optionLetter)}>Undo</p>) :

                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.question_id, optionLetter)} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.question_id, optionLetter)}>
                                </div>
                            </>

                        }
                    </div>
                </motion.div>
            )}
        </div>
    );
};

const Option2 = ({
    currentQuestion,
    idx,
    data,
    handleAnswerUpdate,
}) => {
    const mathFieldContainerRef = useRef(null);
    const mathFieldRef = useRef(null);

    // Initialize MathFieldElement once
    useEffect(() => {
        if (mathFieldContainerRef.current && !mathFieldRef.current) {
            const mathField = new MathfieldElement({
                virtualKeyboardMode: "manual",
                virtualKeyboardLayout: "math",
            });

            // Apply styles
            mathField.style.width = "100%";
            mathField.style.boxSizing = "border-box";
            mathField.style.overflowWrap = "break-word";
            mathField.style.wordBreak = "break-word";
            mathField.style.whiteSpace = "normal";
            mathField.style.resize = "vertical";
            mathField.style.minHeight = "3em";
            mathField.style.padding = "10px";

            mathFieldContainerRef.current.appendChild(mathField);
            mathFieldRef.current = mathField;
        }

        return () => {
            if (mathFieldRef.current && mathFieldContainerRef.current) {
                mathFieldContainerRef.current.removeChild(mathFieldRef.current);
                mathFieldRef.current = null;
            }
        };
    }, []); // Empty dependency array to initialize only once

    // Update event listener when questionId or idx changes
    useEffect(() => {
        if (mathFieldRef.current) {
            const mathField = mathFieldRef.current;

            // Define the event handler
            const handleInput = () => {
                const latexValue = mathField.getValue('latex');

                const updatedAnswer = {
                    optionLetter: getOptionLetter(idx).toUpperCase(),
                    latex: latexValue,
                };

                // Use the latest questionId
                handleAnswerUpdate(currentQuestion.question_id, updatedAnswer);
            };

            // Attach the event listener
            mathField.addEventListener('input', handleInput);

            // Cleanup function to remove the event listener
            return () => {
                mathField.removeEventListener('input', handleInput);
            };
        }
    }, [currentQuestion.question_id, idx, handleAnswerUpdate]);

    // Update MathFieldElement value when mathAnswers change
    useEffect(() => {
        if (mathFieldRef.current) {
            const currentOptionLetter = getOptionLetter(idx).toUpperCase();
            const existingAnswer = currentQuestion.mathAnswers?.find(
                (answer) => answer.optionLetter.toUpperCase() === currentOptionLetter
            );
            if (existingAnswer) {
                mathFieldRef.current.setValue(existingAnswer.latex);
            } else {
                mathFieldRef.current.setValue('');
            }
        }
    }, [currentQuestion.mathAnswers, idx]);

    return (
        <div className="flex flex-row gap-1">
            <div className="w-full gap-2 p-1 m-3 relative">
                <div className="flex">
                    <p>{getOptionLetter(idx) + ". "}</p>
                    <div dangerouslySetInnerHTML={{ __html: data }} />
                </div>
                <div
                    ref={mathFieldContainerRef}
                    className="Z-[400] border border-2 rounded-md mt-2 p-2 min-w-[200px] max-w-full wrap-text break-words whitespace-normal"
                    style={{
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                        maxWidth: '100%',
                    }}
                ></div>
            </div>
        </div>
    );
};

function getOptionLetter(idx) {
    const letters = ["A", "B", "C", "D", "E"];
    return letters[idx] || "A";
}