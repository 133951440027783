import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import 'katex/dist/katex.min.css';
import axios from "axios";
import { BASELINE } from "../../util";

const DevQuestion = ({ ans, handleCrossOutOptions, handleSelectOption, currentQuestion, group, setCurrentQuestion, dumy, handlerationale }) => {
    const boxItemLeftRef = useRef();
    const explanationRef = useRef();

    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);
    const [crossedOutOptions, setCrossedOutOptions] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [g_content, setg_content] = useState(group.content);
    const [frq, setfrq] = useState(null);
    const [exp, setexp] = useState("S");
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null); // Track selected option index
    const [parsedStepByStep, setParsedStepByStep] = useState([]); // State for step-by-step data
    const [isEditingLine, setIsEditingLine] = useState(null); // Track the line being edited
    const [editedLine, setEditedLine] = useState(""); // Store the edited line content
    const [showentire, setshowentire] = useState(true);
    const [editentire, setentire] = useState("");
    const [rationaleeditentire, setrationaleeditentire] = useState("");

    const cleanOptionText = (text) => text.substring(3);
    const [processedContent, setProcessedContent] = useState(currentQuestion.content);
    const [processedOptions, setProcessedOptions] = useState([]);
    const options = JSON.parse(currentQuestion.options);

    const fetchImagesInText = async (text, examName) => {
        const imageRegex = /\[IMAGE\s*#(\d+)\]/g;
        const matches = [...text.matchAll(imageRegex)];
        let replacedText = text;

        for (const match of matches) {
            const imageNumber = match[1];
            try {
                const res = await axios.get(BASELINE + `getImage?exam=${examName}&imageNumber=${imageNumber}`, { responseType: 'blob' });
                const blob = res.data;
                const imgURL = URL.createObjectURL(blob);
                replacedText = replacedText.replace(
                    match[0],
                    `<img src="${imgURL}" alt="Image #${imageNumber}" style="width:25%; height:auto;" />`
                );
            } catch (error) {
                console.error("이미지 로딩 실패:", error);
                // 실패 시 placeholder 유지하거나 다른 처리 가능
            }
        }

        return replacedText;
    };

    useEffect(() => {
        const examName = encodeURIComponent(currentQuestion.exam);

        // content 처리
        (async () => {
            const replacedContent = await fetchImagesInText(currentQuestion.content, examName);
            setProcessedContent(replacedContent);
        })();

        // options 처리
        (async () => {
            const replacedOptions = await Promise.all(
                options.map(async (opt) => {
                    return await fetchImagesInText(cleanOptionText(opt), examName);
                })
            );
            setProcessedOptions(replacedOptions);
        })();
    }, [currentQuestion]);

    const handlesaveentire = () => {
        // Split input into an array of lines, filter lines starting with '->', and map to array format
        const formattedArray = editentire
            .split('\n')                             // Split by newline to get each line
            .filter(line => line.startsWith('->'))    // Keep only lines starting with '->'
            .map(line => line.replace('->', '').trim()); // Remove '->' and trim whitespace

        // Update parsedStepByStep with the formatted array at the selected index
        const updatedStepByStep = [...parsedStepByStep];
        updatedStepByStep[selectedOptionIndex] = formattedArray;

        setParsedStepByStep(updatedStepByStep);       // Update the state with the new array format

        // Optional: Persist the change in currentQuestion
        const updatedQuestion = { ...currentQuestion };
        updatedQuestion.step_by_step = JSON.stringify(updatedStepByStep);
        console.log(updatedQuestion.step_by_step);
        setCurrentQuestion(updatedQuestion.step_by_step);

        // Clear editentire if needed
        setentire("");
    };

    const handleshowentire = () => {
        // Check if both step_by_step and rationale are empty
        const isStepByStepEmpty = !currentQuestion.step_by_step || JSON.parse(currentQuestion.step_by_step).length === 0;
        const isRationaleEmpty = !currentQuestion.rationale || JSON.parse(currentQuestion.rationale).length === 0;

        if (isStepByStepEmpty && isRationaleEmpty) {
            // Set exp to "S" to default to step_by_step slot
            setexp("S");

            // Initialize step_by_step as an empty array if needed
            setParsedStepByStep([]);
        }

        // Toggle the entire view
        setshowentire(!showentire);
    };

    // Function to handle the start of editing a line
    const handleEditLine = (line, lineIdx) => {
        setIsEditingLine(lineIdx);
        setEditedLine(line);
    };

    // Function to handle saving the edited line
    const handleSaveLine = (lineIdx) => {
        // Update the parsedStepByStep state with the new value
        const updatedStepByStep = [...parsedStepByStep];
        updatedStepByStep[selectedOptionIndex][lineIdx] = editedLine;

        // Update state and reset editing mode
        setParsedStepByStep(updatedStepByStep);
        setIsEditingLine(null);
        setEditedLine("");

        // Update the currentQuestion data to reflect the change
        const updatedQuestion = { ...currentQuestion };
        updatedQuestion.step_by_step = JSON.stringify(updatedStepByStep);

        setCurrentQuestion(updatedQuestion.step_by_step);
    };

    // Function to handle canceling the edit
    const handleCancelEdit = () => {
        setIsEditingLine(null);
        setEditedLine("");
    };


    useEffect(() => {
        if (currentQuestion.group_id) {
            //    fetchTestName(currentQuestion.group_id);
        } else {
            setg_content("");
        }

        // Check and parse tags safely
        try {
            const tags = currentQuestion.tags ? JSON.parse(currentQuestion.tags) : [];
            if (tags[0] === 'frq') {
                setfrq(true);
            }
        } catch (error) {
            console.error("Invalid JSON in currentQuestion.tags:", error);
        }

        // Check and parse step_by_step safely
        if (currentQuestion && currentQuestion.step_by_step) {
            let stepData = currentQuestion.step_by_step;

            try {
                const parsedData = JSON.parse(stepData);
                setParsedStepByStep(parsedData);
            } catch (error) {
                console.error("Failed to parse after escaping:", error);
                setParsedStepByStep([]);
            }
        } else {
            setParsedStepByStep([]);
        }

    }, [currentQuestion]);


    useEffect(() => {
        const script2 = document.createElement('script');
        script2.src = "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js";
        script2.integrity = "sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05";
        script2.crossOrigin = "anonymous";
        script2.defer = true;

        script2.onload = () => {
            if (boxItemLeftRef.current && window.renderMathInElement) {
                window.renderMathInElement(boxItemLeftRef.current, {
                    delimiters: [
                        { left: '$$', right: '$$', display: false },
                        { left: "$", right: "$", display: false },
                        { left: '\\(', right: '\\)', display: false },
                        { left: '\\[', right: '\\]', display: true },
                        { left: '\\begin{array}', right: '\\end{array}', display: true }
                    ],
                    throwOnError: false,
                });
            }
        };
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script2);
        };
    }, [currentQuestion, isEditing, processedContent, processedOptions]);

    useEffect(() => {
        if (explanationRef.current && window.renderMathInElement) {
            window.renderMathInElement(explanationRef.current, {
                delimiters: [
                    { left: '$$', right: '$$', display: false },
                    { left: "$", right: "$", display: false },
                    { left: '\\(', right: '\\)', display: false },
                    { left: '\\[', right: '\\]', display: true },
                    { left: '\\begin{array}', right: '\\end{array}', display: true }
                ],
                throwOnError: false,
            });
        }
    }, [selectedOptionIndex]);

    const parsedOptions = currentQuestion?.options ? JSON.parse(currentQuestion.options) : [];

    return (
        <div id="box-item-left" ref={boxItemLeftRef}>
            {currentQuestion && (
                <div className="flex">
                    <div className="w-[50%]">
                        <div className="ml-[20px] font-bold" dangerouslySetInnerHTML={{ __html: group.content }} />
                        <div className="ml-[20px]" dangerouslySetInnerHTML={{ __html: processedContent }} />
                    </div>
                    <div className="mt-1 w-[50%]">
                        {frq ? (
                            // Render each option individually as plain HTML buttons
                            parsedOptions.map((option, idx) => (
                                <button
                                    key={idx}
                                    className={`ml-[20px] mb-2 w-[95%] p-1 border border-1 ${selectedOptionIndex === idx ? "bg-gray-400 hover:bg-gray-500" : "bg-gray-100 hover:bg-gray-200"
                                        }`}
                                    dangerouslySetInnerHTML={{ __html: option }}
                                    onClick={() => setSelectedOptionIndex(idx)} // Set selected option index
                                />
                            ))
                        ) : (
                            // Otherwise, render using the Option component
                            processedOptions.map((option, idx) => (
                                <Option
                                    key={idx}
                                    handleCrossOutOptions={handleCrossOutOptions}
                                    currentQuestion={currentQuestion}
                                    idx={idx}
                                    handleSelectOption={handleSelectOption}
                                    showCrossOutMenu={showCrossOutMenu}
                                    crossedOutOptions={crossedOutOptions}
                                    data={option}
                                />
                            ))
                        )}
                        <div ref={explanationRef} className="bg-gray-100 border border-1 rounded-sm m-1 p-2">
                            <div className="flex">
                                <div className="underline mb-2">
                                    {exp === 'R' ? "Rationale" : "Step-by-Step"}
                                </div>
                                <button onClick={() => handleshowentire()} className="ml-2 underline bg-gray-400 rounded-md">Entire Code</button>
                            </div>
                            {selectedOptionIndex !== null && showentire === true && (
                                <>
                                    {parsedStepByStep[selectedOptionIndex] ? (
                                        <div className="ml-[20px] max-h-[300px] overflow-y-auto">
                                            {parsedStepByStep[selectedOptionIndex]
                                                .filter(line => line !== null) // Filter out null lines
                                                .map((line, lineIdx) => (
                                                    <div key={lineIdx}>
                                                        {isEditingLine === lineIdx ? (
                                                            // Editing mode: Render textarea with Save and Cancel buttons
                                                            <div>
                                                                <textarea
                                                                    className="w-full p-2 border"
                                                                    value={editedLine}
                                                                    onChange={(e) => setEditedLine(e.target.value)}
                                                                />
                                                                <div className="mt-1">
                                                                    <button
                                                                        className="bg-green-500 text-white px-2 py-1 mr-2"
                                                                        onClick={() => handleSaveLine(lineIdx)}
                                                                    >
                                                                        Save
                                                                    </button>
                                                                    <button
                                                                        className="bg-gray-500 text-white px-2 py-1"
                                                                        onClick={handleCancelEdit}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            // Normal mode: Render the line and allow clicking to edit
                                                            <div
                                                                className="cursor-pointer"
                                                                dangerouslySetInnerHTML={{ __html: line }}
                                                                onClick={() => handleEditLine(line, lineIdx)}
                                                            />
                                                        )}
                                                        <hr />
                                                    </div>
                                                ))}
                                        </div>
                                    ) : (
                                        <div className="ml-[20px] text-gray-500">
                                            No explanation available.
                                        </div>
                                    )}
                                </>
                            )}

                            {showentire === false && (
                                <>
                                    {exp === 'S' && parsedStepByStep[selectedOptionIndex] ? (
                                        <div>
                                            <textarea
                                                className="w-full p-2 border h-[250px]"
                                                value={editentire}
                                                onChange={(e) => setentire(e.target.value)}
                                            />
                                            <div className="mt-1">
                                                <button
                                                    className="bg-green-500 text-white px-2 py-1 mr-2"
                                                    onClick={() => { handlesaveentire(); setshowentire(true); }}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="bg-gray-500 text-white px-2 py-1"
                                                    onClick={() => { setentire(""); setshowentire(true); }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="ml-[20px] text-gray-500">
                                            No explanation available.
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {!frq && (
                <div className="ml-[50%]">Correct Answer: {currentQuestion.answer}</div>
            )}
        </div>
    );
}

export default DevQuestion;

const Option = ({ handleCrossOutOptions, currentQuestion, idx, handleSelectOption, showCrossOutMenu, crossedOutOptions, data }) => {
    return (
        <div className="flex flex-row gap-1">
            <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`}>
                <div className="flex flex-row items-center">
                    <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                <div className="flex flex-row items-center p-[2px]" dangerouslySetInnerHTML={{ __html: data }}>
                </div>
            </motion.div>
            {showCrossOutMenu &&
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) ? (
                            <p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>Undo</p>
                        ) : (
                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                                </div>
                            </>
                        )}
                    </div>
                </motion.div>
            }
        </div>
    )
}

function getOptionLetter(idx) {
    switch (idx) {
        case 0:
            return 'A';
        case 1:
            return 'B';
        case 2:
            return 'C';
        case 3:
            return 'D';
        case 4:
            return 'E';
        default:
            return 'A';
    }
}
