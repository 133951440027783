import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../../util";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { toast } from "react-hot-toast";
import { AiOutlineCheck, AiOutlineInfoCircle } from 'react-icons/ai';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import InfoIconWithTooltip from './../InfoIconWithTooltip';

const RandomACTReady = ({ setLoading }) => {

  const { user } = useContext(UserContext);
  const [selectedButton, setSelectedButton] = useState(null);
  const navigate = useNavigate();
  const [isTutorActive, setIsTutorActive] = useState(false);
  const [isTimedActive, setIsTimedActive] = useState(false);
  const [timerMinutes, setTimerMinutes] = useState(45); // Default time set to 45 minutes
  const [currentPassages, setCurrentPassages] = useState(0);
  const [min, setmin] = useState(0);

  const [totalq, settotalq] = useState([]);

  const [correct, setcorrect] = useState(0);
  const [incorrect, setincorrect] = useState(0);
  const [marked, setmarked] = useState(0);
  const [omitted, setomitted] = useState(0);
  const [englishCount, setEnglishCount] = useState([]);
  const [mathCount, setMathCount] = useState([]);
  const [readingCount, setReadingCount] = useState([]);
  const [scienceCount, setScienceCount] = useState([]);

  const [activeSegment, setActiveSegment] = useState('English');  // Default to 'All'
  const [displaySubjects, setDisplaySubjects] = useState([]);
  const [total, settotal] = useState(0);
  const [testname, setTestname] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16; // 4x4 grid

  const [activeFilters, setActiveFilters] = useState({
    unused: false,
    incorrect: false,
    marked: false,
    omitted: false,
    correct: false
  });

  const [testResults, setTestResults] = useState([]);

  const [esubjects, esetSubjects] = useState([
    {
      name: 'English', count: 0, isOpen: true, isSelected: false, tag: 'English',
      subTopics: [
        { name: 'Conventions of Standard English', code: 'CSE', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Production of Wirting', code: 'POW', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'knowledge of Language', code: 'KLA', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } }
      ]
    }
  ]);

  const [msubjects, msetSubjects] = useState([
    {
      name: 'Math', count: 0, isOpen: true, isSelected: false, tag: 'Math',
      subTopics: [
        { name: 'Intergrating Essential Skills', code: 'IES', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Algebra', code: 'A', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Statistics and Probability', code: 'S', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Geometry', code: 'G', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Number and Quantity', code: 'N', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Functions', code: 'F', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } }
      ]
    }
  ]);

  const [rsubjects, rsetSubjects] = useState([
    {
      name: 'Reading', count: 0, isOpen: true, isSelected: false, tag: 'Reading',
      subTopics: [
        { name: 'Craft and Structure', code: 'CS', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Key Ideas and Details', code: 'KID', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Integration of Knowledge and Ideas', code: 'IKI', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } }
      ]
    }
  ]);

  const [ssubjects, ssetSubjects] = useState([
    {
      name: 'Science', count: 0, isOpen: true, isSelected: false, tag: 'Science',
      subTopics: [
        { name: 'Scientific Investigation', code: 'SIN', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Evaluation Models, Inferences, and Exprimental Results', code: 'EMI', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } },
        { name: 'Interpretation of Data', code: 'IOD', count: 0, isSelected: false, toggle: function () { this.isSelected = !this.isSelected; } }
      ]
    }
  ]);

  useEffect(() => {
    getACTquiz();
  }, []);

  const extractLastThreeChars = (sortedArray) => {
    return sortedArray.map(item => item.test_name.slice(-3));
  };

  async function getACTquiz() {
    try {
      let res = await axios.get(BASELINE + "get/act/test", {});
      setTestname(res.data);
    } catch (error) {
      console.error("Error fetching ACT tests:", error);
      toast.error("Failed to load ACT test names.");
    }

    let data = {
      id: user.u_id
    };
    try {
      let res = await axios.post(BASELINE + "testresult/get", data);
      let datas = res.data;
      let sorted = datas.filter(item => item.type === 'ACT');
      const lastThreeCharsList = extractLastThreeChars(sorted);
      setTestResults(lastThreeCharsList);

    } catch (error) {
      console.error("Error fetching ACT tests:", error);
      toast.error("Failed to load ACT test names.");
    }
  }

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = testname.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(testname.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function processAllTestResults(testDataArray) {
    // Define the keys you expect to have arrays in JSON string format
    const keys = ['Correct_questionIds', 'Incorrect_questionIds', 'Marked_questionIds', 'Omitted_questionIds'];

    // Initialize objects to collect all IDs across all objects
    const allIds = {
      Correct_questionIds: [],
      Incorrect_questionIds: [],
      Marked_questionIds: [],
      Omitted_questionIds: []
    };

    let allQuestionIdsSet = new Set();  // Use Set to avoid duplicates

    // Process each test data object
    testDataArray.forEach(testData => {
      keys.forEach(key => {
        // Parse and extract the 'qid' from each object, add them to the Set
        const questionIds = JSON.parse(testData[key] || '[]').map(item => item.qid);
        questionIds.forEach(qid => allQuestionIdsSet.add(qid));  // Add qid to Set, which automatically prevents duplicates
      });
    });

    // Convert the Set back to an array
    let allQuestionIds = Array.from(allQuestionIdsSet);

    // Process each test data object in the array to collect all IDs for each key
    testDataArray.forEach(testData => {
      keys.forEach(key => {
        // Concatenate all IDs to respective keys
        allIds[key] = allIds[key].concat(JSON.parse(testData[key] || '[]'));
      });
    });

    // Function to remove duplicates based on qid for each key in allIds
    const removeDuplicatesByQid = (questionsArray) => {
      const uniqueQuestionsMap = new Map();
      questionsArray.forEach(question => {
        uniqueQuestionsMap.set(question.qid, question);  // Only keep the latest entry for each qid
      });
      return Array.from(uniqueQuestionsMap.values());
    };

    // Apply the deduplication function to all categories
    allIds.Correct_questionIds = removeDuplicatesByQid(allIds.Correct_questionIds);
    allIds.Incorrect_questionIds = removeDuplicatesByQid(allIds.Incorrect_questionIds);
    allIds.Marked_questionIds = removeDuplicatesByQid(allIds.Marked_questionIds);
    allIds.Omitted_questionIds = removeDuplicatesByQid(allIds.Omitted_questionIds);

    // Set the state for each category
    setcorrect(allIds.Correct_questionIds);
    setincorrect(allIds.Incorrect_questionIds);
    setmarked(allIds.Marked_questionIds);
    setomitted(allIds.Omitted_questionIds);

    return {
      allQuestionIds
    };
  }

  async function createtestoptions() {
    if (!user) {
      toast.error("Please login before starting the test");
      navigate("/login"); // Redirect user to the /login page
      return;
    }

    axios.get(`${BASELINE}tests/byuser/act/${user.u_id}`)
      .then(response => {
        const results = processAllTestResults(response.data);
        if (results) {
          axios.get(`${BASELINE}getquestionidsact`)
            .then(response => {
              const existingIds = new Set(results.allQuestionIds);
              const uniqueQuestions = response.data.filter(question => !existingIds.has(question.qid));
              settotalq(uniqueQuestions);
            })
            .catch(error => {
              console.error("Error fetching question IDs:", error);
            });
        }
      })
      .catch(error => {
        console.error("Error fetching user tests:", error);
      });

  }

  useEffect(() => {
    createtestoptions();
  }, []);

  useEffect(() => {
    let seenIds = new Set();  // To track seen question IDs to avoid duplicates


    const addItems = (items) => {
      items.forEach(item => {
        if (!seenIds.has(item.questionIds)) {  // Check if the question ID has already been added
          seenIds.add(item.questionIds);  // Mark this ID as seen
        }
      });
    };

    if (activeFilters.unused) {
      addItems(totalq);
    }

    if (activeFilters.correct) {
      addItems(correct);
    }

    if (activeFilters.incorrect) {
      addItems(incorrect);
    }

    if (activeFilters.marked) {
      addItems(marked);
    }

    if (activeFilters.omitted) {
      addItems(omitted);
    }

  }, [totalq, correct, incorrect, marked, omitted, activeFilters]);

  const toggleSubTopic = (subjectType, subjectIndex, subTopicIndex) => {
    let setSubjects;
    if (subjectType === "English") {
      setSubjects = esetSubjects;
    } else if (subjectType === "Math") {
      setSubjects = msetSubjects;
    } else if (subjectType === "Reading") {
      setSubjects = rsetSubjects;
    } else if (subjectType === "Science") {
      setSubjects = ssetSubjects;
    } else {
      setSubjects = []; // 기본값 설정 (필요 시)
    }

    setSubjects(prevSubjects => {
      return prevSubjects.map((subject, i) => {
        if (i === subjectIndex) {
          const updatedSubTopics = subject.subTopics.map((subTopic, stIndex) => {
            if (stIndex === subTopicIndex && subTopic.count !== 0) { // Only toggle if count is not zero
              return {
                ...subTopic,
                isSelected: !subTopic.isSelected
              };
            }
            return subTopic;
          });
          // Determine if any subtopic is selected
          const anySubSelected = updatedSubTopics.some(subTopic => subTopic.isSelected);
          return {
            ...subject,
            subTopics: updatedSubTopics,
            isSelected: anySubSelected // Set main subject's isSelected based on subtopics
          };
        }
        return subject;
      });
    });
  };

  const toggleSubjectOpen = (index) => {
    let newSubjects;

    switch (activeSegment) {
      case 'English':
        newSubjects = esubjects.map((subject, i) => {
          if (i === index) {
            return { ...subject, isOpen: !subject.isOpen };
          }
          return subject;
        });
        esetSubjects(newSubjects);
        break;
      case 'Math':
        newSubjects = msubjects.map((subject, i) => {
          if (i === index) {
            return { ...subject, isOpen: !subject.isOpen };
          }
          return subject;
        });
        msetSubjects(newSubjects);
        break;
      case 'Reading':
        newSubjects = rsubjects.map((subject, i) => {
          if (i === index) {
            return { ...subject, isOpen: !subject.isOpen };
          }
          return subject;
        });
        rsetSubjects(newSubjects);
        break;
      case 'Science':
        newSubjects = ssubjects.map((subject, i) => {
          if (i === index) {
            return { ...subject, isOpen: !subject.isOpen };
          }
          return subject;
        });
        ssetSubjects(newSubjects);
        break;
      default:
        console.error("Unknown active segment:", activeSegment);
    }
  };

  const toggleSubjectSelect = (index) => {
    let newSubjects;

    switch (activeSegment) {
      case 'English':
        newSubjects = esubjects.map((subject, i) => {
          if (i === index) {
            const newIsSelected = !subject.isSelected;
            const updatedSubTopics = subject.subTopics.map(subTopic => ({
              ...subTopic,
              isSelected: newIsSelected ? subTopic.count > 0 : false
            }));
            return {
              ...subject,
              isSelected: newIsSelected,
              subTopics: updatedSubTopics
            };
          }
          return subject;
        });
        esetSubjects(newSubjects);
        break;
      case 'Math':
        newSubjects = msubjects.map((subject, i) => {
          if (i === index) {
            const newIsSelected = !subject.isSelected;
            const updatedSubTopics = subject.subTopics.map(subTopic => ({
              ...subTopic,
              isSelected: newIsSelected ? subTopic.count > 0 : false
            }));
            return {
              ...subject,
              isSelected: newIsSelected,
              subTopics: updatedSubTopics
            };
          }
          return subject;
        });
        msetSubjects(newSubjects);
        break;
      case 'Reading':
        newSubjects = rsubjects.map((subject, i) => {
          if (i === index) {
            const newIsSelected = !subject.isSelected;
            const updatedSubTopics = subject.subTopics.map(subTopic => ({
              ...subTopic,
              isSelected: newIsSelected ? subTopic.count > 0 : false
            }));
            return {
              ...subject,
              isSelected: newIsSelected,
              subTopics: updatedSubTopics
            };
          }
          return subject;
        });
        rsetSubjects(newSubjects);
        break;
      case 'Science':
        newSubjects = ssubjects.map((subject, i) => {
          if (i === index) {
            const newIsSelected = !subject.isSelected;
            const updatedSubTopics = subject.subTopics.map(subTopic => ({
              ...subTopic,
              isSelected: newIsSelected ? subTopic.count > 0 : false
            }));
            return {
              ...subject,
              isSelected: newIsSelected,
              subTopics: updatedSubTopics
            };
          }
          return subject;
        });
        ssetSubjects(newSubjects);
        break;
      default:
        console.error("Unknown active segment:", activeSegment);
    }
  };

  const toggleFilter = (filter) => {
    setActiveFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter]
    }));
  };

  useEffect(() => {
    if (user && selectedButton != null) {
      if (selectedButton == null) {
        toast.error('Please select an ACT module before starting the quiz.');
      } else {
        if (user) {
          navigate(`/act/preset/quiz/${selectedButton}`);
        } else {
          toast.error('Please login to start the quiz.');
        }
      }
    }
  }, [selectedButton]);

  useEffect(() => {
    let combinedQuestions = []; // This will hold the combined questions from selected difficulties
    let seenIds = new Set(); // To track seen question IDs to avoid duplicates
    let engQuestions = []; // To hold English questions
    let mathQuestions = []; // To hold Math questions
    let readingQuestions = []; // To hold Math questions
    let scienceQuestions = []; // To hold Math questions

    const addItems = (items) => {
      items.forEach(item => {
        if (!seenIds.has(item.qid)) { // Check if the question ID has already been added
          seenIds.add(item.qid); // Mark this ID as seen
          combinedQuestions.push(item); // Push the item into the combined array
        }
      });
    };
    if (activeFilters.unused) {
      addItems(totalq);
    }
    if (activeFilters.correct) {
      addItems(correct);
    }
    if (activeFilters.omitted) {
      addItems(omitted);
    }
    if (activeFilters.incorrect) {
      addItems(incorrect);
    }

    // Split combined questions into 'eng' and 'math'
    combinedQuestions.forEach(question => {
      if (question.section === 'english') {
        engQuestions.push(question);
      } else if (question.section === 'math') {
        mathQuestions.push(question);
      } else if (question.section === 'reading') {
        readingQuestions.push(question);
      } else if (question.section === 'science') {
        scienceQuestions.push(question);
      }
    });

    setEnglishCount(engQuestions);
    setMathCount(mathQuestions);
    setReadingCount(readingQuestions);
    setScienceCount(scienceQuestions);

  }, [activeFilters]);

  const trimCategory = (category) => {
    if (typeof category === 'string') {
      return category.slice(2, -2);
    }
    // Return the original category if it's too short or not a string
    return category;
  };

  useEffect(() => {
    if (activeSegment === 'English') {
      setDisplaySubjects(esubjects);
    } else if (activeSegment === 'Math') {
      setDisplaySubjects(msubjects);
    } else if (activeSegment === 'Reading') {
      setDisplaySubjects(rsubjects);
    } else if (activeSegment === 'Science') {
      setDisplaySubjects(ssubjects);
    }
  }, [activeSegment, esubjects, msubjects, rsubjects, ssubjects]);

  useEffect(() => {
    const updatedSubjects2 = esubjects.map(subject => ({
      ...subject,
      count: 0,  // Reset subject count to 0 initially
      isSelected: false,
      subTopics: subject.subTopics.map(sub => ({
        ...sub,
        count: 0, // Reset subtopic count to 0
        isSelected: false
      }))
    }));

    const updatedSubjects = msubjects.map(subject => ({
      ...subject,
      count: 0,  // Reset subject count to 0 initially
      isSelected: false,
      subTopics: subject.subTopics.map(sub => ({
        ...sub,
        count: 0,  // Reset subtopic count to 0
        isSelected: false,
      }))
    }));

    const updatedSubjects3 = rsubjects.map(subject => ({
      ...subject,
      count: 0,  // Reset subject count to 0 initially
      isSelected: false,
      subTopics: subject.subTopics.map(sub => ({
        ...sub,
        count: 0,  // Reset subtopic count to 0
        isSelected: false,
      }))
    }));

    const updatedSubjects4 = ssubjects.map(subject => ({
      ...subject,
      count: 0,  // Reset subject count to 0 initially
      isSelected: false,
      subTopics: subject.subTopics.map(sub => ({
        ...sub,
        count: 0,  // Reset subtopic count to 0
        isSelected: false,
      }))
    }));

    mathCount.forEach(question => {
      updatedSubjects.forEach(subject => {
        let subjectCount = 0;
        subject.subTopics.forEach(subTopic => {
          if (subTopic.code === trimCategory(question.category)) {
            subTopic.count += 1; // Increment the count if the topics match
            subTopic.isSelected = true; // Update isSelected based on conditions
            subjectCount += 1;
          }
          if (subTopic.count === 0) {
            subTopic.isSelected = false;
          }
        });
        subject.count += subjectCount;
        if (subject.count > 0) {
          subject.isSelected = true; // Set the subject to selected if any subTopics are selected
        }
      });
    });
    msetSubjects(updatedSubjects);

    englishCount.forEach(question => {
      updatedSubjects2.forEach(subject => {
        let subjectCount = 0;
        subject.subTopics.forEach(subTopic => {
          if (subTopic.code === question.category) {
            subTopic.count += 1; // Increment the count if the topics match
            subTopic.isSelected = true;
            subjectCount += 1;
          }
          if (subTopic.count === 0) {
            subTopic.isSelected = false;
          }
        });
        subject.count += subjectCount;

        if (subject.count > 0) {
          subject.isSelected = true;
        }
      });
    });
    esetSubjects(updatedSubjects2);

    readingCount.forEach(question => {
      updatedSubjects3.forEach(subject => {
        let subjectCount = 0;
        subject.subTopics.forEach(subTopic => {
          if (subTopic.code === question.category) {
            subTopic.count += 1;
            subTopic.isSelected = true;
            subjectCount += 1;
          }
          if (subTopic.count === 0) {
            subTopic.isSelected = false;
          }
        });
        subject.count += subjectCount;

        if (subject.count > 0) {
          subject.isSelected = true;
        }
      });
    });
    rsetSubjects(updatedSubjects3);

    scienceCount.forEach(question => {
      updatedSubjects4.forEach(subject => {
        let subjectCount = 0;
        subject.subTopics.forEach(subTopic => {
          if (subTopic.code === question.category) {
            subTopic.count += 1;
            subTopic.isSelected = true;
            subjectCount += 1;
          }
          if (subTopic.count === 0) {
            subTopic.isSelected = false;
          }
        });
        subject.count += subjectCount;

        if (subject.count > 0) {
          subject.isSelected = true;
        }
      });
    });
    ssetSubjects(updatedSubjects4);

  }, [englishCount, mathCount, readingCount, scienceCount]);  // Depend on mathCount to trigger this effect

  useEffect(() => {
    if (activeSegment === 'English') {
      let total = 0;
      let min = 0;
      esubjects.forEach(subject => {
        let alert = 0;
        subject.subTopics.forEach(subTopic => {
          if (subTopic.isSelected === false) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });
      setmin(min);
      settotal(total);
    }

    if (activeSegment === 'Math') {
      let total = 0;
      let min = 0;
      msubjects.forEach(subject => {
        let alert = 0;
        subject.subTopics.forEach(subTopic => {
          if (!subTopic.isSelected) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });
      settotal(total);  // Assume you have a separate state for math total
      setmin(min);      // Assume you have a separate state for math minimum
    }

    if (activeSegment === 'Reading') {
      let total = 0;
      let min = 0;
      rsubjects.forEach(subject => {
        let alert = 0;
        subject.subTopics.forEach(subTopic => {
          if (!subTopic.isSelected) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });
      settotal(total);  // Assume you have a separate state for math total
      setmin(min);      // Assume you have a separate state for math minimum
    }

    if (activeSegment === 'Science') {
      let total = 0;
      let min = 0;
      ssubjects.forEach(subject => {
        let alert = 0;
        subject.subTopics.forEach(subTopic => {
          if (!subTopic.isSelected) {
            alert = 1;
          } else {
            total += subTopic.count;
            min += 1;
          }
        });
        if (alert === 1) {
          subject.isSelected = false;
        } else {
          subject.isSelected = true;
        }
      });
      settotal(total);  // Assume you have a separate state for math total
      setmin(min);      // Assume you have a separate state for math minimum
    }

  }, [activeSegment, msubjects, esubjects, rsubjects, ssubjects])

  const handleGenerateTest = () => {
    // Step 1: Validate the number of passages
    if (currentPassages < min) {
      toast.error("Number of passages exceeds the minimum allowed.");
      return;
    }

    if (currentPassages > total) {
      toast.error("Number of passages exceeds the maximum allowed.");
      return;
    }

    // Step 2: Combine English and Math questions based on the active segment
    let combinedQuestions = [];
    if (activeSegment === 'English') {
      combinedQuestions = englishCount;
    } else if (activeSegment === 'Math') {
      combinedQuestions = mathCount;
    } else if (activeSegment === 'Reading') {
      combinedQuestions = readingCount;
    } else if (activeSegment === 'Science') {
      combinedQuestions = scienceCount;
    }

    // Step 3: Apply filters based on difficulty
    const difficultyFilteredQuestions = combinedQuestions;

    // Step 4: Ensure at least one subject is selected and matches the selected topics
    let questionsToUse = [];
    const topicsSelected = displaySubjects.filter(subject => subject.isSelected)
      .flatMap(subject => subject.subTopics.filter(st => st.isSelected && st.count > 0));

    // Step 5: Select questions based on selected topics
    topicsSelected.forEach(topic => {
      const questionsForTopic = difficultyFilteredQuestions.filter(question => question.category === topic.code);
      questionsForTopic.forEach(q => {
        if (questionsToUse.length < currentPassages && !questionsToUse.includes(q.qid)) {
          questionsToUse.push(q.qid);
        }
      });
    });

    // Add random questions if needed to meet the passage count
    while (questionsToUse.length < currentPassages) {
      const additionalQuestion = difficultyFilteredQuestions[Math.floor(Math.random() * difficultyFilteredQuestions.length)];
      if (!questionsToUse.includes(additionalQuestion.qid)) {
        questionsToUse.push(additionalQuestion.qid);
      }
    }

    // Prepare data for navigation
    const quizData = {
      questionIds: questionsToUse,
      tutorModeActive: isTutorActive,
      timedModeActive: isTimedActive,
      timeLimit: isTimedActive ? timerMinutes : null,
      tag: activeSegment,
      type: "ACT",
    };

    // Navigate to quiz page with quiz data
    navigate(`/act/quiz/`, { state: { data: quizData } });
  };

  return (<>
    <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
      <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">Create Digital ACT</span>
    </div>
    <h1 className="bg-gray-100 border-[1px] border-gray-300 m-4 p-1 pl-6 text-xl font-bold mt-4">Quick Digital ACT</h1>

    <section className="wrapper mb-6">
      <div className="w-[100%] flex justify-start items-center flex-col px-5 bg-white rounded-2xl">
        <div className="flex flex-row flex-wrap justify-center gap-2">
          {currentItems.map((test, index) => (
            <button
              key={index}
              onClick={() => setSelectedButton(test.exam_code)}
              className={`border border-black border-solid text-black py-2 px-4 rounded 
      ${selectedButton === test.exam_code
                  ? 'bg-gray-400'
                  : testResults.includes(test.exam_code)
                    ? 'bg-gray-400 hover:bg-gray-300' // Darker background if exam_code matches testResults
                    : 'bg-white hover:bg-gray-300'
                }`}
            >
              {`ACT ${test.exam_code} Test`}
            </button>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`mx-1 px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-gray-500 text-white' : 'bg-gray-300 text-black'}`}
            >
              {i + 1}
            </button>
          ))}
        </div>

      </div>
    </section>

    <h1 className="bg-gray-100 border-[1px] border-gray-300 m-4 p-1 pl-6 text-xl font-bold mt-4">Personalize ACT Test</h1>

    <div className="bg-white pb-6 pl-6 pt-2 rounded-lg">
      {/* Test Mode Section */}
      <div className="text-gray-800 text-lg mb-2 font-semibold">Test Mode
      </div>

      <div className="flex items-center justify-between mb-4">

        <div className="flex justify-between items-center space-x-4 mb-2">
          <ToggleSwitch
            label="Tutor"
            isEnabled={isTutorActive}
            toggleSwitch={() => setIsTutorActive(!isTutorActive)}
            desc="Tutor Mode: Shows correct answer and explanation when the user chooses wrong."
          />
          <div className="flex items-center">
            <ToggleSwitch
              label="Timed"
              isEnabled={isTimedActive}
              toggleSwitch={() => setIsTimedActive(!isTimedActive)}
              desc="Quiz Timer: Can set a time limit for quizzes."
            />
            {isTimedActive && (
              <div className="ml-4">
                <input
                  type="number"
                  value={timerMinutes}
                  onChange={(e) => setTimerMinutes(e.target.value)}
                  className="w-20 pl-2 pr-1 border rounded text-gray-800"
                  min="1"
                />
                <span className="ml-1 text-gray-700">min</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Question Mode Section */}
      <div className="mb-4">
        <div className="text-gray-800 text-lg font-semibold mb-1">Question Mode</div>
        <div className="mt-2 flex space-x-4">
          <FilterButton
            label="Unused"
            count={totalq.length}
            isActive={activeFilters.unused}
            toggleFilter={() => toggleFilter('unused')}
          />
          <FilterButton
            label="Correct"
            count={correct.length}
            isActive={activeFilters.correct}
            toggleFilter={() => toggleFilter('correct')}
          />
          <FilterButton
            label="Incorrect"
            count={incorrect.length}
            isActive={activeFilters.incorrect}
            toggleFilter={() => toggleFilter('incorrect')}
          />
          <FilterButton
            label="Marked"
            count={marked.length}
            isActive={activeFilters.marked}
            toggleFilter={() => toggleFilter('marked')}
          />
          <FilterButton
            label="Omitted"
            count={omitted.length}
            isActive={activeFilters.omitted}
            toggleFilter={() => toggleFilter('omitted')}
          />
        </div>
      </div>

      {/* Subjects */}
      <div className="mb-4 mt-8">
        <div className="flex">
          <label className="text-gray-800 text-lg font-semibold mb-1 block">Subjects</label>
          <div className="flex border border-slate-300 rounded-xl overflow-hidden mt-[-5px] mb-[20px] ml-[20px]">
            {['English', 'Math', 'Reading', 'Science'].map(segment => {
              // Get the appropriate count for each segment directly from the arrays
              let count = 0;
              if (segment === 'English') {
                count = englishCount.length; // Directly use the length of engQuestions
              } else if (segment === 'Math') {
                count = mathCount.length; // Directly use the length of mathQuestions
              } else if (segment === 'Reading') {
                count = readingCount.length; // Directly use the length of mathQuestions
              } else if (segment === 'Science') {
                count = scienceCount.length; // Directly use the length of mathQuestions
              }

              return (
                <button
                  key={segment}
                  onClick={() => setActiveSegment(segment)}
                  className={`flex-2 py-2 px-4 text-center transition-colors duration-300
                    ${activeSegment === segment ? 'bg-slate-700 text-white' : 'bg-white text-gray-800 hover:bg-gray-200'}`}
                >
                  {`${segment} (${count})`}
                </button>
              );
            })}
          </div>
        </div>

        <div className="bg-white flex flex-row flex-wrap sm:flex-nowrap items-center justify-center ml-[10%] mr-[10%]">
          {displaySubjects.map((subject, index) => (
            <Subject
              key={`${subject.name}-${subject.tag}`}  // Ensures unique key by combining name and tag
              name={subject.name}
              count={subject.count}
              isOpen={subject.isOpen}
              isSelected={subject.isSelected}
              toggleOpen={() => toggleSubjectOpen(index)}    // Pass toggleOpen
              toggleSelect={() => toggleSubjectSelect(index)} // Pass toggleSelect
              subTopics={subject.subTopics.map((subTopic, subIndex) => ({
                ...subTopic,
                toggle: () => toggleSubTopic(subject.tag, index, subIndex)
              }))}
            />
          ))}
        </div>

      </div>


      <div className="mt-8 mb-4">
        <label className="text-lg font-semibold mb-1 block">No. of Passages</label>
        <div className="flex flex-wrap">

          <div className="flex items-center space-x-4">
            <input
              type="number"
              value={currentPassages}
              onChange={(e) => setCurrentPassages(e.target.value)}
              className="w-24 bg-gray-300 border-gray-300 border text-center p-2"
              min={min}
            />

            <span>Max allowed per test</span>
            <input
              type="number"
              value={total}
              style={{ minWidth: '50px', width: 'auto' }} // Same here for the second input
              readOnly
            />
          </div>


        </div>
      </div>

      <div className="mt-8 space-y-4">
        <div className="text-lg font-bold">Generate Your Test</div>
        <GenerateButton onClick={handleGenerateTest} />
      </div>

    </div>
  </>);

};

const GenerateButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded inline-flex items-center justify-center space-x-2"
    >
      <span>GENERATE TEST</span>
      <AiOutlineInfoCircle />
    </button>
  );
};

const SubTopic = ({ name, count, isSelected, toggle }) => {
  return (
    <div className="flex justify-between items-center p-1">
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={toggle}  // Ensure this is the only handler for onChange
          className="mr-2 cursor-pointer"
        />
        <span>{name}</span>
      </div>
      <span>{`${count}`}</span>
    </div>
  );
};

const Subject = ({ name, count, isOpen, isSelected, toggleOpen, toggleSelect, subTopics }) => {
  return (
    <div className="mb-2 border-b w-[98%] h-[250px] border border-gray-200 p-2 rounded-xl">
      <div className="flex justify-between items-center p-2">
        <div className="flex items-center space-x-2">
          {/* Toggle Open/Close Button */}
          <button
            onClick={toggleOpen}
            className="focus:outline-none"
            aria-label={isOpen ? "Collapse Subject" : "Expand Subject"}
            aria-expanded={isOpen}
          >
            {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}
          </button>

          {/* Selection Checkbox */}
          <input
            type="checkbox"
            checked={isSelected}
            onChange={toggleSelect}
            className="mr-2 cursor-pointer"
            aria-label={`Select ${name}`}
          />

          {/* Clickable Subject Name to Toggle Open/Close */}
          <span
            onClick={toggleOpen}
            className="cursor-pointer"
            role="button"
            tabIndex="0"
            onKeyPress={(e) => { if (e.key === 'Enter') toggleOpen(); }}
          >
            {name}
          </span>
        </div>
        <span>{`${count}`}</span>
      </div>

      {/* Subtopics List */}
      {isOpen && (
        <div className="h-[150px] overflow-y-auto">
          {subTopics.map((subTopic, index) => (
            <SubTopic
              key={`${subTopic.name}-${index}`} // Use a unique key if possible
              name={subTopic.name}
              count={subTopic.count}
              isSelected={subTopic.isSelected}
              toggle={() => subTopic.toggle(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ToggleSwitch = ({ label, isEnabled, toggleSwitch, desc }) => {
  return (
    <div className="flex items-center">
      <span className="text-gray-700 mr-[-8px]">{label}</span>
      <InfoIconWithTooltip tooltipText={desc} />

      <div
        onClick={toggleSwitch}
        className={`ml-2 w-12 h-6 flex items-center bg-gray-200 rounded-full p-1 duration-300 ease-in-out ${isEnabled ? 'bg-slate-700 justify-end' : 'bg-gray-300 justify-start'
          }`}
      >
        <div
          className="bg-white w-4 h-4 rounded-full transform duration-300 ease-in-out"
        />
      </div>
    </div>
  );
};

const FilterButton = ({ label, count, isActive, toggleFilter }) => {
  return (
    <button
      disabled={count === 0} // Disable button if count is 0
      className={`flex items-center space-x-2 border px-3 py-2 rounded-full ${isActive ? 'bg-slate-700 text-white' : 'bg-white text-gray-800'} ${count === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-300'}`}
      onClick={count > 0 ? toggleFilter : undefined} // Prevent function call if count is 0
    >
      {isActive && <AiOutlineCheck className="text-lg" />}
      <span>{label}</span>
      <div className="flex items-center space-x-1">
        <span>{count}</span>
      </div>
    </button>
  );
};


export default RandomACTReady;
