// Message.js
import React, { useState, useEffect, useRef } from 'react';
import useAIAudio from './aivoice';
import 'katex/dist/katex.min.css';
import Latex from "react-latex-next";

const Message = ({ text, voiceOptions, indexs, onGetMoreHelp, type, showGetMoreHelp }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasAutoPlayed, setHasAutoPlayed] = useState(false); // To track auto-play
    const audioRef = useRef(null);

    // Always call useAIAudio
    const audioUrl = useAIAudio(text, voiceOptions, indexs);

    useEffect(() => {
        console.log("-")
        if (audioUrl && indexs === 0 && !hasAutoPlayed) {
            // Auto-play logic
            audioRef.current = new Audio(audioUrl);
            audioRef.current
                .play()
                .then(() => {
                    setIsPlaying(true);
                    setHasAutoPlayed(true);
                    audioRef.current.onended = () => {
                        setIsPlaying(false);
                    };
                })
                .catch((error) => {
                    console.error('Error auto-playing audio:', error);
                });
        }
    }, [audioUrl, indexs, hasAutoPlayed]);

    // Cleanup audio on unmount
    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = '';
                audioRef.current = null;
            }
        };
    }, []);

    // Play Audio button handler for indexs === -1 or -2
    const handlePlay = () => {
        // Stop and reset any currently playing audio
        if (audioRef.current) {
            audioRef.current.pause(); // Stop the current audio
            audioRef.current.currentTime = 0; // Reset to the beginning
        }
    
        // Play the new audio
        if (audioUrl) {
            audioRef.current = new Audio(audioUrl); // Create a new Audio instance
            audioRef.current
                .play()
                .then(() => {
                    setIsPlaying(true);
                    audioRef.current.onended = () => {
                        setIsPlaying(false);
                    };
                })
                .catch((error) => {
                    console.error('Error playing audio:', error);
                });
        }
    };    

    let messageStyle = '';
    if (type === 'userQuestion') {
        messageStyle = 'ml-6 border-gray-400 bg-gray-100';
    } else if (type === undefined && indexs === -1) {
        messageStyle = 'border-green-400 bg-green-100';
    } else if (type === undefined && indexs === -2) {
        messageStyle = 'border-red-400 bg-red-100';
    } else {
        messageStyle = 'mr-6 border-blue-400 bg-blue-100';
    }

    return (
        <div
            className={`m-2 p-4 rounded-md border text-sm flex items-start ${messageStyle}`}
        >
            {/* AI Icon */}
            <div className="mr-3">
                <svg
                    className={`w-6 h-6 ${indexs === -1
                        ? 'text-green-400'
                        : indexs === -2
                            ? 'text-red-400'
                            : 'text-blue-400'
                        }`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path d="M10 2a8 8 0 00-8 8v4a2 2 0 002 2h4v-4H6v-2a4 4 0 118 0v2h-2v4h4a2 2 0 002-2v-4a8 8 0 00-8-8z" />
                </svg>
            </div>
            {/* Message Content */}
            <div className="flex-1">
                <div>
                    <Latex>{text}</Latex>
                </div>

                {/* Button container */}
                <div className="mt-2 flex items-center justify-between">
                    {/* Play Audio Button */}
                    {(indexs === 0) && (
                        <button
                            onClick={handlePlay}
                            disabled={isPlaying || !audioUrl}
                            className={`${indexs === -1
                                ? 'text-green-600'
                                : indexs === -2
                                    ? 'text-red-600'
                                    : 'text-blue-600'
                                } hover:underline mr-4`}
                        >
                            {isPlaying ? 'Playing...' : 'Play Audio'}
                        </button>
                    )}

                    {/* Get More Help Button */}
                    {showGetMoreHelp && (
                        <button
                            onClick={onGetMoreHelp}
                            className="text-blue-600 hover:underline mr-4"
                        >
                            Get More Help
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Message;
