import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASELINE } from "../../util";
import { FiMenu } from "react-icons/fi";

const NavBar = ({
    index,
    questions,
    code,
    topics,
    currentQuestion,
    testIdOptions,
    handleUpdate,
    qidOptions,
    handleTestIdChange,
    handleNext,
    handleBack,
    handleOptionClick,
    handleNext2,
    handleBack2,
    setQuestions,
    tags,
    setTags,
    handleupdatetag,
    setcheck_tags
}) => {
    const navigate = useNavigate();
    const [selectedTestId, setselectedTestId] = useState("");
    const [subject, setSubject] = useState([]);
    const [qid, setQid] = useState(qidOptions);
    const [flag, setflag] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [list, setlist] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [isModalOpen4, setIsModalOpen4] = useState(false);
    const [isModalOpen5, setIsModalOpen5] = useState(false);
    const [isModalOpen6, setIsModalOpen6] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isimgComplete, setIsImgComplete] = useState(false);
    const [isModalOpen7, setIsModalOpen7] = useState(false);
    const [isModalOpen8, setIsModalOpen8] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const toggleModal = () => { setIsMenuOpen(false); setIsModalOpen(!isModalOpen); }
    const toggleModal2 = () => { setIsMenuOpen(false); setIsModalOpen2(!isModalOpen2); }
    const toggleModal3 = () => { setIsMenuOpen(false); setIsModalOpen3(!isModalOpen3); }
    const toggleModal4 = () => { setIsMenuOpen(false); setIsModalOpen4(!isModalOpen4); }
    const toggleModal5 = () => { setIsMenuOpen(false); setIsModalOpen5(!isModalOpen5); }
    const toggleModal6 = () => { setIsMenuOpen(false); setIsModalOpen6(!isModalOpen6); }
    const toggleModal7 = () => { setIsMenuOpen(false); setIsModalOpen7(!isModalOpen7); }
    const toggleModal8 = () => { setIsMenuOpen(false); setIsModalOpen8(!isModalOpen8); }

    useEffect(() => {
        if (currentQuestion && currentQuestion.tags) {
            try {
                const tags = JSON.parse(currentQuestion.tags);
                if (Array.isArray(tags) && tags.includes('img_complete')) {
                    setIsImgComplete(true);
                } else {
                    setIsImgComplete(false);
                }

            } catch (error) {
                console.error('Error parsing tags:', error);
                setIsImgComplete(false);
            }
        }
    }, [currentQuestion]);

    const handleItemClick = (id) => {
        setQid(id);
        const closestExam = findClosestExam(id);
        setselectedTestId(closestExam);
        handleTestIdChange(closestExam.exam);
        navigate(`/devapcalc/${id}`);
    };

    const toggleimagecomplete = async () => {
        setcheck_tags(true);
        const action = isimgComplete ? 'remove' : 'add';
        try {
            await axios.post(BASELINE + "update/complete/img", {
                question_id: qid,
                action,
            });
            setIsImgComplete(!isimgComplete);
            addTag('img_complete');
        } catch (err) {
            console.error(err);
        }
    };

    const toggleComplete = async () => {
        setcheck_tags(true);
        const action = isComplete ? 'remove' : 'add';
        const closestExam = findClosestExam(qid);
        try {
            await axios.post(BASELINE + "update/complete", {
                exam: closestExam.exam,
                action,
            });
            setIsComplete(!isComplete);
            addTag('complete');
        } catch (err) {
            console.error(err);
        }
    };

    const handlefrq = async () => {
        try {
            let res = await axios.get(BASELINE + "get/ab/frq/exam");
            setlist(res.data.exams);
            toggleModal6();
        } catch (err) {
            console.error(err);
        }
    };

    const handlemcq = async () => {
        try {
            let res = await axios.get(BASELINE + "get/ab/mcq/exam");
            setlist(res.data.exams);
            toggleModal5();
        } catch (err) {
            console.error(err);
        }
    };

    const handleflag = async () => {
        try {
            let res = await axios.get(BASELINE + "getflags");
            setlist(res.data.flagged_questions);
            toggleModal4();
        } catch (err) {
            console.error(err);
        }
    };

    const handlenornc = async () => {
        try {
            let res = await axios.get(BASELINE + "nocornc");
            setlist(res.data.no_cor_nc_questions);
            toggleModal3();
        } catch (err) {
            console.error(err);
        }
    };

    const handlemathpix = async () => {
        try {
            let res = await axios.get(BASELINE + "get/mathpix");
            setlist(res.data.question_ids);
            toggleModal7();
        } catch (err) {
            console.error(err);
        }
    };

    const handlefindemptyanswer = async () => {
        try {
            let res = await axios.get(BASELINE + "noanswer");
            setlist(res.data.no_answer_questions);
            toggleModal2();
        } catch (err) {
            console.error(err);
        }
    };

    const handleFind2DArray = async () => {
        try {
            let res = await axios.get(BASELINE + "find2darr");
            setlist(res.data.single_arr);
            toggleModal();
        } catch (err) {
            console.error(err);
        }
    };

    const handlenoanswer = async () => {
        try {
            let res = await axios.get(BASELINE + "get/nocalcanswer");
            setlist(res.data.question_ids);
            toggleModal8();
        } catch (err) {
            console.error(err);
        }
    };

    const addTag = (tag) => {
        let updatedTags;
        if (tags.includes(tag)) {
            updatedTags = tags.filter((t) => t !== tag);
        } else { updatedTags = [...tags, tag]; }
        setTags(updatedTags);
        setcheck_tags(true);
    };

    const handleEdit = (e) => {
        const newTags = e.target.innerText
            .split(',')
            .map((tag) => tag.trim())
            .filter((tag) => tag.length > 0);
        setTags(newTags);
    };

    useEffect(() => {
        if (currentQuestion && currentQuestion.tags.length > 2) {
            setTags(JSON.parse(currentQuestion.tags));
        }
    }, [currentQuestion]);

    const fetchTestName = async (q_id) => {
        try {
            let res = await axios.get(BASELINE + "get/apcalc/testname", {
                params: {
                    q_id: q_id,
                },
            });
            setSubject(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchTestName({ exam: "AP Art History 2008" });
    }, []);

    useEffect(() => {
        if (selectedTestId) {
            fetchTestName(selectedTestId);
        }
    }, [selectedTestId]);

    const findClosestExam = (qid) => {
        let closest = testIdOptions[0];
        for (let i = 0; i < testIdOptions.length; i++) {
            if (testIdOptions[i].question_id <= qid) {
                closest = testIdOptions[i];
            } else {
                break;
            }
        }
        return closest;
    };

    const handleQidChange = (e) => {
        const newQid = parseInt(e.target.value, 10);
        setQid(newQid);

        const closestExam = findClosestExam(newQid);
        setselectedTestId(closestExam);
        handleTestIdChange(closestExam.exam);
        navigate(`/devapcalc/${newQid}`);
    };

    const handleNextQuestion = () => {
        const newQid = parseInt(qid, 10) + 1;
        setQid(newQid);

        const closestExam = findClosestExam(newQid);
        setselectedTestId(closestExam);
        handleTestIdChange(closestExam.exam);
        navigate(`/devapcalc/${newQid}`);
    };

    const handlePreviousQuestion = () => {
        const newQid = parseInt(qid, 10) - 1;
        setQid(newQid);

        const closestExam = findClosestExam(newQid);
        setselectedTestId(closestExam);
        handleTestIdChange(closestExam.exam);
        navigate(`/devapcalc/${newQid}`);
    };

    const handleExamChange = (e) => {
        const selectedTest = testIdOptions[e.target.selectedIndex];
        setselectedTestId(selectedTest);
        setQid(selectedTest.question_id);
        handleTestIdChange(selectedTest.exam);
        navigate(`/devapcalc/${selectedTest.question_id}`);
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            const isCtrlOrCmd = e.ctrlKey || e.metaKey;

            if (!isCtrlOrCmd) return;

            switch (e.key.toLowerCase()) {
                case 'z':
                    e.preventDefault();
                    toggleComplete();
                    break;
                case 'x':
                    e.preventDefault();
                    toggleimagecomplete();
                    break;
                case 'a':
                    e.preventDefault();
                    addTag('NC');
                    break;
                case 's':
                    e.preventDefault();
                    addTag('C');
                    break;
                case 'd':
                    e.preventDefault();
                    addTag('flag');
                    break;
                case 'arrowright':
                    e.preventDefault();
                    handleNextQuestion();
                    break;
                case 'arrowleft':
                    e.preventDefault();
                    handlePreviousQuestion();
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [tags, qid]);

    return (
        <nav className="bg-slate-700 text-white p-4 flex justify-between items-center relative">
            <div className="flex gap-4">
                {code === false && (
                    <>
                        <div className="flex">
                            <div className="mr-2 mt-2 cursor-pointer" onClick={toggleMenu}>
                                <FiMenu />
                            </div>

                            {isMenuOpen && (
                                <div className="absolute top-10 left-0 bg-slate-700 border border-gray-300 shadow-lg rounded-md z-[2000]">
                                    <button
                                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                        onClick={handlemcq}
                                    >
                                        List of BC MCQ Exams
                                    </button>
                                    <button
                                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                        onClick={handlefrq}
                                    >
                                        List of BC FRQ Exams
                                    </button>
                                    <button
                                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                        onClick={handleflag}
                                    >
                                        Flag Questions
                                    </button>
                                    <button
                                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                        onClick={handleFind2DArray}
                                    >
                                        [Calc] Find2DArray
                                    </button>
                                    <button
                                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                        onClick={handlefindemptyanswer}
                                    >
                                        [Calc] No Step by Step or Ratoinale
                                    </button>
                                    <button
                                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                        onClick={handlenornc}
                                    >
                                        [Calc] Check Tag C or NC
                                    </button>
                                    <button
                                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                        onClick={handlemathpix}
                                    >
                                        [Calc] Every img problem
                                    </button>
                                    <button
                                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                        onClick={handlenoanswer}
                                    >
                                        [Calc] Every [IMAGE] problem
                                    </button>
                                </div>
                            )}

                            {isModalOpen && (
                                <div className="z-[4000] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white text-black p-6 rounded-lg w-1/2">
                                        <h2 className="text-xl font-bold mb-4">List of 1D array</h2>
                                        List of question IDs that do not include a rationale or step-by-step explanation in a 2D array.
                                        <div className="border p-4 rounded overflow-y-auto max-h-64">
                                            {list.length > 0 ? (
                                                <ul>
                                                    {list.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            className="mb-2 cursor-pointer hover:underline"
                                                            onClick={() => handleItemClick(item)}
                                                        >
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No items in the list.</p>
                                            )}
                                        </div>
                                        <button
                                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                            onClick={toggleModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isModalOpen2 && (
                                <div className="z-[4000] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white text-black p-6 rounded-lg w-1/2">
                                        <h2 className="text-xl font-bold mb-4">List of Empty answer</h2>
                                        <div className="border p-4 rounded overflow-y-auto max-h-64">
                                            {list.length > 0 ? (
                                                <ul>
                                                    {list.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            className="mb-2 cursor-pointer hover:underline"
                                                            onClick={() => handleItemClick(item)}
                                                        >
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No items in the list.</p>
                                            )}
                                        </div>
                                        <button
                                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                            onClick={toggleModal2}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isModalOpen3 && (
                                <div className="z-[4000] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white text-black p-6 rounded-lg w-1/2">
                                        <h2 className="text-xl font-bold mb-4">List of not having C or NC</h2>
                                        This list helps ensure that every question is properly tagged with either C or NC to clarify whether a calculator is allowed or not. Questions missing both tags may cause confusion and should be reviewed for proper categorization.
                                        <div className="border p-4 rounded overflow-y-auto max-h-64">
                                            {list.length > 0 ? (
                                                <ul>
                                                    {list.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            className="mb-2 cursor-pointer hover:underline"
                                                            onClick={() => {
                                                                handleItemClick(item.id); handleTestIdChange(item.exam);
                                                            }}
                                                        >
                                                            <span className="font-bold">ID:</span> {item.id} - <span className="font-bold">Exam:</span> {item.exam}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No items in the list.</p>
                                            )}
                                        </div>
                                        <button
                                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                            onClick={toggleModal3}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isModalOpen4 && (
                                <div className="z-[4000] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white text-black p-6 rounded-lg w-1/2">
                                        <h2 className="text-xl font-bold mb-4">List of Flag alert</h2>
                                        <div className="border p-4 rounded overflow-y-auto max-h-64">
                                            {list.length > 0 ? (
                                                <ul>
                                                    {list.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            className="mb-2 cursor-pointer hover:underline"
                                                            onClick={() => {
                                                                handleItemClick(item.id); handleTestIdChange(item.exam);
                                                            }}
                                                        >
                                                            <span className="font-bold">ID:</span> {item.id} - <span className="font-bold">Exam:</span> {item.exam}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No items in the list.</p>
                                            )}
                                        </div>
                                        <button
                                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                            onClick={toggleModal4}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isModalOpen5 && (
                                <div className="z-[4000] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white text-black p-6 rounded-lg w-1/2">
                                        <h2 className="text-xl font-bold mb-4">List of BC MCQ exam name</h2>
                                        <div className="border p-4 rounded overflow-y-auto max-h-64">
                                            {list.length > 0 ? (
                                                <ul>
                                                    {list.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            className={`mb-2 cursor-pointer hover:underline p-2 rounded ${item.tags?.includes('complete') ? 'bg-green-300' : 'bg-white'
                                                                }`}
                                                            onClick={() => {
                                                                handleItemClick(item.id);
                                                                handleTestIdChange(item.exam);
                                                            }}
                                                        >
                                                            <span className="font-bold">ID:</span> {item.id} - <span className="font-bold">Exam:</span> {item.exam}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No items in the list.</p>
                                            )}
                                        </div>
                                        <button
                                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                            onClick={toggleModal5}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isModalOpen6 && (
                                <div className="z-[4000] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white text-black p-6 rounded-lg w-1/2">
                                        <h2 className="text-xl font-bold mb-4">List of BC FRQ exam name</h2>
                                        <div className="border p-4 rounded overflow-y-auto max-h-64">
                                            {list.length > 0 ? (
                                                <ul>
                                                    {list.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            className={`mb-2 cursor-pointer hover:underline p-2 rounded ${item.tags?.includes('complete') ? 'bg-green-300' : 'bg-white'
                                                                }`}
                                                            onClick={() => {
                                                                handleItemClick(item.id);
                                                                handleTestIdChange(item.exam);
                                                            }}
                                                        >
                                                            <span className="font-bold">ID:</span> {item.id} - <span className="font-bold">Exam:</span> {item.exam}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No items in the list.</p>
                                            )}
                                        </div>
                                        <button
                                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                            onClick={toggleModal6}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isModalOpen7 && (
                                <div className="z-[4000] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white text-black p-6 rounded-lg w-1/2">
                                        <h2 className="text-xl font-bold mb-4">
                                            List of &lt;img&gt; questions
                                        </h2>

                                        <div className="border p-4 rounded overflow-y-auto max-h-64">
                                            {list.length > 0 ? (
                                                <ul>
                                                    {list.map((item, index) => {
                                                        let parsedTags = [];

                                                        try {
                                                            parsedTags = JSON.parse(item.tags);
                                                        } catch (error) {
                                                            // JSON 파싱 실패 시 빈 배열로 처리
                                                            parsedTags = [];
                                                        }

                                                        const hasImgComplete = Array.isArray(parsedTags) && parsedTags.includes('img_complete');
                                                        return (
                                                            <li
                                                                key={index}
                                                                className={`mb-2 cursor-pointer hover:underline ${hasImgComplete ? 'bg-blue-300' : ''}`}
                                                                onClick={() => {
                                                                    handleItemClick(item.id);
                                                                    handleTestIdChange(item.exam);
                                                                }}
                                                            >
                                                                <span className="font-bold">ID:</span> {item.id} - <span className="font-bold">Exam:</span> {item.exam}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            ) : (
                                                <p>No items in the list.</p>
                                            )}
                                        </div>
                                        <button
                                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                            onClick={toggleModal7}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isModalOpen8 && (
                                <div className="z-[4000] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white text-black p-6 rounded-lg w-1/2">
                                        <h2 className="text-xl font-bold mb-4">List of [IMAGE] Problem</h2>
                                        <div className="border p-4 rounded overflow-y-auto max-h-64">
                                            {list.length > 0 ? (
                                                <ul>
                                                    {list.map((item, index) => {
                                                        let parsedTags = [];

                                                        try {
                                                            parsedTags = JSON.parse(item.tags);
                                                        } catch (error) {
                                                            // JSON 파싱 실패 시 빈 배열로 처리
                                                            parsedTags = [];
                                                        }
                                                        const hasImgComplete = Array.isArray(parsedTags) && parsedTags.includes('img_complete');
                                                        return (
                                                            <li
                                                                key={index}
                                                                className={`mb-2 cursor-pointer hover:underline ${hasImgComplete ? 'bg-blue-300' : ''}`}
                                                                onClick={() => {
                                                                    handleItemClick(item.id);
                                                                    handleTestIdChange(item.exam);
                                                                }}
                                                            >
                                                                <span className="font-bold">ID:</span> {item.id} - <span className="font-bold">Exam:</span> {item.exam}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            ) : (
                                                <p>No items in the list.</p>
                                            )}
                                        </div>

                                        {/* New Button for Clipboard */}
                                        <button
                                            className="mr-4 mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                            onClick={() => {
                                                const idsString = list.map(item => item.id).join(', '); // Create the comma-separated string
                                                navigator.clipboard.writeText(`(${idsString})`) // Write to clipboard
                                                    .then(() => {
                                                        alert('Copied to clipboard!'); // Show success alert
                                                    })
                                                    .catch(err => {
                                                        alert('Failed to copy to clipboard.'); // Handle errors
                                                        console.error('Clipboard error:', err);
                                                    });
                                            }}
                                        >
                                            Get the Array
                                        </button>

                                        <button
                                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                            onClick={toggleModal8}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}

                            <label htmlFor="testId" className="mr-2 mt-2">
                                Exam ID:
                            </label>
                            <select
                                id="testId"
                                className="bg-slate-600 text-white p-1 rounded"
                                value={selectedTestId.exam}
                                onChange={handleExamChange}
                            >
                                {testIdOptions.map((testId, index) => (
                                    <option key={index} value={testId.exam}>
                                        {testId.exam}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="flex">
                            <div className="mr-2 mt-[8px]">Question #:</div>
                            <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                                <input
                                    type="number"
                                    value={qid}
                                    onChange={handleQidChange}
                                    className="bg-slate-600 text-white p-1 rounded"
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="mr-2 mt-[8px]">GroupId:</div>
                            <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                                {currentQuestion?.group_id}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="mr-2 mt-[8px]">Tag:</div>
                            <div
                                id="qid"
                                className="bg-slate-600 text-white p-1 rounded flex-1"
                                contentEditable
                                suppressContentEditableWarning
                                onInput={handleEdit}
                            >
                                {tags && tags.length > 0 ? tags.join(', ') : 'No tags'}
                            </div>
                        </div>
                        <div className="flex gap-1">
                            <button
                                className="m-1 bg-red-300 px-2 py-1 rounded hover:bg-red-500 hover:text-white transition duration-300"
                                onClick={() => addTag('flag')}
                            >
                                [D] Flag
                            </button>
                            <button
                                className="m-1 bg-gray-300 px-2 py-1 rounded hover:bg-red-500 hover:text-white transition duration-300"
                                onClick={() => addTag('NC')}
                            >
                                [A]  NC
                            </button>
                            <button
                                className="m-1 bg-gray-300 px-2 py-1 rounded hover:bg-red-500 hover:text-white transition duration-300"
                                onClick={() => addTag('C')}
                            >
                                [S] C
                            </button>
                            <button
                                className={`m-1 px-2 py-1 rounded transition duration-300 ${isComplete ? 'bg-green-500 text-white' : 'bg-green-300'
                                    }`}
                                onClick={toggleComplete}
                            >
                                {isComplete ? 'Remove Complete' : '[Z] Mark as Complete'}
                            </button>
                            <button
                                className={`m-1 px-2 py-1 rounded transition duration-300 ${isimgComplete ? 'bg-blue-500 text-white' : 'bg-blue-300'
                                    }`}
                                onClick={toggleimagecomplete}
                            >
                                {isimgComplete ? 'Remove Img Complete' : '[X] Img Complete'}
                            </button>
                        </div>

                    </>
                )}

                <button className="ml-[100px]" onClick={() => window.location.reload()}>
                    Reset
                </button>
                <button onClick={() => handleUpdate()}>Update</button>
                {code === false && (
                    <>
                        <button onClick={handlePreviousQuestion}>Back</button>
                        <button onClick={handleNextQuestion}>Next</button>
                    </>
                )}
                {code === true && (
                    <>
                        <button onClick={() => handleBack2()}>Back</button>
                        <button onClick={() => handleNext2()}>Next</button>
                    </>
                )}
            </div>
        </nav>
    );
};

export default NavBar;