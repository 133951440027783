import React, { useEffect, useState, useContext } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import axios from "axios";
import { BASELINE } from "../../util";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { UserContext } from "../../context/UserContext";

// Assuming FilterButton has been imported or defined in the same file
const FilterButton = ({ label, isActive, onClick }) => {
    return (
        <button
            className={`flex items-center space-x-2 border px-3 py-2 rounded-full ${isActive ? 'bg-slate-700 text-white' : 'bg-white text-gray-800'} hover:bg-gray-300`}
            onClick={onClick}
        >
            {isActive && <AiOutlineCheck className="text-lg" />}
            <span>{label}</span>
        </button>
    );
};

const ModalComponent = ({ topic, isOpen, onClose, section, type }) => {
    const { user } = useContext(UserContext);

    const [tutorMode, setTutorMode] = useState(false);
    const [timerMode, setTimerMode] = useState(false);
    const [timeInMinutes, setTimeInMinutes] = useState(0);
    const [difficulty, setDifficulty] = useState({
        E: false,
        M: false,
        H: false
    });
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);
    const [groupedQuestions, setGroupedQuestions] = useState({ E: [], M: [], H: [] });
    const [st, setst] = useState(false);
    const [total, settotal] = useState(0);
    const [combined, setcombined] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        let newCombined = [];

        // Recalculate total when difficulty changes
        const newTotal = Object.keys(difficulty).reduce((sum, key) => {
            if (difficulty[key]) {
                // Combine the arrays when the difficulty is toggled on
                newCombined = [...newCombined, ...groupedQuestions[key]];
            }
            const count = groupedQuestions[key].length;
            return difficulty[key] ? sum + count : sum;
        }, 0);
        setcombined(newCombined); // Set the combined array state
        settotal(newTotal); // Set the total state

    }, [difficulty]);

    useEffect(() => {
        if (section === 'ACT') {
            axios.get(BASELINE + "get/amount/bytopic/act", {
                params: { topic }
            })
                .then(response => {
                    settotal(response.data.length);
                    setcombined(response.data);
                    setst(true);
                })
                .catch(err => {
                    console.error("Error fetching SAT question data:", err);
                });
        } else if (section === 'Apcalcab') {
            axios.get(BASELINE + "get/amount/bytopic/apcalcab", {
                params: { topic }
            })
                .then(response => {
                    settotal(response.data.length);
                    setcombined(response.data);
                    setst(true);
                })
                .catch(err => {
                    console.error("Error fetching SAT question data:", err);
                });
        } else {
            if (type === 'SAT10') {
                axios.get(BASELINE + "get/amount/bytopic/10", {
                    params: { topic }
                })
                    .then(response => {
                        const grouped = response.data.reduce((acc, question) => {
                            const difficulty = question.difficultys;
                            if (!acc[difficulty]) {
                                acc[difficulty] = [];
                            }
                            acc[difficulty].push(question);
                            return acc;
                        }, { E: [], M: [], H: [] });
                        setGroupedQuestions(grouped);
                        setst(true);
                    })
                    .catch(err => {
                        console.error("Error fetching SAT question data:", err);
                    });
            } else if (type === 'SAT89') {
                axios.get(BASELINE + "get/amount/bytopic/89", {
                    params: { topic }
                })
                    .then(response => {
                        const grouped = response.data.reduce((acc, question) => {
                            const difficulty = question.difficultys;
                            if (!acc[difficulty]) {
                                acc[difficulty] = [];
                            }
                            acc[difficulty].push(question);
                            return acc;
                        }, { E: [], M: [], H: [] });
                        setGroupedQuestions(grouped);
                        setst(true);
                    })
                    .catch(err => {
                        console.error("Error fetching SAT question data:", err);
                    });
            } else {
                axios.get(BASELINE + "get/amount/bytopic", {
                    params: { topic }
                })
                    .then(response => {
                        const grouped = response.data.reduce((acc, question) => {
                            const difficulty = question.difficultys;
                            if (!acc[difficulty]) {
                                acc[difficulty] = [];
                            }
                            acc[difficulty].push(question);
                            return acc;
                        }, { E: [], M: [], H: [] });
                        setGroupedQuestions(grouped);
                        setst(true);
                    })
                    .catch(err => {
                        console.error("Error fetching SAT question data:", err);
                    });
            }
        }
    }, [topic]);

    const handleToggle = (key) => {
        if (key === 'tutorMode') {
            setTutorMode(!tutorMode);
        } else if (key === 'timerMode') {
            setTimerMode(!timerMode);
        } else {
            setDifficulty(prev => ({ ...prev, [key]: !prev[key] }));
        }
    };

    const handleStart = () => {
        // combined 배열에서 numberOfQuestions 수만큼 랜덤으로 questionIds를 선택
        const getRandomQuestionIds = (combinedArray, numberOfQuestions) => {
            const shuffled = [...combinedArray].sort(() => 0.5 - Math.random());
            const selected = shuffled.slice(0, numberOfQuestions);
            return selected.map(item => item.questionIds);
        };

        const getRandomQuestionIdsACT = (combinedArray, numberOfQuestions) => {
            // Shuffle the combinedArray
            const shuffledArray = combinedArray.sort(() => 0.5 - Math.random());

            // Select the first `numberOfQuestions` items
            const selectedQuestions = shuffledArray.slice(0, numberOfQuestions);

            // Extract the qid from the selected questions
            const questionIds = selectedQuestions.map(question => question.qid);

            // Return the array of qid
            return questionIds;
        };

        const getRandomQuestionIdsAP = (combinedArray, numberOfQuestions) => {
            // Shuffle the combinedArray
            const shuffledArray = combinedArray.sort(() => 0.5 - Math.random());
            // Select the first `numberOfQuestions` items
            const selectedQuestions = shuffledArray.slice(0, numberOfQuestions);
            // Extract the qid from the selected questions
            const questionIds = selectedQuestions.map(question => question.question_id);

            // Return the array of qid
            return questionIds;
        };

        if (numberOfQuestions <= 0) {
            toast.error("Please enter a valid number of questions greater than 0.");
            return;
        }

        if (numberOfQuestions > total) {
            toast.error(`The number of questions exceeds the available questions. Maximum available questions are ${combined.length}.`);
            return;
        }
        let selectedQuestionIds;

        if (section === 'ACT') {
            selectedQuestionIds = getRandomQuestionIdsACT(combined, numberOfQuestions);
        } else if (section === 'Apcalcab') {
            selectedQuestionIds = getRandomQuestionIdsAP(combined, numberOfQuestions);
        } else {
            selectedQuestionIds = getRandomQuestionIds(combined, numberOfQuestions);
        }

        let quizData = {
            questionIds: selectedQuestionIds,
            tutorModeActive: tutorMode,
            timedModeActive: timerMode,
            timeLimit: timerMode ? timeInMinutes : null,
            tag: section,
            type: type
        };

        if (section === 'ACT') {
            navigate(`/act/quiz/`, { state: { data: quizData } });
        } else if (section === 'Apcalcab') {
            quizData.tag = 'MCQ'
            navigate(`/ap/quiz/`, { state: { data: quizData } });
        } else {
            navigate(`/sat/quiz/`, { state: { data: quizData } });
        }
    };

    const handleClose = () => {
        // Reset all states to default values
        setTutorMode(false);
        setTimerMode(false);
        setTimeInMinutes(0);
        setDifficulty({ E: false, M: false, H: false });
        setNumberOfQuestions(0);
        settotal(0);
        setcombined([]);
        setGroupedQuestions({ E: [], M: [], H: [] });

        // Close the modal
        onClose(false);
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center z-50 ${isOpen ? '' : 'hidden'}`}>
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="relative bg-white p-5 rounded-lg w-[90%] sm:w-[70%] md:w-[50%] lg:w-[40%]">
                <button className="absolute top-0 right-0 p-2" onClick={handleClose}>
                    Close
                </button>
                <h2 className="text-lg font-semibold">Setup Quick Test</h2>
                <p>Topic: {topic}</p>
                <div className="mt-3 flex gap-2 flex-wrap">
                    <FilterButton label="Tutor Mode" isActive={tutorMode} onClick={() => handleToggle('tutorMode')} />
                    <FilterButton label="Timer Mode" isActive={timerMode} onClick={() => handleToggle('timerMode')} />
                    {timerMode && (
                        <>
                            <input
                                type="number"
                                value={timeInMinutes}
                                onChange={(e) => setTimeInMinutes(e.target.value)}
                                placeholder="Time in minutes"
                                className="border border-gray-300 p-1 rounded"
                            />
                            min
                        </>
                    )}
                </div>
                <div className="mt-3 flex gap-2 flex-wrap">
                    {st === true && section !== "ACT" && section !== "Apcalcab" && (
                        <>
                            <FilterButton label={`Easy (${groupedQuestions.E.length})`} isActive={difficulty.E} onClick={() => handleToggle('E')} />
                            <FilterButton label={`Medium (${groupedQuestions.M.length})`} isActive={difficulty.M} onClick={() => handleToggle('M')} />
                            <FilterButton label={`Hard (${groupedQuestions.H.length})`} isActive={difficulty.H} onClick={() => handleToggle('H')} />
                        </>
                    )}
                </div>
                <div className="mt-3">
                    <label className="flex items-center space-x-2">
                        <span>Number of Questions:</span>
                        <input
                            type="number"
                            value={numberOfQuestions}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Ensure the input is a valid number and not negative
                                if (!isNaN(value) && Number(value) >= 0) {
                                    setNumberOfQuestions(Number(value));
                                }
                            }}
                            min="0"  // Set the minimum value to 0 to prevent negative numbers
                            className="border border-gray-300 p-1 rounded w-20 text-center"  // Width set to 5rem (20) and centered text

                        />
                        <span>/ {total}</span> {/* Ensure this part stays aligned */}
                    </label>
                </div>

                <div className="mt-3">
                    {user && user.premium === 1 ? (
                        <button className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded" onClick={handleStart}>
                            Start
                        </button>
                    ) : (
                        <button className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded">
                            🔒 Start
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ModalComponent;
