import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { fadeInFromLeft } from "../../util/FadeFromLeftAnimation";
import { UserContext } from "../../context/UserContext";
import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Leaderboard from "./ranking";
import axios from "axios";
import { BASELINE } from "../../util";
import { FaStar } from "react-icons/fa";

const Hero = () => {
  const { logoutUser, user } = useContext(UserContext);
  const navigate = useNavigate();

  const random_sat = () => {
    // Prepare data for navigation
    const quizData = {
      questionIds: [
        "1e11190a",
        "433184f1",
        "541bef2f",
        "4d037075",
        "98d3393a",
        "6ab30ce3",
        "153ee763",
        "263f9937",
        "95cad55f",
        "c178d4da",
        "25e1cfed",
        "4dd4efcf",
        "b0dc920d",
        "371cbf6b",
        "f89e1d6f",
        "66bce0c1",
        "ef926848",
        "52cb8ea4",
        "651d83bb",
        "e2e3942f"
      ],
      tutorModeActive: false,
      timedModeActive: false,
      timeLimit: null,
      tag: "Math",
      type: "SAT",
      rank: true,
    };

    // Navigate to quiz page with quiz data
    navigate(`/sat/quiz/`, { state: { data: quizData } });
  }

  return (
    <section className="w-full">
      <div className="flex justify-center items-center pt-[180px]">
        <img
          src="/hero.png"
          className="w-full object-cover h-full absolute top-0 left-0 sm:max-h-[750px] max-h-[700px]"
          alt=""
          loading="lazy"
        />
        <div className="relative z-10 flex justify-center items-center flex-col gap-8 text-center w-full">
          <motion.h3
            {...fadeInFromLeft(0.1)}
            className="text-white font-archivo leading-[1.2] text-[40px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-bold uppercase"
          >
            MASTER <span className="gradient-text">SAT & ACT</span> <br /> WITH CONFIDENCE
          </motion.h3>
          <motion.div {...fadeInFromLeft(0.2)} className="">
            <motion.div className="text-white font-archivo text-2xl leading-[1.2] font-bold">
              Dive into our comprehensive problem bank
            </motion.div>
          </motion.div>

          {/*
          <div className="flex flex-col sm:flex-row gap-4">
          <ChooseYourTestCard title="Challenge for Rank" delay={0.1} onClick={random_sat} />

            {user ? (
              <>
              </>
            ) : (
              <>
                <ChooseYourTestCard title="Try Digital-SAT" delay={0.1} link="/freetrial/sat" />
                <ChooseYourTestCard title="Try Math Quiz" delay={0.1} link="/freetrial/mathquiz" />
              </>
            )}
          </div>
*/}
          <div className="items-center">
            <Leaderboard />
            <ChooseYourTestCard title="Challenge for Rank" delay={0.1} onClick={random_sat} />
          </div>

        </div>
      </div>
    </section>
  );
};

const ChooseYourTestCard = (props) => {
  return (
    <motion.div
      className="text-white p-2 flex justify-center items-center sm:h-20 h-20 rounded-lg cursor-pointer duration-500 relative overflow-hidden group"
      onClick={props.onClick}
      whileHover={{ scale: 1.1, rotate: 2 }}
      whileTap={{ scale: 0.95 }}
    >
      {/* Hover 시 배경 애니메이션 */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-blue-500 opacity-0 group-hover:opacity-100 transition-opacity duration-500 group-hover:animate-gradientWave"></div>

      {/* 콘텐츠 */}
      {props.link ? (
        <Link to={props.link}>
          <h3 className="relative z-10 font-archivo text-xl sm:text-xl font-semibold tracking-wider flex items-center gap-2">
          ✨ {props.title}
          </h3>
        </Link>
      ) : (
        <h3 className="relative z-10 font-archivo text-xl sm:text-xl font-semibold tracking-wider flex items-center gap-2">
          ✨ {props.title}
        </h3>
      )}
    </motion.div>
  );
};

export default Hero;