import React, { useState } from "react";
import { RiChatSmileFill } from "react-icons/ri";
import { Doughnut } from 'react-chartjs-2';
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import ModalComponent from "../pick_modal";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const DigitalSAT89View = ({ setActiveIndex, user, lastTest, data2, options2, setdashboard, avg_sat, avg_eng, avg_math, Incorrect_questionIds, allSATScores }) => {
    const [isVisible, setIsVisible] = useState(true);
    const topEngTopics = findTopMissedTopics(Incorrect_questionIds, "eng");
    const topMathTopics = findTopMissedTopics(Incorrect_questionIds, "math");
    const [topic, settopic] = useState(false);
    const [section, setsection] = useState("");
    const type = 'SAT89';
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const handleClose = () => {
        setIsVisible(false);
    };

    const handleRowClick = (subjectName, section) => {
        setsection(section);
        settopic(subjectName);
        setIsModalOpen2(true);
        // You can perform any actions here, such as navigation or displaying details
    };

    function findTopMissedTopics(data, tag) {
        const topicCount = {};

        // Count incorrect topics for the specified tag
        data.forEach(item => {
            if (item.tag === tag && !item.correct) {
                topicCount[item.topic] = (topicCount[item.topic] || 0) + 1;
            }
        });

        // Convert the topicCount object into an array of [topic, count] pairs
        const sortedTopics = Object.entries(topicCount).sort((a, b) => b[1] - a[1]);

        // Select the top 10
        return sortedTopics.slice(0, 10).map(entry => ({ topic: entry[0], count: entry[1] }));
    }

    function generateLabels(length) {
        return Array.from({ length }, (_, index) => `Test ${index + 1}`);
    }


    const data3 = {
        labels: generateLabels(allSATScores.length),  // Dynamic labels based on the number of scores
        datasets: [
            {
                label: 'PSAT 8/9 Score',
                data: allSATScores,
                fill: false,
                borderColor: 'rgba(51, 65, 85, 1)',
                tension: 0.1
            }
        ]
    };

    const data4 = {
        labels: [''],
        datasets: [
            {
                label: 'Max Score',
                data: [800],
                backgroundColor: 'rgba(201, 203, 207, 0.6)',
            },
            {
                label: 'English Score',
                data: [avg_eng],
                backgroundColor: 'rgba(51, 65, 85, 1)',
            },
            {
                label: 'Math Score',
                data: [avg_math],
                backgroundColor: 'rgba(22,30,45,255)',
            },
        ],
    };

    const options4 = {
        indexAxis: 'y',
        scales: {
            x: {
                max: 800,
                beginAtZero: true,
            },
        },
    };

    // Options for the line graph
    const options3 = {
        scales: {
            y: {
                beginAtZero: false,  // Change this as needed
                suggestedMax: 1600  // Assuming SAT scores are out of 1600
            }
        },
        maintainAspectRatio: false
    };

    const data = {
        datasets: [{
            data: [avg_sat, 1600 - avg_sat],
            backgroundColor: ['#334155', '#D0D0D0'],
            hoverBackgroundColor: ['#334155', '#D0D0D0'],
            borderWidth: 0,
        }],
        labels: ['Score', 'Remaining'],
    };


    return (
        <div className="bg-white">
            <ModalComponent topic={topic} isOpen={isModalOpen2} onClose={setIsModalOpen2} section={section} type={type}/>
            <div className="p-6 flex flex-col items-center">
                <div className="w-full max-w-[100%]">
                    <div className="mb-4 text-gray-500 text-sm">
                        <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">Digital PSAT 8/9 View</span>
                    </div>
                    <h1 className="text-2xl font-bold mt-4">Digital PSAT 8/9 View</h1>

                    {isVisible && (
                        <div className="p-6 bg-white shadow-md rounded-md">
                            <button
                                onClick={handleClose}
                                className="text-gray-600 hover:text-gray-800 ml-[99%]"
                            >
                                ✖
                            </button>
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <div className="bg-indigo-500 rounded-full p-4">
                                        <RiChatSmileFill className="text-white text-4xl" />
                                    </div>
                                </div>
                                {user && (
                                    <div>
                                        <div className="text-lg font-medium text-black">
                                            Welcome <span className="gradient-text font-bold">{user.first_name}</span> <span role="img" aria-label="wave">👋</span>
                                        </div>
                                        <div className="text-gray-500">{user.email}</div>
                                        <div className="mt-2 text-gray-600">
                                            Study hard and aim for a <span className="font-semibold text-black">1600 </span> on the <span className="text-red-500 font-semibold">PSAT 8/9!</span> <span role="img" aria-label="gift">🎁</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="pl-6 pr-6 flex flex-col items-center">
                <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="p-6 bg-gradient-to-br from-gray-800 to-gray-900 text-white shadow-md rounded-md flex flex-col justify-between">
                            <div>
                                <div className="flex items-center mb-4">
                                    <span className="bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital PSAT 8/9</span>
                                    <span className="text-lg font-medium">Your Latest PSAT 8/9 Score</span>
                                </div>

                                <div className="flex">
                                    <div className="text-5xl font-bold mb-4">{lastTest}</div>
                                    <div className="text-5xl mb-4 ml-4"> | 400-1600</div>
                                </div>

                                <span className="gradient-text">
                                    Check if you have an unfinished test. <br /> Complete the test to get your analytics report.
                                </span>
                            </div>
                            <div className="flex gap gap-2">
                                <button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 rounded-md mb-2" onClick={() => { setdashboard(5); }}
                                >

                                    Continue the Test
                                </button>
                                <button className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 rounded-md mb-2" onClick={() => { setdashboard(201); }}
                                >
                                    Get Help Session
                                </button>
                            </div>
                        </div>

                        <div className="p-6 bg-white shadow-md rounded-md">
                            <div className="h-full flex flex-col justify-center items-center">
                                <div className="w-full h-64 flex justify-center items-center">
                                    <Bar data={data2} options={options2} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 pl-6 pr-6 flex flex-col items-center">
                <div className="w-full flex gap-4" style={{ maxHeight: '330px' }}>
                    <div className="relative flex-3 bg-white p-4 shadow rounded" style={{ flex: 3 }}>
                        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                            <div className="h-full w-full bg-white rounded-xl"></div>
                        </div>
                        <div className="relative">
                            <h2 className="text-lg font-semibold mb-2">Overall Average PSAT 8/9 Score</h2>
                            <div style={{ height: '180px' }}>  {/* Set a specific height for the graph container */}
                                <Doughnut
                                    data={data}
                                    options={{
                                        maintainAspectRatio: false, // This prevents the chart from inheriting aspect ratio
                                        aspectRatio: 1, // Sets the aspect ratio to 1:1 (you can adjust as needed)
                                    }}
                                />
                            </div>
                            <div className="text-center mt-2">
                                {avg_sat && (<>
                                    <span className="text-2xl font-bold">{avg_sat}</span> / 1600
                                </>)}
                            </div>
                        </div>
                    </div>

                    <div className="relative flex-3 bg-white p-4 shadow rounded" style={{ flex: 7 }}>
                        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                            <div className="h-full w-full bg-white rounded-xl"></div>
                        </div>

                        <div className="relative">
                            <h2 className="text-lg font-semibold mb-2">Average English, Math Scores</h2>

                            <div className="h-full flex flex-col justify-center items-center">
                                <div className="w-full h-64 flex justify-center items-center">
                                    <Bar data={data4} options={options4} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 pl-6 pr-6 flex flex-col items-center">
                <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="p-6 bg-gradient-to-br from-gray-800 to-gray-900 text-white shadow-md rounded-md flex flex-col justify-between">
                            <div>
                                <div className="flex items-center mb-4">
                                    <span className="bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital PSAT 8/9</span>
                                    <span className="text-xl font-bold">🏆Recomend English Missing Topics</span>
                                </div>
                                <div className="flex-7 p-4 rounded overflow-auto" style={{ maxHeight: '240px', overflowY: 'auto' }}>

                                    <ul className="list-disc pl-5">
                                        {topEngTopics.map((topic, index) => (
                                            <li
                                                key={index}
                                                className="mb-1 text-xl cursor-pointer hover:bg-gray-300"
                                                onClick={() => handleRowClick(topic.topic, "English")}
                                                style={{ transition: 'background-color 0.3s' }}

                                            >
                                                {topic.topic}: {topic.count}
                                            </li>
                                        ))}
                                    </ul>


                                </div>
                            </div>

                        </div>

                        <div className="p-6 bg-gradient-to-br from-gray-800 to-gray-900 text-white shadow-md rounded-md flex flex-col justify-between">
                            <div>
                                <div className="flex items-center mb-4">
                                    <span className="bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital PSAT 8/9</span>
                                    <span className="text-xl font-bold">🏆Recomend Math Missing Topics</span>
                                </div>

                                <div className="flex-7 p-4 rounded overflow-auto" style={{ maxHeight: '240px', overflowY: 'auto' }}>

                                    <ul className="list-disc pl-5">
                                        {topMathTopics.map((topic, index) => (
                                            <li
                                                key={index}
                                                className="mb-1 text-xl cursor-pointer hover:bg-gray-300"
                                                onClick={() => handleRowClick(topic.topic, "Math")}
                                                style={{ transition: 'background-color 0.3s' }}
                                            >
                                                {topic.topic}: {topic.count}
                                            </li>
                                        ))}
                                    </ul>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="relative flex-3 p-6 bg-white pr-6 pl-6 mr-6 ml-6 mb-[100px] shadow rounded mt-6">
                <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                    <div className="h-full w-full bg-white rounded-xl"></div>
                </div>

                <div className="relative">
                    <h2 className="text-lg font-semibold mb-2">Overall PSAT 8/9 Scores</h2>

                    <div className="h-full flex flex-col justify-center items-center">
                        <div className="w-full h-64 flex justify-center items-center">
                            <Line data={data3} options={options3} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default DigitalSAT89View;
