import React, { useEffect, useState, useContext, useRef } from "react";
import { RiChatSmileFill } from "react-icons/ri";
import { Doughnut } from 'react-chartjs-2';
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import ModalComponent from "../pick_modal";
import axios from "axios";
import { BASELINE } from "../../../util";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const DigitalActView = ({ user, setdashboard }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [topic, settopic] = useState(false);
    const [section, setsection] = useState("");
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [scores, setScores] = useState({ english: 0, math: 0, reading: 0, science: 0 });
    const [allscores, setallScores] = useState([]);
    const [topMissedTopics, setTopMissedTopics] = useState([]);
    const [activeSubject, setActiveSubject] = useState('english');

    const [topics, settopics] = useState([
        { topic: '["IES"]', full: "Intergrating Essential Skills" },
        { topic: '["A"]', full: "Statistics and Probability" },
        { topic: '["F"]', full: "Algebra" },
        { topic: '["G"]', full: "Geometry" },
        { topic: '["F"]', full: "Functions" },
        { topic: '["N"]', full: "Number and Quantity" },
        { topic: '["T"]', full: "Trigonometry" },
        { topic: '["S"]', full: "Statistics and Probability" }
    ]);

    const handleRowClick = (subjectName, section) => {
        const matchedTopic = topics.find(t => t.full === subjectName);

        if (matchedTopic) {
            setsection("ACT"); // Assuming setSection is correctly defined
            settopic(matchedTopic.topic); // Set the 'topic' state to the matched 'topic' value
            setIsModalOpen2(true); // Open the modal
        }
    };


    const getChartDataForSubject = (subject) => {
        const labels = allscores.map((_, index) => `Test ${index + 1}`);
        const data = allscores.map(score => score[subject]);

        return {
            labels,
            datasets: [{
                label: `${subject.charAt(0).toUpperCase() + subject.slice(1)} Scores`,
                data,
                fill: false,
                borderColor: 'rgba(51, 65, 85, 1)',
                tension: 0.1
            }]
        };
    };

    // Use this function in your Line chart:
    const lineChartData = getChartDataForSubject(activeSubject);

    const subjects = ['English', 'Math', 'Reading', 'Science'];
    const getChartData = (score) => ({
        labels: ['Score', 'Remaining'],
        datasets: [
            {
                data: score ? [score, 36 - score] : [0, 36],
                backgroundColor: ['#4F46E5', '#2D3748'],
                borderWidth: 0,
            },
        ],
    });

    const countTopicsAndFindTopTen = (incorrectQuestionIds) => {
        const topicCounts = {};
        // Count each topic occurrence, filtering out null or undefined topics
        incorrectQuestionIds.forEach(question => {
            const topic = question.category;
            if (topic !== null && topic !== undefined && question.section === "math" && question.section_data !== null) {
                if (topicCounts[topic]) {
                    topicCounts[topic]++;
                } else {
                    topicCounts[topic] = 1;
                }
            }
        });

        // Convert the topicCounts object into an array and sort by count
        const sortedTopics = Object.entries(topicCounts).sort((a, b) => b[1] - a[1]);

        // Select the top 10 most frequent topics
        return sortedTopics.slice(0, 10).map(entry => {
            const topicAbbr = entry[0];
            const topicFull = topics.find(t => t.topic === topicAbbr)?.full || topicAbbr;
            return {
                topic: topicFull,
                count: entry[1]
            }
        });
    };

    const getChartData2 = (subjectIndex) => {
        const score = calculateAverageBySubject(subjectIndex);
        return {
            labels: ['Score', 'Remaining'],
            datasets: [
                {
                    data: [score, 36 - score],  // Calculate the remaining score
                    backgroundColor: ['#4F46E5', '#2D3748'],  // Visualization colors
                    borderWidth: 0,
                },
            ],
        };
    };


    const calculateAverageBySubject = (subject) => {
        const total = allscores.reduce((acc, scores) => {
            return {
                english: acc.english + scores.english,
                math: acc.math + scores.math,
                reading: acc.reading + scores.reading,
                science: acc.science + scores.science
            };
        }, { english: 0, math: 0, reading: 0, science: 0 });

        const numberOfEntries = allscores.length;

        const averages = {
            english: total.english / numberOfEntries,
            math: total.math / numberOfEntries,
            reading: total.reading / numberOfEntries,
            science: total.science / numberOfEntries
        };

        // Map numbers to subjects
        const subjectMap = {
            1: 'english',
            2: 'math',
            3: 'reading',
            4: 'science'
        };

        // Return the average for the selected subject
        // Default to 0 if subject number is invalid
        return averages[subjectMap[subject]] || 0;
    };


    const addNewScoreSet = (newScoreSet) => {
        setallScores(prevScores => [
            ...prevScores,
            {
                english: newScoreSet.english || 0,
                math: newScoreSet.math || 0,
                reading: newScoreSet.reading || 0,
                science: newScoreSet.science || 0
            }
        ]);
    };

    const latest_ACT = () => {
        axios.get(`${BASELINE}get/act/scores`, { params: { u_id: user.u_id } })
            .then(function (response) {
                let allIncorrectQuestions = [];

                response.data.forEach(entry => {

                    // Ensure `eng_score` exists and is not null
                    if (entry.eng_score !== null) {
                        try {
                            const data = JSON.parse(entry.eng_score);  // Parse the `eng_score` safely

                            // Add each new score set to the historical list
                            addNewScoreSet({
                                english: data.english || 0,  // Safely handle missing values
                                math: data.math || 0,
                                reading: data.reading || 0,
                                science: data.science || 0
                            });
                        } catch (err) {
                            console.error("Error parsing eng_score:", err.message);
                        }
                    }

                    if (entry.Incorrect_questionIds) {
                        allIncorrectQuestions.push(...JSON.parse(entry.Incorrect_questionIds));
                    }
                });

                // Process the latest score (with section_data check)
                if (response.data && response.data.length > 0) {
                    const latestEntry = response.data[response.data.length - 1];

                    // Check if section_data is present and not empty for the latest entry
                    if (latestEntry.section_data) {
                        const sectionData = JSON.parse(latestEntry.section_data);
                    }

                    if (latestEntry.eng_score) {
                        const data = JSON.parse(latestEntry.eng_score);
                        setScores({
                            english: data.english || 0,
                            math: data.math || 0,
                            reading: data.reading || 0,
                            science: data.science || 0
                        });
                    }

                    const topMissedTopics = countTopicsAndFindTopTen(allIncorrectQuestions);
                    setTopMissedTopics(topMissedTopics);

                } else {
                    console.error("Received empty or invalid data:", response.data);
                }
            })
            .catch(function (error) {
                console.error("Error fetching ACT scores:", error);
                alert("Something went wrong while fetching ACT scores.");
            });
    };

    useEffect(() => {
        latest_ACT();
    }, [user]); // Call when `user` changes


    const handleClose = () => {
        setIsVisible(false);
    };

    function findTopMissedTopics(data, tag) {
        const topicCount = {};

        // Count incorrect topics for the specified tag
        data.forEach(item => {
            if (item.tag === tag && !item.correct) {
                topicCount[item.topic] = (topicCount[item.topic] || 0) + 1;
            }
        });

        // Convert the topicCount object into an array of [topic, count] pairs
        const sortedTopics = Object.entries(topicCount).sort((a, b) => b[1] - a[1]);

        // Select the top 10
        return sortedTopics.slice(0, 10).map(entry => ({ topic: entry[0], count: entry[1] }));
    }

    const options3 = {
        scales: {
            y: {
                beginAtZero: false,  // Change this as needed
                suggestedMax: 36  // Assuming SAT scores are out of 1600
            }
        },
        maintainAspectRatio: false
    };


    return (
        <div className="bg-white">
            <ModalComponent topic={topic} isOpen={isModalOpen2} onClose={setIsModalOpen2} section={section} />
            <div className="p-6 flex flex-col items-center">
                <div className="w-full max-w-[100%]">
                    <div className="mb-4 text-gray-500 text-sm">
                        <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">Digital ACT View</span>
                    </div>
                    <h1 className="text-2xl font-bold mt-4">Digital ACT View</h1>

                    {isVisible && (
                        <div className="p-6 bg-white shadow-md rounded-md">
                            <button
                                onClick={handleClose}
                                className="text-gray-600 hover:text-gray-800 ml-[99%]"
                            >
                                ✖
                            </button>
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <div className="bg-indigo-500 rounded-full p-4">
                                        <RiChatSmileFill className="text-white text-4xl" />
                                    </div>
                                </div>
                                {user && (
                                    <div>
                                        <div className="text-lg font-medium text-black">
                                            Welcome <span className="gradient-text font-bold">{user.first_name}</span> <span role="img" aria-label="wave">👋</span>
                                        </div>
                                        <div className="text-gray-500">{user.email}</div>
                                        <div className="mt-2 text-gray-600">
                                            Study hard and aim for a <span className="font-semibold text-black">36 </span> on the <span className="text-red-500 font-semibold">ACT!</span> <span role="img" aria-label="gift">🎁</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="pl-6 pr-6">
                <div className="bg-slate-900 text-white p-6 rounded-lg shadow-md flex flex-col">
                    <div className="flex items-center mb-4">
                        <span className="bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital ACT</span>
                        <span className="text-lg font-medium">Your Latest ACT Score</span>
                    </div>
                    <div className="flex justify-between mb-8">
                        {subjects.map((subject, index) => {
                            return (
                                <div key={index} className="text-center mt-2">
                                    <Doughnut
                                        data={getChartData(scores[subject.toLowerCase()])}
                                        options={{
                                            cutout: '80%',
                                            plugins: {
                                                tooltip: { enabled: false },
                                                legend: { display: false },
                                            },
                                        }}
                                        className="w-24 h-24 mx-auto"
                                    />
                                    <div className="mt-2 text-lg">
                                        <p className="gradient-text inline-block">{subject}:</p>
                                        <p className="inline-block text-white font-bold ml-1">
                                            {scores[subject.toLowerCase()]} /36
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <span className="gradient-text">
                        Check if you have an unfinished test. Complete the test to get your analytics report.
                    </span>
                    <div className="flex gap gap-2">
                        <button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 rounded-md mb-2" onClick={() => { setdashboard(5); }}>
                            Continue the Test
                        </button>
                        <button className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 rounded-md mb-2" onClick={() => { setdashboard(201); }}
                        >
                            Get Help Session
                        </button>
                    </div>
                </div>
            </div>

            <div className="mt-6 pl-6 pr-6 flex flex-col items-center">
                {/* First Row of Charts */}
                <div className="w-full flex justify-between gap-2" style={{ maxHeight: '330px' }}>
                    {subjects.map((subject, index) => (
                        <div key={index} className="flex-1 relative bg-white p-4 rounded">
                            <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                                <div className="h-full w-full bg-white rounded-xl"></div>
                            </div>
                            <div className="relative">
                                <h2 className="text-lg font-semibold mb-2">Average {subject} Score</h2>
                                <div style={{ height: '180px' }}>
                                    <Doughnut
                                        data={getChartData2(index + 1)}  // Plus 1 because subjects array is 0-based and subjectMap is 1-based
                                        options={{
                                            maintainAspectRatio: false,
                                            aspectRatio: 1,
                                        }}
                                    />
                                </div>
                                <div className="text-center mt-2">
                                    <span className="text-2xl font-bold">
                                        {calculateAverageBySubject(index + 1).toFixed(1)} / 36
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <div className="mt-6 pl-6 pr-6 flex flex-col items-center">
                <div className="w-full">
                    <div className="p-6 bg-gradient-to-br from-gray-800 to-gray-900 text-white shadow-md rounded-md flex flex-col justify-between">
                        <div>
                            <div className="flex items-center mb-4">
                                <span className="bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital SAT</span>
                                <span className="text-xl font-bold">🏆 Recommend Missing Math Topics</span>
                            </div>
                            <div className="flex-7 p-4 rounded overflow-auto" style={{ maxHeight: '240px', overflowY: 'auto' }}>
                                <ul className="list-disc pl-5">
                                    {topMissedTopics.map((topic, index) => (
                                        <li key={index}
                                            className="mb-1 text-xl cursor-pointer hover:bg-gray-300"
                                            onClick={() => handleRowClick(topic.topic, "English")}
                                            style={{ transition: 'background-color 0.3s' }}
                                        >
                                            {topic.topic}: {topic.count}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative flex-3 p-6 bg-white pr-6 pl-6 mr-6 ml-6 mb-[100px] shadow rounded mt-6">
                <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                    <div className="h-full w-full bg-white rounded-xl"></div>
                </div>

                <div className="relative">
                    <h2 className="text-lg font-semibold mb-2">Overall SAT Scores</h2>

                    {/* Tabs for selecting the subject */}
                    <div className="flex border-b">
                        {subjects.map((subject, index) => (
                            <button
                                key={index}
                                onClick={() => setActiveSubject(subject.toLowerCase())}
                                className={`py-2 px-4 text-sm font-medium ${activeSubject === subject.toLowerCase() ? 'border-blue-500 text-blue-500 border-b-2' : 'text-gray-500 hover:text-gray-700'}`}
                            >
                                {subject}
                            </button>
                        ))}
                    </div>


                    {/* Line Graph Display */}
                    <div className="h-full flex flex-col justify-center items-center">
                        <div className="w-full h-64 flex justify-center items-center">
                            <Line data={lineChartData} options={options3} />
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default DigitalActView;
