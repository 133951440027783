import { useState, useEffect, useContext, useRef } from "react";
import { useStopwatch } from "react-timer-hook";
import DevQuestion from "./MathQuestion";
import axios from "axios";
import { motion } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import { BASELINE } from "../../util";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from 'react-select'
import NavBar from "./navbar";

const DevQuizAPCALC = () => {
  const {
    seconds,
    minutes,
    start
  } = useStopwatch();

  const boxItemLeftRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [error, setError] = useState('');
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [status, setStatus] = useState(null);
  const [q_id, setQ_id] = useState(null);
  const [note, setNote] = useState("");
  const [ans, setans] = useState(null);
  const [raion, setRaion] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [selectopic, settopic] = useState('');
  const [selectskills, setskills] = useState([]);
  const [format, setformat] = useState(null);

  const [state, setState] = useState(true);
  const [sat, setsat] = useState(true);

  const [devmode, setdevmode] = useState(false);
  const [testname, setTestname] = useState([]);

  const [code, setcode] = useState(false);
  const [group, setGroup] = useState({ content: "" });
  const [dumy, setdumy] = useState("");
  const [tags, setTags] = useState();
  const [check_tags, setcheck_tags] = useState(false);

  // 로컬 옵션 상태
  const [localOptions, setLocalOptions] = useState([]);

  const options = currentQuestion?.options ? (() => {
    try {
      return JSON.parse(currentQuestion.options);
    } catch (error) {
      console.error("Invalid JSON in currentQuestion.options:", error);
      return [];
    }
  })() : [];

  // 현재 질문이 바뀔 때마다 localOptions 초기화
  useEffect(() => {
    if (options.length > 0) {
      const trimmed = options.map(opt => opt.slice(3).trimStart());
      setLocalOptions(trimmed);
    } else {
      setLocalOptions([]);
    }
  }, [currentQuestion]);

  // 로컬 옵션 수정 핸들러
  const handleLocalOptionChange = (index, newOptionText) => {
    const updatedOptions = [...localOptions];
    updatedOptions[index] = newOptionText;
    setLocalOptions(updatedOptions);

    // 실시간 업데이트를 위해 currentQuestion도 바로 반영
    const finalOptions = updatedOptions.map((opt, i) => `(${String.fromCharCode(65 + i)}) ${opt}`);
    setCurrentQuestion(prev => ({
      ...prev,
      options: JSON.stringify(finalOptions)
    }));
  };

  const fetchTestData = async () => {
    try {
      if (currentQuestion?.group_id) {
        const res = await axios.get(`${BASELINE}ap/group`, {
          params: { g_id: currentQuestion.group_id }
        });
        setGroup(res.data[0]);
      } else {
        setGroup({ content: "" });
      }
    } catch (error) {
      console.error("Error fetching apcalc tests:", error);
    }
  };

  useEffect(() => {
    fetchTestData();
    if (currentQuestion && currentQuestion.rationale === null && currentQuestion.step_by_step === null) {
      const optionsCount = currentQuestion.options ? JSON.parse(currentQuestion.options).length : 0;
      const dummyArray = Array.from({ length: optionsCount }, (_, idx) => [`example${idx + 1}`]);
      setdumy(JSON.stringify(dummyArray));
    }
  }, [currentQuestion, setTestname]);

  // KaTeX 로딩 스크립트
  useEffect(() => {
    const script2 = document.createElement('script');
    script2.src = "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js";
    script2.integrity = "sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05";
    script2.crossOrigin = "anonymous";
    script2.defer = true;

    script2.onload = () => {
      // 스크립트 로드 후 렌더링 한 번
      if (boxItemLeftRef.current && window.renderMathInElement) {
        window.renderMathInElement(boxItemLeftRef.current, {
          delimiters: [
            { left: '\\(', right: '\\)', display: false },
            { left: '\\[', right: '\\]', display: true },
            { left: '$$', right: '$$', display: false },
            { left: "$", right: "$", display: false },
          ],
          throwOnError: false,
        });
      }
    };
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script2);
    };
  }, []);

  // currentQuestion, localOptions 변화 시마다 수식 재렌더링
  useEffect(() => {
    if (boxItemLeftRef.current && window.renderMathInElement) {
      window.renderMathInElement(boxItemLeftRef.current, {
        delimiters: [
          { left: '\\(', right: '\\)', display: false },
          { left: '\\[', right: '\\]', display: true },
          { left: '$$', right: '$$', display: false },
          { left: "$", right: "$", display: false },
        ],
        throwOnError: false,
      });
    }
  }, [currentQuestion, localOptions, group]);

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, bookMarked: !question.bookMarked };
        } else {
          return question;
        }
      })
    }))
  }

  async function getAPCALCquiz() {
    try {
      let res = await axios.get(BASELINE + "get/ap/test", {});
      setTestname(res.data);
    } catch (error) {
      console.error("Error fetching apcalc tests:", error);
    }
  }

  useEffect(() => {
    getAPCALCquiz();

    if (isNaN(id)) {
      setcode(true);
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestionsbyid();
      setformat(null);
      setdevmode(true);
    } else {
      setcode(false);
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestions();
      setformat(null);
      setdevmode(false);
    }
  }, [id])

  useEffect(() => {
    if (questions.length > 0 && currentQuestion) {
      let q = questions.find((ques => ques?.id === currentQuestion?.id));
      setQ_id(q.id);
      setCurrentQuestion(q);
    }
  }, [questions])

  async function handleCrossOutOptions(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }
  async function handleAnswerValueChange(itemId, value) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, selectedOption: value };
        } else {
          return question;
        }
      })
    }))
  }

  async function getQuestionsbyid() {
    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "questions/ap/code", { params: { id: id } });
      if (res.data.length < 1) {
        toast.error("Questions not found.")
      } else {
        start();
      }
      let arr = [res.data];
      let mappedQuestions = arr[0].map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });

      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
      setans(arr[0].correct_answer);
      setRaion(mappedQuestions[0].rationale);

      if (Array.isArray(arr) && arr.length > 0) {
        if (arr[0].practice_category_primary_section) {
          settopic(arr[0].practice_category_primary_section);
        }
        if (arr[0].skills) {
          setskills(arr[0].skills.split(","));
        }
      }
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  async function getQuestions() {
    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "questions/ap", { params: { id: [id] } });
      if (res.data.length < 1) {
        toast.error("Questions not found.")
      } else {
        start();
      }
      let arr = [res.data]
      let mappedQuestions = arr[0].map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });

      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
      setans(arr[0].correct_answer);
      setRaion(mappedQuestions[0].rationale);

      if (Array.isArray(arr) && arr.length > 0) {
        if (arr[0].practice_category_primary_section) {
          settopic(arr[0].practice_category_primary_section);
        }
        if (arr[0].skills) {
          setskills(arr[0].skills.split(","));
        }
      }
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  const handleNext = () => {
    const nextId = Number(id) + 1;
    navigate(`/devapcalc/${nextId}`);
  }

  const handleBack = () => {
    const nextId = Number(id) - 1;
    navigate(`/devapcalc/${nextId}`);
  }

  const handleNext2 = () => {
    if (currentQuestion.index < questions.length - 1) {
      setCurrentQuestion(questions[currentQuestion.index + 1]);
    }
  };

  const handleBack2 = () => {
    if (currentQuestion.index > 0) {
      setCurrentQuestion(questions[currentQuestion.index - 1]);
    }
  };

  const handleflag = () => {
    var data = {
      q_id: q_id,
      flag: !currentQuestion.flag
    };

    axios.post(BASELINE + 'ap/flag', [data])
      .then((response) => {
        setCurrentQuestion((prev) => ({
          ...prev,
          flag: !currentQuestion.flag,
        }));
      })
      .catch((error) => {
        //  console.log(error);
      });

  };

  const handleOptionClick = (option) => {
    if (option === "Pass") {
      const userConfirmed = window.confirm('Are you sure you want to remove this?');

      if (userConfirmed) {
        var data = {
          q_id: q_id,
        };

        axios.post(BASELINE + 'questions/remove/note/byqid', [data])
          .then((response) => {
            alert("Note Removed");
          })
          .catch((error) => {
            //  console.log(error);
          });
      }
    }
    setStatus(option);
  };

  const handleUpdate = async () => {
    var updatedGroup = {
      content: group,
      group_id: currentQuestion.group_id
    };

    try {
      const response = await axios.post(BASELINE + "update/ap/questionids", {
        currentQuestion,
        group: updatedGroup,
        tags: JSON.stringify(tags)
      });

      if (response.status === 200) {
        toast.success("Question updated successfully!");
      } else {
        toast.error("Failed to update question.");
      }
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Error updating question.");
    }
  };

  const handleupdatetag = async () => {
    setcheck_tags(false);
    var updatedGroup = {
      content: group,
      group_id: currentQuestion.group_id
    };

    try {
      const response = await axios.post(BASELINE + "update/ap/questionids", {
        currentQuestion,
        group: updatedGroup,
        tags: JSON.stringify(tags)
      });

      if (response.status !== 200) {
        toast.error("Failed to update question.");
      }
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Error updating question.");
    }
  };

  useEffect(() => {
    if (currentQuestion && check_tags === true) {
      handleupdatetag();
    }
  }, [tags]);

  const handlerationale = (newContent) => {
    setCurrentQuestion((prev) => ({
      ...prev,
      rationale: newContent,
    }));
  };

  const handlestepbystep = (newContent) => {
    setCurrentQuestion((prev) => ({
      ...prev,
      step_by_step: newContent,
    }));
  };

  const handlePrompt2 = (newContent) => {
    setGroup(prevGroup => ({ ...prevGroup, content: newContent }));
  };

  const handleprompt = (newContent) => {
    setCurrentQuestion((prev) => ({
      ...prev,
      content: newContent,
    }));
  };

  const handlestimulus = (newContent) => {
    setCurrentQuestion((prev) => ({
      ...prev,
      stimulus: newContent,
    }));
  };

  return (
    <div className="fbg-white" ref={boxItemLeftRef}> {/* boxItemLeftRef 전체 감싸기 */}
      <NavBar
        index={currentQuestion?.index + 1}
        questions={questions}
        code={code}
        testIdOptions={testname}
        qidOptions={id}
        handleTestIdChange={(value) => console.log('Selected Test ID:')}
        handleSubjectChange={(value) => console.log('Selected Subject:')}
        handleQidChange={(value) => console.log('Selected ID:')}
        currentQuestion={currentQuestion}
        handleNext={handleNext}
        handleBack={handleBack}
        handleNext2={handleNext2}
        handleBack2={handleBack2}
        handleOptionClick={handleOptionClick}
        handleUpdate={handleUpdate}
        handleflag={handleflag}
        setQuestions={setQuestions}
        tags={tags}
        setTags={setTags}
        handleupdatetag={handleupdatetag}
        setcheck_tags={setcheck_tags}
      />

      {loadingQuestions ?
        <div className="flex flex-row justify-center items-center py-48">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-52 w-52"></div>
        </div>
        :
        <>

          {currentQuestion ? <>
            <DevQuestion
              ans={ans}
              sat={sat}
              format={format}
              q_id={q_id}
              id={id}
              handleAnswerValueChange={handleAnswerValueChange}
              handleCrossOutOptions={handleCrossOutOptions}
              currentQuestion={currentQuestion}
              idx={1}
              bookmarkedQuestions={bookmarkedQuestions}
              setBookMarkedQuestions={setBookMarkedQuestions}
              handleBookMark={handleBookMark}
              group={group}
              setCurrentQuestion={handlestepbystep}
              handlerationale={handlerationale}
              dumy={dumy}
            />

            <div className="flex flex-col justify-start w-[50%]">
              <div>
                <textarea
                  value={group.content}
                  onChange={(e) => handlePrompt2(e.target.value)}
                  className={`w-full ${group.content ? 'h-[500px]' : 'h-[20px]'} p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300`}
                  placeholder="Edit question content here"
                />
              </div>
            </div>
            <div className="flex z-[10] mt-[10px]">
              <div className="flex flex-col justify-start w-[50%]">
                <div>
                  <textarea
                    value={currentQuestion.content || ""}
                    onChange={(e) => handleprompt(e.target.value)}
                    className={`w-full ${currentQuestion.content ? 'h-[500px]' : 'h-[20px]'} p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300`}
                    placeholder="Edit question content here"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 my-4 w-[50%]">
                {localOptions.map((option, idx) => (
                  <div key={idx}>
                    <p className="mt-2">Answer {String.fromCharCode(65 + idx)}:</p>
                    <textarea
                      className="border-[1px] border-gray-300 p-2 w-full"
                      value={option}
                      onChange={(e) => handleLocalOptionChange(idx, e.target.value)}
                      placeholder={`Edit option ${String.fromCharCode(65 + idx)}`}
                    />
                  </div>
                ))}

                <p className="mt-2">Correct Answer:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.answer || ""}
                  onChange={(e) => {
                    const newAnswer = e.target.value;
                    setCurrentQuestion(prev => ({
                      ...prev,
                      answer: newAnswer 
                    }));
                  }}
                  placeholder="Edit correct answer"
                />
              </div>
            </div>

            <p className="font-bold">Question Step_by_step</p>
            <div className=" flex z-[10] mt-[10px] gap-2 p-2">
              <div className="w-full">
                <textarea
                  value={currentQuestion.step_by_step || ""}
                  onChange={(e) => handlestepbystep(e.target.value)}
                  className="h-[400px] w-full p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] p-2"
                  placeholder="Edit step by step explanation here"
                />
              </div>
            </div>
          </> : <></>}

          <div className="flex flex-row justify-around  border-t-2 border-black pt-4 relative">
            {devmode === false && (
              <div className="mb-2 flex flex-row items-center gap-2 text-white">
                <button
                  className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => window.location.reload()} 
                >
                  Reset
                </button>
                <button className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => handleUpdate()}
                >Update</button>
                <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleBack}>
                  Back
                </motion.button>
                {currentQuestion ?
                  <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleNext}>
                    Next
                  </motion.button>
                  :
                  <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800">
                    Done
                  </motion.button>
                }
              </div>
            )}

          </div>
        </>
      }
    </div >
  );
};
export default DevQuizAPCALC;
